// **************************** NAVIGATION ACTION TYPES **************************** //

export const FETCH_SELECTED_NAV_INDEX = 'FETCH_SELECTED_NAV_INDEX';
export const UPDATE_SELECTED_NAV_INDEX = 'UPDATE_SELECTED_NAV_INDEX';

// ************************** AUTHENTICATION ACTION TYPES ************************** //

export const LOGIN = 'LOGIN';
export const LOGIN_WITH_SSO_TOKEN = 'LOGIN_WITH_SSO_TOKEN';
export const GENERATE_SAMPLE_SSO_AUTHENTICATION_TOKEN = 'GENERATE_SAMPLE_SSO_AUTHENTICATION_TOKEN';
export const LOGOUT = 'LOGOUT';

// ****************************** MEMBER ACTION TYPES ****************************** //

export const VENDOR_GET_TERMINATED_VP_NAMES_MEMBER = 'MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB';
export const GET_PRODUCT_CATEGORY_FOR_MEMBER = 'GET_PRODUCT_CATEGORY_FOR_MEMBER';

// MEMBER OVERVIEW
export const MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB = 'MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB';
export const MEMBER_PURCHASE_WITHIN_IMARK = 'MEMBER_PURCHASE_WITHIN_IMARK';
export const MEMBER_PURCHASE_OUTSIDE_IMARK = 'MEMBER_PURCHASE_OUTSIDE_IMARK';
export const MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY = 'MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY';
export const VENDOR_FULL_INFO = 'VENDOR_FULL_INFO';
export const MEMBER_PRODUCT_CATEGORY = 'MEMBER_PRODUCT_CATEGORY';

// MEMBER VERIFIED
export const MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB = 'MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB';
export const MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES = 'MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES';
export const MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH = 'MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH';

// MEMBER INVOICES
export const MEMBER_GET_INVOICE_DATA = 'MEMBER_GET_INVOICE_DATA';

// MEMBER PURCHASE ORDERS
export const MEMBER_GET_PURCHASED_ORDERS = 'MEMBER_GET_PURCHASED_ORDERS';

// MEMBER CONVERSION OPPORTUNITY
export const MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS = 'MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS';
export const MEMBER_GET_TERMINATED_VP_OPPORTUNITIES = 'MEMBER_GET_TERMINATED_VP_OPPORTUNITIES';

// ****************************** CORPORATE ACTION TYPES ****************************** //

// CORPORATE OVERVIEW
export const CORPORATE_GET_CARDS_DATA_FOR_OVERVIEW_DATA_TAB = 'CORPORATE_GET_CARDS_DATA_FOR_OVERVIEW_DATA_TAB';
export const CORPORATE_GET_OVERVIEW_VENDOR_PERFORMANCE_DATA = 'CORPORATE_GET_OVERVIEW_VENDOR_PERFORMANCE_DATA';
export const CORPORATE_GET_OVERVIEW_MEMBER_PERFORMANCE_DATA = 'CORPORATE_GET_OVERVIEW_MEMBER_PERFORMANCE_DATA';
export const CORPORATE_GET_OVERVIEW_GROWTH_DATA = 'CORPORATE_GET_OVERVIEW_GROWTH_DATA';
export const CORPORATE_GET_OVERVIEW_GRAPH_DATA = 'CORPORATE_GET_OVERVIEW_GRAPH_DATA';
export const CORPORATE_GET_OVERVIEW_VP_INVOICE = 'CORPORATE_GET_OVERVIEW_VP_INVOICE';
export const CORPORATE_GET_OVERVIEW_MEMBER_INVOICE = "CORPORATE_GET_OVERVIEW_MEMBER_INVOICE";

// CORPORATE VENDOR DATA
export const CORPORATE_GET_CARDS_DATA_FOR_VENDOR_DATA_TAB = 'CORPORATE_GET_CARDS_DATA_FOR_VENDOR_DATA_TAB';
export const CORPORATE_GET_VENDOR_DATA_GROWTH_DATA = 'CORPORATE_GET_VENDOR_DATA_GROWTH_DATA';
export const CORPORATE_SUPPLIER_FILTERS = 'CORPORATE_SUPPLIER_FILTERS';

// CORPORATE OPPORTUNITY
export const CORPORATE_GET_CARDS_DATA_FOR_OPPORTUNITY_TAB = 'CORPORATE_GET_CARDS_DATA_FOR_OPPORTUNITY_TAB';
export const CORPORATE_OPPORTUNITY_MEMBER = 'CORPORATE_OPPORTUNITY_MEMBER';
export const CORPORATE_OPPORTUNITY_VENDOR = 'CORPORATE_OPPORTUNITY_VENDOR';
export const CORPORATE_OPPORTUNITY_PRODUCT_GROUP = 'CORPORATE_OPPORTUNITY_PRODUCT_GROUP';
export const CORPORATE_OPPORTUNITY_SALES_CHART_DATA = 'CORPORATE_OPPORTUNITY_SALES_CHART_DATA';
export const CORPORATE_OPPORTUNITY_VENDOR_LIST = "CORPORATE_OPPORTUNITY_VENDOR_LIST";
export const CORPORATE_OPP_FILTERS = "CORPORATE_OPP_FILTERS";
export const CORPORATE_OPPORTUNITY_REGION = "CORPORATE_OPPORTUNITY_REGION";

// CORPORATE COMPLIANCE
export const CORPORATE_COMPLIANCE_FILTERS = 'CORPORATE_COMPLIANCE_FILTERS';
export const CORPORATE_COMPLIANCE_MEMBER = 'CORPORATE_COMPLIANCE_MEMBER';
export const CORPORATE_COMPLIANCE_VENDOR = 'CORPORATE_COMPLIANCE_VENDOR';

// CORPORATE CONVERSION
export const CORPORATE_CONVERSION_FILTERS = 'CORPORATE_CONVERSION_FILTERS';
export const CORPORATE_CONVERSION_MEMBER = 'CORPORATE_CONVERSION_MEMBER';
export const CORPORATE_CONVERSION_PRODUCT_GROUP = "CORPORATE_CONVERSION_PRODUCT_GROUP";
export const CORPORATE_CONVERSION_PO_DETAILS = 'CORPORATE_CONVERSION_PO_DETAILS';
export const CORPORATE_CONVERSION_OPPORTUNITY_OVERVIEW_DETAILS = 'CORPORATE_CONVERSION_OPPORTUNITY_OVERVIEW_DETAILS';
export const CORPORATE_CONVERSION_OPPORTUNITY_VENDOR_LIST = 'CORPORATE_CONVERSION_OPPORTUNITY_VENDOR_LIST';
export const CORPORATE_CONVERSION_PRODUCT_CATEGORY_LIST = 'CORPORATE_CONVERSION_PRODUCT_CATEGORY_LIST';
export const CORPORATE_CONVERSION_FAV_VENDOR_LIST = 'CORPORATE_CONVERSION_FAV_VENDOR_LIST';
export const CORPORATE_CONVERSION_PRODUCTS = 'CORPORATE_CONVERSION_PRODUCTS';
export const CORPORATE_CONVERSION_TOP_TEN_MEMBER = 'CORPORATE_CONVERSION_TOP_TEN_MEMBER';
export const CORPORATE_CONVERSION_TOP_TEN_PRODUCT_GROUP = 'CORPORATE_CONVERSION_TOP_TEN_PRODUCT_GROUP';

// CORPORATE FEEDBACK
export const CORPORATE_FEEDBACK_WRONG_CATEGORY = 'CORPORATE_FEEDBACK_WRONG_CATEGORY';
export const CORPORATE_FEEDBACK_FAV_SUPPLIER = 'CORPORATE_FEEDBACK_FAV_SUPPLIER';
export const CORPORATE_FEEDBACK_PRODUCT_NOT_SOLD = 'CORPORATE_FEEDBACK_PRODUCT_NOT_SOLD';
export const CORPORATE_FEEDBACK_IN_VENDOR = 'CORPORATE_FEEDBACK_IN_VENDOR';
export const CORPORATE_FEEDBACK_OUT_VENDOR = 'CORPORATE_FEEDBACK_OUT_VENDOR';
export const CORPORATE_FEEDBACK_BUY_SELL_VENDOR = 'CORPORATE_FEEDBACK_BUY_SELL_VENDOR';
