import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';

export default class InfoModal extends Component {
    render() {
        let { show, selectedSupplier, handleClose } = this.props;
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    Contact Info for {selectedSupplier.vendorName}
                </Modal.Header>
                <Modal.Body>
                    <div className="supplierInfo" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 6fr)' }}>
                        <div>
                            <p>Supplier ID</p>
                            <p>Supplier Name</p>
                            <p>Contact Address</p>
                            <p>Year</p>
                            <p>Phone</p>
                            <p>Email</p>
                            <p>Contact Person</p>
                        </div>
                        <div>
                            <p>{!selectedSupplier.vendorId ? '-' : selectedSupplier.vendorId}</p>
                            <p>{!selectedSupplier.vendorName ? '-' : selectedSupplier.vendorName}</p>
                            <p>{!selectedSupplier.address ? '-' : selectedSupplier.address}</p>
                            <p>{!selectedSupplier.year ? '-' : selectedSupplier.year }</p>
                            <p>{!selectedSupplier.phone ? '-' : selectedSupplier.phone}</p>
                            <p>{!selectedSupplier.email ? '-' : selectedSupplier.email}</p>
                            <p>{!selectedSupplier.contactPerson ? '-' : selectedSupplier.contactPerson}</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
