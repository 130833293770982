import { ProgressBar } from 'react-bootstrap';

export const priceFormatter = (cell, row) => {
    return (
        <span>{cell ? Number(cell).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 }) : '-'}</span>
    );
}

export const opportunityFormatter = (cell, row) => {
    return (
        <span>
            <ProgressBar now={cell} />
        </span>
    );
}

export const convertJSONToURLParams = (selections) => {
    let searchParams = new URLSearchParams();

    for(let key in selections) {
        searchParams.append(key, selections[key])
    }

    return searchParams.toString();
}

export const formatYOY = (value, lastYearValue) => {
    if(value && lastYearValue) {
        let yoyValue = (value - lastYearValue) / lastYearValue;
        return Number(yoyValue * 100).toFixed(2);
    } else {
        return 'NA';
    }
}

export const dateFormatter = (date) => {
    return date && new Date(date).toLocaleString('en-US').split(',')[0];
}



export const switchMonths = (value) => {
    let month = value === 0 ? value + 1 : value;
    switch(month) {
        case 1:
            return "Jan";
        case 2:
            return "Feb";
        case 3:
            return "Mar";
        case 4:
            return "Apr";
        case 5:
            return "May";
        case 6:
            return "Jun";
        case 7:
            return "Jul";
        case 8:
            return "Aug";
        case 9:
            return "Sep";
        case 10:
            return "Oct";
        case 11:
            return "Nov";
        case 12:
            return "Dec";
        default:
            return;
    }
}

export const switchGradients = (tier) => {
    switch(tier) {
        case 1:
            return "#000066";
        case 2:
            return "#000099";
        case 3:
            return "#0000b3";
        case 4:
            return "#0000cc";
        case 5:
            return "#0000e6";
        case 6:
            return "#0000ff";
        case 7:
            return "#1a1aff";
        case 8:
            return "#3333ff";
        case 9:
            return "#4d4dff";
        case 10:
            return "#6666ff";
        default:
            return null;
    }
}