import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';
import ConversionOpportunityFilters from './ConversionOpportunityFilters/ConversionOpportunityFilters';
import ConversionOpportunityPODetails from './ConversionOpportunityPODetails/ConversionOpportunityPODetails';
import ConversionOpportunityTable from './ConversionOpportunityTable/ConversionOpportunityTable';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
// import { convertJSONToURLParams } from '../../utils/formatters';

import {
    updateSelectedNavIndex,
    // getTerminatedVPOpportunitiesDetails,
    // getTerminatedVPOpportunities 
} from '../../../actions';
import {
    getTerminatedVPOpportunitiesDetails,
    getConversionMonthsFilter,
    getTerminatedVPOpportunities,
    getConversionOpportunityVendorList,
    getTerminatedVPNamesForMember
} from '../../../api/member-api';

class MemberConversionOpportunity extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            isLoaded: false,
            isContentLoaded: false,
            year: currentDate.getFullYear(),
            month: "",
            period: "YTD",
            quarter: "1",
            vendorId: "",
            terminatedSupplierId: "",
            terminatedSupplierName: "",
            conversionOpportunityVendorList: [{vendorId: 0, vendorName: 'All'}],
            conversionOpportunityPODetails: [],
            conversionOpportunity: [],
            terminatedVPNamesForMember: [],
            conversionMonthsFilter: {},
            listOfYears: [],
            listOfMonthsForSelectedYear: [],
            isQuarterSelected: false
        }
    }

    static getDerivedStateFromProps(props) {
        console.log('Member ID in Member Component', props.match.params.id);
        return { memberId: props.match.params.id };
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);
        
        this.loadData();
    }

    componentDidUpdate(pP, pS, sS) {
        if(pS.year !== this.state.year) {
            this.getConversionOpportunityData();
        }

        if(pS.month !== this.state.month) {
            this.getConversionOpportunityData();
        }

        if(pS.period !== this.state.period) {
            this.getConversionOpportunityData();
        }

        if(pS.quarter !== this.state.quarter) {
            this.getConversionOpportunityData();
        }

        if(pS.terminatedSupplierId !== this.state.terminatedSupplierId && pS.terminatedSupplierName !== this.state.terminatedSupplierName) {
            this.getConversionOpportunityData();
        } 
    }

    loadData = () => {
        this.mounted = true;

        // const selections = convertJSONToURLParams(formData);
        // this.props.getTerminatedVPOpportunitiesDetailsAPI(selections)
        // this.props.getTerminatedVPOpportunitiesAPI(selections)

        Promise.allSettled([
            getConversionOpportunityVendorList({ memberId: this.state.memberId }),
            getConversionMonthsFilter({ memberId: this.state.memberId })
        ])
        .then((allResponses) => {
            console.log('MEMBER-CONVERSION ON MOUNT ==>', allResponses);

            if(this.mounted) {
                if(allResponses[0].value) {
                    let conversionOpportunityVendorList = this.state.conversionOpportunityVendorList.concat(allResponses[0].value)
                    this.setState({
                        conversionOpportunityVendorList
                    })
                }
                if(allResponses[1].value) {
                    const availableYears = Object.keys(allResponses[1].value).sort((a, b) => b - a);

                    this.setState((prevState) => ({
                        conversionMonthsFilter: allResponses[1].value && allResponses[1].value,
                        listOfYears: allResponses[1].value && Object.keys(allResponses[1].value),
                        // year: allResponses[1].value && Object.keys(allResponses[1].value)[0]
                        params: {
                            ...prevState.params,
                            // year: allResponses[0].value && Object.keys(allResponses[0].value)[0]
                        }
                    }), () => {
                    this.getListOfMonths(allResponses[1].value ,availableYears[0]);
                    })
                }
            }

            this.setState({ isLoaded: true })
        })
        .catch((err) => {
            console.log(err);
        })
    }

    getConversionOpportunityData = () => {
        this.mounted = true;

        this.setState({ isContentLoaded: true })

        const formData = {
            period: this.state.period,
            year: this.state.year,
            month: this.state.month,
            quarter: this.state.quarter,
            memberId: this.state.memberId,
            terminatedVendorId: this.state.terminatedSupplierId
        }

        Promise.allSettled([
            getTerminatedVPOpportunitiesDetails(formData),
            getTerminatedVPOpportunities(formData),
            getTerminatedVPNamesForMember()
        ])
        .then((allResponses) => {
            console.log('MEMBER-CONVERSION DATA ==>', allResponses)
            if(this.mounted) {
                if(allResponses[0].value) {
                    this.setState({ conversionOpportunityPODetails: allResponses[0].value ? allResponses[0].value : [] })
                }
                if(allResponses[1].value) {
                    this.setState({ conversionOpportunity: allResponses[1].value ? allResponses[1].value : [] })
                }
                if(allResponses[2].value) {
                    this.setState({ terminatedVPNamesForMember: allResponses[2].value ? allResponses[2].value : [] })
                }
            }

            this.setState({ isContentLoaded: false })
        })
        .catch((err) => console.log(err))
    }

    
    getListOfMonths = (allYears, year) => {
        for (const [key, value] of Object.entries(allYears)) {
            if(year == key) {
                this.setState({
                    listOfMonthsForSelectedYear: value,
                    month: value[value.length - 1],year
                })
            }
        }
        // if(this.state.listOfMonthsForSelectedYear.length==0){
        //     this.setState({
        //         listOfMonthsForSelectedYear: allYears["2020"],
        //         month: allYears["2020"][allYears["2020"].length - 1]
        //     })
        // }
    }

    handleYear = (e) => {
        this.setState({ year: e.target.value }, () => {
            this.getListOfMonths(this.state.conversionMonthsFilter, e.target.value);
        });
    }

    handleMonth = (e) => this.setState({ month: e.target.value });
    handlePeriod = (e) => this.setState({
        period: e.target.value,
        isQuarterSelected: e.target.value === "QTR" ? true : false,
        quarter: e.target.value === "MTH" || e.target.value === "YTD" ? "1" : "1"
    });
    handleQuarter = (e) => this.setState({ quarter: e.target.value });

    handleTerminatedSupplier = (option) => {
        let e = option.value
        if(e.vendorId!=0) {
            this.setState({
                terminatedSupplierId: e.vendorId,
                terminatedSupplierName: e.vendorName
            })
        } else {
            this.setState({
                terminatedSupplierId: "",
                terminatedSupplierName: ""
            })
        }
    }

    render() {
        let {
            isLoaded,
            isContentLoaded,
            year,
            month,
            period,
            quarter,
            // memberId,
            // terminatedSupplierId,
            // terminatedSupplierName,
            conversionOpportunityVendorList,
            conversionOpportunityPODetails,
            conversionOpportunity,
            terminatedVPNamesForMember,
            conversionMonthsFilter,
            listOfYears,
            listOfMonthsForSelectedYear,
            isQuarterSelected
        } = this.state;

        // let {
        //     conversionOpportunityPODetails,
        //     conversionOpportunity
        // } = this.props;

        return !isLoaded ? <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <Spinner animation="border" variant="primary" />
        </div> :
            <div>
                <ConversionOpportunityFilters
                    year={year}
                    month={month}
                    period={period}
                    quarter={quarter}
                    handleYearReference={this.handleYear}
                    handleMonthReference={this.handleMonth}
                    handlePeriodReference={this.handlePeriod}
                    handleQuarterReference={this.handleQuarter}
                    handleTerminatedSupplierReference={this.handleTerminatedSupplier}
                    conversionMonthsFilter={conversionMonthsFilter}
                    listOfYears={listOfYears}
                    listOfMonthsForSelectedYear={listOfMonthsForSelectedYear}
                    conversionOpportunityVendorList={conversionOpportunityVendorList}
                    conversionOpportunityPODetails={conversionOpportunityPODetails}
                    conversionOpportunity={conversionOpportunity}
                    isQuarterSelected={isQuarterSelected}
                    memberName={localStorage.getItem('groupName')}
                />
                {/* <pre style={{ marginLeft: 60 }}>{JSON.stringify({
                    // isLoaded,
                    // isContentLoaded,
                    memberId,
                    year,
                    month,
                    period,
                    quarter,
                    terminatedSupplierId,
                    terminatedSupplierName,
                    // conversionOpportunityPODetails,
                    // conversionOpportunity,
                    // conversionMonthsFilter: JSON.stringify(conversionMonthsFilter),
                    // listOfYears: JSON.stringify(listOfYears),
                    // listOfMonthsForSelectedYear: JSON.stringify(listOfMonthsForSelectedYear)
                }, null, 4)}</pre> */}
                {isContentLoaded ?
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                        minHeight: 1000
                    }}>
                        <Spinner animation="border" variant="primary" />
                    </div> :
                    <React.Fragment>
                        <ConversionOpportunityPODetails
                            conversionOpportunityPODetails={conversionOpportunityPODetails}
                        />
                        <ConversionOpportunityTable
                            conversionOpportunity={conversionOpportunity}
                            terminatedVPNamesForMember={terminatedVPNamesForMember}
                        />
                    </React.Fragment>
                }
            </div>
    }
}

function mapStateToProps(state) {
    return {
        conversionOpportunityPODetails: state.member.getTerminatedVPOpportunitiesDetails,
        conversionOpportunity: state.member.getTerminatedVPOpportunities
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,
        // getTerminatedVPOpportunitiesDetailsAPI: getTerminatedVPOpportunitiesDetails,
        // getTerminatedVPOpportunitiesAPI: getTerminatedVPOpportunities 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberConversionOpportunity);
