import React from 'react';
import ReactTable from "react-table-6";
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap';
import { priceFormatter } from '../../../../utils/formatters';
import Pagination from "../../../../Common/Pagination/Pagination";
import axios from 'axios';
import '../../../../assets/styles/PurchaseOrdersTable.scss';

export default class PurchaseOrdersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // purchaseOrders: [],
            searchKey: "",
            tag:"",
            numberOfRowsLoaded: 499,
            filteredSearchResults: [...this.props.purchaseOrders],
            isFilteredDataAvailable: true,
            memberId: sessionStorage.getItem('imarkElectricalMemberId')
        }
    }


    componentDidUpdate(pP, pS, sS) {
        if (this.props.purchaseOrders !== pP.purchaseOrders) {
            this.setState({
                isFilteredDataAvaiable: true,
                filteredSearchResults: this.props.purchaseOrders
            })
        }
    }
    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = this.props.purchaseOrders.filter((item) => {
            return (item.vendorName && item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) || (item.productDescription.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
                || (item.totalAmount.toString().indexOf(e.target.value.toLowerCase()) >= 0);
        })

        this.setState({
            isFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        tag: "",
        isFilteredDataAvailable: true,
        filteredSearchResults: [...this.props.purchaseOrders]
    });
    
    loadMoreData = () => {
        this.props.loadMoreData(this.state.numberOfRowsLoaded+1, 500);
        this.setState({numberOfRowsLoaded: this.state.numberOfRowsLoaded+500});
    }

    handleChange = async(event) => {
        // this.setState({ searchKey: event.target.value }, () =>
        //   this.globalSearch()
        // );
        console.log('memberId',this.state.memberId)
        let tag=event.target.value
        this.setState({ tag: event.target.value })
        console.log('memberId',tag)
        let result=await axios(`https://imark-electrical.tredence.com/imark-electrical/purchase-order/search?tag=${tag}&memberId=${this.state.memberId}`,
        {method: "GET",
        headers: {
           'mode': 'cors',
           'Access-Control-Allow-Origin': '*',
           'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken'),
       }}
    ).then((response) => {
        let srchData=response.data
        console.log('handle chnage serch data==>', response.data);
        return srchData

    })
    this.setState({
        filteredSearchResults: result
    });
      console.log('handle chnage click',result)

      };

    //   globalSearch = () => {
    //     let { searchKey } = this.state;
    // let filteredData =  this.props.purchaseOrders.filter(value => {
    //   return (
    //     value.vendorName.toLowerCase().includes(searchKey.toLowerCase()) ||
    //     value.productDescription.toLowerCase().includes(searchKey.toLowerCase()) ||
    //     value.totalAmount
    //       .toString()
    //       .toLowerCase()
    //       .includes(searchKey.toLowerCase())
    //   );
    // });
    // this.setState({filteredSearchResults:filteredData});
    // console.log('data',filteredData)
    //   };


    render() {
        let {
            // purchaseOrders,
            searchKey,
            filteredSearchResults,
            tag
        } = this.state;
        let { purchaseOrders } = this.props;

        return (
            <Card className="purchaseOrdersTableContainer">
                <Card.Header>Member Invoices</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search for member invoices info.."
                                aria-label="Search for member invoices info.."
                                aria-describedby="basic-addon2"
                                value={this.state.tag|| ""}
                                // onChange={(e) => this.searchTable(e, purchaseOrders)}
                                onChange={(e)=>this.handleChange(e)}
                            />
                            <InputGroup.Append>
                                <Button onClick={() => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <ReactTable
                        data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.purchaseOrders}
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>PO No.</b>,
                                accessor: "poNo",
                                sortable: true,
                                resizable: true,
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>PO Date</b>,
                                accessor: 'poDate',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Name</b>,
                                accessor: 'vendorName',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>IN/OUT Supplier</b>,
                                accessor: 'vendorIn',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { textAlign: 'center' },
                                Cell: props => <div style={{ textAlign: "center" }}>{!props.original.vendorIn ? "OUT" : "IN"}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Product Description</b>,
                                accessor: 'productDescription',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b>Amount</b>,
                                accessor: 'totalAmount',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { textAlign: 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.totalAmount)}</div>,
                            }
                        ]}
                        defaultPageSize={100}
                        PaginationComponent={Pagination}
                        loadMoreData={this.loadMoreData}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                    />
                </div>
            </Card>
        );
    }
}
