import React, { Component } from 'react';
import C3 from '../../C3/C3';
import { priceFormatter } from '../../../../utils/formatters';
import { Card } from 'react-bootstrap';

export default class TopTenProductGroups extends Component {
    productGroups = []
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { topTenProductGroups } = this.props;

        let purchases = [];
        //let productGroups = [];
        this.productGroups = [];

        if(topTenProductGroups) {
            topTenProductGroups.forEach(item => {
                purchases.push(item.purchases);
                this.productGroups.push(item.productGroupName);
            });
        }
        console.log(this.productGroups,"topTenProductGroups");
        if(this.productGroups.length>=1){
        const topTenProductGroupsChart = {
            data: {
                x: 'x',
                columns: [
                    [ 'x', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10' ],
                    [ 'Purchases', ...purchases ]
                ],
                keys: {
                    value: ["Purchases"],
                },
                colors: {
                    "Purchases": '#1f77b4'
                },
                type: 'bar',
                order: null,
                bar: {
                    width: {
                        ratio: 0.2
                    },
                },
                labels: {
                    format: function (d) { return priceFormatter(d).props.children; }
                },
                legend: {
                    show: true
                },
            },
            axis: {
                x: {
                    label: { text : 'Rank', position: 'inner-right' },
                    type: 'category',
                    height: 80
                },
                y: {
                    label: { text : 'Purchase Amount', position: 'outer-middle' } ,
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            },
            tooltip: {
                show: true,
                format: {
                    title: (d) => this.productGroups[d],
                    value: (value, ratio, id) => priceFormatter(value).props.children
                }
            },
        };

        return (
            <C3
                heading={"Top 10 Product Groups"}
                data={topTenProductGroupsChart.data}
                tooltip={topTenProductGroupsChart.tooltip}
                axis={topTenProductGroupsChart.axis}
            />
        )
    }
    
        else {
            return   <div className="">
            <Card>
                <Card.Header>Top 10 Product Groups</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10,height:'400px' }}>
                    <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>                    </div>    
                </div>
            </Card>
        </div>
        

        }
}
}
