import React from 'react'
import { Form, Col, Button } from 'react-bootstrap';
import ReactExport from "react-export-excel";

import '../../../../assets/styles/InvoiceFilters.scss';

export const InvoiceFilters = React.forwardRef((props, ref) => {
    let {
        invoices,
        suppliers,
        handleSelectedSupplierReference,
        handleInvoiceNoReference,
        handleStartDateReference,
        handleEndDateReference,
        handleSearchInvoicesReference,
        handleClearInvoiceNoReference
    } = props;

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <React.Fragment>
            <div className="invoiceFiltersContainer">
                <div className="filterTitle">
                    <p>{props.memberName}</p>
                </div>
                <div className="invoiceFilters">
                    <div className="invoiceFiltersRowOne">
                        <Form.Group as={Col} controlId="formSelectSupplier">
                            <Form.Control as="select" defaultValue="Select Supplier" onChange={(e) => handleSelectedSupplierReference(e)}>
                                <option value="">Select Supplier</option>
                                {suppliers.map((item, index) => {
                                    return <option key={index} value={`${item.vendorId}=${item.vendorName}`}>{item.vendorName}</option>
                                })}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="date1" onChange={(e) => handleStartDateReference(e)}>
                            <Form.Control type="date" name="date1" placeholder="Start Date" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="date2" onChange={(e) => handleEndDateReference(e)}>
                            <Form.Control type="date" name="date2" placeholder="End Date" />
                        </Form.Group>
                    </div>
                    <div className="invoiceFiltersRowTwo">
                        <Form.Group as={Col} controlId="formSearchInput" style={{ marginLeft: 10 }} className="invoiceSearch">
                            <Form.Control ref={ref} type="text" placeholder="Search Invoice No." style={{ fontSize: 12 }} onChange={(e) => handleInvoiceNoReference(e)} />
                        </Form.Group>
                        
                        <div className="invoiceFiltersButtons">
                            <Button style={{ height: 30 }} onClick={() => handleSearchInvoicesReference()}>Search</Button>
                            <Button style={{ height: 30, marginLeft: 2 }} onClick={(e) => handleClearInvoiceNoReference(e)}>Reset</Button>
                            <ExcelFile element={<Button style={{ fontSize: 12, height: 30, marginLeft: 2 }}>Export</Button>}>
                                <ExcelSheet data={invoices} name="Invoices">
                                    <ExcelColumn label="VENDOR NAME" value="vendorName"/>
                                    <ExcelColumn label="INVOICE NO" value="invoiceNo"/>
                                    <ExcelColumn label="PO NO" value="poNo"/>
                                    <ExcelColumn label="INVOICE DATE" value="invoiceDate"/>
                                    <ExcelColumn label="PRODUCT" value="productDesc"/>
                                    <ExcelColumn label="QUANTITY" value="quantity"/>
                                    <ExcelColumn label="PRICE/UNIT" value="pricePerUnit"/>
                                    <ExcelColumn label="AMOUNT" value="totalAmount"/>
                                </ExcelSheet>
                            </ExcelFile>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})
