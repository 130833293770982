import React from 'react';
import './Modal.css';
class Modal extends React.Component {
    state = {
        tableWidth: "",
        memberDataModalColumns: [
            { name: 'programName', title: 'PROGRAM NAME' },
            { name: 'rebateRecieved', title: 'REBATE RECIEVED' },
        ], memberDataModalRows: [
            {
                programName: 'All Categories Quarterly Flat VP1',
                rebateRecieved: '$30,783',
            },
            {
                programName: 'All Categories Quarterly Flat VP2',
                rebateRecieved: '$33,000',
            }
        ],
        memberDataModalColumnWidths: [
            { columnName: 'programName', width: 250 },
            { columnName: 'rebateRecieved', width: 200 }
        ],
        memberBHVerifiedDataModalColumns: [
            { name: 'programName', title: 'PROGRAM NAME' },
            { name: 'bHVerifiedRebateData', title: 'BH VERIFIED REBATE DATA' },
            { name: 'vPartnerRebateData', title: 'VENDOR PARTNER REBATE DATA' },
        ], memberBHVerifiedDataModalRows: [
            {
                programName: 'All Categories Quarterly Flat VP1',
                bHVerifiedRebateData: '$60,918.66',
                vPartnerRebateData: '$50,958'
            },
            {
                programName: 'All Categories Quarterly Flat VP2',
                bHVerifiedRebateData: '$56,000',
                vPartnerRebateData: '$51,954'
            }
        ],
        memberBHVerifiedDataModalColumnWidths: [
            { columnName: 'programName', width: 250 },
            { columnName: 'bHVerifiedRebateData', width: 250 },
            { columnName: 'vPartnerRebateData', width: 250 }
        ],
    }
    closeModal = () => {
        this.props.closeModal();

    }

    renderArrow() {
        if (this.props.propsForModal.growth === "positive") {
            return (<small className=" green">{this.props.propsForModal.growthAmount} <i className="fas fa-arrow-circle-up green"></i></small>

            )
        } else if (this.props.propsForModal.growth === "negative") {
            return (
                <small className=" red">{this.props.propsForModal.growthAmount} <i className="fas fa-arrow-circle-down red"></i></small>
            )
        }
    }




    renderModalType() {
        if (this.props.modalType === "card") {
            return (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>

                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className={this.props.propsForModal.icon}></i>{this.props.propsForModal.title}</h5>
                                <button onClick={(e) => { this.closeModal() }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p >{this.props.propsForModal.amount}<div style={{ float: "right" }}>YOY</div></p>
                                <hr />
                                <small className="updatedOn">Updated On <span className="imp">{this.props.propsForModal.date}</span></small>
                                <div className="figures" style={{ float: "right" }}>
                                    {/* will print arrow up or down based on growth */}
                                    {this.renderArrow()}
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" onClick={(e) => { this.closeModal() }} className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (this.props.modalType === "error") {
            return (
                <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block",top:"4rem" }}>

                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className={this.props.propsForModal.icon}></i>{this.props.propsForModal.title}</h5>
                                <button onClick={(e) => { this.closeModal() }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>{this.props.propsForModal.message}</p>
                            </div>

                            <div className="modal-footer">
                                <button type="button" onClick={(e) => { this.closeModal() }} className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (this.props.modalType === "info") {

        }
    }
    render() {

        return (
            <React.Fragment> {this.renderModalType()}</React.Fragment>
        )
    }
}

export default Modal;