import React, { Component } from 'react';
import { Form, Button, ListGroup, Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";
import { updateFeedbackforInOutLpgBuySellVendor, updateSelectedFeedbackforInOutLpgBuySellVendor } from '../../../api/corporate-api';

class OutSupplierFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbacks: this.props.getCorporateFeedbackOUTVendor,
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({ feedbacks: nextProps });
        }
    }

    updateFeedback = (feedback, status) => {
        updateFeedbackforInOutLpgBuySellVendor(feedback, { feedbackStatus: status }).then((response) => {
            let existingFeedback = [];
            Object.assign(existingFeedback, this.state.feedbacks);
            if (existingFeedback) {
                existingFeedback.getCorporateFeedbackOUTVendor.splice(existingFeedback.getCorporateFeedbackOUTVendor.findIndex(feed => feed.id === feedback), 1);
                this.setState({ feedbacks: existingFeedback });
                toast(response.text, 50);
            }
        })
    }
    updateSelectedFeedback = (status) => {
        const formData = {
            feedbackStatus: status,
            ids: this.state.selectedFeedbackIds
        }
        updateSelectedFeedbackforInOutLpgBuySellVendor(formData).then((response) => {
            let existingFeedback = [];
            Object.assign(existingFeedback, this.state.feedbacks);
            if (existingFeedback) {
                this.state.selectedFeedbackIds.forEach(feedbackId => existingFeedback.getCorporateFeedbackOUTVendor.splice(existingFeedback.getCorporateFeedbackOUTVendor.findIndex(feed => feed.id === parseInt(feedbackId)), 1));
                this.setState({ feedbacks: existingFeedback, selectedFeedbackIds: [] });
                toast(response.text, 50);
            }
        })
    }
    selectFeedback = (event) => {
        let existingSelectedFeedbackIds = [];
        Object.assign(existingSelectedFeedbackIds, this.state.selectedFeedbackIds);
        if (event.target.checked === true) {
            existingSelectedFeedbackIds.push(event.target.value);
        } else {
            existingSelectedFeedbackIds.splice(existingSelectedFeedbackIds.indexOf(event.target.value, 1));
        }
        this.setState({ selectedFeedbackIds: existingSelectedFeedbackIds });
    };
    render() {
        const { getCorporateFeedbackOUTVendor } = this.props;
        return (
            <React.Fragment>
                <ListGroup.Item variant="light" bg="light">
                    <Form inline>
                        <Button variant="success" className="mr-2" onClick={() => this.updateSelectedFeedback('Approved')}>Approve selected feedbacks</Button>
                        <Button variant="danger" onClick={() => this.updateSelectedFeedback('Rejected')}>Reject selected feedbacks</Button>
                    </Form>
                </ListGroup.Item>
                <ListGroup variant="flush">
                    {
                        getCorporateFeedbackOUTVendor && getCorporateFeedbackOUTVendor.length > 0 ? getCorporateFeedbackOUTVendor.map(feedback => <ListGroup.Item key={feedback.id} className="border my-2">
                            <Row>
                                <Col xs="auto"><Form.Check type="checkbox" value={feedback.id} onChange={this.selectFeedback} /></Col>
                                <Col>
                                    <p>Out supplier <strong>{feedback.outVendorName && feedback.outVendorName.toUpperCase()}</strong> is same out supplier <strong>{feedback.vendorNameSuggested}</strong>.</p>
                                    {feedback.feedbackComments ? <p>Comments: <em>{feedback.feedbackComments}</em></p> : ''}
                                    <small>
                                        Feedback from {feedback.createdBy && feedback.createdBy.userType.description + ' user: '}
                                        <strong>
                                            {(feedback.createdBy && feedback.createdBy.firstName ? feedback.createdBy.firstName : '') + (feedback.createdBy && feedback.createdBy.lastName ? ' ' + feedback.createdBy.lastName : '')}
                                            <em> ({feedback.createdBy && feedback.createdBy.email ? feedback.createdBy.email : ''})</em>
                                        </strong>
                                    </small>
                                    <p>
                                        <small>
                                            Created On:
                                            <strong style={{ marginLeft: '4px' }}>
                                                {new Date(feedback.createdOn).toLocaleDateString('en-US')}
                                            </strong>
                                            <em style={{ marginLeft: '4px' }}>
                                                Updated On: </em>
                                            <strong style={{ marginLeft: '4px' }}>
                                                {new Date(feedback.updatedOn).toLocaleDateString('en-US')}
                                            </strong>

                                        </small>
                                    </p>
                                </Col>
                                <Col xs="auto">
                                    <Button variant="outline-success" onClick={() => this.updateFeedback(feedback.id, 'Approved')}>Approve</Button>
                                    <Button variant="outline-danger ml-2" onClick={() => this.updateFeedback(feedback.id, 'Rejected')}>Reject</Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>) : <ListGroup.Item className="text-center text-primary border my-2 bg-light">No Feedbacks</ListGroup.Item>
                    }
                </ListGroup>
            </React.Fragment>
        );
    }
}

export default OutSupplierFeedback;