import React, { Component } from 'react';
import C3 from '../../supplier/C3/C3';
import { priceFormatter } from '../../../utils/formatters';

export default class TopTenMembers extends Component {
    memberNames = []
    constructor(props) {
        super(props);
        this.state = {
            titles: [
                "data2",
                "Alpha Electric Supply Company",
                "Alameda Electrical Distributors, Inc"
            ]
        }
    }

    render() {
        let { getCorporateConversionTopTenMember } = this.props;
        var Purchases = ["Purchases"];
        var memberId = ["members"];
        this.memberNames = [];

        if (getCorporateConversionTopTenMember) {
            getCorporateConversionTopTenMember.forEach((item) => {
                Purchases.push(item.purchases);
                this.memberNames.push(item.memberName);
                //memberId.push([item.memberId, item.leavingVendorId].join(' - '))
                memberId.push(item.memberId)

            })
        }
        

        const topTenMembersChartData = {
            data: {
               
                x: "members",
                columns: [
                    Purchases,
                    memberId
                ],
                colors: {
                    position: 'outer-middle' ,
                },
                bar: {
                    width: {
                        ratio: 0.2
                    },
                },
                type: 'bar',
                labels: {
                    format: function (d) { return priceFormatter(d).props.children; }
                },
            },
            legend: {
                show: true
            },
            axis: {
                x: {
                    label: { text: 'Purchases', position: 'outer-middle' },
                    height:40,
                    type: "category",
                    tick: {
                        rotate: -15,
                        multiline: false,
                    }

                },
                y: {
                    label: { text: 'Purchase Amount', position: 'outer-right' },
                    tick: {
                        rotate: -20,
                        multiline: false,
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            },
            tooltip: {
                format: {
                    title: (d) => this.memberNames[d],
                    value: (value, ratio, id) => priceFormatter(value).props.children
                }
            }
            // tooltip: {
            //     format: {
            //         title: function (d) { return d },
            //         value: (value, ratio, id) => priceFormatter(value)
            //     }
            // }
        };

        return (
            <C3
                heading={"Top 10 Members"}
                data={topTenMembersChartData.data}
                tooltip={topTenMembersChartData.tooltip}
                axis={topTenMembersChartData.axis}
            />
        )
    }
}
