import React from "react";
import ReactTable from "react-table-6";
import { Button, Card, Modal, InputGroup, FormControl } from 'react-bootstrap';
import ProductDetails from '../ProductDetails/ProductDetails';
import { priceFormatter } from '../../../../utils/formatters';

import '../../../../assets/styles/OpportunityByProductGroup.scss';
import {
    getConversionOpportunityProducts
} from "../../../../api/supplier-api";

export default class OpportunityByProductGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            searchKey: "",
            filteredSearchResults: [...this.props.opportunityByProductGroup],
            isFilteredDataAvailable: true,
            selectedProductGroupId: "",
            selectedProductGroupName: ""
        }
    }

    componentDidUpdate(pP, pS, sS) {
        if(pS.selectedProductGroupId !== this.state.selectedProductGroupId) {
            this.getProducts();
        }
    }

    getProducts = () => {
        const formData = {
            year: this.props.year,
            productGroupId: this.state.selectedProductGroupId
        };

        getConversionOpportunityProducts(formData)
            .then((responseData) => {
                this.setState({ productDetails: responseData })
            })
            .catch((err) => {
                console.log(err)
            });
    }

    handleClose = () => this.setState({ show: false })

    reactBootstrapModal = () => {
        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Product Details for <strong style={{ marginLeft: 4 }}>
                        {this.state.selectedProductGroupName}
                    </strong>
                </Modal.Header>
                <Modal.Body>
                    <ProductDetails
                        productDetails={this.state.productDetails}
                        selectedProductGroupId={this.state.selectedProductGroupId}
                        selectedProductGroupName={this.state.selectedProductGroupName}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={() => this.handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.productGroupName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        filteredSearchResults: [...this.props.opportunityByProductGroup],
        isFilteredDataAvailable: true
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        const { opportunityByProductGroup } = this.props;
        console.log(opportunityByProductGroup,"opportunityByProductGroupopportunityByProductGroup")
        
        return (
            <div className="opportunityByProductGroupContainer">
                {this.reactBootstrapModal()}
                <Card>
                    <Card.Header>Opportunity By Product Group</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for product group info.."
                                    aria-label="Search for product group info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, opportunityByProductGroup)}
                                />
                            <InputGroup.Append>
                                <Button onClick={() => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? this.props.opportunityByProductGroup : []}
                            columns={[
                                {
                                    Header: <b>Product Group</b>,
                                    accessor: "productGroupName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: rows =><div style={{textAlign:'center'}}>{rows.value}</div>

                                }, {
                                    Header: <b>Purchase</b>,
                                    accessor: "purchases",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'center' },
                                    Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.purchases)}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                    accessor: "leavingVendorName",
                                    sortable: true,
                                    resizable: true,
                                    style: {  'whiteSpace': 'unset', 'textAlign': 'center' },
                                    Cell: rows =><div style={{textAlign:'center'}}>{rows.value}</div>

                                }, {
                                    Header: <b>Products</b>,
                                    accessor: "products",
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => <i className="fas fa-info-circle"
                                        onClick={() => this.setState({
                                            show: true,
                                            selectedProductGroupId: props.original.productGroupId,
                                            selectedProductGroupName: props.original.productGroupName
                                        })} style={{ color: '#0D47A1' }}></i>,
                                    style: { textAlign: "center" }
                                },
                            ]}
                            defaultSorted={[
                                {
                                    id: "purchases",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                </Card>
            </div>
        );
    }
}
