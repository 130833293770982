import React from 'react';
import ReactExport from "react-export-excel";
import { Form, Col, ButtonGroup, Button } from 'react-bootstrap';
import { switchMonths } from '../../../../utils/formatters';

import '../../../../assets/styles/Filters.scss';
import Select from 'react-select';

export default class CoversionOpportunityFilters extends React.Component {

    render() {
        const {
            period,
            handleYearReference,
            handleMonthReference,
            handlePeriodReference,
            handleQuarterReference,
            handleTerminatedVendorReference,
            handleVendorReference,
            vendorOptions,
            listOfYears,
            listOfMonthsForSelectedYear,
            terminatedCOVendorList,
            opportunityByMember,
            topTenMembers,
            opportunityByProductGroup,
            topTenProductGroups,
            isQuarterSelected
        } = this.props;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        const customStyles = {
            container: base => ({
                ...base,
                width: '250px',
            }),
            control: base => ({
                ...base,
                boxShadow: 'none',
                borderRadius: '6px'
            }),
            valueContainer: base => ({
                ...base,
                fontSize: '15px',
                marginLeft: '4px',
            }),
            placeholder: base => ({
                ...base,
                marginTop: '0px',
                position: 'absolute',
            })
        }

        return (
            <div className="filtersContainer">
                <div className="filterTitle">
                    <p>{sessionStorage.getItem('imarkElectricalSupplierName')}</p>
                </div>
                <div className="filterItems">
                    <Form.Group as={Col} id="group2">
                        {console.log(terminatedCOVendorList,'terminatedCOVendorList')}
                        {/* <Select
                            className="full-width-select"
                            onChange={(value) => handleTerminatedVendorReference(value)}
                            placeholder='Select Targeted Supplier'
                            styles={customStyles}
                            isSearchable={true}
                           // options={terminatedCOVendorList.map((Item) => ({ value: Item, label: Item.vendorName }))}
                        /> */}

                        <Form.Control as="select" defaultValue="Select Targeted Supplier" id="select2"
                            onChange={(value) => handleTerminatedVendorReference(value)}
                        >
                            <option value="">All</option>
                            {terminatedCOVendorList && terminatedCOVendorList.map((item, index) => {
                                return (
                                    <option key={index} value={item.vendorId}>{item.vendorName}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} id="group2">
                        <Form.Control
                            as="select"
                            onChange={(value) => handleVendorReference(value)}
                            id="select2"
                            style={{ minWidth: 100 }}
                        >
                            {vendorOptions.map((item) => {
                                return <option key={item} value={item.vendorId}>{item.vendorName}{item.vendorName === "All" ? null : `-${item.vendorId}`}</option>
                            })}
                        </Form.Control>

                    </Form.Group>
                    <ButtonGroup className="yearGroup">
                        <Button
                            className={`btn ${period === "MTH" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="MTH"
                        >
                            MTH
                        </Button>
                        <Button
                            className={`btn ${period === "YTD" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="YTD"
                        >
                            YTD
                        </Button>
                        <Button
                            className={`btn ${period === "QTR" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="QTR"
                        >
                            QTR
                        </Button>
                    </ButtonGroup>
                    <Form.Group as={Col} id="group3">
                        <Form.Control
                            as="select"
                            // defaultValue={"2020"}
                            id="select2"
                            onChange={(e) => handleYearReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            {listOfYears.map((year) => {
                                return <option key={year} selected={year == this.props.year} value={year}>{year}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    {!isQuarterSelected && <Form.Group as={Col} id="group4">
                        <Form.Control
                            as="select"
                            // defaultValue={listOfMonthsForSelectedYear[listOfMonthsForSelectedYear.length - 1]}
                            id="select3"
                            onChange={(e) => handleMonthReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            {listOfMonthsForSelectedYear.map((month) => {
                                return <option key={month} selected={month == this.props.month} value={`${month}`}>{switchMonths(month)}</option>
                            })}
                        </Form.Control>
                    </Form.Group>}
                    {isQuarterSelected && <Form.Group as={Col} id="group4">
                        <Form.Control
                            as="select"
                            defaultValue={"1"}
                            id="select4"
                            onChange={(e) => handleQuarterReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            <option value="1">QTR1</option>
                            <option value="2">QTR2</option>
                            <option value="3">QTR3</option>
                            <option value="4">QTR4</option>
                        </Form.Control>
                    </Form.Group>}
                    <ExcelFile element={<Button className="export" color="primary" style={{ fontSize: 12 }}>Export</Button>}>
                        <ExcelSheet data={opportunityByMember} name="Opportunity By Members">
                            <ExcelColumn label="ID" value="memberId" />
                            <ExcelColumn label="Member Name" value="memberName" />
                            <ExcelColumn label="Purchase" value={(col) => col.purchases} />
                            <ExcelColumn label="Leaving Supplier" value="leavingVendorName" />
                        </ExcelSheet>
                        <ExcelSheet data={topTenMembers} name="Top 10 Members">
                            <ExcelColumn label="ID" value="memberId" />
                            <ExcelColumn label="Member Name" value="memberName" />
                            <ExcelColumn label="Purchase" value={(col) => col.purchases} />
                            <ExcelColumn label="Leaving Supplier" value="leavingVendorName" />
                        </ExcelSheet>
                        <ExcelSheet data={opportunityByProductGroup} name="Opportunity by Product Group">
                            <ExcelColumn label="Product Group" value="productGroupName" />
                            <ExcelColumn label="Purchase" value={(col) => col.purchases} />
                            <ExcelColumn label="Leaving Supplier" value="leavingVendorName" />
                        </ExcelSheet>
                        <ExcelSheet data={topTenProductGroups} name="Top 10 Product Group">
                            <ExcelColumn label="Product Group" value="productGroupName" />
                            <ExcelColumn label="Purchase" value={(col) => col.purchases} />
                            <ExcelColumn label="Leaving Supplier" value="leavingVendorName" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </div>
        )
    }
}
