import React from 'react'
import { Form, Col, Button, ButtonGroup } from 'react-bootstrap';
import ReactExport from "react-export-excel";


import '../../../../assets/styles/PurchaseOrdersFilters.scss';


export const PurchaseOrdersFilters = React.forwardRef((props, ref) => {
    let {
        FileDownload,
        handleSelectedBranchReference,
        handleSelectedSupplierReference,
        handlePoNoReference,
        handleSearchPoReference,
        handleClearPoReference,
        handleStatusReference,
        handleStartDateReference,
        handleEndDateReference,
        branchName,
        inOut,
        suppliers,
        branches,
        purchaseOrders,
    } = props;

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    return (
        <React.Fragment>
            <div className="purchaseOrdersFiltersContainer">
                <div className="filterTitle">
                    <p style={{ marginBottom: 10 }}>{props.memberName}</p>
                </div>
                <div className="purchaseOrdersFilters">
                    <div className="purchaseOrdersFiltersRowOne">
                        <Form.Group as={Col} controlId="formSelectBranch">
                            <Form.Control as="select" defaultValue={branchName} onChange={(e) => handleSelectedBranchReference(e)}>
                                {branches.map((item, index) => {
                                    return <option key={index} value={`${item.memberId}=${item.memberName}`}>{item.memberName}</option>
                                })}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formSelectSupplier">
                            <Form.Control as="select" defaultValue="Select Supplier" onChange={(e) => handleSelectedSupplierReference(e)}>
                                <option value="">Select Supplier</option>
                                {suppliers.map((item, index) => {
                                    return <option key={index} value={`${item.vendorId}=${item.vendorName}`}>{item.vendorName}</option>
                                })}
                            </Form.Control>
                        </Form.Group>

                        <ButtonGroup className="purchaseOrdersButtonGroup" aria-label="Basic example">
                            <Button id="both" name="BOTH" style={{ height: 30 }} onClick={(e) => handleStatusReference(e)} variant={inOut === "BOTH" ? 'success' : 'info'}>BOTH</Button>
                            <Button id="out" name="OUT" style={{ height: 30 }} onClick={(e) => handleStatusReference(e)} variant={inOut === "OUT" ? 'success' : 'info'}>OUT</Button>
                            <Button id="in" name="IN" style={{ height: 30 }} onClick={(e) => handleStatusReference(e)} variant={inOut === "IN" ? 'success' : 'info'}>IN</Button>
                        </ButtonGroup>

                        <Form.Group as={Col} controlId="date1" onChange={(e) => handleStartDateReference(e)}>
                            <Form.Control type="date" name="date1" placeholder="Start Date" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="date2" onChange={(e) => handleEndDateReference(e)}>
                            <Form.Control type="date" name="date2" placeholder="End Date" />
                        </Form.Group>
                    </div>
                    <div className="purchaseOrdersFiltersRowTwo">
                        <Form.Group as={Col} controlId="formSearchPO" className="purchaseOrdersSearch">
                            {/* <Form.Control ref={ref} type="text" placeholder="Search PO No." style={{ fontSize: 12 }} onChange={(e) => handlePoNoReference(e)} /> */}
                            <Form.Control  ref={ref} type="text" placeholder="Search PO No." style={{ fontSize: 12 }} onChange={(e) => handlePoNoReference(e)} />

                        </Form.Group>
                        
                        <div className="purchaseOrdersButton">

                            <Button style={{ height: 30, fontSize: 12 }} onClick={()=>handleSearchPoReference()}>Search</Button>
                            <Button style={{ height: 30, marginLeft: 2, fontSize: 12 }} onClick={() => handleClearPoReference()}>Reset</Button>
                            <Button style={{ height: 30, fontSize: 12 }} className="btn btn-primary" 
                         onClick={e=>FileDownload(e)} >Export</Button>
                            {/* <ExcelFile element={<Button style={{ height: 30, marginLeft: 2, fontSize: 12 }}>Export</Button>}>
                                <ExcelSheet data={purchaseOrders} name="Purchase Orders">
                                    <ExcelColumn label="PO NUMBER" value="poNo" />
                                    <ExcelColumn label="PO DATE" value="poDate" />
                                    <ExcelColumn label="VENDOR  NAME" value="vendorName" />
                                    <ExcelColumn label="VP / UNAPPROVED SUPPLIERS" value="vendorName" />
                                    <ExcelColumn label="PRODUCT DESCRIPTION" value="productDescription" />
                                    <ExcelColumn label="AMOUNT ($)" value="totalAmount" />
                                </ExcelSheet>
                            </ExcelFile> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})
