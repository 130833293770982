import React from "react";
import ReactTable from "react-table-6";
import { Card, ProgressBar, InputGroup, FormControl, Button } from 'react-bootstrap';

import '../../../../assets/styles/SalesOpportunityByBusinessGroup.scss';

export default class SalesOpportunityByBusinessGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: "",
            filteredSearchResults: [],
            isFilteredDataAvailable: false
        }
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.businessName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        const {
            salesOpportunityByBusinessGroup,
            handleBusinessIdReference,
            selectedRowValuesInOppByBGReference,
            selectedBusinessGroupRow,
            handleResetTableDataReference
        } = this.props;

        let opportunities = salesOpportunityByBusinessGroup && salesOpportunityByBusinessGroup.map((item) => item.opportunity);
        let maxOpportunity = Math.max(...opportunities);

        return (
            <div className="salesOpportunityByBusinessGroupContainer">
                <Card>
                    <Card.Header>Product Group Summary</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for product group summary info.."
                                    aria-label="Search for product group summary info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, salesOpportunityByBusinessGroup)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => {
                                        this.clearTable();
                                        handleResetTableDataReference();
                                    }}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.salesOpportunityByBusinessGroup}
                            columns={[
                                {
                                    Header: <b>Product Group</b>,
                                    accessor: "businessName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props => <div style={{textAlign:'center'}}><span style={{ cursor: 'pointer' }}>
                                        {props.original.businessName}
                                    </span></div>
                                }, {
                                    Header: <b>Opportunity</b>,
                                    accessor: "opportunity",
                                    sortable: true,
                                    resizable: true,
                                    desc: true,
                                    Cell: props => <ProgressBar
                                        now={(props.original.opportunity * 100 / maxOpportunity) > 1 ?
                                            props.original.opportunity * 100 / maxOpportunity : 1}
                                        style={{ cursor: 'pointer' }}
                                    />
                                }
                            ]}
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                    return {
                                        onClick: e => {
                                            // console.log(rowInfo.index);
                                            // console.log(rowInfo.original && rowInfo.original.vendorName);
                                            handleBusinessIdReference(rowInfo.original && rowInfo.original.businessId);
                                            selectedRowValuesInOppByBGReference(rowInfo.index, rowInfo.original && rowInfo.original.businessName);
                                        },
                                        style: {
                                            background:
                                                rowInfo.index === selectedBusinessGroupRow ? "lightblue" : null
                                        }
                                    };
                                } else {
                                    return {};
                                }
                            }}
                            defaultSorted={[
                                {
                                    id: "opportunity",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                </Card>
            </div>
        );
    }
}
