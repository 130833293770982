import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getStoredToken } from './utils/getStoredToken';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route
        {...rest}
        render={props => getStoredToken() ? <Component {...props} /> : <Redirect to={{
                pathname: '/',
                state: { from: props.location }
            }}
        />}
    >
    </Route>
}