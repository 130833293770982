import React from 'react';
import ReactTable from "react-table-6";
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap';
import { dateFormatter, priceFormatter } from '../../../../utils/formatters';
import Pagination from "../../../../Common/Pagination/Pagination";

import '../../../../assets/styles/InvoicesTable.scss';

export default class InvoicesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // invoices: [],
            searchKey: "",
            numberOfRowsLoaded: 499,
            filteredSearchResults: [...this.props.invoices],
            isFilteredDataAvaiable: true
        }
    }

    componentDidUpdate(pP, pS, sS) {
        if(this.props.invoices!== pP.invoices){
            this.setState({
                isFilteredDataAvaiable: true,
                filteredSearchResults: this.props.invoices
            })
        }
    }
    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return (item.vendorName && item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
                || (item.productDesc.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0)
                || (item.totalAmount.toString().indexOf(e.target.value.toLowerCase()) >= 0);
        })

        this.setState({
            isFilteredDataAvaiable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvaiable: true,
        filteredSearchResults: [...this.props.invoices]
    });

    loadMoreData = () => {
        this.props.loadMoreData(this.state.numberOfRowsLoaded+1, 500);
        this.setState({numberOfRowsLoaded: this.state.numberOfRowsLoaded+500});
    }

    render() {
        let {
            // invoices,
            searchKey,
            filteredSearchResults,
        } = this.state;
        let { invoices } = this.props;

        return (
            <Card className="invoicesTableContainer">
                <Card.Header>Supplier Invoices</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search for supplier invoices info.."
                                aria-label="Search for supplier invoices info.."
                                aria-describedby="basic-addon2"
                                value={searchKey}
                                onChange={(e) => this.searchTable(e, invoices)}
                            />
                            <InputGroup.Append>
                                <Button onClick={(e) => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <ReactTable
                        data={this.state.isFilteredDataAvaiable ? filteredSearchResults : this.props.invoices}
                        // filterable
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Name</b>,
                                accessor: "vendorName",
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Invoice No.</b>,
                                accessor: 'invoiceNo',
                                sortable: true,
                                resizable: true,
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>PO No.</b>,
                                accessor: 'poNo',
                                sortable: true,
                                resizable: true,
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Invoice Date</b>,
                                accessor: 'invoiceDate',
                                sortable: false,
                                resizable: true,
                                filterable: false,
                                Cell: props => <div style={{ textAlign: "center" }}>{dateFormatter(props.original.invoiceDate)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Product Description</b>,
                                accessor: 'productDesc',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b>Quantity</b>,
                                accessor: 'quantity',
                                sortable: false,
                                resizable: true,
                                filterable: false,
                                style: { 'textAlign': 'right' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b>Price</b>,
                                accessor: 'pricePerUnit',
                                sortable: false,
                                resizable: true,
                                filterable: false,
                                style: { 'textAlign': 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.pricePerUnit)}</div>,
                            },
                            {
                                Header: <b>Amount</b>,
                                accessor: 'totalAmount',
                                sortable: false,
                                resizable: true,
                                filterable: false,
                                style: { 'textAlign': 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.totalAmount)}</div>,
                            }
                        ]}
                        defaultPageSize={100}
                        PaginationComponent={Pagination}
                        loadMoreData={this.loadMoreData}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                    />
                </div>
            </Card>
        );
    }
}
