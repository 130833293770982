import React, { Component } from 'react'
import ReactTable from "react-table-6";
import Toggle from 'react-toggle';
import { toast } from "react-toastify";
import { Button, Form, Col, Modal } from 'react-bootstrap';
import { getCorpConversionSaveProductsFeedback } from '../../../api/corporate-api';
import Select from 'react-select';
import "react-toggle/style.css";
import Noty from 'noty';
import "noty/lib/noty.css";  
import "noty/lib/themes/mint.css";
//import Select from 'react-select';
import ReactExport from "react-export-excel";
import _findIndex from 'lodash/findIndex';


export default class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            chesseIsReady: false,
            productDesc: "",
            productId: '',
            productDetails: [
                {
                    "id": 1,
                    "productDesc": "JONEST C27150 1-1/2 CLOSET SPUD W/WASHER & RING WR-1309008",
                    "feedback": ""
                }
            ]
        }
    }

    handleSubmit = () => {
        const formData = {
            productId: this.state.productId,
            productDesc: this.state.productDesc,
            categoryId: this.props.productGroupId,
            categoryName: this.props.productGroup,
            productCategoryWrong: true,
            suggestedCategoryId: this.state.suggestedCategoryId,
            suggestedCategoryName: this.state.suggestedCategoryName,
        }
        if (!this.state.suggestedCategoryName) {
            this.setState({ suggestedCategoryNameErrorMessage: 'Please select a Category' }, () => {
            });
        }
        else{
        getCorpConversionSaveProductsFeedback(formData)
        .then((data) => {
            new Noty({
                text: data.text,
                layout: "topRight",
                theme: "mint",
                type: "success"
            }).show();
           // toast(data.text);
            this.setState({ show: false });
        })
        .catch((err) => {
            console.log(err);
        });
    }
    }

    handleClose = () => this.setState({ show: false, cheeseIsReady: false,suggestedCategoryNameErrorMessage:'' })

    handleCheeseChange = () => {
        if (this.state.cheeseIsReady) {
            this.setState({ cheeseIsReady: false })
        } else {
            this.setState({ cheeseIsReady: true })
        }
    }
    handleChangeProductCategory1 = (value) => {
        let arr = [];

      
        if (value ) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
        }
        let categoryId = arr[0].split(',')[0];
        let categoryName = arr[0].split(',')[1];
        console.log(arr)
        
        this.setState({
            suggestedCategoryId: categoryId,
            suggestedCategoryName: categoryName,
            suggestedCategoryNameErrorMessage :''
        })

    
      };
    
    handleChangeProductCategory = (e) => {
     
        if (e.value) {
            let categoryId = e.value.split(',')[0];
            let categoryName = e.value.split(',')[1];

            this.setState({
                suggestedCategoryId: categoryId,
                suggestedCategoryName: categoryName,
                showSubmit: true
            })
        }
        else {
            let categoryName = _findIndex(e,"Select Supplier");
            let categoryId = '';
            this.setState({
                suggestedCategoryId: categoryId,
                suggestedCategoryName: categoryName,
                showSubmit: false
            })
          }
    }

    reactBootstrapModal = () => {
        let { productDesc } = this.state;
        let { productCategoryList } = this.props;
        
        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Product Feedback
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p><b>{productDesc}</b> Is wrongly classified as ?</p>
                        <Toggle
                            id='cheese-status5'
                            icons={false}
                            defaultChecked={this.state.cheeseIsReady}
                            onChange={() => this.handleCheeseChange()}
                        />
                    </div>
                    {this.state.cheeseIsReady && <Form.Group as={Col} controlId="formSelectNewProductCategory">

                        {/* <Form.Control
                            as="select"
                            defaultValue={"Select New Product Category"}
                            onChange={(e) => this.handleChangeProductCategory(e)}
                        >
                            <option value="">Select new Product Category</option>
                            {productCategoryList && productCategoryList.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.productCategoryId},${item.productCategory}`}>{item.productCategory}</option>
                                )
                            })}
                        </Form.Control> */}

                          <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleChangeProductCategory(e)}
                            placeholder={'Select Supplier'}
                        
                            options={Array.from(
                                productCategoryList && productCategoryList.map((item, index) => {
                                return {
                                  value: item.productCategoryId + ','+ item.productCategory,
                                  label: item.productCategory,
                                };
                                
                              })
                            )}
                          />
        
                    </Form.Group>}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea></textarea>
                    </div>
                </Modal.Body>
                <span className="errorText">{this.state.suggestedCategoryNameErrorMessage}</span>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.handleSubmit()}>
                        Submit
                </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { productCategoryData } = this.props;
        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        return (
            <div className="productDetailsContainer">
                {this.reactBootstrapModal()}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <h6>Product Details</h6>
                    {/* <Button style={{ fontSize: 12 }}>Download Product Names</Button> */}
                    <ExcelFile element={<Button style={{ fontSize: 12 }}>Download Product Names</Button>}>
                        <ExcelSheet data={productCategoryData} name="Product Details">
                            <ExcelColumn label="Product" value="productDesc" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
                <ReactTable
                    data={productCategoryData}
                    columns={[
                        {
                            Header: <b style={{ 'whiteSpace': 'normal' }}>Product</b>,
                            accessor: "productDesc",
                            sortable: true,
                            resizable: true,
                            style: { 'whiteSpace': 'unset' }
                        }, {
                            Header: <b style={{ 'whiteSpace': 'normal' }}>Feedback</b>,
                            accessor: "feedback",
                            sortable: true,
                            resizable: true,
                            width: 80,
                            Cell: props => <i className="fa fa-comment-alt" onClick={() => this.setState({ show: true, productDesc: props.original.productDesc, productId: props.original.productId })} style={{ color: '#0D47A1' }}></i>,
                            style: { textAlign: "center" }
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: "productDesc",
                            desc: true
                        }
                    ]}
                    defaultPageSize={25}
                    style={{ height: "400px" }}
                    className="-striped -highlight"
                />
                <br />
            </div>
        );
    }
}
