import Axios from 'axios';
import { BASE_URL } from '../index';
import { convertJSONToURLParams } from '../utils/formatters';

// SUPPLIER

export const getProductCategory = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/product-category?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ProductCategory', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const saveProductFeedback = (formData) => {
    let request= Axios.post(`/save-product-feedback`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'mode': 'cors'
        }
    })

    return request
        .then((res) => {
            // console.log('SavedProductFeedback', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// SUPPLIER - OVERVIEW TAB

export const getVendorConversionOpportunity = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/vendor-conversion-opportunity?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('VendorConversionOpportunity', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getVendorCompliantStatus = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/compliant?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('VendorCompliantStatus', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getVendorMonthsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/vendor-months-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('VendorMonthsFilter', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getVendorOverviewBoxes = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/vendor-overview-boxes?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('VendorOverviewBoxes', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getVendorSalesVsOpportunityGraph = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/opportunity-by-vendor?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('VendorSalesVsOpportunityGraph', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getGrowthRebate = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/growth-rebate-vendor?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('GrowthRebate', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getSalesRebateOverview = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/sales-rebate-overview?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            // console.log('SalesRebateOverview', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getSalesOpportunityByRegion = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/sales-opportunity-region?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('SalesOpportunityByRegion', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getSalesOpportunityByMember = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/sales-opportunity-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('SalesOpportunityByMember', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getSalesOpportunityByBusinessGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/sales-opportunity-business?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('SalesOpportunityByBusiness', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getSalesOpportunityByProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/sales-opportunity-product-group?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('SalesOpportunityByProductGroup', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}
export const getVendorBranches = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/filter-vendor-ids?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            let arr = res.data;
            let obj = {
                vendorId: arr.map((item) => item.vendorId).join(','),
                vendorName: 'All'
            };
            arr.unshift(obj);
            return arr;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getProductDetailsInSalesOpportunityByProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/sales-opportunity-product-list?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ProductDetailsInSalesOpportunityByProductGroup', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// SUPPLIER - CONVERSION TAB

export const getConversionMontsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/conversion-months-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ConversionMonthsFilter', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getConversionOpportunityVendorList = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/conversion-opportunity-vendor-list?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ConversionOpportunityVendorList', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getTopTenConversionOpportunityByMember = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/top-ten-conversion-opportunity-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            // console.log('TopTenConversionOpportunityByMember', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getConversionOpportunityByMember = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/conversion-opportunity-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ConversionOpportunityByMember', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getTopTenConversionOpportunityByProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/top-ten-conversion-opportunity-product-group?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('TopTenConversionOpportunityByProductGroup', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getConversionOpportunityByProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/conversion-opportunity-product-group?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ConversionOpportunityByProductGroup', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getConversionOpportunityProducts = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/conversion-opportunity-products?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            // console.log('ConversionOpportunityProducts', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}
