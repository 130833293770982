import React from 'react';
import ReactTable from "react-table-6";
import InfoModal from '../InfoModal/InfoModal';
// import ReactTooltip from 'react-tooltip';
import { Card, ProgressBar, InputGroup, FormControl, Button } from 'react-bootstrap';
import { priceFormatter } from '../../../../utils/formatters';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { getMemberPurchaseWithinImarkValue } from '../../../actions';
// import { convertJSONToURLParams } from '../../../utils/formatters';

import { getVendorFullInfo } from '../../../../api/member-api';

export default class PurchaseWithinImark extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedSupplier: {},
            // purchaseWithinImark: [],
            searchKey: '',
            key:'',
            filteredSearchResults: [...this.props.purchaseWithinImark],
            isFilteredDataAvailable: false
        }
    }

    displayInfoModal = (vendorId) => {
        
        getVendorFullInfo(vendorId)
            .then((responseData) => {
                console.log('VendorFullInfo', responseData);
                this.setState({ selectedSupplier: responseData })
            })
            .catch((err) => {
                console.log(err);
            })
            
    }

    handleClose = () => this.setState({ show: false })
    handleShow = (row) => this.setState({ show: true, selectedSupplier: row })

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        if(e.target.value==''){
            this.setState({
                isFilteredDataAvailable: false,
            });
            return
        }
        let results = this.props.purchaseWithinImark.filter((item) => {
            // return item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
            if (e.target.value === "") {
                        return item;
                      } else if (
                        (item.vendorName?.toLowerCase().includes(e.target.value.toLowerCase()))
                      ) {
                        return item;
                      }
                })

        this.setState({
            isFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchResults: results
        });
    }


    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: true,
        filteredSearchResults: this.props.purchaseWithinImark
    }
    );


    render() {
        let {
            // purchaseWithinImark,
            searchKey,
            filteredSearchResults
        } = this.state;
        let { purchaseWithinImark } = this.props;

        let potentialRebates = purchaseWithinImark && purchaseWithinImark.map((item) => item.potentialRebate);
        let maxPotentialRebate = potentialRebates && Math.max(...potentialRebates);

        return (
            <React.Fragment>
                <InfoModal
                    show={this.state.show}
                    handleClose={this.handleClose}
                    selectedSupplier={this.state.selectedSupplier}
                />
                <Card>
                    {/* <pre>{JSON.stringify(this.state, null, '\t')}</pre> */}
                    <Card.Header>Purchases from IMARK Preferred Suppliers</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for Purchases from IMARK Preferred Suppliers info.."
                                    aria-label="Search for purchase within IMARK info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, purchaseWithinImark)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={(e) => this.clearTable(e)}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        {console.log(this.props.purchaseWithinImark ,this.state.isFilteredDataAvailable,"this.props.purchaseWithinImark this.props.purchaseWithinImark this.props.purchaseWithinImark ")}
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ?  this.state.filteredSearchResults : this.props.purchaseWithinImark  }
                            // filterable
                            columns={[
                                {
                                    Header: <b style={{  'whiteSpace': 'normal' }}>Supplier Name</b>,
                                    accessor: "vendorName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    // width: 250
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                },
                                {
                                    Header: <b style={{  'whiteSpace': 'normal' }}>Potential Rebate</b>,
                                    accessor: "potentialRebate",
                                    sortable: true,
                                    resizable: true,
                                    filterable: false,
                                    Cell: props => (
                                        <React.Fragment>
                                            <div>
                                                <ProgressBar now={((props.original.potentialRebate * 100) / maxPotentialRebate)} />
                                            </div>
                                        </React.Fragment>
                                    )
                                },
                                {
                                    Header: <b style={{  'whiteSpace': 'normal' }}>Potential Purchase</b>,
                                    accessor: 'potentialPurchase',
                                    sortable: true,
                                    resizable: true,
                                    filterable: false,
                                    style: { textAlign: "right" },
                                    // Cell: props =>priceFormatter(props.original.potentialPurchase),
                                    Cell: props=><div style={{ textAlign: "center" }}>{priceFormatter(props.original.potentialPurchase)}</div>

                                },
                                {
                                    Header: <b style={{  'whiteSpace': 'normal' }}>Member Purchase</b>,
                                    accessor: 'memberPurchase',
                                    sortable: true,
                                    resizable: true,
                                    filterable: false,
                                    style: { textAlign: "right" },
                                    Cell: props =><div style={{ textAlign: "center" }}>{priceFormatter(props.original.memberPurchase)}</div>
                                },
                                {
                                    Header: <b style={{  'whiteSpace': 'normal' }}>PO Count</b>,
                                    accessor: "poCount",
                                    sortable: true,
                                    resizable: true,
                                    style: { textAlign: "right"  },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                },
                                {
                                    Header: <b>Info</b>,
                                    accessor: 'info',
                                    sortable: false,
                                    resizable: false,
                                    filterable: false,
                                    Cell: props => <i className="fa fa-info-circle" onClick={() => {
                                        this.setState({
                                            show: true,
                                            selectedSupplier: props.original
                                        });
                                        this.displayInfoModal(props.original.vendorId);
                                    }} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>,
                                    style: { textAlign: "center" }
                                }
                            ]}
                            defaultPageSize={25}
                            defaultSorted={[
                                {
                                  id: "memberPurchase",
                                  desc: true
                                }
                              ]}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                    {/* <pre>{JSON.stringify(this.state, null, '\t')}</pre> */}
                </Card>
            </React.Fragment>
        );
    }
}

// const mapStateToProps = (state) => {
//     return {
//         purchaseWithinImark: state.member.getMemberPurchaseWithinImarkValue
//     };
// }

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({
//         getMemberPurchaseWithinImarkValueAPI: getMemberPurchaseWithinImarkValue
//     }, dispatch);
// }

// export default connect(mapStateToProps, mapDispatchToProps)(PurchaseWithinImark);
