import {
    LOGIN,
    LOGIN_WITH_SSO_TOKEN,
    GENERATE_SAMPLE_SSO_AUTHENTICATION_TOKEN,
    LOGOUT
} from '../actions/types';

export default function reducerCorporate(state = {
    isFetching: true,
    isLoggedIn: false
}, action) {
    let isFetching = true;
    switch (action.type) {
        case LOGIN:
            isFetching = false;
            return { ...state, isLoggedIn: action.payload, isFetching };
        case LOGIN_WITH_SSO_TOKEN:
            isFetching = false;
            return { ...state, isLoggedIn: action.payload, isFetching };
        case GENERATE_SAMPLE_SSO_AUTHENTICATION_TOKEN:
            isFetching = false;
            return { ...state, generatedSampleSSOAuthenticationTokenData: action.payload, isFetching };
        case LOGOUT:
            isFetching = false;
            return { ...state, isLoggedIn: action.payload, isFetching };
        default:
            return state;
    }
}
