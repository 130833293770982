import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BASE_URL } from '../../..';
import ReactTable from "react-table-6";
import ReactExport from "react-export-excel";
import Cards from '../../../Common/Cards/Cards';
import { InputGroup, FormControl, Button, OverlayTrigger, Tooltip, Form, Col, ButtonGroup } from "react-bootstrap";
import { PriceFormatter1, PercentFormatter } from '../../../helper';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import { switchMonths } from '../../../utils/formatters';

import {
    getCardsDataForCorporateVendorDataTab,
    getCorpVendorPerformanceData,
    getCorpMemberPerformanceData,
    getCorpVendorDataGrowthData,
    getCorporateOverviewVPInvoices,
    getCorporateOverviewMemberInvoices,
    getCorpSupplierMonthsFilter
} from '../../../api/corporate-api';
import { updateSelectedNavIndex } from '../../../actions';

import './index.scss';

class CorporateVendorData extends Component {
    constructor(props) {
        super(props);
        const currentDate = new Date(2021, 11, 31);

        this.state = {
            isQuarterSelected: false,
            period: "YTD",
            source: "vendor",
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            quarter: 1,
            buyingGroupId: 0,
            isCompliant: "A",
            subTabVendor: "Sales",
            subTabMember: "Purchase",
            startRowNo: 0,
            params: {
                period: "YTD",
                source: "vendor",
                year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
                quarter: 1,
                buyingGroupId: 0,
                isCompliant: "A",
                subTabVendor: "Sales",
                subTabMember: "Purchase",
                startRowNo: 0
            },
            listOfMonthsForSelectedYear: [],
            coporateVendorDataFilters: [],
            corporateVendorDataCardItems: [],
            corporateVendorDataVendorPerformance: [],
            corporateVendorDataMemberPerformance: [],
            corporateVendorDataGrowthRebate: [],
            searchKeyVendor: "",
            searchKeyMember: "",
            searchKeyGrowth: "",
            isVendorFilteredDataAvailable: false,
            isMemberFilteredDataAvailable: false,
            isGrowthFilteredDataAvailable: false,
            filteredSearchVendorData: [],
            filteredSearchMemberData: [],
            filteredSearchGrowthData: [],
        }
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);

        this.loadData();
    }

    componentDidUpdate(pP, pS, sS) {
        if(pS.buyingGroupId !== this.state.buyingGroupId && pS.params.buyingGroupId !== this.state.params.buyingGroupId) {
            this.getVendorData();
        }

        if(pS.isCompliant !== this.state.isCompliant && pS.params.isCompliant !== this.state.params.isCompliant) {
            this.getVendorData();
        }

        if(pS.year !== this.state.year && pS.params.year !== this.state.params.year) {
            this.getVendorData();
        }

        if(pS.month !== this.state.month && pS.params.month !== this.state.params.month) {
            this.getVendorData();
        }

        if(pS.period !== this.state.period && pS.params.period !== this.state.params.period) {
            this.getVendorData();
        }

        if(pS.quarter !== this.state.quarter && pS.params.quarter !== this.state.params.quarter) {
            this.getVendorData();
        }
    }

    loadData = () => {
        Promise.allSettled([ getCorpSupplierMonthsFilter({ source: this.state.source }) ])
        .then((allResponses) => {
            if (allResponses[0].value) {
                const availableYears = Object.keys(allResponses[0].value).sort((a, b) => b - a);

                this.setState((prevState) => ({
                    coporateVendorDataFilters: allResponses[0].value && allResponses[0].value,
                    listOfYears: allResponses[0].value && Object.keys(allResponses[0].value),
                    // year: allResponses[0].value && Object.keys(allResponses[0].value)[0],
                    params: {
                        ...prevState.params,
                        // year: allResponses[0].value && Object.keys(allResponses[0].value)[0]
                    }
                }), () => {
                    this.getListOfMonths(allResponses[0].value ,availableYears[0]);
                })
            }
        })
        .catch(err => console.log(err));
    }

    getVendorData = () => {
        const formData = {
            year: this.state.year,
            month: this.state.month,
            source: this.state.source,
            period: this.state.period,
            quarter: this.state.quarter,
            isCompliant: this.state.isCompliant,
            buyingGroupId: this.state.buyingGroupId
        }

        Promise.allSettled([
            getCardsDataForCorporateVendorDataTab(formData),
            getCorpVendorPerformanceData(formData),
            getCorpMemberPerformanceData(formData),
            getCorpVendorDataGrowthData(formData)
        ])
        .then((allResponses) => {
            if (allResponses[0].value) {
               // this.setState({ corporateVendorDataCardItems: allResponses[0].value ? allResponses[0].value : [] })
                this.formatCards(allResponses[0].value)
            }
            if (allResponses[1].value) {
                this.setState({
                    corporateVendorDataVendorPerformance: allResponses[1].value ? allResponses[1].value : [],
                    filteredSearchVendorData: allResponses[1].value ? allResponses[1].value : [],
                    isVendorFilteredDataAvailable: true
                })
            }
            if (allResponses[2].value) {
                this.setState({
                    corporateVendorDataMemberPerformance: allResponses[2].value ? allResponses[2].value : [],
                    filteredSearchMemberData: allResponses[2].value ? allResponses[2].value : [],
                    isMemberFilteredDataAvailable: true
                })
            }
            if (allResponses[3].value) {
                this.setState({
                    corporateVendorDataGrowthRebate: allResponses[3].value ? allResponses[3].value : [],
                    filteredSearchGrowthData: allResponses[3].value ? allResponses[3].value : [],
                    isGrowthFilteredDataAvailable: true
                })
            }
        })
        .catch(err => console.log(err));
    } 

    formatCards = (cards) => {
        cards.forEach(card => {
            let cardTitle = card.cardTitle 
                var day = cardTitle;
                switch (cardTitle) {
                  case "Total Transactions":
                    day = "Total Supplier Sales";
                    break;
                  case "Base Rebate":
                    day = "Total Estimated Base Rebate";
                    break;
                  case "Estimated Growth Rebate":
                    day = "Estimated Group Growth Rebate";
                    break;
                  case "Total Rebate":
                    day = "Total Estimated Rebate";
                    break;
                }
                card.cardTitle = day
        });
        this.setState({ corporateVendorDataCardItems: cards })
    }

    showVendorPopUp = (cell, row) => {
        return (
            <div>
                <OverlayTrigger
                    overlay={(
                        <Tooltip id="hi">
                            View Invoices/PO for {cell.original.vendorName}
                        </Tooltip>
                    )}
                    popperConfig={{ modifiers: { preventOverflow: { boundariesElement: 'viewport' } } }}
                    placement="top"
                >
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.showVendorInvoice(cell.original)
                        }}
                    >
                        <i className="fas fa-th-list" style={{ color: '#0D47A1', cursor: 'pointer' }}></i>
                    </div>
                </OverlayTrigger>
            </div>
        )
    }

    getListOfMonths = (allYears, year) => {
        console.log(allYears);
        for (const [key, value] of Object.entries(allYears)) {
            console.log('year', year, key);
            if (year == key) {
                console.log('in if');
                this.setState({ listOfMonthsForSelectedYear: value, month: value[value.length -1],year}, ()=>{this.getVendorData()})
            }
        }
    }

    showMemberPopUp = (cell, row) => {
        return (
            <div>
                <OverlayTrigger
                    overlay={(
                        <Tooltip id="hi">
                            View Invoices/PO for {cell.original.memberName}
                        </Tooltip>
                    )}
                    popperConfig={{ modifiers: { preventOverflow: { boundariesElement: 'viewport' } } }}
                    placement="top"
                >
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.showMemberInvoice(cell.original)
                        }}
                    >
                        <i className="fas fa-th-list" style={{ color: '#0D47A1', cursor: 'pointer' }}></i>
                    </div>
                </OverlayTrigger>
            </div>
        )
    }

    showVendorInvoice = (row) => {
        this.setState({
            vendorId: row.vendorId,
            vendorName: row.vendorName,
            showVendorInvoice: true
        }, () => {
            let selections = this.state.params;
            selections.memberId = '';
            selections.vendorId = this.state.vendorId;

            getCorporateOverviewVPInvoices(selections)
                .then((responseData) => {
                    this.setState({ selectedSupplier: responseData })
                })
                .catch((err) => {
                    console.log(err);
                })
        });
    }

    showMemberInvoice = (row) => {
        this.setState({
            memberId: row.memberId,
            memberName: row.memberName,
            showMemberInvoice: true
        }, () => {
            let selections = this.state.params;
            selections.vendorId = "";
            selections.memberId = this.state.memberId;

            getCorporateOverviewMemberInvoices(selections)
                .then((responseData) => {
                    this.setState({ selectedMember: responseData })
                })
                .catch((err) => {
                    console.log(err);
                })
        });
    }

    closeVendorInvoice = () => {
        this.setState({ showVendorInvoice: false, showMemberInvoice: false });
    }

    handleRowClickVendorPerformance = (selectedVendorId) => {
        let params = this.state.params;
        params.vendorId = selectedVendorId;

        getCorpMemberPerformanceData(params).then((data) => {
            this.setState({
                corporateVendorDataMemberPerformance: data,
                filteredSearchMemberData: data,

            })
        })
    }

    handleRowClickMemberPerformance = (selectedVendorId) => {
        let params = this.state.params;
        params.memberId = selectedVendorId;

        getCorpVendorPerformanceData(params).then((data) => {
            this.setState({
                corporateVendorDataVendorPerformance: data,
                filteredSearchVendorData: data,

            })
        })
    }

    searchTable = (e, data) => {
        this.setState({ searchKeyVendor: e.target.value });
        let results = data.filter((item) => {
            return item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isVendorFilteredDataAvailable: results.length >= 1 ? true : false, 
            filteredSearchVendorData: results
        });
    }

    handleSearchForMember = (e, data) => {
        this.setState({ searchKeyMember: e.target.value });
        let results = data.filter((item) => {
            return item.memberName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isMemberFilteredDataAvailable: results.length >= 1 ? true : false, 
            filteredSearchMemberData: results
        });
    }

    handleSearchForGrowth = (e, data) => {
        this.setState({ searchKeyGrowth: e.target.value });
        let results = data.filter(value => {
            return (
                value.vendorName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.programName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.criteria.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.currentYearSales.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.targetSales.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.gapAmount.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.attainmentPercent.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.rebateAmount.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.rebatePercent.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.vendorId.toString().toLowerCase().includes(e.target.value.toLowerCase())
            );
        });
        this.setState({
            isGrowthFilteredDataAvailable: results.length >= 1 ? true : false, 
            filteredSearchGrowthData: results
        });
    }

    clearTable = () => {
        this.setState({
            searchKeyVendor: "",
            isVendorFilteredDataAvailable: true,
            filteredSearchVendorData: [...this.state.corporateVendorDataVendorPerformance],
            selectedTableRowIndex: null
        })

        let params = this.state.params;
        params.vendorId = '';

        getCorpMemberPerformanceData(params).then((data) => {
            this.setState({
                corporateVendorDataMemberPerformance: data,
                filteredSearchMemberData: data,
                isMemberFilteredDataAvailable: true
            })
        })
    }

    clearButtonForMember = () => {
        this.setState({
            searchKeyMember: "",
            isMemberFilteredDataAvailable: true,
            filteredSearchMemberData: [...this.state.corporateVendorDataMemberPerformance],
            selectedTableRowIndexForMember: null
        });

        let params = this.state.params;
        params.memberId = '';

        getCorpVendorPerformanceData(params).then((data) => {
            this.setState({
                corporateVendorDataVendorPerformance: data,
                filteredSearchVendorData: data,
                isVendorFilteredDataAvailable: true
            })
        })
    }

    clearButtonForGrowth = () => {
        this.setState({
            searchKeyGrowth: "",
            isGrowthFilteredDataAvailable: true,
            filteredSearchGrowthData: [...this.state.corporateVendorDataGrowthRebate],
        });

        let params = this.state.params;

        getCorpVendorDataGrowthData(params).then((data) => {
            this.setState({
                corporateVendorDataVendorPerformance: data,
                filteredSearchGrowthData: data,
                isGrowthFilteredDataAvailable: true
            })
        })
    }

    handleChangeOfDropdownextra(event) {
        let obj = {};
        obj[event.name] = event.value;

        this.setState(obj, () => {
            this.setFilter({
                buyingGroupId: this.state.buyingGroupId,
                isCompliant: this.state.isCompliant,
                year: this.state.year,
                month: this.state.month,
                period: this.state.period,
                quarter: this.state.quarter,
                isQuarterSelected: this.state.period === "QTR" ? true : false
            });
        });
    }

    handleBranch = (e) => {
        this.setState((prevState) => ({
            buyingGroupId: e.target.value,
            params: {
                ...prevState.params,
                buyingGroupId: e.target.value
            }
        }));
    }

    handleCompliant = (e) => {
        this.setState((prevState) => ({
            isCompliant: e.target.value,
            params: {
                ...prevState.params,
                isCompliant: e.target.value
            }
        }));
    }

    handlePeriod = (e) => {
        if(e.target.name === "QTR") {
            this.setState((prevState) => ({
                isQuarterSelected: true,
                period: e.target.name,
                params: {
                    ...prevState.params,
                    period: e.target.name
                }
            }));
        } else {
            this.setState((prevState) => ({
                isQuarterSelected: false,
                period: e.target.name,
                params: {
                    ...prevState.params,
                    period: e.target.name
                }
            }));
        }
    }

    handleYear = (e) => {
        this.setState((prevState) => ({
            year: e.target.value,
            params: {
                ...prevState.params,
                year: e.target.value
            },
            searchKeyVendor: "",
            searchKeyMember: "",
            searchKeyGrowth: "",
            filteredSearchVendorData: [],
            filteredSearchMemberData: [],
            filteredSearchGrowthData: []
        }), () => {
            this.getListOfMonths(this.state.coporateVendorDataFilters, this.state.year);
        });
    }

    handleMonth = (e) => {
        this.setState((prevState) => ({
            month: e.target.value,
            params: {
                ...prevState.params,
                month: e.target.value
            }
        }));
    }

    handleQuarter = (e) => {
        this.setState((prevState) => ({
            quarter: e.target.value,
            params: {
                ...prevState.params,
                quarter: e.target.value
            }
        }));
    }

    render() {
        let {
            period,
            // source,
            // ytd,
            // qtr,
            // mth,
            // year,
            // month,
            // quarter,
            // buyingGroupId,
            // isCompliant,
            // subTabVendor,
            // subTabMember,
            // startRowNo,
            // params,
            listOfYears,
            listOfMonthsForSelectedYear,
            corporateVendorDataCardItems,
            isQuarterSelected
        } = this.state;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <div>
                {/* <Filters /> */}
                <div className="filtersContainer" >
                    <div className="filterTitle">
                        <p>Supplier Data</p>
                    </div>
                    <div className="filterItems">
                        <Form.Group as={Col} id="group1">
                            <Form.Control
                                as="select"
                                defaultValue="0"
                                id="select1"
                                onChange={(e) => { this.handleBranch(e) }}>
                                <option value="0">IMARK ELECTRICAL & IMARK LLC</option>
                                <option value="1">IMARK LLC</option>
                                <option value="2">IMARK ELECTRICAL</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} id="group2">
                            <Form.Control
                                as="select"
                                defaultValue="A"
                                id="select2"
                                onChange={(e) => { this.handleCompliant(e) }}
                            >
                                <option value="A">All</option>
                                <option value="Y">Compliant</option>
                                <option value="N">Non-Compliant</option>
                            </Form.Control>
                        </Form.Group>
                        <ButtonGroup className="yearGroup">
                            <Button
                                onClick={(e) => { this.handlePeriod(e) }}
                                style={{ fontSize: 12, background: `${period === "MTH" ? "#0074d9" : "white"}`, color: `${period === "MTH" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="MTH"
                            >
                                MTH
                            </Button>
                            <Button
                                onClick={(e) => { this.handlePeriod(e) }}
                                style={{ fontSize: 12, background: `${period === "YTD" ? "#0074d9" : "white"}`, color: `${period === "YTD" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="YTD"
                            >
                                YTD
                            </Button>
                            <Button
                                onClick={(e) => { this.handlePeriod(e) }}
                                style={{ fontSize: 12, background: `${period === "QTR" ? "#0074d9" : "white"}`, color: `${period === "QTR" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="QTR"
                            >
                                QTR
                            </Button>
                        </ButtonGroup>
                        <Form.Group as={Col} id="group3">
                            <Form.Control
                                as="select"
                                id="select2"
                                onChange={(e) => { this.handleYear(e) }}>
                                {listOfYears && listOfYears.map((year) => {
                                    return <option key={year} selected={this.state.year==year} value={year}>{year}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        {!isQuarterSelected ?
                            <Form.Group as={Col} id="group4">
                                <Form.Control
                                    as="select"
                                    defaultValue={listOfMonthsForSelectedYear[listOfMonthsForSelectedYear.length - 1]}
                                    id="select3"
                                    onChange={(e) => { this.handleMonth(e) }}
                                    style={{ minWidth: 100 }}
                                >
                                    {listOfMonthsForSelectedYear && listOfMonthsForSelectedYear.map((month) => {
                                        return <option key={month} selected={this.state.month==month} value={month}>{switchMonths(month)}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        :
                            <Form.Group as={Col} id="group4">
                                <Form.Control
                                    as="select"
                                    defaultValue={"1"}
                                    id="select4"
                                    onChange={(e) => { this.handleQuarter(e) }}
                                    style={{ minWidth: 100 }}
                                >
                                    <option value="1">QTR1</option>
                                    <option value="2">QTR2</option>
                                    <option value="3">QTR3</option>
                                    <option value="4">QTR4</option>
                                </Form.Control>
                            </Form.Group>
                        }
                        <ExcelFile filename="SUPPLIER DATA" element={<Button className="export" color="primary" onClick={(e) => { console.log("hey clicked", this.state.corporateVendorDataGrowthRebate) }} style={{ fontSize: 12 }}>Export</Button>}>
                            <ExcelSheet data={this.state.corporateVendorDataGrowthRebate} name="GROWTH REBATE">
                                <ExcelColumn label="VENDOR ID" value="vendorId" />
                                <ExcelColumn label="VENDOR NAME" value="vendorName" />
                                <ExcelColumn label="PROGRAM NAME" value="programName" />
                                <ExcelColumn label="TIER" value="tier" />
                                <ExcelColumn label="CRITERIA" value={(col) => col.criteria[0]} />
                                <ExcelColumn label="ACTUAL SALES" value={(col) => col.currentYearSales[0]} />
                                <ExcelColumn label="TARGET SALES" value={(col) => col.targetSales[0]} />
                                <ExcelColumn label="GAP" value={(col) => col.gapAmount[0]} />
                                <ExcelColumn label="ATTAINMENT PERCENT" value={(col) => col.attainmentPercent[0]} />
                                <ExcelColumn label="REBATE AMOUNT" value="rebateAmount" />
                                <ExcelColumn label="REBATE %" value="rebatePercent" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.corporateVendorDataVendorPerformance} name="VENDOR PERFORMANCE">
                                <ExcelColumn label="VENDOR ID" value="vendorId" />
                                <ExcelColumn label="VENDOR NAME" value="vendorName" />
                                <ExcelColumn label="VENDOR SALES" value="vendorSales" />
                                <ExcelColumn label="TOTAL REBATE" value="vendorTotalRebate" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.corporateVendorDataMemberPerformance} name="MEMBER PERFORMANCE">
                                <ExcelColumn label="MEMBER ID" value="memberId" />
                                <ExcelColumn label="MEMBER NAME" value="memberName" />
                                <ExcelColumn label="MEMBER PURCHASE" value="vendorSales" />
                                <ExcelColumn label="TOTAL REBATE" value="vendorTotalRebate" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>

                {/* <pre style={{ fontSize: 10 }}>
                    {JSON.stringify({
                        params,
                        period,
                        source,
                        ytd,
                        qtr,
                        mth,
                        year,
                        month,
                        quarter,
                        buyingGroupId,
                        isCompliant,
                        subTabVendor,
                        subTabMember,
                        startRowNo,
                        listOfMonthsForSelectedYear: JSON.stringify(listOfMonthsForSelectedYear, null, 2)
                    }, null, 2)}
                </pre> */}

                <Cards cardItems={corporateVendorDataCardItems} />

                <div className="card-group shadow-sm mb-3" style={{ marginLeft: "60px", marginRight: "60px" }}>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Supplier Performance</span>
                        </div>
                        <div style={{ margin: '10px 10px 0 10px' }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for vendor performance info.."
                                    aria-label="Search for vendor performance info.."
                                    aria-describedby="basic-addon2"
                                    value={this.state.searchKeyVendor}
                                    onChange={(e) => this.searchTable(e, this.state.corporateVendorDataVendorPerformance)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => this.clearTable()}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <div>
                            <ReactTable
                                data={this.state.isVendorFilteredDataAvailable ? this.state.filteredSearchVendorData : []}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Name</b>,
                                        accessor: "vendorName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Sales</b>,
                                        accessor: 'vendorSales',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.vendorSales)}</div>,
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>IMARK Remits</b>,
                                        accessor: 'verifiedSales',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.verifiedSales)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}> IMARK Rebate (SAP)</b>,
                                        accessor: 'verifiedTotalRebate',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.verifiedTotalRebate)}</div>,
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Dashboard</b>,
                                        accessor: 'info',
                                        sortable: false,
                                        resizable: true,
                                        width: 40,
                                        style: { textAlign: "center" },
                                        Cell: props => (
                                            <div onClick={() => {
                                                this.props.updateSelectedNavIndex(1)
                                                localStorage.setItem('imarkElectricalSupplierId', props.original.vendorId)
                                                sessionStorage.setItem('imarkElectricalSupplierId', props.original.vendorId)
                                                localStorage.setItem('imarkElectricalSupplierName', props.original.vendorName)
                                                sessionStorage.setItem('imarkElectricalSupplierName', props.original.vendorName)

                                                process.env.REACT_APP_API_ROOT_URL === BASE_URL ? window.open(`${process.env.PUBLIC_URL}/#/supplier/${localStorage.getItem('imarkElectricalSupplierId') && localStorage.getItem('imarkElectricalSupplierId')}/overview`, '_blank') : window.open(`${process.env.PUBLIC_URL}/#/supplier/overview`)
                                                window.scrollTo({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}>
                                                <i
                                                    className="fas fa-laptop"
                                                    style={{ color: '#0D47A1', cursor: 'pointer' }}
                                                >
                                                </i>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>View Invoice/PO</b>,
                                        accessor: 'info',
                                        sortable: false,
                                        resizable: true,
                                        style: { textAlign: "center" },
                                        Cell: this.showVendorPopUp
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "verifiedSales",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px", margin: 10 }}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: e => {
                                                this.setState({ selectedTableRowIndex: rowInfo.index })
                                                this.handleRowClickVendorPerformance(rowInfo.original.vendorId);
                                            },
                                            style: {
                                                background:
                                                    rowInfo.index === this.state.selectedTableRowIndex ? "lightgrey" : null
                                            }
                                        };
                                    } else {
                                        return {};
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Member Performance</span>
                        </div>
                        <div style={{ margin: '10px 10px 0 10px' }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for member performance info.."
                                    aria-label="Search for member performance info.."
                                    aria-describedby="basic-addon2"
                                    value={this.state.searchKeyMember}
                                    onChange={(e) => this.handleSearchForMember(e, this.state.corporateVendorDataMemberPerformance)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => this.clearButtonForMember()}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>

                        <div>
                            <ReactTable
                                data={this.state.isMemberFilteredDataAvailable ? this.state.filteredSearchMemberData : []}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                        accessor: "memberName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Purchase</b>,
                                        accessor: 'memberSales',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.memberSales)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Purchase</b>,
                                        accessor: 'vendorSales',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.vendorSales)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>IMARK Remits</b>,
                                        accessor: 'verifiedSales',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props =><div style={{textAlign:'center'}}>{PriceFormatter1(props.original.verifiedSales)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Dashboard</b>,
                                        accessor: 'info',
                                        sortable: false,
                                        resizable: true,
                                        width: 40,
                                        style: { textAlign: "center" },
                                        Cell: props => (
                                            <div onClick={() => {
                                                this.props.updateSelectedNavIndex(1);
                                                
                                                localStorage.setItem('imarkElectricalMemberId', props.original.memberId)
                                                sessionStorage.setItem('imarkElectricalMemberId', props.original.memberId)
                                                localStorage.setItem('imarkElectricalMemberName', props.original.memberName)
                                                sessionStorage.setItem('imarkElectricalMemberName', props.original.memberName)
                                                localStorage.setItem('groupName', props.original.memberName)
                                                sessionStorage.setItem('groupName', props.original.memberName)


                                                window.open(`${process.env.PUBLIC_URL}/#/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}`, '_blank')
                                                window.scrollTo({
                                                    top: 0,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}>
                                                <i
                                                    className="fas fa-laptop"
                                                    style={{ color: '#0D47A1', cursor: 'pointer' }}
                                                >
                                                </i>
                                            </div>
                                        )
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>View Invoice/PO</b>,
                                        accessor: 'info',
                                        sortable: false,
                                        resizable: true,
                                        style: { textAlign: "center" },
                                        Cell: this.showMemberPopUp
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "verifiedSales",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px", margin: '10px' }}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: e => {
                                                this.setState({ selectedTableRowIndexForMember: rowInfo.index });
                                                this.handleRowClickMemberPerformance(rowInfo.original.memberId);
                                            },
                                            style: {
                                                background:
                                                    rowInfo.index === this.state.selectedTableRowIndexForMember ? "lightgrey" : null
                                            }
                                        };
                                    } else {
                                        return {};
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="card shadow-sm mb-3" style={{ marginLeft: "60px", marginRight: "60px" }}>
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <span>Group Growth Rebate</span>
                    </div>
                    <div className="card-body">
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for growth rebate info.."
                                    aria-label="Search for growth rebate info.."
                                    aria-describedby="basic-addon2"
                                    value={this.state.searchKeyGrowth}
                                    onChange={(e) => this.handleSearchForGrowth(e, this.state.corporateVendorDataGrowthRebate)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => this.clearButtonForGrowth()}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isGrowthFilteredDataAvailable ? this.state.filteredSearchGrowthData : []}
                            columns={[
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>ID</b>,
                                    accessor: "vendorId",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Name</b>,
                                    accessor: "vendorName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Program</b>,
                                    accessor: "programName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Criteria</b>,
                                    accessor: 'criteria',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Level</b>,
                                    accessor: 'tier',
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => (
                                        <div style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            border: '1px solid #2b8ebb',
                                            textAlign: 'center',
                                            width: 'max-content',
                                            padding: '0 10px',
                                            color: '#2b8ebb',
                                            fontWeight: 600
                                        }}>
                                            {props.original.tier}
                                        </div>
                                    )
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Actual Volume</b>,
                                    accessor: 'currentYearSales[0]',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.currentYearSales && props.original.currentYearSales[0] && props.original.currentYearSales[0])}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Target</b>,
                                    accessor: 'targetSales[0]',
                                    sortable: true,
                                    resizable: true,
                                    style: { color: "green", 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.targetSales && props.original.targetSales[0] && props.original.targetSales[0])}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Gap</b>,
                                    accessor: 'gapAmount[0]',
                                    sortable: true,
                                    resizable: true,
                                    style: { color: "green", 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.gapAmount && props.original.gapAmount[0] && props.original.gapAmount[0])}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Attainment (%)</b>,
                                    accessor: 'attainmentPercent[0]',
                                    sortable: true,
                                    resizable: true,
                                    style: { textAlign: "center" },
                                    Cell: props => <span style={{
                                        width: 'max-content',
                                        padding: '2px 10px',
                                        borderRadius: '25px',
                                        color: '#FFFFFF',
                                        background:
                                            props.original.attainmentPercent > 99 && props.original.attainmentPercent <= 100 ? '#1a237e'
                                                : props.original.attainmentPercent < 99 && props.original.attainmentPercent > 75 ? '#7986cb'
                                                    : '#c5cae9'
                                    }}>
                                        {PercentFormatter(props.original.attainmentPercent)}
                                    </span>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Rebate</b>,
                                    accessor: 'rebateAmount',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.rebateAmount)}</div>
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Rebate (%)</b>,
                                    accessor: 'rebatePercent',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'center' },
                                    Cell: props =><div style={{textAlign:'center'}}>{PercentFormatter(props.original.rebatePercent)}</div>
                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: "attainmentPercent[0]",
                                    desc: true
                                }
                            ]}
                           defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                    <div className="card-footer" style={{ textAlign: 'center' }}>
                        All Records are YTD till Sep-2020
                    </div>
                </div>

                {this.state.showVendorInvoice ?
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog" role="document" style={{ minWidth: "100px" }}>
                            <div className="modal-content" style={{ width: "700px" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" style={{ color: '#0D47A1' }} > Invoices Per PO for {this.state.vendorName}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { this.closeVendorInvoice() }} >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" >
                                    <ReactTable
                                        data={this.state.selectedSupplier}
                                        columns={[
                                            {
                                                Header: <b>Member ID</b>,
                                                accessor: "memberId",
                                                sortable: true,
                                                resizable: true,
                                                width: 250
                                            },
                                            {
                                                Header: <b>Member Name</b>,
                                                accessor: 'memberName',
                                                sortable: true,
                                                resizable: true,
                                                width: 150,
                                            },
                                            {
                                                Header: <b>Ratio</b>,
                                                accessor: 'ratio',
                                                sortable: true,
                                                resizable: true,
                                                width: 200,
                                            }
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "verifiedSales",
                                                desc: true
                                            }
                                        ]}
                                        defaultPageSize={25}
                                        style={{ height: "400px", margin: 10 }}
                                        className="-striped -highlight"
                                    />
                                    <div >
                                        <span className="errorText">{ }</span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={(e) => { this.closeVendorInvoice() }} className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }

                {this.state.showMemberInvoice ?
                    <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog" role="document" style={{ minWidth: "100px" }}>
                            <div className="modal-content" style={{ width: "700px" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" style={{ color: '#0D47A1' }} > Invoices Per PO for {this.state.memberName}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { this.closeVendorInvoice() }} >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" >
                                    <ReactTable
                                        data={this.state.selectedMember}
                                        columns={[
                                            {
                                                Header: <b>Supplier ID</b>,
                                                accessor: "vendorId",
                                                sortable: true,
                                                resizable: true,
                                                width: 250
                                            },
                                            {
                                                Header: <b>Supplier Name</b>,
                                                accessor: 'vendorName',
                                                sortable: true,
                                                resizable: true,
                                                width: 150,
                                            },
                                            {
                                                Header: <b>Ratio</b>,
                                                accessor: 'ratio',
                                                sortable: true,
                                                resizable: true,
                                                width: 200,
                                            }
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "verifiedSales",
                                                desc: true
                                            }
                                        ]}
                                        defaultPageSize={25}
                                        style={{ height: "400px", margin: 10 }}
                                        className="-striped -highlight"
                                    />
                                    <div >
                                        <span className="errorText">{}</span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={(e) => { this.closeVendorInvoice() }} className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div> : ''
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateVendorData);
