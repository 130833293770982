import React from 'react';
import { Card } from 'react-bootstrap';
import { switchIndicator } from '../../../utils/switchIndicator';
import { formatYOY, priceFormatter } from '../../../utils/formatters';

import '../../../assets/styles/SupplierCards.scss';
import moment from 'moment'

const Cards = ({ cardItems }) => {
    return (
        <div className="vendorCardsContainer">
            {cardItems && cardItems.map((card, index) => {
                return (
                    <Card className="rebateCard" key={index} style={{
                        background:
                            card.cardTitle === "IMARK Total Sales" || card.cardTitle === "Total IMARK Remits (SAP)" ? `rgb(52, 152, 219)` :
                                card.cardTitle === "Vendor Total Sales" || card.cardTitle === "Total Supplier Invoices" ? `#28a745` :
                                    card.cardTitle === "Sales Opportunity ($)" ? `rgb(230, 126, 34)` : null,
                        color: '#FFFFFF'
                    }}>
                        <Card.Body>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Card.Text className="cardTitle" style={{ color: '#FFFFFF' }}>{card.cardTitle}</Card.Text>
                                {card.updateDate!=""?<Card.Text className="cardUpdatedOn" style={{ color: '#FFFFFF' }}> Data Thru<br /> {card.updated_on}</Card.Text>:<Card.Text />}
                            </div>
                            <div>
                                <Card.Text className="supplierCardSubtitle" style={{ width: '70%', fontSize: '18px', fontWeight: 500 }}>{priceFormatter(card.value)}</Card.Text>
                                {card.updateDate!=""?<Card.Text className="supplierCardSubtitle" style={{ width: '30%', float: 'right', textAlign: 'center' }}>{moment(card.updateDate ? card.updateDate : '-').format('MM/DD/YYYY')}</Card.Text>:<Card.Text />}
                            </div>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
            })}
        </div>
    );
};

export default Cards;