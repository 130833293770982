import Axios from 'axios';
import React from 'react';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logout } from '../../actions';
import contactUs from '../../assets/images/contactUs.svg';
import imarkLogo from '../../assets/images/imarkLogo.jpg';
import logoutImg from '../../assets/images/logoutImg.svg';
import '../../assets/styles/Navigation.scss';
import MemberConversionOpportunity from './Conversion';
import MemberInvoices from './Invoices';
import MemberOverview from './Overview';
import MemberPurchaseOrders from './PurchaseOrders';
import MemberImarkVerified from './Verified';
import { getVisitedTabId } from '../../utils/getVisitedTabId';

import { updateSelectedNavIndex } from '../../actions';

class Member extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { memberId: 0 };
    }

    static getDerivedStateFromProps(props) {
        console.log('Member ID in Member Component', props.match.params.id);
        return { memberId: props.match.params.id };
    }
    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);
    }

    setNavIndex = (idx) => this.props.updateSelectedNavIndex(idx);
    handleLogout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userType');
        this.props.logoutAPI();
        window.location.hash = '/';
    }
    
    handleDashboardLink() {
        Axios.get('/sso/switch-dashboard ', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'mode': 'cors',
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            },
        }).then((res) => {
            console.log(res)
            if (res.status == 200) {
                console.log(res.data.redirectUrl)
                window.location = (res.data.redirectUrl);
            }
        })
    }

    render() {
        const { memberId } = this.state;
        return <React.Fragment>
            <div className="navigationContainer">
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Navbar.Brand>
                        <img src={imarkLogo} alt="noImage" height="32" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="navbar-nav mr-auto">
                            <Link className="nav-link" to={'/member/' + memberId}>Member Overview</Link>
                            <Link className="nav-link" to={'/member/' + memberId + '/verified'}>IMARK Remits</Link>
                            <Link className="nav-link" to={'/member/' + memberId + '/invoices'}>Supplier Invoices</Link>
                            <Link className="nav-link" to={'/member/' + memberId + '/purchase-orders'}>Member Invoices</Link>
                            {/* <Link className="nav-link" to={'/member/' + memberId + '/conversion'}>Conversion Opportunity</Link> */}
                            <Link className="nav-link" to={'/member/' + memberId + '/conversion'}>IMARK Electrical Targets</Link>

                        </Nav>
                        <Nav className="nav-right">
                            {/* <Button className="demo-dashboard-btn" onClick={(e) => { this.handleDashboardLink() }}>{'https://imark-electrical.tredence.com/imark-electrical' === process.env.REACT_APP_API_ROOT_URL ? 'Demo Dashboard' : 'Go to Dashboard'}</Button>
                             */}
                            <div>
                                {/* <img className="contact-us" style={{ cursor: 'pointer' }} src={contactUs} alt="noImage" height="20" /> */}
                                <a href="mailto:imarkelectrical.helpdesk@tredence.com,imark.support@tredence.com" className="btn btn-link" data-placement="bottom" data-container="body" data-title="Contact Us"><i className="fas fa-envelope"></i></a>

                                <img className="logout" style={{ cursor: 'pointer' }} src={logoutImg} alt="noImage" height="20"
                                    onClick={() => this.handleLogout()}
                                />
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            <div className="memberContent">
                <Route exact path="/member/:id" component={MemberOverview} />
                <Route exact path="/member/:id/verified" component={MemberImarkVerified} />
                <Route exact path="/member/:id/invoices" component={MemberInvoices} />
                <Route exact path="/member/:id/purchase-orders" component={MemberPurchaseOrders} />
                <Route exact path="/member/:id/conversion" component={MemberConversionOpportunity} />
            </div>
        </React.Fragment>;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.authentication.isLoggedIn
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,

        logoutAPI: logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Member);
