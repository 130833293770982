import React from 'react';
import { Card } from 'react-bootstrap';
import { switchIndicator } from '../../utils/switchIndicator';
import { formatYOY, priceFormatter } from '../../utils/formatters';
import '../../assets/styles/Cards.scss';

const Cards = ({ cardItems }) => {
    return (
        <div className="cardsContainer">
            {cardItems && cardItems.map((card, index) => {
                if(card.cardTitle == 'Potential Purchase')
                {
                    return (
                        <Card className="rebateCard" key={index}>
                            <Card.Body>
                                <Card.Text className="cardTitle">Potential Purchase (Some product lines excluded)</Card.Text>
                                <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                                <div className="cardBottom">
                                    <Card.Text>YOY</Card.Text>
                                    <div className="cardRatings">
                                        <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                        {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    )
                }
                else{
                return (
                    <Card className="rebateCard" key={index}>
                        <Card.Body>
                            <Card.Text className="cardTitle">{card.cardTitle}</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )
                }
            })}
        
        </div>
    );
};

export default Cards;