import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';
import VerifiedFilters from './VerifiedFilters/VerifiedFilters';
import Cards from '../../../Common/Cards/Cards';
import C3 from '../../../Common/C3/C3';
import PurchaseWithinImarkVerified from './PurchaseWithinImarkVerified/PurchaseWithinImarkVerified';
import { priceFormatter } from '../../../utils/formatters';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
// import { convertJSONToURLParams } from '../../../utils/formatters';

import {
    updateSelectedNavIndex,
    // getCardsDataForIMARKVerifiedDataTab,
    // getVerifiedRebateTrendsGraph,
    // getbHverifiedVendorPartnerPurchases
} from '../../../actions';
import {
    getMemberVerifiedMonthsFilter,
    getFilterMemberIds,
    getCardsDataForIMARKVerifiedDataTab,
    getVerifiedRebateTrendsGraph,
    getbHverifiedVendorPartnerPurchases
} from '../../../api/member-api';

class MemberImarkVerified extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            isLoaded: false,
            isContentLoaded: false,
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: "YTD",
            quarter: "4",
            branchName: "",
            imarkVerifiedCardItems: [],
            rebateGraphDataImarkVerified: [],
            purchaseWithinImarkVerified: [],
            memberVerifiedMonthsFilter: [],
            listOfYears: [],
            listOfMonthsForSelectedYear: [],
            filterMemberIds: [],
            memberName: sessionStorage.getItem('groupName')
        }
    }

    // static getDerivedStateFromProps(props) {
    //     console.log('Member ID in Member Component', props.match.params.id);
    //     return { memberId: props.match.params.id };
    // }

    componentDidMount() {
      
        this.loadData();
        this.setState( { memberId: this.props.match.params.id },() => {
            let idx = getVisitedTabId(window.location.hash);
            this.props.updateSelectedNavIndex(idx);
            this.loadData();
    }
        )
    }

    componentDidUpdate(pP, pS, sS) {
        if(pS.year !== this.state.year) {
            this.getImarkVerifiedData();
        }

        if(pS.month !== this.state.month) {
            this.getImarkVerifiedData();
        }

        if(pS.period !== this.state.period) {
            this.getImarkVerifiedData();
        }

        if(pS.quarter !== this.state.quarter) {
            this.getImarkVerifiedData();
        }

        if(pS.memberId !== this.state.memberId && pS.branchName !== this.state.branchName) {
            this.getImarkVerifiedData();
        }
        if(pS.memberId !== this.state.memberId ) {
            this.getImarkVerifiedData();
        }
    }

    loadData = () => {
        this.mounted = true;

        // const selections = convertJSONToURLParams(formData);
        // this.props.getCardsDataForIMARKVerifiedDataTabAPI(selections)
        // this.props.getVerifiedRebateTrendsGraphAPI(selections)
        // this.props.getbHverifiedVendorPartnerPurchasesAPI(selections)

        Promise.allSettled([
            getFilterMemberIds({ memberId: this.state.memberId, year: this.state.year }),

            getMemberVerifiedMonthsFilter({ memberId: this.state.memberId }),
            //getFilterMemberIds({ memberId: this.state.memberId, year: this.state.year })
        ])
        .then((allResponses) => {
            console.log('MEMBER-IMARK-VERIFIED ON MOUNT ==>', allResponses);

            if(this.mounted) {
                if(allResponses[0].value) {
                    this.setState({
                        filterMemberIds: allResponses[0].value ? allResponses[0].value : [],
                        memberId: allResponses[0].value[0].branchId,
                        // branchName: allResponses[1].value && allResponses[1].value.length >= 1 ? allResponses[1].value.find((item) => item.mainBranch).branchName : ""
                    })
                }
                if(allResponses[1].value) {
                    const availableYears = Object.keys(allResponses[1].value).sort((a, b) => b - a);

                    this.setState({
                        memberVerifiedMonthsFilter: allResponses[1].value ? allResponses[1].value : {},
                        listOfYears: allResponses[1].value && Object.keys(allResponses[1].value),
                        // year: allResponses[0].value && Object.keys(allResponses[0].value)[0]
                    })
                    this.getListOfMonths(allResponses[1].value ,availableYears[0]);
                }
                
            }

            this.setState({ isLoaded: true })
        })
        .catch((err) => {
            console.log(err);
        })
    }

    formatCards = (cards) => {
        // cards.forEach(card => {
        //     let cardTitle = card.cardTitle
        //         var day = cardTitle;
        //         switch (cardTitle) {
        //           case "Total Purchases":
        //             day = "Member Preferred Remits";
        //             break;
        //           case "Total Base Rebate":
        //             day = "Total Estimated Base Rebate";
        //             break;
        //           case "Total Gain Share":
        //             day = "Total Estimated Gain Share";
        //             break;
        //           case "Total Revenue":
        //             day = "Total Estimated Rebate";
        //             break;
        //         }
        //         card.cardTitle = day
        // });
        this.setState({ imarkVerifiedCardItems: cards })
        // if(this.state.imarkVerifiedCardItems[0].value !='0'){

        console.log('state checking1',this.state.imarkVerifiedCardItems)
        // }
        // if(this.state.imarkVerifiedCardItems[0].value != '0'){

        // }

    }

    getImarkVerifiedData = () => {
        this.mounted = true;

        this.setState({ isContentLoaded: true })

        const formData = {
            memberId: this.state.memberId,
            period: this.state.period,
            quarter: this.state.quarter,
            month: this.state.month,
            year: this.state.year
        }

        Promise.allSettled([
            getCardsDataForIMARKVerifiedDataTab(formData),
            getVerifiedRebateTrendsGraph(formData),
            getbHverifiedVendorPartnerPurchases(formData)
        ])
        .then((allResponses) => {
            console.log('MEMBER-IMARK-VERIFIED ON CHANGE ==>', allResponses);

            if(this.mounted) {
                if(allResponses[0].value) {
                    // this.setState({ imarkVerifiedCardItems: allResponses[0].value ? allResponses[0].value : [] })
                    // if(allResponses[0].value[0].value != '0'){
                    this.formatCards(allResponses[0].value)
                    // if(allResponses[0].value[0].value != '0')
                    console.log('allresponses value',allResponses[0].value)
                    // }

                }
                if(allResponses[1].value) {
                    this.setState({ rebateGraphDataImarkVerified: allResponses[1].value ? allResponses[1].value : [] })
                }
                if(allResponses[2].value) {
                    this.setState({ purchaseWithinImarkVerified: allResponses[2].value ? allResponses[2].value : [] })
                }
            }

            this.setState({ isContentLoaded: false })
        })
        .catch((err) => {
            console.log(err);
        })
    }

    getListOfMonths = (allYears, year) => {
        console.log('all', allYears, year);
        for (const [key, value] of Object.entries(allYears)) {
            if(year == key) {
                this.setState({ listOfMonthsForSelectedYear: value, month: value[value.length - 1],year })
            }
        }
    }

    handleYear = (e) => {
        this.setState({ year: e.target.value }, () => {
            this.getListOfMonths(this.state.memberVerifiedMonthsFilter, e.target.value)
        });
    }

    handleMonth = (e) => this.setState({ month: e.target.value });
    handlePeriod = (e) => this.setState({
        period: e.target.name,
        isQuarterSelected: e.target.name === "QTR" ? true : false,
        quarter: (e.target.name === "YTD" || e.target.name === "MTH") ? "1" : "1"
    });
    handleQuarter = (e) => this.setState({ quarter: e.target.value })

    handleBranch = (e) => {
        if(e.target.value) {
            this.setState({
                memberId: e.target.value
            })
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const {
            isLoaded,
            isContentLoaded,
            branchId,
            // memberId,
            year,
            month,
            period,
            // quarter,
            branchName,
            imarkVerifiedCardItems,
            rebateGraphDataImarkVerified,
            purchaseWithinImarkVerified,
            // memberVerifiedMonthsFilter,
            listOfYears,
            listOfMonthsForSelectedYear,
            filterMemberIds,
            isQuarterSelected
        } = this.state;

        // let {
        //     imarkVerifiedCardItems,
        //     rebateGraphDataImarkVerified,
        //     purchaseWithinImarkVerified
        // } = this.props;

        let imarkVerifiedTotalPurchases = ['Member Preferred Remits ($)', 606000, 890000, 900000, 1298567, 1595893, 1900765, 2700657, 3302882, 3699798 ];
        let imarkVerifiedTotalRebate = ['Total Estimated Rebate ($)', 28170.8747342423, 41373.0668539201, 41837.9327736271, 60365.9542756118, 74187.6267198912, 88360.0869871815, 125544.340011806, 153539.727861359, 171991 ];

        let imarkVerifiedRebateEarnedCY = ['Estimated Rebate Earned CY ($)', 606000, 890000, 900000, 1298567, 1595893, 1900765, 2700657, 3302882, 3699798 ];
        let imarkVerifiedRebateEarnedPY = ['Rebate Earned PY ($)',  545500, 801000, 810000, 1168710, 1436304, 1710689, 2430591, 2972594, 3329818 ];

        if(rebateGraphDataImarkVerified) {
            let totalPurchases = rebateGraphDataImarkVerified.map((item) => item.totalPurchases);
            let totalRebate = rebateGraphDataImarkVerified.map((item) => item.rebateEarnedCY);

            let rebateEarnedCY = rebateGraphDataImarkVerified.map((item) => item.rebateEarnedCY);
            let rebateEarnedPY = rebateGraphDataImarkVerified.map((item) => item.rebateEarnedPY);

            imarkVerifiedTotalPurchases = ['Member Preferred Remits ($)', ...totalPurchases];
            imarkVerifiedTotalRebate = ['Total Estimated Rebate ($)', ...totalRebate];

            imarkVerifiedRebateEarnedCY = ['Estimated Rebate Earned CY ($)', ...rebateEarnedCY];
            imarkVerifiedRebateEarnedPY = ['Rebate Earned PY ($)', ...rebateEarnedPY];
        }

        const chart1 = {
            data: {
                x: 'x',
                columns: [
                    ['x', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                    imarkVerifiedTotalPurchases,
                    imarkVerifiedTotalRebate
                ],
                type: 'line'
            },
            axis: {
                x: {
                    label: "Months",
                    type: 'category'
                },
                y: {
                    label: "Member Preferred Remits ($)",
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            }
        }
        
        const chart2 = {
            data: {
                x: 'x',
                columns: [
                    ['x', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                    imarkVerifiedRebateEarnedCY,
                    imarkVerifiedRebateEarnedPY
                ],
                type: 'line'
            },
            axis: {
                x: {
                    label: "Months",
                    type: 'category'
                },
                y: {
                    label: "Estimated Rebate Earned CY ($)",
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            }
        };
        
        console.log('imarkVerifiedCardItems', imarkVerifiedCardItems);
        return !isLoaded ? <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <Spinner animation="border" variant="primary" />
        </div> :
            <div>
                <VerifiedFilters
                    year={year}
                    month={month}
                    period={period}
                    branchId={branchId}
                    branchName={branchName}
                    handleYearReference={this.handleYear}
                    handleMonthReference={this.handleMonth}
                    handlePeriodReference={this.handlePeriod}
                    handleBranchReference={this.handleBranch}
                    handleQuarterReference={this.handleQuarter}
                    listOfYears={listOfYears}
                    listOfMonthsForSelectedYear={listOfMonthsForSelectedYear}
                    filterMemberIds={filterMemberIds}
                    purchaseWithinImarkVerified={purchaseWithinImarkVerified}
                    isQuarterSelected={isQuarterSelected}
                    memberName={this.state.memberName}
                    imarkVerifiedCardItems={imarkVerifiedCardItems}
                />
                {/* <pre style={{ marginLeft: 60 }}>
                    {JSON.stringify({
                        // isLoaded,
                        // isContentLoaded,
                        memberId,
                        year,
                        month,
                        period,
                        quarter,
                        branchName,
                        // memberVerifiedMonthsFilter: JSON.stringify(memberVerifiedMonthsFilter),
                        // listOfYears: JSON.stringify(listOfYears),
                        // listOfMonthsForSelectedYear: JSON.stringify(listOfMonthsForSelectedYear)
                    }, null, `\t`)}
                </pre> */}
                {isContentLoaded ?
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                        minHeight: 1000
                    }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                    <React.Fragment>
                        <Cards cardItems={imarkVerifiedCardItems} />
                        <div className="c3ChartsContainer">
                            <C3
                                heading={"Member Preferred Remits vs Total Estimated Rebate (IMARK Remits)"}
                                data={chart1.data}
                                axis={chart1.axis}
                            />
                            <C3
                                heading={"Estimated Rebate Earned CY vs Rebate Earned PY (IMARK Remits)"}
                                data={chart2.data}
                                axis={chart2.axis}
                            />
                        </div> 
                        <PurchaseWithinImarkVerified
                            purchaseWithinImarkVerified={purchaseWithinImarkVerified} month={this.state.month} year={this.state.year}
                            listOfMonthsForSelectedYear={listOfMonthsForSelectedYear}
                        />
                    </React.Fragment>}
            </div>
    }
}

function mapStateToProps(state) {
    return {
        imarkVerifiedCardItems: state.member.getCardsDataForIMARKVerifiedDataTab,
        rebateGraphDataImarkVerified: state.member.getVerifiedRebateTrendsGraph,
        purchaseWithinImarkVerified: state.member.getbHverifiedVendorPartnerPurchases
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,
        // getCardsDataForIMARKVerifiedDataTabAPI: getCardsDataForIMARKVerifiedDataTab,
        // getVerifiedRebateTrendsGraphAPI: getVerifiedRebateTrendsGraph,
        // getbHverifiedVendorPartnerPurchasesAPI: getbHverifiedVendorPartnerPurchases
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberImarkVerified);
