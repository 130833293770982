import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';

import ConversionOpportunityFilters from './ConversionOpportunityFilters/ConversionOpportunityFilters';
import TopTenMembers from './TopTenMembers/TopTenMembers';
import TopTenProductGroups from './TopTenProductGroups/TopTenProductGroups';
import OpportunityByMember from './OpportunityByMember/OpportunityByMember';
import OpportunityByProductGroup from './OpportunityByProductGroup/OpportunityByProductGroup';

import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import { updateSelectedNavIndex } from '../../../actions';
import {
    getConversionMontsFilter,
    getConversionOpportunityVendorList,
    getTopTenConversionOpportunityByMember,
    getConversionOpportunityByMember,
    getConversionOpportunityByProductGroup,
    getTopTenConversionOpportunityByProductGroup,
    getProductCategory,
    getVendorBranches
} from '../../../api/supplier-api';

import '../../../assets/styles/SupplierConversion.scss';

class SupplierConversion extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            isLoaded: false,
            isContentLoaded: false,
            isQuarterSelected: false,
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: 'YTD',
            quarter: '1',
            terminatedVendorId: "",
            terminatedVendorName: "",
            conversionMonthsFilter: [],
            listOfYears: [],
            listOfMonthsForSelectedYear: [],
            terminatedCOVendorList: [],
            topTenMembers: [],
            topTenProductGroups: [],
            opportunityByMember: [],
            opportunityByProductGroup: [],
            productDetails: [],
            productCategory: [],
            vendorOptions: [],
        }
    }

    // static getDerivedStateFromProps(props) {
    //     console.log('Vendor ID in Vendor Component', props.match.params.id);
    //     return { supplierId: props.match.params.id, vendorId: props.match.params.id };
    // }

    componentDidMount() {
        // let idx = getVisitedTabId(window.location.hash);
        // this.props.updateSelectedNavIndex(idx);
        // this.loadData();
        this.setState( { supplierId: this.props.match.params.id, vendorId: this.props.match.params.id },() =>
        {let idx = getVisitedTabId(window.location.hash);
    
            this.loadData();});
    }

    componentDidUpdate(pP, pS, sS) {
        if (pS.year !== this.state.year) {
            this.getSupplierConversionData();
        }
        if (pS.month !== this.state.month) {
            this.getSupplierConversionData();
        }
        if (pS.period !== this.state.period) {
            this.getSupplierConversionData();
        }
        if (pS.vendorId !== this.state.vendorId) {
            this.getSupplierConversionData();
        }
        if (pS.terminatedVendorId !== this.state.terminatedVendorId) {
            this.getSupplierConversionData();
        }
    }

    loadData = () => {
        this.mounted = true;

        Promise.allSettled([
            getVendorBranches({ vendorId: this.state.vendorId, year: this.state.year }),
            getConversionMontsFilter({ vendorId: this.state.vendorId }),
            getConversionOpportunityVendorList({ vendorId: this.state.vendorId }),
            
        ])
            .then((allResponses) => {
                console.log('SUPPLIER CONVERSION: ON MOUNT ==>', allResponses);

                if (this.mounted) {
                    if (allResponses[0].value) {
                        this.setState({ vendorOptions: allResponses[0].value, vendorId: allResponses[0].value[0].vendorId })
                    }
                    if (allResponses[1].value) {
                        const availableYears = Object.keys(allResponses[1].value).sort((a, b) => b - a);

                        this.setState({
                            conversionMonthsFilter: allResponses[1].value ? allResponses[1].value : {},
                            listOfYears: allResponses[1].value && Object.keys(allResponses[1].value),
                            // year: allResponses[0].value && Object.keys(allResponses[0].value)[0]
                        }, () => {
                            this.getListOfMonths(allResponses[1].value, availableYears[0])
                        })
                    }
                    if (allResponses[2].value) {
                        this.setState({ terminatedCOVendorList: allResponses[2].value ? allResponses[2].value : [] })
                    }
                    
    
                    this.setState({ isLoaded: true })
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    getSupplierConversionData = () => {
        this.mounted = true;

        let formData = {
            year: this.state.year,
            month: this.state.month,
            period: this.state.period,
            quarter: this.state.quarter,
            vendorId: this.state.vendorId,
            terminatedVendorId: this.state.terminatedVendorId
        }

        Promise.allSettled([
            getTopTenConversionOpportunityByMember(formData),
            getConversionOpportunityByMember(formData),
            getTopTenConversionOpportunityByProductGroup(formData),
            getConversionOpportunityByProductGroup(formData),
            getProductCategory(formData),
        ])
            .then((allResponses) => {
                console.log('SUPPLIER CONVERSION: ON CHANGE ==>', allResponses);

                if (this.mounted) {
                    if (allResponses[0].value) {
                        this.setState({ topTenMembers: allResponses[0].value ? allResponses[0].value : [] })
                    }
                    if (allResponses[1].value) {
                        this.setState({ opportunityByMember: allResponses[1].value ? allResponses[1].value : [] })
                    }
                    if (allResponses[2].value) {
                        this.setState({ topTenProductGroups: allResponses[2].value ? allResponses[2].value : [] })
                    }
                    if (allResponses[3].value) {
                        this.setState({ opportunityByProductGroup: allResponses[3].value ? allResponses[3].value : [] })
                    }
                    if (allResponses[4].value) {
                        this.setState({ productCategory: allResponses[4].value ? allResponses[4].value : [] })
                    }
                    
                    this.setState({ isContentLoaded: true })
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    getListOfMonths = (allYears, year) => {
        for (const [key, value] of Object.entries(allYears)) {
            if (year == key) {
                this.setState({ listOfMonthsForSelectedYear: value, month: value[value.length - 1],year })
            }
        }
    }

    handleYear = (e) => {
        this.setState({ year: e.target.value },
            () => {
                this.getListOfMonths(this.state.conversionMonthsFilter, this.state.year)
            });
    }
    handleMonth = (e) => this.setState({ month: e.target.value });
    handlePeriod = (e) => this.setState({
        period: e.target.name,
        isQuarterSelected: e.target.name === "QTR" ? true : false,
        quarter: e.target.name === "YTD" || e.target.name === "MTH" ? "1" : "1"
    });
    handleQuarter = (e) => this.setState({ quarter: e.target.value });
    handleVendorChange = (e) => {
        this.setState({ vendorId: e.target.value })
    }

    handleTerminatedVendor = (e) => {
        if (e) {
        this.setState({ terminatedVendorId: e.target.value });
        }
        else {
       this.setState({ terminatedVendorId: "", terminatedVendorName: "" });
   
        }
    }

    // handleTerminatedVendor = (option) => {
    //     debugger
    //     let e = option.value
    //     console.log(e);
    //     if (e) {
    //         let terminatedVendorId = e.vendorId;
    //         let terminatedVendorName = e.vendorName;

    //         this.setState({ terminatedVendorId, terminatedVendorName });
    //     } else {
    //         this.setState({ terminatedVendorId: "", terminatedVendorName: "" });
    //     }
    // }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const {
            isLoaded,
            isContentLoaded,
            isQuarterSelected,
            year,
            month,
            period,
            quarter,
            vendorId,
             terminatedVendorId,
             terminatedVendorName,
            listOfYears,
            listOfMonthsForSelectedYear,
            terminatedCOVendorList,
            vendorOptions,
            topTenMembers,
            topTenProductGroups,
            opportunityByMember,
            opportunityByProductGroup
        } = this.state;

        return !isLoaded ? <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <Spinner animation="border" variant="primary" />
        </div> :
            <div>
                <ConversionOpportunityFilters
                    year={year}
                    month={month}
                    period={period}
                    listOfYears={listOfYears}
                    listOfMonthsForSelectedYear={listOfMonthsForSelectedYear}
                    vendorOptions={vendorOptions}
                    handleVendorReference={this.handleVendorChange}
                    handleYearReference={this.handleYear}
                    handleMonthReference={this.handleMonth}
                    handlePeriodReference={this.handlePeriod}
                    handleQuarterReference={this.handleQuarter}
                    handleTerminatedVendorReference={this.handleTerminatedVendor}
                    terminatedCOVendorList={terminatedCOVendorList}
                    topTenMembers={topTenMembers}
                    topTenProductGroups={topTenProductGroups}
                    opportunityByMember={opportunityByMember}
                    opportunityByProductGroup={opportunityByProductGroup}
                    isQuarterSelected={isQuarterSelected}
                    supplierName={localStorage.getItem('groupName')}
                />

                {/* <pre style={{ marginLeft: 60 }}>
                    {JSON.stringify({
                        vendorId,
                        year,
                        month,
                        period,
                        quarter,
                        // terminatedVendorId,
                        // terminatedVendorName,
                        // listOfYears: JSON.stringify(listOfYears),
                        // listOfMonthsForSelectedYear: JSON.stringify(listOfMonthsForSelectedYear)
                    }, null, '\t')}
                </pre> */}

                {!isContentLoaded ?
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                        minHeight: 1000
                    }}>
                        <Spinner animation="border" variant="primary" />
                    </div>
                    :
                    <React.Fragment>
                        <div className="tableDivider">
                            <TopTenMembers
                                topTenMembers={topTenMembers}
                            />
                            <OpportunityByMember
                                opportunityByMember={opportunityByMember}
                            />
                        </div>
                        <div className="tableDivider">
                            <OpportunityByProductGroup
                                year={year}
                                month={month}
                                period={period}
                                quarter={quarter}
                                vendorId={vendorId}
                                opportunityByProductGroup={opportunityByProductGroup}
                            />
                            <TopTenProductGroups
                                topTenProductGroups={topTenProductGroups}
                            />
                        </div>
                    </React.Fragment>}
            </div>
    }
}

const mapStateToProps = state => {
    return {
        selectedNavIndex: state.navigation.selectedNavIndex,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierConversion);
