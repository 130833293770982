const Validator = require("validator");
const isEmpty = require("is-empty");

export const validateLogin = (data) => {
    let errors = {};

    // Convert empty fields to an empty string so we can use validator functions
    data.username = !isEmpty(data.username) ? data.username : "";
    data.password = !isEmpty(data.password) ? data.password : "";

    // Email checks
    if (Validator.isEmpty(data.username)) {
        errors.username = "Email field shouldn't be empty";
    } else if (!Validator.isEmail(data.username)) {
        errors.username = "Email is invalid";
    }

    // Password checks
    if (Validator.isEmpty(data.password)) {
        errors.password = "Password field shouldn't be empty";
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
};