import React from 'react';
import ReactTable from "react-table-6";
import ReactTooltip from 'react-tooltip';
import { Card, Modal, ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import { dateFormatter, priceFormatter, switchMonths } from '../../../../utils/formatters';

import '../../../../assets/styles/PurchaseWithinImarkVerified.scss';

export default class PurchaseWithinImarkVerified extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            // purchaseWithinImarkVerified: [],
            searchKey: "",
            filteredSearchResults: [],
            isFilteredDataAvailable: false
        }
    }

    handleClose = () => this.setState({ show: false })
    handleShow = () => this.setState({ show: true })

    reactBootstrapModal = () => {
        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Contact Info for Moeen Incorportaed
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <ListGroup.Item>Vendor Id</ListGroup.Item>
                            <ListGroup.Item>Vendor Name</ListGroup.Item>
                            <ListGroup.Item>Contact Address</ListGroup.Item>
                            <ListGroup.Item>Year</ListGroup.Item>
                            <ListGroup.Item>Phone</ListGroup.Item>
                            <ListGroup.Item>Email</ListGroup.Item>
                            <ListGroup.Item>Contact Person</ListGroup.Item>
                        </div>
                        <div>
                            <ListGroup.Item>40000002</ListGroup.Item>
                            <ListGroup.Item>Delta Faucet</ListGroup.Item>
                            <ListGroup.Item>55 E. 11th Street</ListGroup.Item>
                            <ListGroup.Item>2020</ListGroup.Item>
                            <ListGroup.Item>714-202-7362</ListGroup.Item>
                            <ListGroup.Item>fkc@deltafaucet.com</ListGroup.Item>
                            <ListGroup.Item>Frederick Chang</ListGroup.Item>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    showTooltip = (tier, tierNo,rebatePercent) => {
        console.log("chkkkkkkkkkkkkk",tier);
        console.log(tierNo);
        let target = priceFormatter(tier.target).props.children;
        return `
            Tier: ${tierNo}<br/>
            
            Attainment: ${tier.attainmentPercent.toFixed(2)}% <br/>

            Rebate Percent: ${rebatePercent}%
        `
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
        // filteredSearchResults: [...this.props.purchaseWithinImarkVerified]
    });

    render() {
        const {
            // purchaseWithinImarkVerified,
            searchKey,
            filteredSearchResults
        } = this.state;
        const { purchaseWithinImarkVerified } = this.props;

        return (
            <Card className="purchaseWithinImarkVerifiedContainer">
                {this.reactBootstrapModal()}
                <Card.Header> Purchases from IMARK Preferred Suppliers (SAP)</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search for Purchases from IMARK Preferred Suppliers (SAP) info.."
                                aria-label="Search for purchase within IMARK (IMARK Verified) info.."
                                aria-describedby="basic-addon2"
                                value={searchKey}
                                onChange={(e) => this.searchTable(e, purchaseWithinImarkVerified)}
                            />
                            <InputGroup.Append>
                                <Button onClick={() => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <ReactTable
                        data={this.state.isFilteredDataAvailable ? this.state.filteredSearchResults : this.props.purchaseWithinImarkVerified}
                        // filterable
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Compliant Status</b>,
                                accessor: "isCompliant",
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                Cell: props => props.original.isCompliant ?
                                    <i className="fa fa-check" style={{ color: 'green' }}></i> :
                                    <i className="fa fa-times" style={{ color: 'red' }}></i>,
                                style: { textAlign: 'center' }
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Name</b>,
                                accessor: 'vendorName',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Date</b>,
                                accessor: 'updateDateVendorData',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                Cell: props => <div style={{ textAlign: "center" }}>{dateFormatter(props.original.updateDateVendorData)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Remits Date</b>,
                                accessor: 'updateDateImarkVerified',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                Cell: props => <div style={{ textAlign: "center" }}>{switchMonths(props.original.updateDateImarkVerified)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Invoices</b>,
                                accessor: 'purchaseAmountVendorData',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { textAlign: 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.purchaseAmountVendorData)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Purchases (SAP)</b>,
                                accessor: 'purchaseAmountImarkVerified',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { textAlign: 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.purchaseAmountImarkVerified)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Group Growth</b>,
                                accessor: 'growthRebate',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { margin: '0 auto' },
                                width: 150,
                                Cell: props => props.original.growthRebate && props.original.growthRebate[0] &&
                                    props.original.growthRebate[0].rebateTier.map((item) => {
                                        return item.rebateTierCriteria.map((tier, idx) => {
                                            return <div key={idx} style={{
                                                display: 'grid !important',
                                                gridTemplateColumns: `repeat(${props.original.growthRebate[0].rebateTier.length},${props.original.growthRebate[0].rebateTier.length}fr) !important`,
                                                gridGap: 2,
                                                marginBottom: 2,
                                                marginRight: 4,
                                                float: 'left',
                                                cursor: 'pointer',
                                                width: 40
                                            }}>
                                                <div
                                                    data-for="tip"
                                                    data-tip={`${this.showTooltip(tier, item.tier,item.rebatePercent)}`}
                                                    data-iscapture="true"
                                                    style={{
                                                        backgroundColor:
                                                            tier.attainmentPercent < 50 ? '#c5cae9' :
                                                                tier.attainmentPercent > 50 && tier.attainmentPercent < 100 ? '#7986cb' :
                                                                    tier.attainmentPercent === 100 ? '#1a237e' : null,
                                                        padding: 6
                                                    }}
                                                >
                                                </div>
                                                <ReactTooltip
                                                    id="tip"
                                                    place={"top"}
                                                    type={"dark"}
                                                    effect={"solid"}
                                                    multiline={true}
                                                />
                                            </div>
                                        })
                                    })
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Estimated Gain Share (SAP)</b>,
                                accessor: 'estimatedGainShare',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { textAlign: 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.estimatedGainShare)}</div>
                            }
                        ]}
                        defaultPageSize={25}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                    />
                </div>
                <Card.Footer style={{ textAlign: 'center' }}>All Records Are YTD Till {switchMonths(this.props.month)}, {this.props.year}</Card.Footer>
            </Card>
        );
    }
}
