import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import WrongCategoryFeedback from './WrongCategoryFeedback';
import { bindActionCreators } from 'redux';
import FavorableSupplierFeedback from './FavorableSupplierFeedback';
import ProductNotSoldFeedback from './ProductNotSoldFeedback';
import InSupplierFeedback from './InSupplierFeedback';
import OutSupplierFeedback from './OutSupplierFeedback';
import BuysellRepSupplierFeedback from './BuysellRepSupplierFeedback';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import {
    getCorpFeedBackWrongCat,
    getCorpFeedBackFavSupplier,
    getCorpFeedBackProductNotSold,
    getCorpFeedBackINSupplier,
    getCorpFeedBackOUTSupplier,
    getCorpFeedBackBuySellSupplier

} from '../../../api/corporate-api';
import { updateSelectedNavIndex } from '../../../actions';

import 'react-tabs/style/react-tabs.css';

class CorporateFeedBack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTabId: 1,
            getCorporateFeedbackWrongCat: [],
            getCorporateFeedbackFavSupplier: [],
            getCorporateFeedbackProductNotSold: [],
            feedbackTabOptions: [
                {
                    id: 1,
                    title: "Wrong Category",
                    component: 'WrongCategoryFeedback'
                }, {
                    id: 2,
                    title: "Favorable Supplier",
                    component: 'FavorableSupplierFeedback'
                }, {
                    id: 3,
                    title: "Products Not Sold",
                    component: 'ProductNotSoldFeedback'
                }, {
                    id: 4,
                    title: "IN Supplier",
                    component: 'InSupplierFeedback'
                }, {
                    id: 5,
                    title: "OUT Supplier",
                    component: 'OutSupplierFeedback'
                }, {
                    id: 6,
                    title: "Rep Agencies",
                    component: "BuysellRepSupplierFeedback"
                }
            ]
        }
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);
        this.loadData();
    }

    loadData = () => {
        Promise.allSettled([
            getCorpFeedBackWrongCat(),
            getCorpFeedBackFavSupplier(),
            getCorpFeedBackProductNotSold(),
            getCorpFeedBackINSupplier(),
            getCorpFeedBackOUTSupplier(),
            getCorpFeedBackBuySellSupplier()
        ])
        .then((allResponses) => {
            if (allResponses[0].value) {
                this.setState({ getCorporateFeedbackWrongCat: allResponses[0].value ? allResponses[0].value : [] })
            }
            if (allResponses[1].value) {
                this.setState({ getCorporateFeedbackFavSupplier: allResponses[1].value ? allResponses[1].value : [] })
            }
            if (allResponses[2].value) {
                this.setState({ getCorporateFeedbackProductNotSold: allResponses[2].value ? allResponses[2].value : [] })
            }
            if (allResponses[3].value) {
                this.setState({ getCorporateFeedbackINVendor: allResponses[3].value ? allResponses[3].value : [] })
            }
            if (allResponses[4].value) {
                this.setState({ getCorporateFeedbackOUTVendor: allResponses[4].value ? allResponses[4].value : [] })
            }
            if (allResponses[5].value) {
                this.setState({ getCorporateFeedbackBuySellVendor: allResponses[5].value ? allResponses[5].value : [] })
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    setSelectedTabId = (id) => this.setState({ selectedTabId: id })

    render() {
        let { selectedTabId, feedbackTabOptions } = this.state;

        // console.log('getCorporateFeedbackINVendor', this.state.getCorporateFeedbackINVendor);
        // console.log('getCorporateFeedbackWrongCat',this.state.getCorporateFeedbackWrongCat);
        return (
            <Tabs>
                <TabList>
                    {feedbackTabOptions.map((item, index) => {
                        return <Tab key={index} onClick={() => {
                                    this.setSelectedTabId(item.id);
                                    this.loadData();
                                }}
                            >
                            <span style={{ fontWeight: selectedTabId === item.id ? 700 : 400 }}>
                                {item.title}
                            </span>
                        </Tab>
                    })}
                </TabList>
                {feedbackTabOptions.map((item, index) => {
                    return (
                        <TabPanel key={index} >
                            {item.component === 'WrongCategoryFeedback' ?
                                <WrongCategoryFeedback getCorporateFeedbackWrongCat={this.state.getCorporateFeedbackWrongCat} />
                                : item.component === 'FavorableSupplierFeedback' ? <FavorableSupplierFeedback getCorporateFeedbackFavSupplier={this.state.getCorporateFeedbackFavSupplier} />
                                : item.component === "ProductNotSoldFeedback" ? <ProductNotSoldFeedback getCorporateFeedbackProductNotSold={this.state.getCorporateFeedbackProductNotSold} />
                                : item.component === "InSupplierFeedback" ? <InSupplierFeedback getCorporateFeedbackINVendor={this.state.getCorporateFeedbackINVendor} />
                                : item.component === "OutSupplierFeedback" ? <OutSupplierFeedback getCorporateFeedbackOUTVendor={this.state.getCorporateFeedbackOUTVendor} />
                                : <BuysellRepSupplierFeedback getCorporateFeedbackBuySellVendor={this.state.getCorporateFeedbackBuySellVendor} />
                            }
                        </TabPanel>
                    )
                })}
            </Tabs>
        )
    }
}

function mapStateToProps(state) {
    return {
        getCorporateFeedbackWrongCat: state.corporate.getCorporateFeedbackWrongCat,
        getCorporateFeedbackFavSupplier: state.corporate.getCorporateFeedbackFavSupplier,
        getCorporateFeedbackProductNotSold: state.corporate.getCorporateFeedbackProductNotSold,
        getCorporateFeedbackINVendor: state.corporate.getCorporateFeedbackINVendor,
        getCorporateFeedbackOUTVendor: state.corporate.getCorporateFeedbackOUTVendor,
        getCorporateFeedbackBuySellVendor: state.corporate.getCorporateFeedbackBuySellVendor
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateFeedBack);
