import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { PurchaseOrdersFilters } from './PurchaseOrdersFilters/PurchaseOrdersFilters';
import PurchaseOrdersTable from './PurchaseOrdersTable/PurchaseOrdersTable';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
 //import { convertJSONToURLParams } from '../../../utils/formatters';

 import axios from 'axios';
import { BASE_URL } from '../index';
import { convertJSONToURLParams } from '../../../utils/formatters';



import {
    updateSelectedNavIndex,
    // getPurchasedOrdersMember
} from '../../../actions';
import {
    getBranches,
    getPurchasedOrdersMember,
    getTerminatedVPNamesForMember,
    FileDownloadForPurchaseOrder
} from '../../../api/member-api';
import { addSyntheticTrailingComment } from 'typescript';

class MemberPurchaseOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isButtonDisabled: false,
            isLoaded: false,
            isContentLoaded: false,
            memberName: sessionStorage.getItem('groupName'),
            poNo: "",
            branchName: "",
            vendorId: "",
            vendorName: "",
            startDate: "",
            endDate: "",
            inOut: "BOTH",
            suppliers: [],
            branches: [],
            purchaseOrders: [],
            filteredPOs: [],
            searchKey:"",
            tag:"",

        }
        this.poNoRef = React.createRef();
        this.tagRef = React.createRef();
    }

    static getDerivedStateFromProps(props) {
        console.log('Member ID in Member Component', props.match.params.id);
        return { memberId: props.match.params.id };
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);

        this.loadData();
    }

    componentDidUpdate(pP, pS, sS) {
        if(pS.inOut !== this.state.inOut) {
            this.getPurchaseOrders();
        }
        if(pS.startDate !== this.state.startDate) {
            this.getPurchaseOrders();
        }

        if(pS.endDate !== this.state.endDate) {
            this.getPurchaseOrders();
        }

        if(pS.memberId !== this.state.memberId && pS.branchName !== this.state.branchName) {
            this.getPurchaseOrders();
        }

        if(pS.vendorId !== this.state.vendorId && pS.vendorName !== this.state.vendorName) {
            this.getPurchaseOrders();
        }
    }

    loadData = () => {
        this.mounted = true;

        // const selections = convertJSONToURLParams(formData);
        // this.props.getPurchasedOrdersMemberAPI(selections)

        Promise.allSettled([
            getTerminatedVPNamesForMember(),
            getBranches({ memberId: this.state.memberId })
        ])
        .then((allResponses) => {
            console.log('MEMBER-PURCHASE-ORDERS ON MOUNT ==>', allResponses);

            if(this.mounted) {
                if(allResponses[0].value) {
                    this.setState({ suppliers: allResponses[0].value ? allResponses[0].value : [] })
                }
                if(allResponses[1].value) {
                    this.setState({ branches: allResponses[1].value ? allResponses[1].value : [] })
                }

                this.getPurchaseOrders();

                this.setState({ isLoaded: true });
            }
        })
        .catch((err) => console.log(err))
    }

    getPurchaseOrders = () => {
        this.mounted = true;

        const formData = {
            memberId: this.state.memberId,
            inOut: this.state.inOut,
            fromDate: this.state.startDate,
            toDate: this.state.endDate,
            vendorId: this.state.vendorId,
            poNumber: this.state.poNo,
        }

        Promise.allSettled([ getPurchasedOrdersMember(formData) ])
        .then((allResponses) => {
            console.log('MEMBER-PURCHASE-ORDERS ON CHANGE ==>', allResponses[0].value);

            if(this.mounted) {
                if(allResponses[0].value) {
                    this.setState({ purchaseOrders: allResponses[0].value ? allResponses[0].value : [] })
                }

                this.setState({ isContentLoaded: true })
            }
        })
        .catch((err) => console.log(err));
    }
    getMorePurchaseOrdersRows = (start, length) => {
        this.mounted = true;

        const formData = {
            memberId: this.state.memberId,
            inOut: this.state.inOut,
            fromDate: this.state.startDate,
            toDate: this.state.endDate,
            vendorId: this.state.vendorId,
            poNumber: this.state.poNo,
            start: start,
            length: length
        }

        Promise.allSettled([ getPurchasedOrdersMember(formData) ])
        .then((allResponses) => {
            console.log('MEMBER-PURCHASE-ORDERS ON CHANGE ==>', allResponses);

            if(this.mounted) {
                if(allResponses[0].value) {
                    let purchaseOrders = this.state.purchaseOrders.concat(allResponses[0].value);
                    this.setState({ purchaseOrders: purchaseOrders })
                }

                this.setState({ isContentLoaded: true })
            }
        })
        .catch((err) => console.log(err));
    }

    handleSelectedBranch = (e) => {
        if(e.target.value) {
            this.setState({
                memberId: e.target.value.split('=')[0],
                branchName: e.target.value.split('=')[1]
            })
        } else {
            this.setState({ memberId: "", branchName: "" })
        }
    }

    handleSelectedSupplier = (e) => {
        if(e.target.value) {
            this.setState({
                vendorId: e.target.value.split('=')[0],
                vendorName: e.target.value.split('=')[1]
            });
        } else {
            this.setState({ vendorId: "", vendorName: "" });
        }
    }

    handleStatus = (e) => this.setState({ inOut: e.target.name });
    handleStartDate = (e) => this.setState({ startDate: moment(e.target.value).locale('en').format('YYYY-MM-DD') });
    handleEndDate = (e) => this.setState({ endDate: moment(e.target.value).locale('en').format('YYYY-MM-DD') });

    handlePoNo = (e) => {
        this.setState({ poNo: e.target.value },
            () => {
                this.filterByInvoiceNo()
            }
        );
    }

    

//  window.open('https://imark-electrical.tredence.com/imark-electrical/purchase-order/download?'+convertJSONToURLParams((formData)));
  FileDownload =()=> {
    const formData = {
        memberId: this.state.memberId,
        inOut: this.state.inOut,
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        vendorId: this.state.vendorId,
        poNumber: this.state.poNo,
    }

   axios({
     url:'https://imark-electrical.tredence.com/imark-electrical/purchase-order/download?'+convertJSONToURLParams((formData)),
     method: 'GET',
     responseType: 'blob', 
     headers: {
        'mode': 'cors',
        'Access-Control-Allow-Origin': '*',
        'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken'),
       
    }
   
   }).then(response =>{ 
   const url = window.URL.createObjectURL(new Blob([response.data]));
   const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Purchased_Orders.csv'); 
  document.body.appendChild(link);
  link.click();


 } )
 this.setState({
    isButtonDisabled: true
  });
  
}





handlePoNo =async(e) => {
    this.state.tag=e.target.value
    console.log('onChage event',this.state.tag)
}


    filterByInvoiceNo = async(e) => {
        let results = this.state.purchaseOrders.filter((item) => {
            return item.poNo.toString().indexOf(this.state.poNo.toString()) >= 0;
        })

        this.setState({
            filteredPOs: results
        });
        console.log('results',this.state.purchaseOrders)

    }


    // handleSearchPo =() => {
    //     let filteredData = this.state.purchaseOrders.filter(item => {
    //         return item.poNo.toLowerCase().indexOf(this.state.poNo.toLowerCase()) >= 0;
    //         })
    //     this.setState({ filteredPOs: filteredData });
    //     console.log('results',filteredData)
    // }

     handleSearchPo =async() => {
        let result=  await axios(`https://imark-electrical.tredence.com/imark-electrical/purchase-order/search?tag=${this.state.tag}&memberId=${this.state.memberId}`,
       {method: "GET",
       headers: {
          'mode': 'cors',
          'Access-Control-Allow-Origin': '*',
          'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken'),
      }}
   ).then((response) => {
       let srchData=response.data
       console.log('serch data==>', response.data);
       return srchData

   })
   this.setState({
       filteredPOs: result
   });
     console.log('click',result)
}

    // handleClearPo = () => {
    //     this.setState({ poNo: "", filteredPOs: [] }, () => {
    //         this.poNoRef.current.value = "";
    //     });
    // }

    handleClearPo = () => this.setState({
        poNo: "",
        isFilteredDataAvailable: true,
        filteredPOs:[]},()=>{
            this.poNoRef.current.value = "";
        });
    
        componentWillUnmount()
        {
            this.mounted = false;
        }
    
    
   

    render(){
        let {isLoaded,
            isContentLoaded,
            branchName,
            poNo,
            inOut,
            suppliers,
            branches,
            purchaseOrders,
            filteredPOs,
            searchKey,
            tag
        } = this.state;
        // let { purchaseOrders } = this.props;

        return !isLoaded ? <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <Spinner animation="border" variant="primary" />
        </div> :
            <div>
                <PurchaseOrdersFilters
                    handlePoNoReference={this.handlePoNo}
                    handleSearchPoReference={this.handleSearchPo}
                    handleClearPoReference={this.handleClearPo}
                    handleSelectedBranchReference={this.handleSelectedBranch}
                    handleSelectedSupplierReference={this.handleSelectedSupplier}
                    handleStatusReference={this.handleStatus}
                    handleStartDateReference={this.handleStartDate}
                    handleEndDateReference={this.handleEndDate}
                    branchName={branchName}
                    inOut={inOut}
                    suppliers={suppliers}
                    branches={branches}
                    purchaseOrders={filteredPOs.length ? filteredPOs : purchaseOrders}
                    ref={this.poNoRef}
                    memberName={this.state.memberName}
                    FileDownload ={this.FileDownload}
                    searchKey={this.state.searchKey}
                />
                
                {!isContentLoaded ? <div style={{
                    display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                    minHeight: 1000
                }}>
                    <Spinner animation="border" variant="primary" />
                </div> :
                <PurchaseOrdersTable
                    heading={"Purchase Orders"}
                    purchaseOrders={filteredPOs.length ? filteredPOs : purchaseOrders}
                    poNo={poNo}
                    loadMoreData = {this.getMorePurchaseOrdersRows}
                />}
            </div>
    }
}

function mapStateToProps(state) {
    return {
        purchaseOrders: state.member.getPurchasedOrdersMember
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,
        // getPurchasedOrdersMemberAPI: getPurchasedOrdersMember
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberPurchaseOrders);