import React from "react";
import ReactTable from "react-table-6";
import { Button, Card, Modal, ProgressBar, InputGroup, FormControl } from 'react-bootstrap';
import ProductList from '../ProductList/ProductList';

import '../../../../assets/styles/SalesOpportunityByProductGroup.scss';

export default class SalesOpportunityByProductGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProductGroupId: "",
            selectedProductGroupName: "",
            show: false,
            searchKey: "",
            filteredSearchResults: [],
            isFilteredDataAvailable: false
        }
    }

    handleClose = () => this.setState({ show: false })
    handleShow = () => this.setState({ show: true });

    productListModal = () => {
        return (
            <Modal show={this.state.show} size="lg" onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Product Details for <strong style={{ marginLeft: 4 }}>{this.state.selectedProductGroupName}</strong>
                </Modal.Header>
                <Modal.Body>
                    <ProductList
                        productGroupId={this.state.selectedProductGroupId}
                        year={this.props.year}
                        month={this.props.month}
                        period={this.props.period}
                        quarter={this.props.quarter}
                        vendorId={this.props.vendorId}
                        selectedProductGroupId={this.state.selectedProductGroupId}
                        selectedProductGroupName={this.state.selectedProductGroupName}
                        productCategory={this.props.productCategory}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={() => this.handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.tierFour.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        let {
            salesOpportunityByProductGroup,
            handleResetTableDataReference
        } = this.props;

        let opportunities = salesOpportunityByProductGroup && salesOpportunityByProductGroup.map((item) => item.missedOpportunity);
        let maxOpportunity = Math.max(...opportunities);

        return (
            <div className="salesOpportunityByProductGroupContainer">
                {this.productListModal()}
                <Card>
                    <Card.Header>Product Group Detail</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for product group detail info.."
                                    aria-label="Search for product group detail info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, salesOpportunityByProductGroup)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => {
                                        this.clearTable();
                                        handleResetTableDataReference();
                                    }}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.salesOpportunityByProductGroup}
                            getTrProps={this.onRowClick}
                            columns={[
                                {
                                    Header: <b>Product Group Detail</b>,
                                    accessor: "tierFour",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props =><div style={{textAlign:'center'}}> <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => this.handleShow(props.original.tierFourId)}
                                    >
                                        {props.original.tierFour}
                                    </span></div>
                                }, {
                                    Header: <b>Opportunity</b>,
                                    accessor: "missedOpportunity",
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => <ProgressBar
                                        now={(props.original.missedOpportunity * 100 / maxOpportunity) > 1 ?
                                            props.original.missedOpportunity * 100 / maxOpportunity : 1}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            this.setState({
                                                selectedProductGroupId: props.original.tierFourId,
                                                selectedProductGroupName: props.original.tierFour
                                            });
                                            this.handleShow();
                                        }}
                                    />
                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: "missedOpportunity",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                </Card>
            </div>
        );
    }
}
