import React from "react";
import ReactTable from "react-table-6";
import { Button, Card, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { priceFormatter } from '../../../../utils/formatters';

import '../../../../assets/styles/GrowthRebate.scss';

export default class GrowthRebate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            searchKey: "",
            filteredSearchResults: [],
            isFilteredDataAvailable: false
        }
    }

    handleClose = () => this.setState({ show: false })

    reactBootstrapModal = () => {
        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Contact Info for Moeen Incorportaed
                </Modal.Header>
                <Modal.Body>
                    <div className="supplierInfo" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 6fr)' }}>
                        <div>
                            <p>Supplier Id</p>
                            <p>Supplier Name</p>
                            <p>Contact Address</p>
                            <p>Year</p>
                            <p>Phone</p>
                            <p>Email</p>
                            <p>Contact Person</p>
                        </div>
                        <div>
                            <p>40000002</p>
                            <p>Delta Facet</p>
                            <p>55 E. 11th Street</p>
                            <p>2020</p>
                            <p>714-202-7362</p>
                            <p>john.smith@abc.com</p>
                            <p>John Smith</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={() => this.handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
        filteredSearchResults: []
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        const { growthRebate } = this.props;

        return (
            <div className="growthRebateContainer">
                {this.reactBootstrapModal()}
                <Card>
                    <Card.Header>Group Growth Rebate</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for growth rebate info.."
                                    aria-label="Search for growth rebate info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, growthRebate)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => this.clearTable()}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.growthRebate}
                            columns={[
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier ID</b>,
                                    accessor: "vendorId",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Name</b>,
                                    accessor: "vendorName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Program Name</b>,
                                    accessor: "programName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Criteria</b>,
                                    accessor: "criteria",
                                    sortable: true,
                                    resizable: true,
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Level/Tier</b>,
                                    accessor: "tier",
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => (
                                        <div style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            border: '1px solid #2b8ebb',
                                            textAlign: 'center',
                                            width: 'max-content',
                                            padding: '0 10px',
                                            color: '#2b8ebb',
                                            fontWeight: 600
                                        }}>
                                            {props.original.tier}
                                        </div>
                                    )
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Actual Sales ($)</b>,
                                    accessor: "currentYearSales[0]",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.currentYearSales)}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Target Sales ($)</b>,
                                    accessor: "targetSales[0]",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.targetSales)}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Gap ($)</b>,
                                    accessor: "gapAmount[0]",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'right' },
                                    Cell: props => <div style={{textAlign:'center'}}><p style={{ color: 'green' }}>
                                        {priceFormatter(props.original.targetSales - props.original.currentYearSales)}
                                    </p></div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Attainment (%)</b>,
                                    accessor: "attainmentPercent[0]",
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => (
                                        <div style={{
                                            display: 'block',
                                            margin: '0 auto',
                                            borderRadius: 16,
                                            textAlign: 'center',
                                            width: 'fit-content',
                                            padding: '0 10px',
                                            color: 'FFFFFF',
                                            background:
                                                props.original.attainmentPercent > 99 && props.original.attainmentPercent <= 100 ? '#1a237e'
                                                    : props.original.attainmentPercent < 99 && props.original.attainmentPercent > 75 ? '#7986cb'
                                                        : '#c5cae9'
                                        }}>
                                            {Math.floor((props.original.currentYearSales * 100) / props.original.targetSales)}
                                        </div>
                                    )
                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: 'tier',
                                    asc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                    <Card.Footer style={{ textAlign: 'center' }}>All Records Are YTD Till Sep, 2020</Card.Footer>
                </Card>
            </div>
        );
    }
}