import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';
import OverviewFilters from './OverviewFilters/OverviewFilters';
import Cards from '../../../Common/Cards/Cards';
import PurchaseWithinImark from './PurchaseWithinImark/PurchaseWithinImark';
import PurchaseOutsideImark from './PurchaseOutsideImark/PurchaseOutsideImark';
import OutsidePurchasesByProductGroup from './OutsidePurchasesByProductGroup/OutsidePurchasesByProductGroup';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';

import {
    updateSelectedNavIndex
} from '../../../actions';
import {
    getMemberMonthsFilter,
    getFilterMemberIds,
    getCompliantStatusForMember,
    getCardsDataForMemberDataTab,
    getMemberPurchaseWithinImarkValue,
    getMemberPurchaseOutsideImarkValue,
    getMemberOutsidePurchaseByCategory,
    getTerminatedVPNamesForMember
} from '../../../api/member-api';

import '../../../assets/styles/MemberOverview.scss';

class MemberOverview extends Component {
    
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            isLoaded: false,
            isContentLoaded: false,
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: "YTD",
            quarter: "1",
            branchName: "",
            vendorName: "",
            productGroupId: "",
            memberOverviewCardItems: [],
            purchaseWithinImark: [],
            purchaseOutsideImark: [],
            outsidePurchasesByProductGroup: [],
            memberMonthsFilter: [],
            listOfYears: [],
            listOfMonthsForSelectedYear: [],
            filterMemberIds: [],
            compliant: null,
            selectedTableRowIndexInPuchasesOutsideImark: null,
            selectedVendorNameInPuchasesOutsideImark: null,
            selectedTableRowIndexInOutsidePurchasesByPG: null,
            selectedProductGroupIdInOutsidePurchasesByPG: null,
            isQuarterSelected: false,
            memberName: sessionStorage.getItem('groupName') ? sessionStorage.getItem('groupName') : sessionStorage.getItem('imarkElectricalMemberName'),
            memberId:sessionStorage.getItem('imarkElectricalMemberId')
        }
    }

    // static getDerivedStateFromProps(props) {
    //     console.log('Member ID in Member Component', props.match.params.id);
    //     return { memberId: props.match.params.id };
    
    // }

    componentDidMount() {
        console.log('componentdid render',this.state.memberId,this.props.match.params.id)

          this.setState( { memberId: this.props.match.params.id },() => {
                let idx = getVisitedTabId(window.location.hash);
                this.props.updateSelectedNavIndex(idx);
                this.loadData();
        },

            )
        
    }

    componentDidUpdate(pP, pS, sS) {
        if (pS.year !== this.state.year) {
            this.getOverviewData();
        }

        if (pS.month !== this.state.month) {
            this.getOverviewData();
        }

        if (pS.period !== this.state.period) {
            this.getOverviewData();
        }

        if (pS.quarter !== this.state.quarter) {
            this.getOverviewData();
        }

        if (pS.memberId !== this.state.memberId) {
            this.getOverviewData();
        }

        if (pS.selectedVendorNameInPurchasesOutsideImark !== this.state.selectedVendorNameInPurchasesOutsideImark) {
            this.handleSelectedRowForVendorName();
        }

        if (pS.productGroupId !== this.state.productGroupId) {
            this.handleSelectedRowForProductGroupId();
        }
    }

    loadData = () => {
        this.mounted = true;

        Promise.allSettled([
            getFilterMemberIds({ memberId: this.state.memberId, year: this.state.year }),
            getCompliantStatusForMember({ memberId: this.state.memberId, year:this.state.year }),
            getMemberMonthsFilter({ memberId: this.state.memberId }),
           
        ])
            .then((allResponses) => {

                console.log('MEMBER-OVERVIEW ON MOUNT ==>', allResponses,this.state.year,this.mounted)
                console.log('if block')
               // console.log('MEMBER-OVERVIEW ON MOUNT LOADED==>', allResponses[2].value[0].branchId )

                if (this.mounted) {
                    if (allResponses[0].value) {
                        //console.log('filterMemberIds', this.state.filterMemberIds, allResponses[2].value);
                        console.log('MEMBER-OVERVIEW ON MOUNT LOADED==>', allResponses[0].value[0].branchId ,allResponses[0].value)
                        this.setState({ filterMemberIds: allResponses[0].value ? allResponses[0].value : [],
                            // memberId: allResponses[0].value[0].branchId
                        })
                    }
                    if (allResponses[1].value) {
                        this.setState({ compliant: allResponses[1].value ? allResponses[1].value : null })
                    }
                    if (allResponses[2].value) {
                        const availableYears = Object.keys(allResponses[2].value).sort((a, b) => b - a);
                        this.setState({
                            memberMonthsFilter: allResponses[2].value ? allResponses[2].value : [],
                            listOfYears: allResponses[2].value && Object.keys(allResponses[2].value),

                        }, () => {
                            this.getListOfMonths(allResponses[2].value ,availableYears[0])
                        })
                    }
                    
                }

                this.setState({ isLoaded: true })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    formatCards = (cards) => {
        this.setState({ memberOverviewCardItems: cards })
    }

    getOverviewData = () => {
        this.mounted = true;

        this.setState({ isContentLoaded: true }, () => {
            const formData = {
                memberId: this.state.memberId,
                period: this.state.period,
                quarter: this.state.quarter,
                month: this.state.month,
                year: this.state.year
            }

            Promise.allSettled([
                getCardsDataForMemberDataTab(formData),
                getMemberPurchaseWithinImarkValue(formData),
                getMemberPurchaseOutsideImarkValue(formData),
                getMemberOutsidePurchaseByCategory(formData),
                getTerminatedVPNamesForMember(formData)
            ])
                .then((allResponses) => {
                    console.log('MEMBER-OVERVIEW ON CHANGE ==>', allResponses)

                    if (this.mounted) {
                        if (allResponses[0].value) {
                            console.log('allR', allResponses[0].value);
                            // this.setState({ memberOverviewCardItems: allResponses[0].value ? allResponses[0].value : [] })
                            this.formatCards(allResponses[0].value)
                        }
                        if (allResponses[1].value) {
                            this.setState({ purchaseWithinImark: allResponses[1].value ? allResponses[1].value : [] })
                        }
                        if (allResponses[2].value) {
                            this.setState({ purchaseOutsideImark: allResponses[2].value ? allResponses[2].value : [] })
                        }
                        if (allResponses[3].value) {
                            this.setState({ outsidePurchasesByProductGroup: allResponses[3].value ? allResponses[3].value : [] })
                        }
                        if (allResponses[4].value) {
                            this.setState({ terminatedVPNamesForMember: allResponses[4].value ? allResponses[4].value : [] })
                        }
                    }

                    this.setState({ isContentLoaded: false })
                })
                .catch((err) => {
                    console.log(err);
                })
        })
    }

    getListOfMonths = (allYears, year) => {
        console.log(year);
        for (const [key, value] of Object.entries(allYears)) {
            if (year == key) {
                let month = Math.min(value[value.length - 1], this.state.month)
                this.setState({ listOfMonthsForSelectedYear: value, month: value[value.length - 1], year});
                this.getOverviewData();
            }
        }
    }

    
    handleYear = (e) => {
        this.setState({ year: e.target.value }, () => {
            this.getListOfMonths(this.state.memberMonthsFilter, e.target.value);

        });
    }

    handleMonth = (e) => this.setState({ month: e.target.value });
    handlePeriod = (e) => this.setState({
        period: e.target.name,
        isQuarterSelected: e.target.name === "QTR" ? true : false
    });
    handleQuarter = (e) => this.setState({ quarter: e.target.value });

    handleBranch = (e) => {
        if (e.target.value) {
            this.setState({
                memberId: e.target.value,
                // branchName: e.target.value.split("=")[1]
            })
        }
    }

    handleVendorName = (vendorName) => this.setState({ vendorName: vendorName })
    handleProductGroupId = (productGroupId) => this.setState({ productGroupId })

    handleSelectedRowValuesInPuchasesOutsideImark = (index, vendorName) => {
        this.setState({
            selectedTableRowIndexInPurchasesOutsideImark: index,
            selectedVendorNameInPurchasesOutsideImark: vendorName
        })
    }

    handleSelectedRowValuesInOutsidePurchasesByPG = (index, productGroupId) => {
        this.setState({
            selectedTableRowIndexInOutsidePurchasesByPG: index,
            selectedProductGroupIdInOutsidePurchasesByPG: productGroupId
        })
    }

    handleSelectedRowForVendorName = () => {
        // 
        // Call an api to get all the info related to the selected vendorName
        // and pass it to: Outside Purchases By Product Group
        let formData = {
            memberId: this.state.memberId,
            period: this.state.period,
            quarter: this.state.quarter,
            month: this.state.month,
            year: this.state.year,
            outVendor: this.state.vendorName
        }
// 
        Promise.allSettled([getMemberOutsidePurchaseByCategory(formData)])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    this.setState({ outsidePurchasesByProductGroup: allResponses[0].value
                         ? allResponses[0].value : [],isFilteredDataAvailable: false,
                     })
                }
            })
            .catch((err) => console.log(err));

            // 
    }

    handleSelectedRowForProductGroupId = () => {
        // Call an api to get all the info related to the selected productGroupId
        // and pass it to: Purchase Outside IMARK
        let formData = {
            memberId: this.state.memberId,
            period: this.state.period,
            quarter: this.state.quarter,
            month: this.state.month,
            year: this.state.year,
            productGroupId: this.state.productGroupId
        }

        Promise.allSettled([getMemberPurchaseOutsideImarkValue(formData)])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    this.setState({ purchaseOutsideImark: allResponses[0].value ? allResponses[0].value : [] })
                }
            })
            .catch((err) => console.log(err));
    }

    handleClearRetrievedTableData = () => {
        this.mounted = true;
        // 

        this.setState({
            selectedTableRowIndexInPurchasesOutsideImark: null,
            selectedVendorNameInPurchasesOutsideImark: null,
            selectedTableRowIndexInOutsidePurchasesByPG: null,
            selectedProductGroupIdInOutsidePurchasesByPG: null
        }, () => {
            const formData = {
                memberId: this.state.memberId,
                period: this.state.period,
                quarter: this.state.quarter,
                month: this.state.month,
                year: this.state.year
            }

            Promise.allSettled([
                getMemberPurchaseOutsideImarkValue(formData),
                getMemberOutsidePurchaseByCategory(formData)
            ])
                .then((allResponses) => {
                    console.log('MEMBER-OVERVIEW ON CHANGE ==>', allResponses)

                    if (this.mounted) {
                        if (allResponses[0].value) {
                            this.setState({ purchaseOutsideImark: allResponses[0].value ? allResponses[0].value : [] })
                        }
                        if (allResponses[1].value) {
                            this.setState({ outsidePurchasesByProductGroup: allResponses[1].value ? allResponses[1].value : [] })
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        })

        // 
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        let {
            isLoaded,
            isContentLoaded,
            memberId,
            year,
            month,
            period,
            quarter,
            // branchName,
            vendorName,
            productGroupId,
            memberOverviewCardItems,
            purchaseWithinImark,
            purchaseOutsideImark,
            outsidePurchasesByProductGroup,
            terminatedVPNamesForMember,
            // memberMonthsFilter,
            listOfYears,
            listOfMonthsForSelectedYear,
            filterMemberIds,
            selectedTableRowIndexInPurchasesOutsideImark,
            selectedVendorNameInPurchasesOutsideImark,
            selectedTableRowIndexInOutsidePurchasesByPG,
            selectedProductGroupIdInOutsidePurchasesByPG,
            isQuarterSelected
        } = this.state;

        return (
            !isLoaded ? <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <Spinner animation="border" variant="primary" />
            </div> :
                <div>
                    <OverviewFilters
                        year={year}
                        month={month}
                        period={period}
                        handleBranchReference={this.handleBranch}
                        handleYearReference={this.handleYear}
                        handleMonthReference={this.handleMonth}
                        handlePeriodReference={this.handlePeriod}
                        handleQuarterReference={this.handleQuarter}
                        listOfYears={listOfYears}
                        listOfMonthsForSelectedYear={listOfMonthsForSelectedYear}
                        filterMemberIds={filterMemberIds}
                        purchaseWithinImark={purchaseWithinImark}
                        purchaseOutsideImark={purchaseOutsideImark}
                        outsidePurchasesByProductGroup={outsidePurchasesByProductGroup}
                        isQuarterSelected={isQuarterSelected}
                        memberName={this.state.memberName}
                    />
                    {/* <pre style={{ marginLeft: 60 }}>
                    {JSON.stringify({
                        isLoaded,
                        isContentLoaded,
                        memberId,
                        year,
                        month,
                        period,
                        quarter,
                        branchName,
                        vendorName,
                        productGroupId,
                        memberMonthsFilter: JSON.stringify(memberMonthsFilter),
                        listOfYears: JSON.stringify(listOfYears),
                        listOfMonthsForSelectedYear: JSON.stringify(listOfMonthsForSelectedYear)
                    }, null, 8)}
                </pre> */}
                    {isContentLoaded ?
                        <div style={{
                            display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                            minHeight: 1000
                        }}>
                            <Spinner animation="border" variant="primary" />
                        </div> :
                        <React.Fragment>
                            <Cards cardItems={memberOverviewCardItems} />
                            <div className="tableDivider">
                                <PurchaseWithinImark
                                    purchaseWithinImark={purchaseWithinImark}
                                    year={year}
                                />
                                <PurchaseOutsideImark
                                    purchaseOutsideImark={purchaseOutsideImark}
                                    terminatedVPNamesForMember={terminatedVPNamesForMember}
                                    productGroupId={productGroupId}
                                    selectedTableRowIndexInPurchasesOutsideImark={selectedTableRowIndexInPurchasesOutsideImark}
                                    selectedVendorNameInPurchasesOutsideImark={selectedVendorNameInPurchasesOutsideImark}
                                    selectedRowValuesInPuchasesOutsideImarkReference={this.handleSelectedRowValuesInPuchasesOutsideImark}
                                    handleVendorName={this.handleVendorName}
                                    clearRetrievedTableData={this.handleClearRetrievedTableData}
                                />
                            </div>
                            
                            <OutsidePurchasesByProductGroup
                                memberId={memberId}
                                year={year}
                                month={month}
                                period={period}
                                quarter={quarter}
                                outsidePurchasesByProductGroup={outsidePurchasesByProductGroup}
                                terminatedVPNamesForMember={terminatedVPNamesForMember}
                                vendorName={vendorName}
                                selectedTableRowIndexInOutsidePurchasesByPG={selectedTableRowIndexInOutsidePurchasesByPG}
                                selectedProductGroupIdInOutsidePurchasesByPG={selectedProductGroupIdInOutsidePurchasesByPG}
                                selectedRowValuesInOutsidePurchasesByPGReference={this.handleSelectedRowValuesInOutsidePurchasesByPG}
                                handleProductGroupId={this.handleProductGroupId}
                                clearRetrievedTableData={this.handleClearRetrievedTableData}
                            />
                        </React.Fragment>}
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        memberOverviewCardItems: state.member.getCardsDataForMemberDataTab,
        purchaseWithinImark: state.member.getMemberPurchaseWithinImarkValue,
        purchaseOutsideImark: state.member.getMemberPurchaseOutsideImarkValue,
        outsidePurchasesByProductGroup: state.member.getMemberOutsidePurchaseByCategory
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberOverview);



