import React, { Component } from 'react';
import { VectorMap } from 'react-jvectormap';
import {
    Card,
    Button,
} from 'react-bootstrap';
import { getRegionCode, getRegionName, REGIONS } from '../../../../helper';

export default class SalesOpportunityByRegion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clear: false,
            regionSeries: {},
            regionValues: {},
            regionMinValue: 0,
            regionMaxValue: 0,
            selectedStates: [],
        }
    }

    componentDidMount() {
        if (this.props.salesOpportunityByRegion) {
            const opportunities = new Set()
            let regionSeries = {};
            let regionValues = {};

            this.props.salesOpportunityByRegion.map(state => {
                let opportunity = state.missedAmount
                let stateCode = state.region
                regionSeries['US-' + stateCode.toUpperCase()] = opportunity;
                opportunities.add(opportunity);
            });

            for (let regionCode in REGIONS) {
                REGIONS[regionCode].states.forEach(stateCode => {
                    if (regionSeries['US-' + stateCode.toUpperCase()] == undefined) {
                        regionSeries['US-' + stateCode.toUpperCase()] = 0
                        regionValues['US-' + stateCode.toUpperCase()] = 0;
                        opportunities.add(0);
                    }
                })
            }

            const opportunitiesArray = Array.from(opportunities).sort(function (a, b) { return a - b });

            this.props.salesOpportunityByRegion.map(state => {
                let value = opportunitiesArray.indexOf(state.missedAmount);
                let stateCode = state.region
                regionValues['US-' + stateCode.toUpperCase()] = value;
            });

            this.setState({ regionSeries, regionMinValue: 0, regionMaxValue: opportunitiesArray.length - 1, regionValues });
        }
    }

    handleRegionTipShow = (e, el, code) => {
        let value = Number(this.state.regionSeries[code]).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 });
        let regionName = getRegionName(code);
        el.html(`Region: ${regionName}<br><b>Sales Opportunity: ${value}</b>`);
    }

    handleRegionClick = (e, el) => {
        // Get the Region Code from getRegionCode(code);
        // Pass the Region Code in Opportunity by Member, Opp by Business Group and Opp by Product Group API.
        console.log('Region:' + el, 'RegionCode:' + getRegionCode(el));
        const region = getRegionCode(el);
        this.props.handleSelectedRegionCodeReference(region);

        let selectedStates = [];
        REGIONS[region].states.forEach((state) => {
            selectedStates.push('US-' + state.toUpperCase());
        })
        this.setState({ selectedStates })
    }

    clearToolTip = (e) => {
        document.querySelectorAll('.jvectormap-tip').forEach((ele) => ele.remove())
    }
    handleClearButton = () => {
        this.setState({ selectedStates: [] })
        this.props.handleResetTableDataReference()
    }

    render() {

        return (
            <div className="salesOpportunityByRegionContainer">
                {this.clearToolTip()}
                <Card>
                    <Card.Header className="d-flex">
                        Sales Opportunity By Region
                        <Button className="ml-auto"
                            onClick={() => this.handleClearButton()}
                            style={{ height: 24, fontSize: 14, padding: '0 10px' }}
                        >
                            Clear
                        </Button>
                    </Card.Header>
                    <VectorMap
                        containerClassName="map"
                        containerStyle={{
                            width: '-webkit-fill-available',
                            height: '466px',
                            padding: 10
                        }}
                        ref="map"
                        map={'us_aea'}
                        series={{
                            regions: [{
                                scale: ['#e6f9ff', '#0099cc'],
                                attribute: 'fill',
                                values: this.state.regionValues,
                                min: 0,
                                max: this.state.regionMaxValue
                            }]
                        }}
                        backgroundColor="transparent"
                        regionsSelectable="true"
                        selectedRegions={this.state.selectedStates}
                        regionStyle={{
                            initial: {
                                fill: "#e4e4e4",
                                "fill-opacity": 0.9,
                                stroke: "none",
                                "stroke-width": 0,
                                "stroke-opacity": 0,
                            },
                            hover: {
                                "fill-opacity": 0.8,
                                cursor: "pointer"
                            },
                            selected: {
                                fill: "rgb(100, 200, 100)"
                            },

                        }}
                        zoomMax={1}
                        onRegionTipShow={(e, el, code) => this.handleRegionTipShow(e, el, code)}
                        onRegionClick={(e, el, code) => this.handleRegionClick(e, el, code)}
                    // onRegionOut={(e) => this.clearToolTip(e)}
                    />
                </Card>
            </div>
        )
    }
}
