import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button, ButtonGroup, Col, Form, FormControl, InputGroup,
    Modal, ProgressBar, OverlayTrigger, Tooltip, Spinner
} from 'react-bootstrap';
import {
    getCardsDataForCorporateOpportunityTab,
    getCorpOppFilter,
    getCorpOpportunityPurchaseMember,
    getCorpOpportunityPurchaseProductGroup,
    getCorpOpportunityPurchaseVendor,
    getCorpOpportunityRegion,
    getCorpOpportunitySaveVendorFeedback,
    getCorpOpportunityVendorList,
    getCorporateOpportunitySalesChartData
} from '../../../api/corporate-api';
import { updateSelectedNavIndex } from '../../../actions';

import C3Chart from 'react-c3js';
import ReactTable from "react-table-6";
import ReactExport from "react-export-excel";

import Cards from '../../../Common/CardsWithMax3Colums/CardsWithMax3Cloumns';
import MissedOpportunityByRegion from './MissedOpportunityByRegion';

import { radioItemsInCorporate } from '../../../utils/feedbackOptions';
import { priceFormatter, switchMonths } from '../../../utils/formatters';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import { PriceFormatter1 } from '../../../helper';
import Select from 'react-select';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';



import './index.scss';

class CorporateOpportunity extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            isLoading: true,
            show: false,
            isQuarterSelected: false,
            selectedRadioId: 0,
            source: 'verified',
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: "YTD",
            quarter: "1",
            buyingGroupId: "0",
            isCompliant: 'A',
            subTabVendor: "Sales",
            subTabMember: "Purchase",
            startRowNo: 0,
            params: {
                source: "verified",
                year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
                period: "YTD",
                quarter: "1",
                buyingGroupId: "0",
                isCompliant: "A",
                subTabVendor: "Sales",
                subTabMember: "Purchase",
                startRowNo: 0
            },
            selectedUnapprovedSupplier: {},
            coporateOpportunityFilters: {},
            listOfMonthsForSelectedYear: [],
            listOfYears: [],
            getCardsDataForCorporateOpportunityTab: [],
            getCorporateOpportunityMember: [],
            getCorporateOpportunityVendor: [],
            getCorporateOpportunityProductGroup: [],
            getCorporateOppRegion: [],
            searchKeyMember: "",
            searchKeyVendor: "",
            searchKeyProductGroup: "",
            isMemberFilteredDataAvailable: false,
            isVendorFilteredDataAvailable: false,
            isProductGroupFilteredDataAvailable: false,
            filteredSearchMember: [],
            filteredSearchVendor: [],
            filteredSearchProductGroup: [],
            salesChartData: {
                months: ['x', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                sales: ['Sales ($)', 166785899, 349008774, 543000445, 654788332, 700874000, 750876221, 950000223, 1100773331, 1150772889, 1250774321, 1320890665, 1423699798],
                salesOpportunity: ['Sales Opportunity ($)', 46898265, 98137228, 152685441, 184118901, 197077657, 211137703, 267129068, 309524721, 323584018, 351703263, 371419168, 400327906],
                rebate: ['Rebate ($)', 3836404, 8027889, 12490080, 15061422, 16121483, 17271633, 21851877, 25319956, 26470044, 28770274, 30383088, 32747901],
                rebateOpportunity: ['Rebate Opportunity ($)', 1500744, 3140391, 4885934, 5891805, 6306485, 6756406, 8548130, 9904791, 10354689, 11254504, 11885413, 12810493]
            },
            classigicationFlag: [
                { flag: 'IN', text: 'IN IMARK VENDOR' },
                { flag: 'BUYSELL', text: 'BUYSELL REP VENDOR' },
                { flag: 'LPG', text: 'LPG VENDOR' },
                { flag: 'PARENT', text: 'Map to Existing Out Vendor' }
            ]
        }
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);

        this.loadData();
    }

    componentDidUpdate(pP, pS, sS) {
        if (pS.year !== this.state.year && pS.params.year !== this.state.params.year) {
            console.log('parent year changed', pS.year, this.state.year);
            this.getData();
        }

        if (pS.month !== this.state.month && pS.params.month !== this.state.params.month) {
            console.log('parent month changed', pS.month, this.state.month);
            this.getData();
        }

        if (pS.period !== this.state.period && pS.params.period !== this.state.params.period) {
            this.getData();
        }

        if (pS.quarter !== this.state.quarter && pS.params.quarter !== this.state.params.quarter) {
            this.getData();
        }

        if (pS.buyingGroupId !== this.state.buyingGroupId && pS.params.buyingGroupId !== this.state.params.buyingGroupId) {
            this.getData();
        }
    }

    loadData = () => {
        Promise.allSettled([getCorpOppFilter()])
            .then((allResponses) => {
                console.log('loadData', allResponses);
                if (allResponses[0].value) {
                    const availableYears = Object.keys(allResponses[0].value).sort((a, b) => b - a);

                    this.setState((prevState) => ({
                        coporateOpportunityFilters: allResponses[0].value ? allResponses[0].value : {},
                        listOfYears: allResponses[0].value && Object.keys(allResponses[0].value),
                        // year: allResponses[0].value && Object.keys(allResponses[0].value)[0],
                        params: {
                            ...prevState.params,
                            // year: allResponses[0].value && Object.keys(allResponses[0].value)[0]
                        }
                    }), () => {
                        this.getListOfMonths(allResponses[0].value ,availableYears[0]);
                    })
                }
            })
            .catch((err) => console.log(err));
    }

    getData = () => {
        const formData = {
            year: this.state.year,
            month: this.state.month,
            period: this.state.period,
            source: this.state.source,
            quarter: this.state.quarter,
            isCompliant: this.state.isCompliant,
            buyingGroupId: this.state.buyingGroupId
        }
        this.setState({ isLoading: true })

        console.log('getData', formData.year);
        Promise.allSettled([
            getCardsDataForCorporateOpportunityTab(formData),
            getCorpOpportunityPurchaseMember(formData),
            getCorpOpportunityPurchaseVendor(formData),
            getCorpOpportunityPurchaseProductGroup(formData),
            getCorporateOpportunitySalesChartData(formData),
            getCorpOpportunityRegion(formData)
        ])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    this.setState({ getCardsDataForCorporateOpportunityTab: allResponses[0].value ? allResponses[0].value : [] })
                }
                if (allResponses[1].value) {
                    this.setState({
                        getCorporateOpportunityMember: allResponses[1].value ? allResponses[1].value : [],
                        filteredSearchMember: allResponses[1].value ? allResponses[1].value : [],
                        isMemberFilteredDataAvailable: false
                    })
                }
                if (allResponses[2].value) {
                    this.setState({
                        getCorporateOpportunityVendor: allResponses[2].value ? allResponses[2].value : [],
                        filteredSearchVendor: allResponses[2].value ? allResponses[2].value : [],
                        isVendorFilteredDataAvailable: false
                    })
                }
                if (allResponses[3].value) {
                    this.setState({
                        getCorporateOpportunityProductGroup: allResponses[3].value ? allResponses[3].value : [],
                        filteredSearchProductGroup: allResponses[3].value ? allResponses[3].value : [],
                        isProductGroupFilteredDataAvailable: false
                    })
                }
                if (allResponses[4].value) {
                    this.setState({ salesChartData: allResponses[4].value ? allResponses[4].value : [] })
                }
                if (allResponses[5].value) {
                  //  console.log('allResponses', allResponses[5].value);
                    this.setState({ getCorporateOppRegion: allResponses[5].value ? allResponses[5].value : [], isLoading: false })
                    //console.log('allResponses',this.state.getCorporateOppRegion)
                }
            })
            .catch((err) => console.log(err));
    }

    getListOfMonths = (allYears, year) => {
        for (const [key, value] of Object.entries(allYears)) {
            if (year == key) {
                let params = this.state.params
                params.month = value[value.length - 1]
                this.setState({
                    listOfMonthsForSelectedYear: value,
                    month: value[value.length - 1],
                    params,year
                }, () => {
                    this.getData()
                })
            }
        }
    }

    handleClose = () => this.setState({ show: false, selectedRadioId: 0, showSubmit: false })
    handleShow = () => this.setState({ show: true })

    selectedRadio = (id) => this.setState({ selectedRadioId: id, showSubmit: true, suggestedVendorId: '', suggestedVendorName: '' })

    reactBootstrapModal = () => {
        let { selectedUnapprovedSupplier, getCorporateOpportunityVendorList } = this.state;
        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Feedback for Out Supplier {selectedUnapprovedSupplier.vendorName}
                </Modal.Header>
                <Modal.Body>
                    <p>How do you want to classify <b>{selectedUnapprovedSupplier.vendorName}</b> as?</p>
                    {radioItemsInCorporate.map((item, index) => {
                        return (
                            <div key={index}>
                                <input
                                    type={item.type}
                                    checked={item.id === this.state.selectedRadioId ? true : false}
                                    style={{ marginRight: 10, cursor: 'pointer' }}
                                    onChange={() => this.selectedRadio(item.id)}
                                />
                                {item.label}
                                <br />
                            </div>
                        )
                    })}
                    {
                        this.state.selectedRadioId === "IN" &&
                        <div style={{ marginTop: 10 }}>
                            <Select

                                value={this.state.selectOptions}
                                isDisabled={this.state.isLoading}
                                onChange={(e) => this.handleSelectedSupplier(e)}
                                placeholder={'Select Supplier'}

                                options={Array.from(
                                    getCorporateOpportunityVendorList && getCorporateOpportunityVendorList.map((item, index) => {
                                        return {
                                            value: item.vendorId + ',' + item.vendorName,
                                            label: item.vendorName,
                                        };

                                    })
                                )}
                            />
                            {/* <Form.Group as={Col} controlId="formSelectSupplier">
                                <label>Select Supplier</label>
                                <Form.Control as="select" defaultValue="Select Supplier" onChange={(e) => this.handleSelectedSupplier(e)}>
                                    <option>Select Supplier</option>
                                    {getCorporateOpportunityVendorList && getCorporateOpportunityVendorList.map((item, index) => {
                                        return (
                                            <option key={index} value={`${item.vendorId},${item.vendorName}`}>
                                                {item.vendorName}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> */}
                        </div>
                    }
                    {
                        ((this.state.selectedRadioId === "BUYSELL") || (this.state.selectedRadioId === "NP") || (this.state.selectedRadioId === "EXCLUDE")) &&
                        <div style={{ marginTop: 10 }}>
                            <Select

                                value={this.state.selectOptions}
                                isDisabled={this.state.isLoading}
                                onChange={(e) => this.handleSelectedSupplier(e)}
                                placeholder={'Select Supplier'}

                                options={Array.from(
                                    getCorporateOpportunityVendorList && getCorporateOpportunityVendorList.map((item, index) => {
                                        return {
                                            value: item.vendorId + ',' + item.vendorName,
                                            label: item.vendorName,
                                        };

                                    })
                                )}
                            />
                            {/* <Form.Group as={Col} controlId="formSelectSuggestedSupplier">
                                <label>Select Suggested Supplier</label>
                                <Form.Control as="select" defaultValue="Select Suggested Supplier" onChange={(e) => this.handleSelectedSupplier(e)}>
                                    <option>Select Supplier</option>
                                    {getCorporateOpportunityVendorList && getCorporateOpportunityVendorList.map((item, index) => {
                                        return (
                                            <option key={index} value={`${item.vendorId},${item.vendorName}`}>
                                                {item.vendorName}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> */}
                        </div>
                    }
                    {
                        this.state.selectedRadioId === "PARENT" &&
                        <div style={{ marginTop: 10 }}>
                            <Form.Group as={Col} controlId="formUnapprovedSupplier">
                                <label>Provide an existing Non-Preferred Supplier</label>
                                {/* <Form.Control type="text"></Form.Control> */}
                                <Form.Control type="text" name="suggestedVendorName" ref="suggestedVendorName" onChange={(e, value) => {
                                    this.setState({ suggestedVendorName: e.target.value, ErrorMessage: '' });;
                                }} />
                            </Form.Group>
                        </div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.state.showSubmit ? "primary" : "secondary"} disabled={!this.state.showSubmit} onClick={() => this.handleSupplierFeedbackSubmit()}>
                        Submit Feedback
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderOpportunityByMember() {
        let {
            getCorporateOpportunityMember
        } = this.state;
        let Opportunity = getCorporateOpportunityMember && getCorporateOpportunityMember.map((item) => item.rebateOpportunity);
        let maxOpportunity = Opportunity && Math.max(...Opportunity);

        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Non-Preferred Purchases by Member</span>
                        </div>
                        <div className="card-body" style={{ maxHeight: " 410" }}>
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for purchase outside IMARK by member info.."
                                        aria-label="Search for purchase outside IMARK by member info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyMember}
                                        onChange={(e) => this.handleSearchForMember(e, this.state.getCorporateOpportunityMember)}

                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForMember()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isMemberFilteredDataAvailable ? this.state.filteredSearchMember : this.state.getCorporateOpportunityMember}
                                // filterable
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                        accessor: "memberName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Rebate Opportunity</b>,
                                        accessor: 'rebateOpportunity',
                                        sortable: true,
                                        resizable: true,
                                        Cell: props => (
                                            <React.Fragment>
                                                <OverlayTrigger overlay={(<Tooltip >{priceFormatter(props.original.rebateOpportunity).props.children}</Tooltip>)} >
                                                    <div
                                                        data-iscapture="true"
                                                    >
                                                        <ProgressBar now={((props.original.rebateOpportunity * 100) / maxOpportunity)} />
                                                    </div>
                                                </OverlayTrigger>
                                            </React.Fragment>
                                        )
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Purchases</b>,
                                        accessor: 'missedOpportunity',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props =><div style={{textAlign:'center'}}>{PriceFormatter1(props.original.missedOpportunity)}</div>
                                    },
                                ]}
                                defaultSorted={[
                                    {
                                        id: "missedOpportunity",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: e => {
                                                this.setState({
                                                    selectedTableRowIndex: rowInfo.index,
                                                })
                                                this.handleRowClickMember(rowInfo.original.memberId);
                                            },
                                            style: {
                                                background:
                                                    rowInfo.index === this.state.selectedTableRowIndex ? "lightgrey" : null
                                            }
                                        };
                                    } else {
                                        return {};
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderOpportunityByOutVendors() {
        let {
            getCorporateOpportunityVendor
        } = this.state;
        let Opportunity = getCorporateOpportunityVendor && getCorporateOpportunityVendor.map((item) => item.rebateOpportunity);
        let maxOpportunity = Opportunity && Math.max(...Opportunity);
        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span> Non-Preferred Purchases by Supplier</span>
                        </div>
                        <div className="card-body" style={{ maxHeight: "356" }}>
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for purchase from supplier outside IMARK info.."
                                        aria-label="Search for purchase from supplier outside IMAEK info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyVendor}
                                        onChange={(e) => this.handleSearchForVendor(e, this.state.getCorporateOpportunityVendor)}

                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForVendor()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isVendorFilteredDataAvailable ? this.state.filteredSearchVendor : this.state.getCorporateOpportunityVendor}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Non-Preferred Supplier</b>,
                                        accessor: "vendorName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Rebate Opportunity</b>,
                                        accessor: 'rebateOpportunity',
                                        sortable: true,
                                        resizable: true,
                                        Cell: props => (
                                            <React.Fragment>
                                                <OverlayTrigger overlay={(<Tooltip >{priceFormatter(props.original.rebateOpportunity).props.children}</Tooltip>)} >
                                                    <div
                                                        data-iscapture="true"
                                                    >
                                                        <ProgressBar now={((props.original.rebateOpportunity * 100) / maxOpportunity)} />
                                                    </div>
                                                </OverlayTrigger>

                                            </React.Fragment>
                                        )
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Purchase</b>,
                                        accessor: 'missedOpportunity',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.missedOpportunity)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Feedback</b>,
                                        accessor: 'feedback',
                                        sortable: true,
                                        resizable: true,
                                        Cell: props => <i className="fa fa-comment-alt" onClick={() => this.vendorFeedbackModel(props)} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>,
                                        style: { textAlign: "center" }
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "missedOpportunity",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: e => {
                                                this.setState({
                                                    selectedTableRowIndexForVendor: rowInfo.index,
                                                })
                                                this.handleRowClickVendor(rowInfo.original.vendorName);
                                            },
                                            style: {
                                                background:
                                                    rowInfo.index === this.state.selectedTableRowIndexForVendor ? "lightgrey" : null
                                            }
                                        };
                                    } else {
                                        return {};
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderOpportunityByProductGroup() {
        let {
            getCorporateOpportunityProductGroup
        } = this.state;
        let Opportunity = getCorporateOpportunityProductGroup && getCorporateOpportunityProductGroup.map((item) => item.rebateOpportunity);
        let maxOpportunity = Opportunity && Math.max(...Opportunity);

        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Non-Preferred Opportunity by Product Group</span>
                        </div>
                        <div className="card-body" style={{ maxHeight: " 356" }}>
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for missed opportunity by product group info.."
                                        aria-label="Search for  missed opportunity by product group info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyProductGroup}
                                        onChange={(e) => this.handleSearchForProductGroup(e, this.state.getCorporateOpportunityProductGroup)}

                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForProductGroup()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isProductGroupFilteredDataAvailable ? this.state.filteredSearchProductGroup : getCorporateOpportunityProductGroup}
                                // filterable
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Product Category</b>,
                                        accessor: "tierFour",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Rebate Opportunity</b>,
                                        accessor: 'rebateOpportunity',
                                        sortable: true,
                                        resizable: true,
                                        Cell: props => (
                                            <React.Fragment>
                                                <OverlayTrigger overlay={(<Tooltip >{priceFormatter(props.original.rebateOpportunity).props.children}</Tooltip>)} >
                                                    <div
                                                        data-iscapture="true"
                                                    >
                                                        <ProgressBar now={((props.original.rebateOpportunity * 100) / maxOpportunity)} />
                                                    </div>
                                                </OverlayTrigger>
                                            </React.Fragment>
                                        )
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Missed Opportunity</b>,
                                        accessor: 'missedOpportunity',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{PriceFormatter1(props.original.missedOpportunity)}</div>
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "missedOpportunity",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                                getTrProps={(state, rowInfo) => {
                                    if (rowInfo && rowInfo.row) {
                                        return {
                                            onClick: e => {
                                                this.setState({
                                                    selectedTableRowIndexForProduct: rowInfo.index,
                                                })
                                                this.handleRowClickProduct(rowInfo.original.tierFourId);
                                            },
                                            style: {
                                                background:
                                                    rowInfo.index === this.state.selectedTableRowIndexForProduct ? "lightgrey" : null
                                            }
                                        };
                                    } else {
                                        return {};
                                    }
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    vendorFeedbackModel = (props) => {
        this.setState({ show: true, selectedUnapprovedSupplier: props.original })
        getCorpOpportunityVendorList().then((data) => {
            this.setState({
                getCorporateOpportunityVendorList: data
            })
        })
    }

    handleSupplierFeedbackSubmit = () => {
        const formData = {
            vendorDisplayName: this.state.selectedUnapprovedSupplier.vendorName,
            classificationFlag: this.state.selectedRadioId,
            suggestedVendorId: this.state.suggestedVendorId,
            suggestedVendorName: this.state.suggestedVendorName,
        }
        getCorpOpportunitySaveVendorFeedback(formData).then((data) => {
            this.setState({ show: false, showSubmit: false, suggestedVendorId: false, suggestedVendorName: false, selectedRadioId: 0 })
        })
    }

    handleSelectedSupplier1 = (value) => {
        let arr = [];

        let vendorId = '';
        let vendorName = '';

        if (value) {
            let valueee = value.map((option, index) => {
                arr.push(option.value);

            });
            let vendorId = arr[0].split(',')[0];
            let vendorName = arr[0].split(',')[1];

        }
        else {
            let vendorName = _findIndex(value, "Select Supplier");
            let vendorId = '';
        }

        this.setState({
            suggestedVendorId: vendorId,
            suggestedVendorName: vendorName
        })

    };


    handleSelectedSupplier = (e) => {
        if (e.value) {
            let vendorId = e.value.split(',')[0];
            let vendorName = e.value.split(',')[1];

            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: true
            })
        }
        else {
            let vendorName = _findIndex(e, "Select Supplier");
            let vendorId = '';
            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: false
            })
        }
    }

    handleRowClickMember = (selectedMemberId) => {
        let params = this.state.params;
        params.memberId = selectedMemberId;
        this.setState({ isLoading: true })
        getCorpOpportunityPurchaseVendor(params).then((data) => {
            this.setState({
                getCorporateOpportunityVendor: data
            })
        })
        getCorpOpportunityRegion(params).then((data) => {
            this.setState({
                getCorporateOppRegion: data,
                isLoading: false
            })
        })
        getCorpOpportunityPurchaseProductGroup(params).then((data) => {
            this.setState({
                getCorporateOpportunityProductGroup: data,
            })
        })
    }

    handleRowClickVendor = (selectedVendorId) => {
        let params = this.state.params;
        params.vendorDisplayName = selectedVendorId;
        this.setState({
            isLoading: true
        })
        getCorpOpportunityPurchaseMember(params).then((data) => {
            this.setState({
                getCorporateOpportunityMember: data
            })
        })
        getCorpOpportunityRegion(params).then((data) => {
            this.setState({
                getCorporateOppRegion: data,
                isLoading: false
            })
        })
        getCorpOpportunityPurchaseProductGroup(params).then((data) => {
            this.setState({
                getCorporateOpportunityProductGroup: data,
            })
        })
    }

    handleRowClickProduct = (selectedProductCat) => {
        let params = this.state.params;
        params.productGroupId = selectedProductCat;
        this.setState({
            isLoading: true
        })
        getCorpOpportunityPurchaseMember(params).then((data) => {
            this.setState({
                getCorporateOpportunityMember: data
            })
        })
        getCorpOpportunityRegion(params).then((data) => {
            this.setState({
                getCorporateOppRegion: data,
                isLoading: false
            })
        })
        getCorpOpportunityPurchaseVendor(params).then((data) => {
            this.setState({
                getCorporateOpportunityVendor: data
            })
        })
    }

    handleSearchForMember = (e, data) => {
        this.setState({ searchKeyMember: e.target.value });
        let results = data.filter((item) => {
            return item.memberName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isMemberFilteredDataAvailable: true,
            filteredSearchMember: results
        });
    }

    clearButtonForMember = () => {
        this.setState({
            searchKeyMember: "",
            isMemberFilteredDataAvailable: false,
            filteredSearchMember: [...this.state.getCorporateOpportunityMember],
            selectedTableRowIndex: null,
            isLoading: true
        });

        let params = this.state.params;
        params.memberId = "";

        getCorpOpportunityPurchaseMember(params).then((data) => {
            this.setState({
                getCorporateOpportunityMember: data,
                // filteredSearchMember: data,
                isMemberFilteredDataAvailable: false
            })
        })

        getCorpOpportunityPurchaseVendor(params).then((data) => {
            this.setState({
                getCorporateOpportunityVendor: data,
                // filteredSearchVendor: data,
                isVendorFilteredDataAvailable: false
            })
        })

        getCorpOpportunityRegion(params).then((data) => {
            console.log('clear button', data);
            this.setState({
                getCorporateOppRegion: data,
                isLoading: false
            })
        })

        getCorpOpportunityPurchaseProductGroup(params).then((data) => {
            this.setState({
                getCorporateOpportunityProductGroup: data,
                // filteredSearchProductGroup: data,
                isProductGroupFilteredDataAvailable: false
            })
        })
    }

    handleSearchForProductGroup = (e, data) => {
        this.setState({ searchKeyProductGroup: e.target.value });
        let results = data.filter((item) => {
            return item.tierFour.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isProductGroupFilteredDataAvailable: true,
            filteredSearchProductGroup: results
        });
    }

    clearButtonForProductGroup = () => {
        this.setState({
            searchKeyProductGroup: "",
            isProductGroupFilteredDataAvailable: false,
            filteredSearchProductGroup: [...this.state.getCorporateOpportunityProductGroup],
            selectedTableRowIndexForProduct: null,
            isLoading: true
        });

        let params = this.state.params;
        params.productGroupId = "";

        getCorpOpportunityPurchaseProductGroup(params).then((data) => {
            this.setState({
                getCorporateOpportunityProductGroup: data,
                // filteredSearchProductGroup: data,
                isProductGroupFilteredDataAvailable: false
            })
        })

        getCorpOpportunityPurchaseVendor(params).then((data) => {
            this.setState({
                getCorporateOpportunityVendor: data,
                // filteredSearchVendor: data,
                isVendorFilteredDataAvailable: false
            })
        })

        getCorpOpportunityPurchaseMember(params).then((data) => {
            this.setState({
                getCorporateOpportunityMember: data,
                // filteredSearchMember: data,
                isMemberFilteredDataAvailable: false
            })
        })

        getCorpOpportunityRegion(params).then((data) => {
            this.setState({
                getCorporateOppRegion: data,
                isLoading: false
            })
        })
    }

    handleSearchForVendor = (e, data) => {
        this.setState({ searchKeyVendor: e.target.value });
        let results = data.filter((item) => {
            return item.vendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isVendorFilteredDataAvailable: true,
            filteredSearchVendor: results
        });
    }

    clearButtonForVendor = () => {
        this.setState({
            searchKeyVendor: "",
            isVendorFilteredDataAvailable: false,
            filteredSearchVendor: [...this.state.getCorporateOpportunityVendor],
            selectedTableRowIndexForVendor: null,
            isLoading: true
        });

        let params = this.state.params;
        params.vendorDisplayName = "";

        getCorpOpportunityPurchaseVendor(params).then((data) => {
            this.setState({
                getCorporateOpportunityVendor: data,
                // filteredSearchVendor: data,
                isVendorFilteredDataAvailable: false
            })
        })

        getCorpOpportunityPurchaseMember(params).then((data) => {
            this.setState({
                getCorporateOpportunityMember: data,
                // filteredSearchMember: data,
                isMemberFilteredDataAvailable: false
            })
        })

        getCorpOpportunityRegion(params).then((data) => {
            this.setState({
                getCorporateOppRegion: data,
                isLoading: false
            })
        })

        getCorpOpportunityPurchaseProductGroup(params).then((data) => {
            this.setState({
                getCorporateOpportunityProductGroup: data,
                // filteredSearchProductGroup: data,
                isProductGroupFilteredDataAvailable: false
            })
        })
    }

    handleChangeOfDropdownextra(event) {
        let obj = {};
        obj[event.name] = event.value;
        this.setState(obj, () => {
            this.setFilter({
                buyingGroupId: this.state.buyingGroupId,
                year: this.state.year,
                period: this.state.period,
                month: this.state.month,
                quarter: this.state.quarter,
            });
        });
    }

    handleSelectedRegionCode = (region) => {
        // this.setState({ selectedRegion: region });
        let params = this.state.params;
        params.region = region;
        getCorpOpportunityPurchaseMember(params).then((data) => {
            this.setState({
                getCorporateOpportunityMember: data
            })
        })
        getCorpOpportunityPurchaseVendor(params).then((data) => {
            this.setState({
                getCorporateOpportunityVendor: data
            })
        })
        getCorpOpportunityPurchaseProductGroup(params).then((data) => {
            this.setState({
                getCorporateOpportunityProductGroup: data,
            })
        })
    }

    handleBranch = (e) => {
        this.setState((prevState) => ({
            buyingGroupId: e.target.value,
            params: { ...prevState.params, buyingGroupId: e.target.value }
        }));
    }

    handlePeriod = (e) => {
        if (e.target.name === "QTR") {
            this.setState((prevState) => ({
                isQuarterSelected: true,
                period: e.target.name,
                params: { ...prevState.params, period: e.target.name }
            }));
        } else {
            this.setState((prevState) => ({
                isQuarterSelected: false,
                period: e.target.name,
                params: { ...prevState.params, period: e.target.name }
            }));
        }
    }

    handleYear = (e) => {
        this.setState((prevState) => ({
            year: e.target.value,
            params: { ...prevState.params, year: e.target.value },
            filteredSearchMember: [],
            filteredSearchProductGroup: [],
            filteredSearchVendor: [],
            searchKeyMember: "",
            searchKeyVendor: "",
            searchKeyProductGroup: ""
        }), () => {
            this.getListOfMonths(this.state.coporateOpportunityFilters, this.state.year);
        });
    }
    handleQuarter = (e) => {
        this.setState((prevState) => ({
            quarter: e.target.value,
            params: { ...prevState.params, quarter: e.target.value }
        }))
    }

    handleMonth = (e) => {
        this.setState((prevState) => ({
            month: e.target.value,
            params: { ...prevState.params, month: e.target.value }
        }))
    }

    render() {

        //console.log('render', this.state.year, this.state.month);
        let {
            period,
            getCardsDataForCorporateOpportunityTab,
            salesChartData
        } = this.state;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        var dataForChart1 = {
            data: {
                x: 'x',
                columns: [
                    salesChartData.months, salesChartData.sales, salesChartData.salesOpportunity
                ],
                type: 'bar',
                groups: [
                    ['Sales ($)', 'Sales Opportunity ($)']
                ],
                onclick: function (d, i) { },
                onmouseover: function (d, i) { },
                onmouseout: function (d, i) { }
            },
            axis: {
                x: {
                    label: "Months",
                    type: 'category'
                },
                y: {
                    label: "Sales Opportunity ($)",
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            }
        };

        var dataForChart2 = {
            data: {
                x: 'x',
                columns: [
                    salesChartData.months, salesChartData.rebate, salesChartData.rebateOpportunity
                ],
                type: 'bar',
                groups: [
                    ['Rebate ($)', 'Rebate Opportunity ($)']
                ],
                onclick: function (d, i) { },
                onmouseover: function (d, i) { },
                onmouseout: function (d, i) { }
            },
            axis: {
                x: {
                    label: "Months",
                    type: 'category'
                },
                y: {
                    label: "Reabte Opportunity ($)",
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            }
        };
        console.log('render', this.state.getCorporateOppRegion);
        // console.log(this.state.year, this.state.params.year);

        return (
            <div>
                {this.reactBootstrapModal()}

                <div className="filtersContainer">
                    <div className="filterTitle">
                        <p>Opportunity</p>
                    </div>
                    <div className="filterItems">
                        <Form.Group as={Col} id="group1">
                            <Form.Control
                                as="select"
                                id="select1"
                                defaultValue="0"
                                onChange={(e) => this.handleBranch(e)}
                            >
                                <option value="0">IMARK ELECTRICAL & IMARK LLC</option>
                                <option value="1">IMARK LLC</option>
                                <option value="2">IMARK ELECTRICAL</option>
                            </Form.Control>
                        </Form.Group>
                        <ButtonGroup className="yearGroup">
                            <Button
                                onClick={(e) => this.handlePeriod(e)}
                                style={{ fontSize: 12, background: `${period === "MTH" ? "#0074d9" : "white"}`, color: `${period === "MTH" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="MTH"
                            >
                                MTH</Button>
                            <Button
                                onClick={(e) => this.handlePeriod(e)}
                                style={{ fontSize: 12, background: `${period === "YTD" ? "#0074d9" : "white"}`, color: `${period === "YTD" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="YTD"
                            >
                                YTD
                            </Button>
                            <Button
                                onClick={(e) => this.handlePeriod(e)}
                                style={{ fontSize: 12, background: `${period === "QTR" ? "#0074d9" : "white"}`, color: `${period === "QTR" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="QTR"
                            >
                                QTR
                            </Button>
                        </ButtonGroup>
                        <Form.Group as={Col} id="group3">
                            <Form.Control
                                as="select"
                                id="select2"
                                defaultValue={this.state.listOfYears[this.state.listOfYears.length - 1]}
                                onChange={(e) => this.handleYear(e)}
                            >
                                {this.state.listOfYears && this.state.listOfYears.map((year) => {
                                    return <option key={year} selected={this.state.year == year ? true : false} value={year}>{year}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        {this.state.isQuarterSelected ? (
                            <Form.Group as={Col} id="group4">
                                <Form.Control
                                    as="select"
                                    id="select3"
                                    defaultValue={"1"}
                                    onChange={(e) => this.handleQuarter(e)}
                                >
                                    <option value="1">QTR1</option>
                                    <option value="2">QTR2</option>
                                    <option value="3">QTR3</option>
                                    <option value="4">QTR4</option>
                                </Form.Control>
                            </Form.Group>
                        ) : (
                            <Form.Group as={Col} id="group4">
                                <Form.Control
                                    as="select"
                                    id="select3"
                                    defaultValue={this.state.listOfMonthsForSelectedYear[this.state.listOfMonthsForSelectedYear.length - 1]}
                                    onChange={(e) => this.handleMonth(e)}
                                >
                                    {this.state.listOfMonthsForSelectedYear[this.state.listOfMonthsForSelectedYear.length - 1]}
                                    {this.state.listOfMonthsForSelectedYear && this.state.listOfMonthsForSelectedYear.map((month) => {
                                        return <option key={month} selected={this.state.month == month ? true : false} value={month}>{switchMonths(month)}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        )}
                        <ExcelFile filename="OPPORTUNITY DATA" element={<Button className="export" color="primary" onClick={(e) => { e.preventDefault() }} style={{ fontSize: 12 }}>Export</Button>}>
                            <ExcelSheet data={this.state.getCorporateOpportunityVendor} name="Missed Opportunity By Vendor">
                                <ExcelColumn label="OUT VENDOR" value="vendorName" />
                                <ExcelColumn label="MISSED OPPORTUNITY" value="missedOpportunity" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.getCorporateOpportunityMember} name="Missed Opportunity By Member">
                                <ExcelColumn label="MEMBER NAME" value="memberName" />
                                <ExcelColumn label="MISSED OPPORTUNITY" value="missedOpportunity" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.getCorporateOpportunityProductGroup} name="Volume By Product Group">
                                <ExcelColumn label="PRODUCT CATEGORY" value="tierFour" />
                                <ExcelColumn label="MISSED OPPORTUNITY" value="missedOpportunity" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>

                <Cards cardItems={getCardsDataForCorporateOpportunityTab} />

                <div className="card-group shadow-sm mb-3" style={{ marginLeft: "60px", marginRight: "60px" }}>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Sales ($) vs Sales Opportunity ($)</div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="chart chart-sm c3" id="membeRebateChart">
                                        <C3Chart data={dataForChart1.data} axis={dataForChart1.axis} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Rebate ($) vs Rebate Opportunity ($)</div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="chart chart-sm c3" id="membeRebateChart">
                                        <C3Chart data={dataForChart2.data} axis={dataForChart2.axis} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "0", marginLeft: "45px", marginRight: "45px" }}>
                    <div className="col-6">
                        {this.state.isLoading ?
                            <div style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', height: 520
                            }}>
                                <React.Fragment>
                                    <Spinner animation="border" variant="primary" />
                                </React.Fragment>
                            </div>
                            : <MissedOpportunityByRegion
                                getCorporateOppRegion={this.state.getCorporateOppRegion}
                                handleSelectedRegionCodeReference={this.handleSelectedRegionCode}
                            />}
                    </div>
                    <div className="col-6">
                        {this.renderOpportunityByMember()}
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "0", marginLeft: "45px", marginRight: "45px" }}>
                    <div className="col-6">
                        {this.renderOpportunityByOutVendors()}
                    </div>
                    <div className="col-6">
                        {this.renderOpportunityByProductGroup()}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        getCardsDataForCorporateOpportunityTab: state.corporate.getCardsDataForCorporateOpportunityTab,
        getCorporateOpportunityMember: state.corporate.getCorporateOpportunityMember,
        getCorporateOpportunityVendor: state.corporate.getCorporateOpportunityVendor,
        getCorporateOpportunityProductGroup: state.corporate.getCorporateOpportunityProductGroup,
        getCorporateOpportunitySalesChartData: state.corporate.getCorporateOpportunitySalesChartData,
        getCorporateOpportunityVendorList: state.corporate.getCorporateOpportunityVendorList,
        getCorporateOppFilters: state.corporate.getCorporateOppFilters,
        getCorporateOppRegion: state.corporate.getCorporateOppRegion
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateOpportunity);
