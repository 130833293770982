import { combineReducers } from 'redux';

import authenticationReducer from './reducerAuthentication';
import navigationReducer from './reducerNavigate';
import memberReducer from './reducerMember';
import supplierReducer from './reducerSupplier';
import corporateReducer from './reducerCorporate';

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    navigation: navigationReducer,
    member: memberReducer,
    supplier: supplierReducer,
    corporate: corporateReducer
});

export default rootReducer;
