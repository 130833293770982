import React from 'react';
import { Card } from 'react-bootstrap';
import { switchIndicator } from '../../utils/switchIndicator';
import { formatYOY, priceFormatter } from '../../utils/formatters';
import '../../assets/styles/Cards.scss';

const OverviewCards = ({ cardItems }) => {
    return (
        <div className="cardsContainer">
            {cardItems && cardItems.map((card, index) => {
                //return (
                if (card.cardTitle == "Total Transactions") {
                    return (<Card className="rebateCard" key={index}>
                        <Card.Body>
                            <Card.Text className="cardTitle">Total Sales (SAP)</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    )
                }
               else if (card.cardTitle == "Base Rebate") {
                    return (<Card className="rebateCard" key={index}>
                        <Card.Body>
                            <Card.Text className="cardTitle">Total Base Rebate (Collected and True-Ups)</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    )
                }
                else if (card.cardTitle == "Estimated Growth Rebate") {
                    return (<Card className="rebateCard" key={index}>
                        <Card.Body>
                            <Card.Text className="cardTitle">Estimated Group Growth Rebate</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    )
                }

                else if (card.cardTitle == "Estimated Gain Share") {
                    return (<Card className="rebateCard" key={index}>
                        <Card.Body>
                            <Card.Text className="cardTitle">Estimated Gain Share (SAP)</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    )
                }
                else if (card.cardTitle == "Gold/Platinum") {
                    return ("")
                }
               else if (card.cardTitle == "IMELCO") {
                    return (""

                    )
                }
               else if (card.cardTitle == "Pass Through") {
                    return (""

                    )
                }
                 
                else if (card.cardTitle == "Total Estimated Rebate") {
                    return (<Card className="rebateCard" key={index}>
                        <Card.Body>
                            <Card.Text className="cardTitle">Total Rebate</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    )
                }
                
            })}
        </div>
    );
};

export default OverviewCards;