import Noty from 'noty';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import React from 'react';
import { Button, Card, Col, Form, FormControl, InputGroup, Modal, ProgressBar } from 'react-bootstrap';
import ReactTable from "react-table-6";
import { saveOutVendorFeedback } from '../../../../api/member-api';
import { radioItems } from '../../../../utils/feedbackOptions';
import { priceFormatter } from '../../../../utils/formatters';
import Select from 'react-select';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';


const CLASSIFICATION_MAP = {
    1: 'IN',
    2: 'BUYSELL',
    3: 'PARENT',
    4: 'EXCLUDE',
    5: 'NP',
    6: 'MEMBER_COMPANY',
    7: 'BRANCH_COMPANY'
};

export default class PurchaseOutsideImark extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSubmit: false,
            selectedRadioId: 0,
            classificationFlag: null,
            suggestedVendorId: null,
            suggestedVendorName: null,
            feedbackComments: null,
            selectedUnapprovedSupplier: {},
            searchKey: "",
            filteredSearchResults: [...props.purchaseOutsideImark]
        }
    }

    handleClose = () => this.setState({ show: false, selectedRadioId: 0,ErrorMessage:'' })
    handleShow = () => this.setState({ show: true })

    selectedRadio = (id) => this.setState({ selectedRadioId: id })

    handleSelectedSupplier1 = (value) => {
        let arr = [];

        let vendorId = '';
        let vendorName = '';
        if (value ) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let vendorId = arr[0].split(',')[0];
          let vendorName = arr[0].split(',')[1];
  
        }
        else {
            let vendorName = _findIndex(value,"Select Supplier");
            let vendorId = '';
          }
        
          this.setState({
            suggestedVendorId: vendorId,
            suggestedVendorName: vendorName,
            showSubmit: true
        })
    
      };
    

    handleSelectedSupplier = (e) => {
        if (e.value) {
            let vendorId = e.value.split(',')[0];
            let vendorName = e.value.split(',')[1];

            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                ErrorMessage:'',
                showSubmit: true
            })
        }
        else {
            let vendorName = _findIndex(e,"Select Supplier");
            let vendorId = '';
            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                ErrorMessage:'',
                showSubmit: false
            })
          }
    }

    handleOutsideSupplierTextbox = (e) => {
        console.log(e.target.value);
        this.setState({
            suggestedVendorName: e.target.value,
            ErrorMessage:'',
            showSubmit: true
        })
    }

    onFeebackSubmit = () => {

        let classificationFlagValue = CLASSIFICATION_MAP[this.state.selectedRadioId] || null;

        let formData = {
            vendorDisplayName: this.state.selectedUnapprovedSupplier.vendorName,
            classificationFlag: classificationFlagValue,
            suggestedVendorId: (classificationFlagValue === 'PARENT') ||
                (classificationFlagValue === 'EXCLUDE') ||
                (classificationFlagValue === 'NP') ||
                (classificationFlagValue === 'MEMBER_COMPANY') ||
                (classificationFlagValue === 'BRANCH_COMPANY') ? null :
                this.state.suggestedVendorId,
            suggestedVendorName: (classificationFlagValue === 'EXCLUDE') ||
                (classificationFlagValue === 'NP') ||
                (classificationFlagValue === 'MEMBER_COMPANY') ||
                (classificationFlagValue === 'BRANCH_COMPANY') ? null :
                this.state.suggestedVendorName,
            feedbackComments: this.state.feedbackComments
        }

        console.log('SaveOutVendorFeedbackDataInPurchaseOutsideImark', formData);
        if(this.state.selectedRadioId == 1 || this.state.selectedRadioId ==2 || this.state.selectedRadioId == 3)
        {
        if (!this.state.suggestedVendorName) {
            this.setState({ ErrorMessage: 'Please select a supplier' }, () => {
            });
        } else {
        saveOutVendorFeedback(formData)
            .then((responseData) => {
                console.log('savedOutVendorFeedback', responseData);
                this.setState({ show: false,selectedRadioId: 0, suggestedVendorId:false,suggestedVendorName:false,ErrorMessage:''}, () => {
                    new Noty({
                        text: responseData.text,
                        layout: "topRight",
                        theme: "mint",
                        type: "success"
                    }).show();
                })
            })
            .catch((err) => console.log(err));
        }
    }
    else{
        saveOutVendorFeedback(formData)
        .then((responseData) => {
            console.log('savedOutVendorFeedback', responseData);
            this.setState({ show: false,selectedRadioId: 0, suggestedVendorId:false,suggestedVendorName:false, ErrorMessage :''}, () => {
                new Noty({
                    text: responseData.text,
                    layout: "topRight",
                    theme: "mint",
                    type: "success"
                }).show();
            })
        })
        .catch((err) => console.log(err));
    }
    }

    purchaseOutsideImarkFeedbackModal = (terminatedVPNamesForMember) => {

        let { selectedUnapprovedSupplier } = this.state;

        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    <p style={{ margin: 0 }}>Feedback for Non-Preferred Supplier <strong> {selectedUnapprovedSupplier.vendorName}</strong></p>
                </Modal.Header>
                <Modal.Body>
                    <p>How do you want to classify <b>{selectedUnapprovedSupplier.vendorName}</b> as?</p>
                    {radioItems.map((item, index) => {
                        return (
                            <div key={index}>
                                <input
                                    type={item.type}
                                    checked={item.id === this.state.selectedRadioId ? true : false}
                                    style={{ marginRight: 10, cursor: 'pointer' }}
                                    onChange={() => this.selectedRadio(item.id)}
                                />
                                {item.label}
                                <br />
                            </div>
                        )
                    })}
                    {
                        this.state.selectedRadioId === 1 &&
                        <div style={{ marginTop: 10 }}>
                
                
                 <Select
                            
                    value={this.state.selectOptions}
                    isDisabled={this.state.isLoading}
                    onChange={(e) => this.handleSelectedSupplier(e)}
                    placeholder={'Select Supplier'}
                    
                    options={Array.from(
                        terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                        return {
                          value: item.vendorId + ','+ item.vendorName,
                          label: item.vendorName,
                        };
                        
                      })
                    )}
                  />

                            {/* <Form.Group as={Col} controlId="formSelectSupplier">
                                <label>Select Supplier</label>
                                <Form.Control as="select" defaultValue="Select Supplier" onChange={(e) => this.handleSelectedSupplier(e)}>
                                    <option>Select Supplier</option>
                                    {terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                        return (
                                            <option key={index} value={`${item.vendorId},${item.vendorName}`}>
                                                {item.vendorName}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> */}
                        </div>
                        
                    }
                    {
                        this.state.selectedRadioId === 2 &&
                        <div style={{ marginTop: 10 }}><Select
                            
                        value={this.state.selectOptions}
                        isDisabled={this.state.isLoading}
                        onChange={(e) => this.handleSelectedSupplier(e)}
                        placeholder={'Select Supplier'}
                        
                        options={Array.from(
                            terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                            return {
                              value: item.vendorId + ','+ item.vendorName,
                              label: item.vendorName,
                            };
                            
                          })
                        )}
                      />
    
                            {/* <Form.Group as={Col} controlId="formSelectSuggestedSupplier">
                                <label>Select Suggested Supplier</label>
                                <Form.Control as="select" defaultValue="Select Suggested Supplier" onChange={(e) => this.handleSelectedSupplier(e)}>
                                    <option>Select Supplier</option>
                                    
                                    {terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                        
                                        return (
                                            <option key={index} value={`${item.vendorId},${item.vendorName}`}>
                                                {item.vendorName}
                                            </option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> */}
                        </div>
                    }
                    {
                        this.state.selectedRadioId === 3 &&
                        <div style={{ marginTop: 10 }}>
                            <Form.Group as={Col} controlId="formUnapprovedSupplier">
                                <label>Provide an existing Non-Preferred Supplier</label>
                                <Form.Control type="text" onChange={(e) => this.handleOutsideSupplierTextbox(e)}></Form.Control>
                            </Form.Group>
                        </div>
                    }
                      <span className="errorText">{this.state.ErrorMessage}</span>
                </Modal.Body>
                <Modal.Footer>
                {/* <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit} onClick={(e) => this.onFeebackSubmit(e)}>
                        Submit Feedback
                    </Button> */}
                    <Button variant="primary" onClick={() => this.onFeebackSubmit()}>
                        Submit Feedback
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    clearTable = () => this.setState({ searchKey: "" });

    render() {

        let opportunities = this.props.purchaseOutsideImark && this.props.purchaseOutsideImark.map((item) => item.rebateOpportunity);
        let maxOpportunity = opportunities && Math.max(...opportunities);

        let filteredRows = this.props.purchaseOutsideImark;

        if (this.state.searchKey) {
            filteredRows = this.props.purchaseOutsideImark.filter((item) => item.vendorName.toLowerCase().includes(this.state.searchKey.toLowerCase()));
        }

        return (
            <Card className="purchaseOutsideImarkContainer">
                {this.purchaseOutsideImarkFeedbackModal(this.props.terminatedVPNamesForMember)}
                <Card.Header>Purchases from Non-Preferred IMARK Suppliers</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search for Purchases from Non-Preferred IMARK Suppliers info.."
                                aria-label="Search for purchase outside IMARK info.."
                                aria-describedby="basic-addon2"
                                value={this.state.searchKey}
                                onChange={(e) => this.setState({ searchKey: e.target.value })}
                            />
                            <InputGroup.Append>
                                <Button onClick={() => {
                                    this.clearTable()
                                    this.props.clearRetrievedTableData();
                                }}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <ReactTable
                        data={filteredRows}
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Non-Preferred Supplier</b>,
                                accessor: "vendorName",
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Estimated Opportunity</b>,
                                accessor: 'rebateOpportunity',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                Cell: props => (
                                    <React.Fragment>
                                        <ProgressBar now={((props.original.rebateOpportunity * 100) / maxOpportunity)} />
                                    </React.Fragment>
                                )
                            },

                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Member Purchase</b>,
                                accessor: 'missedOpportunity',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { textAlign: "right" },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.missedOpportunity)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>PO Count</b>,
                                accessor: "poCount",
                                sortable: true,
                                resizable: true,
                                style: { textAlign: "right"  },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Feedback</b>,
                                accessor: 'feedback',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                Cell: props => <i className="fa fa-comment-alt"
                                    onClick={() => {
                                        this.setState({
                                            show: true,
                                            selectedUnapprovedSupplier: props.original
                                        })
                                    }} style={{ color: '#0D47A1', cursor: 'pointer' }}>
                                </i>,
                                style: { textAlign: "center" }
                            }
                        ]}
                        defaultSorted={[{ id: "missedOpportunity", desc: true }]}
                        defaultPageSize={25}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                return {
                                    onClick: e => {
                                        this.props.selectedRowValuesInPuchasesOutsideImarkReference(rowInfo.index, rowInfo.original && rowInfo.original.vendorName);
                                        this.props.handleVendorName(rowInfo.original && rowInfo.original.vendorName);
                                    },
                                    style: {
                                        background: rowInfo.index === this.props.selectedTableRowIndexInPurchasesOutsideImark ? "lightblue" : null
                                    }
                                };
                            } else {
                                return {};
                            }
                        }}
                    />
                </div>
            </Card>
        );
    }
}