export const radioItems = [
    {
        id: 1,
        type: 'radio',
        label: 'In IMARK Supplier'
    },
    {
        id: 2,
        type: 'radio',
        label: 'Buysell Rep Supplier'
    },
    {
        id: 3,
        type: 'radio',
        label: 'Map to existing Unapproved Supplier'
    },
    {
        id: 4,
        type: 'radio',
        label: 'Non Competitive IMARK Supplier'
    },
    {
        id: 5,
        type: 'radio',
        label: 'Non Electrical Supplier'
    },
    {
        id: 6,
        type: 'radio',
        label: 'Purchase from Member Company'
    },
    {
        id: 7,
        type: 'radio',
        label: 'Purchase from Own Branch Company'
    }
]

export const radioItemsInCorporate = [
    {
        id: "IN",
        type: 'radio',
        label: 'In IMARK Supplier'
    },
    {
        id: "BUYSELL",
        type: 'radio',
        label: 'Buysell Rep Supplier'
    },
    {
        id: "PARENT",
        type: 'radio',
        label: 'Map to Existing Out Supplier'
    },
    {
        id: "EXCLUDE",
        type: 'radio',
        label: 'No Competitive IMARK Supplier'
    },
    {
        id: "NP",
        type: 'radio',
        label: 'Non Electrical Supplier'
    },
    {
        id: "MEMBER_COMPANY",
        type: 'radio',
        label: 'Purchase from Member Company'
    },
    {
        id: "BRANCH_COMPANY",
        type: 'radio',
        label: 'Purchase from Own Branch Company'
    }
]
