import React from 'react';
import Noty from 'noty';
import Toggle from 'react-toggle';
import ReactTable from "react-table-6";
import { Button, Modal, Form, Col } from 'react-bootstrap';
import { priceFormatter } from '../../../../utils/formatters';
import { saveProductFeedback } from '../../../../api/member-api';
import { Card, ProgressBar, InputGroup, FormControl } from 'react-bootstrap';

import '../../../../assets/styles/ProductListByCategory.scss';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import Select from 'react-select';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';


export default class ProductListByCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSubmit: false,
            cheeseIsReady: false,
            selectedProduct: {},
            productId: null,
            productDesc: null,
            categoryId: this.props.suggestedCategoryId,
            categoryName: this.props.suggestedCategoryName,
            productNotSold: false,
            productCategoryWrong: false,
            suggestedCategoryId: null,
            suggestedCategoryName: null,
            suggestedVendorId: null,
            suggestedVendorName: null,
            feedbackComments: null,
            // productListByCategory: [],
            feedbackToggleForWrongVendor: false,
            searchKey: '',
            filteredSearchResults: [],
            isFilteredDataAvailable: false

        }
    }

    handleClose = () => this.setState({ show: false, productCategoryWrong: false, cheeseIsReady: false,  showSubmit: false})
    handleShow = () => this.setState({ show: true })

    searchTable = (e, data) => {
        
        this.setState({ searchKey: e.target.value });

        if (e.target.value == '') {
            this.setState({
                isFilteredDataAvailable: false,
            });
            return
        }
        let results = data.filter((item) => {
            return item.productName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
        
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
    });


    handleProductCategoryWrong = () => {
        if (this.state.productCategoryWrong) {
            this.setState({ productCategoryWrong: false, showSubmit: true })
        } else {
            this.setState({ productCategoryWrong: true, showSubmit: false })
        }
    }

    handleCheeseChange = () => {
        this.setState({ productNotSold: !this.state.productNotSold ,showSubmit: true})
    }

    handleCheeseChange2 = () => {
        this.setState({
            cheeseIsReady: !this.state.cheeseIsReady,
            feedbackToggleForWrongVendor: !this.state.feedbackToggleForWrongVendor,
            showSubmit: this.state.cheeseIsReady
        })
    }
    handleSelectedProductCategory1 = (value) => {
        
        let arr = [];

        let productCategoryId = '';
        let productCategoryName = '';

        if (value) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let productCategoryId = arr[0].split(',')[0];
          let productCategoryName = arr[0].split(',')[1];
  
        }
        else {
            let productCategoryName = _findIndex(value,"Select");
            let productCategoryId = '';
          }
        
          this.setState({
            suggestedCategoryId: productCategoryId,
            suggestedCategoryName: productCategoryName,
            showSubmit: true
        })
        
    
      };
    

    handleSelectedProductCategory = (e) => {
        
        console.log(e.value);
        const firstindex = e.value.indexOf(",");
        if (e.value) {
            let productCategoryId = e.value.substring(0,firstindex);
            let productCategoryName = e.value.substring(firstindex + 1);

            this.setState({
                suggestedCategoryId: productCategoryId,
                suggestedCategoryName: productCategoryName,
                showSubmit: true
            })
        }
       
        else {
            let productCategoryName = _findIndex(e,"Select Supplier");
            let productCategoryId = '';
            this.setState({
                suggestedCategoryId: productCategoryId,
                suggestedCategoryName: productCategoryName,
                showSubmit: false
            })
        }
        
    }

    handleSelectedSupplier1 = (value) => {
        let arr = [];

        let vendorId = '';
        let vendorName = '';

        if (value ) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let vendorId = arr[0].split(',')[0];
          let vendorName = arr[0].split(',')[1];
  
        }
        else {
            let vendorName = _findIndex(value,"Select Supplier");
            let vendorId = '';
          }

        console.log(arr)
        
        this.setState({
            suggestedVendorId: vendorId,
            suggestedVendorName: vendorName,
            showSubmit: true
        })
    }

    handleSelectedSupplier = (e) => {
        // console.log(e.target.value.split(',')[0]);
        // console.log(e.target.value.split(',')[1]);

        if (e.value) {
            let vendorId = e.value.split(',')[0];
            let vendorName = e.value.split(',')[1];

            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: true
            })
        }
        else {
            let vendorName = _findIndex(e,"Select Supplier");
            let vendorId = '';
            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: false
            })
          }
    }

    handleFeedbackComments = (e) => this.setState({ feedbackComments: e.target.value });

    onProductCategoryFeedbackSubmit = () => {
        
        let formData = {
            productId: this.state.productId,
            productDesc: this.state.productDesc,
            categoryId: this.state.categoryId,
            categoryName: this.state.categoryName,
            productNotSold: this.state.productNotSold ? true : null,
            productCategoryWrong: this.state.productCategoryWrong ? true : null,
            suggestedCategoryId: this.state.productCategoryWrong ? this.state.suggestedCategoryId : null,
            suggestedCategoryName: this.state.suggestedCategoryName,
            suggestedVendorId: this.state.suggestedVendorId,
            suggestedVendorName: this.state.suggestedVendorName,
            feedbackToggleForWrongVendor: this.state.feedbackToggleForWrongVendor ? true : null,
            classificationFlag: "IN",
            vendorDisplayName: this.state.selectedProduct.outVendorName
        }

        saveProductFeedback(formData)
            .then((responseData) => {
                this.handleClose();
                this.setState({
                    productNotSold: false,
                    productCategoryWrong: false,
                    feedbackToggleForWrongVendor: false,
                    suggestedVendorName: null,
                    suggestedVendorId: null
                })
                new Noty({
                    text: responseData.text,
                    layout: "topRight",
                    theme: "mint",
                    type: "success"
                }).show();

            })
            
    }

    productListByCategoryFeedbackModal = (terminatedVPNamesForMember, productCategoryForMember) => {
        let { selectedProduct } = this.state;

        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()} style={{ marginTop: 10 }}>
                <Modal.Header closeButton>
                    Product Feedback for {selectedProduct.productName}
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Is <b>{selectedProduct.productName}</b> wrongly classified as <b>{this.props.suggestedCategoryName}?</b></p>
                        <Toggle
                            id='cheese-status1'
                            icons={false}
                            defaultChecked={this.state.productCategoryWrong}
                            onChange={() => this.handleProductCategoryWrong()}
                        />
                    </div>
                    {this.state.productCategoryWrong && <Form.Group as={Col} controlId="formSelectCategory1">
                    <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleSelectedProductCategory(e)}
                            placeholder={'Select Category'}
                            
                            options={Array.from(
                                productCategoryForMember && productCategoryForMember.map((item, index) => {
                                return {
                                  value: item.productCategoryId + ','+ item.productCategory,
                                  label: item.productCategory,
                                };
                                
                              })
                            )}
                          />
                        {/* <Form.Control as="select" defaultValue="Select Category" onChange={(e) => this.handleSelectedProductCategory(e)}>
                            <option>Select new Product Category</option>
                            {productCategoryForMember && productCategoryForMember.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.productCategoryId},${item.productCategory}`}>{item.productCategory}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Is supplier <b>{selectedProduct.outVendorName}</b> wrongly classified as Non-Preferred Supplier?</p>
                        <Toggle
                            id='cheese-status2'
                            icons={false}
                            defaultChecked={this.state.cheeseIsReady}
                            onChange={() => this.handleCheeseChange2()}
                        />
                    </div>
                    {this.state.cheeseIsReady && <Form.Group as={Col} controlId="formSelectCategory2">
                    <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleSelectedSupplier(e)}
                            placeholder={'Select Supplier'}
                            
                            options={Array.from(
                                terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                return {
                                  value: item.vendorId + ','+ item.vendorName,
                                  label: item.vendorName,
                                };
                                
                              })
                            )}
                          />
                        {/* <Form.Control as="select" defaultValue="Select Category" onChange={(e) => this.handleSelectedSupplier(e)}>
                            <option>Select Supplier</option>
                            {terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.vendorId},${item.vendorName}`}>{item.vendorName}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p><b>{selectedProduct.productName}</b> is not sold by any supplier within IMARK?</p>
                        <Toggle
                            id='cheese-status4'
                            icons={false}
                            defaultChecked={this.state.cheeseIsReady}
                            onChange={this.handleCheeseChange}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea onChange={(e) => this.handleFeedbackComments(e)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit} onClick={() => this.onProductCategoryFeedbackSubmit()}>
                        Submit Feedback
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        // let { productListByCategory } = this.state;
        let { productListByCategory, terminatedVPNamesForMember, productCategoryForMember } = this.props;

        return (
            <div className="productListByCategoryContainer">
                {this.productListByCategoryFeedbackModal(terminatedVPNamesForMember, productCategoryForMember)}
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search.."
                                aria-label="Search.."
                                aria-describedby="basic-addon2"
                                value={searchKey}
                                onChange={(e) => this.searchTable(e, productListByCategory)}
                            />
                            <InputGroup.Append>
                                <Button onClick={(e) => this.clearTable(e)}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>

                    <ReactTable
                        data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.productListByCategory}

                        // data={productListByCategory}
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Product Description</b>,
                                accessor: "productName",
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' }
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Non-preferred Supplier</b>,
                                accessor: 'outVendorName',
                                sortable: true,
                                resizable: true,
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Outside Purchases</b>,
                                accessor: 'outsidePurchase',
                                sortable: true,
                                resizable: true,
                                style: { 'textAlign': 'right' },
                                Cell: props => priceFormatter(props.original.outsidePurchase),
                            },
                            {
                                Header: <b>Feedback</b>,
                                accessor: 'feedback',
                                sortable: false,
                                resizable: false,
                                Cell: props => <i className="fa fa-comment-alt" onClick={() => this.setState({
                                    show: true,
                                    productId: props.original.productId,
                                    productDesc: props.original.productName,
                                    selectedProduct: props.original
                                })} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>,
                                style: { textAlign: "center" }
                            }
                        ]}
                        defaultPageSize={20}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                    />
                </div>
            </div>
        );
    }
}
