import React, { Component } from 'react';
import C3 from '../../C3/C3';
import { priceFormatter } from '../../../../utils/formatters';
import _isEqual from 'lodash/isEqual';
import { Card } from 'react-bootstrap';


export default class TopTenMembers extends Component {
    render() {
        const { topTenMembers } = this.props;
        let jsonArrPG = [];

        let allPurchases = [];
        let allMemberIds = [];
        let allMemberNames = [];

        if (topTenMembers) {
            allPurchases = topTenMembers && topTenMembers.map((item) => item.purchases);
            allMemberIds = topTenMembers && topTenMembers.map((item) => item.memberId);
            allMemberNames = topTenMembers && topTenMembers.map((item) => item.memberName);
            jsonArrPG.push(topTenMembers);

        }
        else {
            allPurchases.push([]);;
            allMemberIds.push([]);;
            allMemberNames.push([]);;
            jsonArrPG.push([]);

        }

        jsonArrPG.shift();


        if (!_isEqual(jsonArrPG, this.props.topTenMembers)) {

            const topTenMembersChartData = {
                data: {
                    x: 'x',
                    columns: [
                        ['Purchases', ...allPurchases],
                        ['x', ...allMemberIds]
                    ],
                    colors: {
                        "Purchases": '#1f77b4'
                    },
                    type: 'bar',
                    bar: {
                        width: {
                            ratio: 0.2
                        },
                    },
                    legend: {
                        show: true
                    },
                },
                axis: {
                    x: {
                        label: { text: 'Member Ids', position: 'inner-right' },
                        type: 'category',
                        height: 80
                    },
                    y: {
                        label: { text: 'Purchase Amount', position: 'outer-middle' },
                        tick: {
                            rotate: -20,
                            multiline: false,
                            format: function (d) { return ''; }
                        }
                    }
                },
                tooltip: {
                    show: false,
                    format: {
                        title: (d) => allMemberNames && allMemberNames[d],
                        value: (value, ratio, id) => priceFormatter(value).props.children
                    }
                },
            };

            return (
                <C3
                    heading={"Top 10 Members"}
                    data={topTenMembersChartData.data}
                    tooltip={topTenMembersChartData.tooltip}
                    axis={topTenMembersChartData.axis}
                />
            )
        }
        else {
            return   <div className="">
            <Card>
                <Card.Header>Top 10 Members</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10,height:'400px' }}>
                    <div style={{ borderTop: "2px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>                    </div>    
                </div>
            </Card>
        </div>
        

        }
    }
}
