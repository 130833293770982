import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { dateFormatter } from '../../../utils/formatters';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import ReactTable from "react-table-6";
import ReactExport from "react-export-excel";

import {
    getCorpComplianceMember,
    getCorpComplianceVendor,
    getCorpComplianceFilters
} from '../../../api/corporate-api';
import { updateSelectedNavIndex } from '../../../actions';

class CorporateCompliance extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: "YTD",
            quarter: "4",
            source: 'verified',
            isCompliant: 'A',
            buyingGroupId: "1",
            corporateComplianceFilters: [],
            corporateComplianceMember: [],
            corporateComplianceVendor: [],
            searchKeyMember: "",
            searchKeyVendor: "",
            isMemberFilteredDataAvailable: false,
            isVendorFilteredDataAvailable: false,
            filteredMembers: [],
            filteredVendors: []
        }
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);

        this.loadData();
    }

    componentDidUpdate(pP, pS, sS) {
        if (pS.year !== this.state.year) {
            this.getComplianceData();
        }
    }

    loadData = () => {
        Promise.allSettled([getCorpComplianceFilters()])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    this.setState({
                        corporateComplianceFilters: allResponses[0].value && Object.values(allResponses[0].value),
                        // year: allResponses[0].value && Object.values(allResponses[0].value)[0]
                    }, () => {
                        this.getComplianceData();
                    })
                }
            })
            .catch(err => console.log(err));
    }

    getComplianceData = () => {
        const formData = {
            year: this.state.year,
            month: this.state.month,
            source: this.state.source,
            period: this.state.period,
            quarter: this.state.quarter,
            isCompliant: this.state.isCompliant,
            buyingGroupId: this.state.buyingGroupId
        }

        Promise.allSettled([
            getCorpComplianceMember(formData),
            getCorpComplianceVendor(formData)
        ])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    this.setState({
                        corporateComplianceMember: allResponses[0].value ? allResponses[0].value : [],
                        filteredMembers: allResponses[0].value ? allResponses[0].value : [],
                        isMemberFilteredDataAvailable: true
                    })
                }
                if (allResponses[1].value) {
                    this.setState({
                        corporateComplianceVendor: allResponses[1].value ? allResponses[1].value : [],
                        filteredVendors: allResponses[1].value ? allResponses[1].value : [],
                        isVendorFilteredDataAvailable: true
                    })
                }
            })
            .catch(err => console.log(err));
    }

    renderMemberDetails() {
        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Member Details</span>
                        </div>
                        <div className="card-body">
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for member details info.."
                                        aria-label="Search for member details info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyMember}
                                        onChange={(e) => this.handleSearchForMember(e, this.state.corporateComplianceMember)}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForMember()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isMemberFilteredDataAvailable ? this.state.filteredMembers : []}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Id</b>,
                                        accessor: "groupId",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                    },  
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Name</b>,
                                        accessor: "name",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Company</b>,
                                        accessor: 'groupName',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Compliance Status</b>,
                                        accessor: 'complianceStatus',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Last PAT Transaction File Upload</b>,
                                        accessor: 'lastTransactionUpload',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props =><div style={{textAlign:'center'}}> {dateFormatter(props.original.lastTransactionUpload)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Email ID</b>,
                                        accessor: 'email',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>First Transaction Date</b>,
                                        accessor: 'dataMinDate',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}>{dateFormatter(props.original.dataMinDate)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Last Transaction Date</b>,
                                        accessor: 'dataMaxDate',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}>{ dateFormatter(props.original.dataMaxDate)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>No. of times Dashboard Login</b>,
                                        accessor: 'loginCount',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderVendorDetails() {
        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Supplier Details</span>
                        </div>
                        <div className="card-body">
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for supplier details info.."
                                        aria-label="Search for supplier details info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyVendor}
                                        onChange={(e) => this.handleSearchForVendor(e, this.state.corporateComplianceVendor)}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForVendor()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isVendorFilteredDataAvailable ? this.state.filteredVendors : []}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Vendor Id</b>,
                                        accessor: "groupId",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },  
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Name</b>,
                                        accessor: "name",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Company</b>,
                                        accessor: 'groupName',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Compliance Status</b>,
                                        accessor: 'complianceStatus',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Last PAT Transaction File Upload</b>,
                                        accessor: 'lastTransactionUpload',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}>{dateFormatter(props.original.lastTransactionUpload)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Email ID</b>,
                                        accessor: 'email',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset'},
                                        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>First Transaction Date</b>,
                                        accessor: 'dataMinDate',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}>{dateFormatter(props.original.dataMinDate)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Last Transaction Date</b>,
                                        accessor: 'dataMaxDate',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}>{dateFormatter(props.original.dataMaxDate)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Last Product Catalog Upload</b>,
                                        accessor: 'lastCatalogUpload',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}>{dateFormatter(props.original.lastCatalogUpload)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>No. of times Dashboard Login</b>,
                                        accessor: 'loginCount',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleSearchForMember = (e, data) => {
        this.setState({ searchKeyMember: e.target.value });
        let results = data.filter((item) => {
            return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 ||
                item.groupName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isMemberFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredMembers: results
        });
    }

    handleSearchForVendor = (e, data) => {
        this.setState({ searchKeyVendor: e.target.value });
        let results = data.filter((item) => {
            return item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 ||
                item.groupName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })
        this.setState({
            isVendorFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredVendors: results
        });
    }

    clearButtonForMember = () => {
        this.setState({
            searchKeyMember: "",
            filteredMembers: [...this.state.corporateComplianceMember]
        });

        this.getComplianceData()
    }

    clearButtonForVendor = () => {
        this.setState({
            searchKeyVendor: "",
            filteredVendors: [...this.state.corporateComplianceVendor]
        });

        this.getComplianceData();
    }

    handleYear = (e) => {
        this.setState({
            year: e.target.value,
            searchKeyVendor: "",
            searchKeyMember: "",
            filteredMembers: [],
            filteredVendors: []
        });
    }

    render() {
        const {
            corporateComplianceFilters,
            corporateComplianceMember,
            corporateComplianceVendor
        } = this.state;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <div>
                <div className="filtersContainer">
                    <div className="filterTitle">
                        <p>Compliance</p>
                    </div>
                    <div className="filterItems">
                        <Form.Group as={Col} id="group3">
                            <Form.Control
                                as="select"
                                defaultValue={this.state.corporateComplianceFilters[this.state.corporateComplianceFilters.length - 1]}
                                id="select1"
                                onChange={(e) => this.handleYear(e)}
                            >
                                {corporateComplianceFilters && corporateComplianceFilters.map((year, index) => {
                                    return (
                                        <option key={index} selected={this.state.year == year} value={year}>{year}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <ExcelFile filename="COMPLIANCE DATA" element={<Button className="export" color="primary" style={{ fontSize: 12 }}>Export</Button>}>
                            <ExcelSheet data={corporateComplianceMember} name="MEMBER DETAILS">
                            <ExcelColumn label="MEMBER ID" value="groupId" />
                                <ExcelColumn label="NAME" value="name" />
                                <ExcelColumn label="COMPANY NAME" value="groupName" />
                                <ExcelColumn label="COMPLIANCE NAME" value="complianceStatus" />
                                <ExcelColumn label="lAST TRANSACTION FILE UPLOAD" value="lastTransactionUpload" />
                                <ExcelColumn label="FIRST TRANSACTION DATE" value="dataMinDate" />
                                <ExcelColumn label="LAST TRANSACTION DATE" value="dataMaxDate" />
                                <ExcelColumn label="NO. OF TIME DASHBOARD LOGIN" value="loginCount" />
                                <ExcelColumn label="EMAIL ID" value="email" />
                            </ExcelSheet>
                            <ExcelSheet data={corporateComplianceVendor} name=" VENDOR DETAILS">
                            <ExcelColumn label="VENDOR ID" value="groupId" />
                                <ExcelColumn label="NAME" value="name" />
                                <ExcelColumn label="COMPANY NAME" value="groupName" />
                                <ExcelColumn label="COMPLIANCE NAME" value="complianceStatus" />
                                <ExcelColumn label="lAST TRANSACTION FILE UPLOAD" value="lastTransactionUpload" />
                                <ExcelColumn label="FIRST TRANSACTION DATE" value="dataMinDate" />
                                <ExcelColumn label="LAST TRANSACTION DATE" value="dataMaxDate" />
                                <ExcelColumn label="NO. OF TIME DASHBOARD LOGIN" value="loginCount" />
                                <ExcelColumn label="EMAIL ID" value="email" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>

                <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                    <div className="row">
                        <div className="col-12">
                            {this.renderMemberDetails()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ width: "700px", paddingBottom: "10px" }} >
                            {this.renderVendorDetails()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        corporateComplianceMember: state.corporate.corporateComplianceMember,
        corporateComplianceVendor: state.corporate.corporateComplianceVendor,
        corporateComplianceFilters: state.corporate.corporateComplianceFilters
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateCompliance);
