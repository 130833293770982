import React from 'react';
import { Form, Col, ButtonGroup, Button } from 'react-bootstrap';
import { switchMonths } from '../../../../utils/formatters';
import ReactExport from "react-export-excel";

import '../../../../assets/styles/Filters.scss';

export default class OverviewFilters extends React.Component {
    render() {
        const {
            period,
            handleYearReference,
            handleMonthReference,
            handlePeriodReference,
            handleBranchReference,
            handleQuarterReference,
            listOfYears,
            listOfMonthsForSelectedYear,
            filterMemberIds,
            purchaseWithinImark,
            purchaseOutsideImark,
            outsidePurchasesByProductGroup,
            isQuarterSelected
        } = this.props;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <div className="filtersContainer">
                <div className="filterTitle">
                    <p>{this.props.memberName}</p>
                </div>
                <div className="filterItems">
                    <Form.Group as={Col} id="group2">
                        <Form.Control
                            as="select"
                            defaultValue={filterMemberIds.length >= 1 && filterMemberIds[0]}
                            id="select1"
                            style={{ minWidth: 250 }}
                            onChange={(e) => handleBranchReference(e)}
                        >
                            {filterMemberIds && filterMemberIds.map((item, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={`${item.branchId}`}
                                    >
                                        {item.branchName}{item.branchName === "All" ? null : `-${item.branchId}`}
                                    </option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                    <ButtonGroup className="yearGroup">
                        <Button
                            className={`btn ${period === "MTH" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="MTH"
                        >
                            MTH
                        </Button>
                        <Button
                            className={`btn ${period === "YTD" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="YTD"
                        >
                            YTD
                        </Button>
                        <Button
                            className={`btn ${period === "QTR" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="QTR"
                        >
                            QTR
                        </Button>
                    </ButtonGroup>
                    <Form.Group as={Col} id="group3">
                        <Form.Control
                            as="select"
                            // defaultValue={2020}
                            id="select2"
                            onChange={(e) => handleYearReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            {listOfYears.map((year) => {
                                return <option key={year} selected={year==this.props.year?true:false} value={year}>{year}</option>
                            })}
                        </Form.Control>

                    </Form.Group>
                    {!isQuarterSelected && <Form.Group as={Col} id="group4">
                        <Form.Control
                            as="select"
                            // defaultValue={listOfMonthsForSelectedYear[listOfMonthsForSelectedYear.length - 1]}
                            id="select3"
                            onChange={(e) => handleMonthReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            {listOfMonthsForSelectedYear.map((month) => {
                                return <option key={month} selected={month==this.props.month?true:false} value={`${month}`}>{switchMonths(month)}</option>
                            })}
                        </Form.Control>
                    </Form.Group>}
                    {isQuarterSelected && <Form.Group as={Col} id="group4">
                        <Form.Control
                            as="select"
                            defaultValue={"1"}
                            id="select4"
                            onChange={(e) => handleQuarterReference(e)}
                            style={{ minWidth: 100 }}
                        >
                                <option value="1">QTR1</option>
                                <option value="2">QTR2</option>
                                <option value="3">QTR3</option>
                                <option value="4">QTR4</option>
                            </Form.Control>
                        </Form.Group>
                    }
                    <ExcelFile element={<Button className="export" color="primary" style={{ fontSize: 12 }}>Export</Button>}>
                        <ExcelSheet data={purchaseWithinImark} name="Purchase Within Imark">
                            <ExcelColumn label="VENDOR NAME" value="vendorName" />
                            <ExcelColumn label="MEMBER PURCHASE" value="memberPurchase" />
                            <ExcelColumn label="POTENTIAL PURCHASE" value="potentialPurchase" />
                        </ExcelSheet>
                        <ExcelSheet data={purchaseOutsideImark} name="Purchase Outside Imark">
                            <ExcelColumn label="OUT VENDOR" value="vendorName" />
                            <ExcelColumn label="PURCHASE" value="missedOpportunity" />
                            <ExcelColumn label="OPPORTUNITY" value="rebateOpportunity" />
                        </ExcelSheet>
                        <ExcelSheet data={outsidePurchasesByProductGroup} name="Outside Purchase By Product Group">
                            <ExcelColumn label="PRODUCT GROUP" value="tierFour" />
                            <ExcelColumn label="PURCHASE" value="missedOpportunity" />
                            <ExcelColumn label="FAVOURABLE VENDOR" value="favorableVendorName" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </div>
        )
    }
}
