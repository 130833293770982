import React, { Component } from 'react'
import C3Chart from 'react-c3js';
import { Card } from 'react-bootstrap';

import 'c3/c3.css';
import '../../assets/styles/C3.scss';

export default class C3 extends Component {
    render() {
        const { heading, data, axis } = this.props;
        
        return (
            <div>
                <Card>
                    <Card.Header>{heading}</Card.Header>
                    <C3Chart data={data} axis={axis} />
                </Card>
            </div>
        )
    }
}
