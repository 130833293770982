import React from "react";
import ReactTable from "react-table-6";
import { Card, ProgressBar, InputGroup, FormControl, Button } from 'react-bootstrap';

import '../../../../assets/styles/SalesOpportunityByMember.scss';

export default class SalesOpportunityByMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: "",
            filteredSearchResults: [],
            isFilteredDataAvailable: false
        }
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.memberName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        const {
            salesOpportunityByMember,
            handleResetTableDataReference
        } = this.props;

        let opportunities = salesOpportunityByMember && salesOpportunityByMember.map((item) => item.missedOpportunity);
        let maxOpportunity = Math.max(...opportunities);

        return (
            <div className="salesOpportunityByMemberContainer">
                <Card>
                    <Card.Header>Sales Opportunity By Member</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for member info.."
                                    aria-label="Search for member info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, salesOpportunityByMember)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => {
                                        this.clearTable();
                                        handleResetTableDataReference();
                                    }}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.salesOpportunityByMember}
                            columns={[
                                {
                                    Header: <b>Member</b>,
                                    accessor: "memberName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props =><div style={{textAlign:'center'}}><span>{props.original.memberName}</span></div>
                                }, {
                                    Header: <b>Opportunity</b>,
                                    accessor: "missedOpportunity",
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => <ProgressBar
                                        now={((props.original.missedOpportunity * 100) / maxOpportunity)}
                                    />
                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: "missedOpportunity",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                </Card>
            </div>
        );
    }
}
