import React, { Component } from 'react';
import ReactTable from "react-table-6";
import { Card, InputGroup, FormControl, Button } from "react-bootstrap";
import { priceFormatter } from '../../../../utils/formatters';

import '../../../../assets/styles/OverviewTable.scss';

export default class SalesRebateOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            searchKey: "",
            filteredSearchResults: [],
            isFilteredDataAvailable: false
        }
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.memberName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
        filteredSearchResults: []
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;

        return (
            <div className="overviewTableContainer">
                <Card>
                    <Card.Header>Sales Overview</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for Sales info.."
                                    aria-label="Search for Sales info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, this.props.salesRebateOverview)}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => this.clearTable()}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? filteredSearchResults: this.props.salesRebateOverview}
                            // filterable
                            columns={[
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                    accessor: "memberName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Remits Date</b>,
                                    accessor: "imarkLastBilledDate",
                                    sortable: true,
                                    filterable: false,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset','textAlign': 'center' },
                                    Cell: props => <div style={{textAlign:'center'}}>{(props.original.imarkLastBilledDate ? new Date(props.original.imarkLastBilledDate).toLocaleDateString('en-US'): '-')}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Date</b>,
                                    accessor: "vendorLastBilledDate",
                                    filterable: false,
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset', 'textAlign': 'center' },
                                    Cell: props => <div style={{textAlign:'center'}}> {(props.original.vendorLastBilledDate ? new Date(props.original.vendorLastBilledDate).toLocaleDateString('en-US'): '-')}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}> IMARK Remits (SAP)</b>,
                                    accessor: "verifiedSales",
                                    filterable: false,
                                    sortable: true,
                                    resizable: true,
                                    style: {  'whiteSpace': 'unset', 'textAlign': 'center' },
                                    Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.verifiedSales)}</div>
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Supplier Invoices</b>,
                                    accessor: "vendorSales",
                                    filterable: false,
                                    sortable: true,
                                    resizable: true,
                                    style: { 'textAlign': 'center' },
                                    Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.vendorSales)}</div>
                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: "verifiedSales",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                </Card>
            </div>
        );
    }
}
