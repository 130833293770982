import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Col, ButtonGroup, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { priceFormatter, switchMonths } from '../../../utils/formatters';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import ReactTable from "react-table-6";
import ReactExport from "react-export-excel";
import Toggle from 'react-toggle';
import TopTenMembers from './TopTenMembers';
import TopTenProductGroups from './TopTenProductGroups';
import ProductDetails from './ProductDetails';
import {
    getCorpConversionOppMember,
    getCorpConversionOppProductGroup,
    getCorpConversionOppPODetails,
    getCorpConversionOppConversionDetails,
    getCorpConversionOppVendorList,
    getCorpConversionFilters,
    getCorpConversionProductCategoryList,
    getCorpConversionProducts,
    getCorpConversionFavVendorList,
    getCorpConversionSaveProductsFeedback,
    getCorpConversionSaveFavSupplierFeedback,
    getCorpConversionOppTopTenMember,
    getCorpConversionOppTopTenProductGroup
} from '../../../api/corporate-api';
import { updateSelectedNavIndex } from '../../../actions';

import "react-toggle/style.css";
import Select from 'react-select';
import Noty from 'noty';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';



class CorporateConversion extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
         this.state = {
            show: false,
            showSubmit:false,
            cheeseIsReady: false,
            isQuarterSelected: false,
            showProductDetailsModal: false,
            productGroup: "",
            selectedFavorbaleSupplier: "",
            selectedTargetedSupplier: {},
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: "YTD",
            source: 'verified',
            quarter: "4",
            isCompliant: "A",
            buyingGroupId: "0",
            productGroupId: "",
            terminatedVendorId: "",
            params: {
                year: "2021",
                month: currentDate.getMonth() + 1,
                period: "YTD",
                source: 'verified',
                quarter: "4",
                isCompliant: "A",
                buyingGroupId: "0",
                productGroupId: "",
                terminatedVendorId: ""
            },
            conversionMonthsFilter: {},
            listOfYears: [],
            listOfMonthsForSelectedYear: [],
            getCorporateConversionOpportunityVendorList: [{vendorId: 0, vendorName: 'All'}],
            getCorporateConversionMember: [],
            getCorporateConversionProductGroup: [],
            getCorporateConversionPODetails: [],
            getCorporateConversionOpportunityOverview: [],
            getCorporateConversionTopTenMember: [],
            getCorporateConversionTopTenProductGroup: [],
            searchKeyMember: "",
            searchKeyProductGroup: "",
            searchKeyPODetails: "",
            searchKeyConversion: "",
            isMemberFilteredDataAvailable: false,
            isProductGroupFilteredDataAvailable: false,
            isPODetailsFilteredDataAvailable: false,
            isConversionFilteredDataAvailable: false,
            filteredSearchMember: [],
            filteredSearchProductGroup: [],
            filteredSearchPODetails: [],
            filteredSearchConversion: [],
            ErrorMessage:'',
        }
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);

        this.loadData()
    }

    componentDidUpdate(pP, pS, sS) {
        if (pS.year !== this.state.year && pS.params.year !== this.state.params.year) {
            this.getConversionOpportunityData();
        }

        if (pS.month !== this.state.month && pS.params.month !== this.state.params.month) {
            this.getConversionOpportunityData();
        }

        if (pS.period !== this.state.period && pS.params.period !== this.state.params.period) {
            this.getConversionOpportunityData();
        }

        if (pS.quarter !== this.state.quarter && pS.params.quarter !== this.state.params.quarter) {
            this.getConversionOpportunityData();
        }

        if (pS.buyingGroupId !== this.state.buyingGroupId && pS.params.buyingGroupId !== this.state.params.buyingGroupId) {
            this.getConversionOpportunityData();
        }

        if (pS.terminatedVendorId !== this.state.terminatedVendorId && pS.params.terminatedVendorId !== this.state.params.terminatedVendorId) {
            this.getConversionOpportunityData();
        }
    }

    loadData = () => {
        console.log(this.state.year);
        Promise.allSettled([
            getCorpConversionFilters(),
            getCorpConversionOppVendorList()
        ])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    const availableYears = Object.keys(allResponses[0].value).sort((a, b) => b - a);

                    this.setState((prevState) => ({
                        conversionMonthsFilter: allResponses[0].value ? allResponses[0].value : {},
                        listOfYears: allResponses[0].value && Object.keys(allResponses[0].value),
                        // year: allResponses[0].value && Object.keys(allResponses[0].value)[0],
                        params: {
                            ...prevState.params,
                            // year: allResponses[0].value && Object.keys(allResponses[0].value)[0]
                        }
                    }), () => {
                   // this.getListOfMonths(allResponses[0].value, availableYears[0]);
                   this.getListOfMonths(allResponses[0].value, availableYears[0]);

                    });
                }

                if (allResponses[1].value) {
                    let corporateConversionOpportunityVendorList = this.state.getCorporateConversionOpportunityVendorList.concat(allResponses[1].value)
                    this.setState({ getCorporateConversionOpportunityVendorList: corporateConversionOpportunityVendorList })
                }
            })
            .catch((err) => console.log(err));
    }
    


    getConversionOpportunityData = () => {
        const formData = {
            year: this.state.year,
            month: this.state.month,
            source: this.state.source,
            period: this.state.period,
            quarter: this.state.quarter,
            isCompliant: this.state.isCompliant,
            buyingGroupId: this.state.buyingGroupId,
            terminatedVendorId: this.state.terminatedVendorId
        }

        Promise.allSettled([
            getCorpConversionOppMember(formData),
            getCorpConversionOppProductGroup(formData),
            getCorpConversionOppPODetails(formData),
            getCorpConversionOppConversionDetails(formData),
            getCorpConversionOppTopTenMember(formData),
            getCorpConversionOppTopTenProductGroup(formData)
        ])
            .then((allResponses) => {
                if (allResponses[0].value) {
                    this.setState({
                        getCorporateConversionMember: allResponses[0].value ? allResponses[0].value : [],
                        filteredSearchMember: allResponses[0].value ? allResponses[0].value : [],
                        isMemberFilteredDataAvailable: true
                    })
                }
                if (allResponses[1].value) {
                    this.setState({
                        getCorporateConversionProductGroup: allResponses[1].value ? allResponses[1].value : [],
                        filteredSearchProductGroup: allResponses[1].value ? allResponses[1].value : [],
                        isProductGroupFilteredDataAvailable: true
                    })
                }
                if (allResponses[2].value) {
                    this.setState({
                        getCorporateConversionPODetails: allResponses[2].value ? allResponses[2].value : [],
                        filteredSearchPODetails: allResponses[2].value ? allResponses[2].value : [],
                        isPODetailsFilteredDataAvailable: true
                    })
                }
                if (allResponses[3].value) {
                    this.setState({
                        getCorporateConversionOpportunityOverview: allResponses[3].value ? allResponses[3].value : [],
                        filteredSearchConversion: allResponses[3].value ? allResponses[3].value : [],
                        isConversionFilteredDataAvailable: true
                    })
                }
                if (allResponses[4].value) {
                    this.setState({ getCorporateConversionTopTenMember: allResponses[4].value ? allResponses[4].value : [] })
                }
                if (allResponses[5].value) {
                    this.setState({ getCorporateConversionTopTenProductGroup: allResponses[5].value ? allResponses[5].value : [] })
                }
            })
            .catch((err) => console.log(err));
    }

    handleSupplierFeedbackSubmit = () => {
        
        const formData = {
            favorableVendorWrong: true,
            categoryId: this.state.oppProductGroupId,
            categoryName: this.state.oppProductGroup,
            favorableVendorId: this.state.selectedFavorbaleSupplierId,
            favorableVendorName: this.state.selectedFavorbaleSupplier,
            suggestedFavorableVendorId: this.state.suggestedFavorableVendorId,
            suggestedFavorableVendorName: this.state.suggestedFavorableVendorName,
        }
        if (!this.state.suggestedFavorableVendorName) {
            this.setState({ErrorMessage: 'Please select a Supplier' }, () => {});
            } 
        else{
        getCorpConversionSaveFavSupplierFeedback(formData)
            .then((data) =>{
            this.setState({ show: false,ErrorMessage : '',
            suggestedFavorableVendorName:'',cheeseIsReady:''})
            new Noty({
                text: data.text,
                layout: "topRight",
                theme: "mint",
                type: "success"
            }).show();
         })
            .catch((err) => console.log(err))
        }
        
    }

    handleClose = () => this.setState({ show: false,showSubmit:false,ErrorMessage:'', showProductDetailsModal: false, cheeseIsReady: false,suggestedFavorableVendorName:'' })
    handleShow = () => this.setState({ show: true,showSubmit:false })

    handleCheeseChange = () => {
        if (this.state.cheeseIsReady) {
            this.setState({ cheeseIsReady: false })
        } else {
            this.setState({ cheeseIsReady: true })
        }
    }

    handleSelectedSupplier1 = (value) => {
        let arr = [];
        let categoryId = "";
        let categoryName = "";

      
        if (value ) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let categoryId = arr[0].split(',')[0];
          let categoryName = arr[0].split(',')[1];
  
        }
        else {
            let categoryId = _findIndex(value,"Select");
            let categoryName = '';
          }
        
          this.setState({
            suggestedFavorableVendorId: categoryId,
            suggestedFavorableVendorName: categoryName,
            ErrorMessage:'',
        })
    
      };
      handleSelectedSupplier = (e) => {
        if (e.value) {
            let categoryId = e.value.split(',')[0];
            let categoryName = e.value.split(',')[1];

            this.setState({
                suggestedFavorableVendorId: categoryId,
                suggestedFavorableVendorName: categoryName,
                showSubmit: true
            })
        }
        else {
            let categoryName = _findIndex(e,"Select Supplier");
            let categoryId = '';
            this.setState({
                suggestedFavorableVendorId: categoryId,
                suggestedFavorableVendorName: categoryName,
                showSubmit: false
            })
          }
        } 
    
    handleChangeSupplierDropdown = (e) => {
        if (e.target.value) {
            let categoryId = e.target.value.split(',')[0];
            let categoryName = e.target.value.split(',')[1];
            this.setState({
                suggestedFavorableVendorId: categoryId,
                suggestedFavorableVendorName: categoryName,
                ErrorMessage:'',
            })
        }
    }

    handleProductModel = (props) => {
        this.setState({
            showProductDetailsModal: true,
            productGroupId: props.original.productGroupId,
            productGroupName: props.original.productGroupName
        })
        getCorpConversionProductCategoryList().then((data) => {
            this.setState({
                productCategoryList: data,
            })
        })
        const formData = {
            year: this.state.year,
            productGroupId: props.original.productGroupId,
        }
        getCorpConversionProducts(formData).then((data) => {
            this.setState({
                getCorporateConversionProducts: data,
            })
        })
    }

    productDetailsModal = () => {
        let { productGroupName, productCategoryList, getCorporateConversionProducts } = this.state;
        return (
            <Modal show={this.state.showProductDetailsModal} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Product Details for {productGroupName}
                </Modal.Header>
                <Modal.Body>
                    <ProductDetails
                        productGroup={productGroupName}
                        productCategoryList={productCategoryList}
                        productCategoryData={getCorporateConversionProducts}
                        productGroupId={this.state.productGroupId}
                    // handleSaveFeedback={this.handleSaveFeedback()}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary" onClick={() => this.handleClose()}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    favorableSupplierFeedbackModal = () => {
        let { selectedFavorbaleSupplier, selectedTargetedSupplier, getCorporateConversionFavVendorList } = this.state;

        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Favorable Supplier Feedback
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Is <b>{selectedFavorbaleSupplier}</b> Favorable Supplier wrong for <b>{selectedTargetedSupplier.leaving_supplier}</b>?</p>
                        <Toggle
                            id='cheese-status5'
                            icons={false}
                            defaultChecked={this.state.cheeseIsReady}
                            onChange={() => this.handleCheeseChange()}
                        />
                    </div>
                    {this.state.cheeseIsReady && 
                    <Form.Group as={Col} controlId="formSelectVendor">
 <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleSelectedSupplier(e)}
                            placeholder={'Select Supplier'}
                            
                            options={Array.from(
                                getCorporateConversionFavVendorList && getCorporateConversionFavVendorList.map((item, index) => {
                                return {
                                  value: item.vendorId + ','+ item.vendorName,
                                  label: item.vendorName,
                                };
                                
                              })
                            )}
                          />
                        {/* <Form.Control
                            as="select"
                            defaultValue={"Select Supplier"}
                            onChange={(e) => this.handleChangeSupplierDropdown(e)}
                        >
                            <option value="">Select Supplier</option>
                            {getCorporateConversionFavVendorList && getCorporateConversionFavVendorList.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.vendorId},${item.vendorName}`}>{item.vendorName}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea></textarea>
                    </div>
                    <span className="errorText">{this.state.ErrorMessage}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit}  onClick={() => this.handleSupplierFeedbackSubmit()}>
                        Submit
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderOpportunityByMember() {
        return (
            <div className="" >
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Opportunity by Member</span>
                        </div>
                        <div className="card-body" style={{ maxHeight: " 303" }}>
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for opportunity by member info.."
                                        aria-label="Search for opportunity by member info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyMember}
                                        onChange={(e) => this.handleSearchForMember(e, this.state.getCorporateConversionMember)}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForMember()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isMemberFilteredDataAvailable ? this.state.filteredSearchMember : []}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>ID</b>,
                                        accessor: "memberId",
                                        sortable: true,
                                        resizable: true,
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                        accessor: "memberName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Purchase</b>,
                                        accessor: 'purchases',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}} >{priceFormatter(props.original.purchases)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                        accessor: 'leavingVendorName',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "purchases",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderOpportunityByProductGroup() {
        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Opportunity by Product Group</span>
                        </div>
                        <div className="card-body" style={{ maxHeight: " 305" }}>
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for opportunity by product group info.."
                                        aria-label="Search for opportunity by product group info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyProductGroup}
                                        onChange={(e) => this.handleSearchForProductGroup(e, this.state.getCorporateConversionProductGroup)}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForProductGroup()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isProductGroupFilteredDataAvailable ? this.state.filteredSearchProductGroup : []}
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Product Group</b>,
                                        accessor: "productGroupName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Purchase</b>,
                                        accessor: 'purchases',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props =><div style={{textAlign:'center'}}>{priceFormatter(props.original.purchases)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                        accessor: 'leavingVendorName',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Products</b>,
                                        accessor: "",
                                        sortable: true,
                                        resizable: true,
                                        width: 80,
                                        Cell: props => <i className="fas fa-info-circle" onClick={() => this.handleProductModel(props)} style={{ color: '#0D47A1' }}></i>,
                                        style: { textAlign: "center" }
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: "purchases",
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderConversionOpportunityPODetails() {
        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Conversion Opportunity PO Details</span>
                        </div>
                        <div className="card-body">
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for conversion opportunity po details info.."
                                        aria-label="Search for conversion opportunity po details info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyPODetails}
                                        onChange={(e) => this.handleSearchForPODetails(e, this.state.getCorporateConversionPODetails)}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForPODetails()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isPODetailsFilteredDataAvailable ? this.state.filteredSearchPODetails : []}
                                // filterable
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                        accessor: "leavingVendorName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                        accessor: "memberName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>PO Count</b>,
                                        accessor: 'poCount',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Purchases</b>,
                                        accessor: 'purchases',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.purchases)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Avg. Purchase/PO</b>,
                                        accessor: 'avgPurchases',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.avgPurchases)}</div>,
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: 'purchases',
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderConversionOpportunity() {
        return (
            <div className="">
                <div className="card-deck">
                    <div className="card shadow-sm mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <span>Conversion Opportunity </span>
                        </div>
                        <div className="card-body">
                            <div style={{ marginBottom: 10 }}>
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search for conversion opportunity info.."
                                        aria-label="Search for conversion opportunity info.."
                                        aria-describedby="basic-addon2"
                                        value={this.state.searchKeyConversion}
                                        onChange={(e) => this.handleSearchForConversion(e, this.state.getCorporateConversionOpportunityOverview)}
                                    />
                                    <InputGroup.Append>
                                        <Button onClick={() => this.clearButtonForConversion()}>Clear</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            <ReactTable
                                data={this.state.isConversionFilteredDataAvailable ? this.state.filteredSearchConversion : []}
                                // filterable
                                columns={[
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                        accessor: "leavingVendorName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                        accessor: "memberName",
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Business Group</b>,
                                        accessor: 'businessGroupName',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset', 'textTransform': 'capitalize' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Product Group</b>,
                                        accessor: 'productGroupName',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Purchases</b>,
                                        accessor: 'purchases',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset', 'textAlign': 'right' },
                                        Cell: props => <div style={{textAlign:'center'}}>{priceFormatter(props.original.purchases)}</div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier 1</b>,
                                        accessor: 'favorableVendor1Name',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}><span>
                                            <span>{props.original.favorableVendor1Name} </span>
                                            {props.original.favorableVendor1Name && <i
                                                className="fa fa-info-circle"
                                                onClick={() => this.handleFavVendorFeedback1(props)}
                                                style={{ color: '#0D47A1', cursor: 'pointer' }}
                                            ></i>}
                                        </span></div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier 2</b>,
                                        accessor: 'favorableVendor2Name',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}><span>
                                            <span>{props.original.favorableVendor2Name} </span>
                                            {props.original.favorableVendor2Name && <i
                                                className="fa fa-info-circle"
                                                onClick={() => this.handleFavVendorFeedback2(props)}
                                                style={{ color: '#0D47A1', cursor: 'pointer' }}
                                            ></i>}
                                        </span></div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier 3</b>,
                                        accessor: 'favorableVendor3Name',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props => <div style={{textAlign:'center'}}><span>
                                            <span>{props.original.favorableVendor3Name} </span>
                                            {props.original.favorableVendor3Name && <i
                                                className="fa fa-info-circle"
                                                onClick={() => this.handleFavVendorFeedback3(props)}
                                                style={{ color: '#0D47A1', cursor: 'pointer' }}
                                            ></i>}
                                        </span></div>
                                    },
                                    {
                                        Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier 4</b>,
                                        accessor: 'favorableVendor4Name',
                                        sortable: true,
                                        resizable: true,
                                        style: { 'whiteSpace': 'unset' },
                                        Cell: props =><div style={{textAlign:'center'}}> <span>
                                            <span>{props.original.favorableVendor4Name} </span>
                                            {props.original.favorableVendor4Name && <i
                                                className="fa fa-info-circle"
                                                onClick={() => this.handleFavVendorFeedback4(props)}
                                                style={{ color: '#0D47A1', cursor: 'pointer' }}
                                            ></i>}
                                        </span></div>
                                    }
                                ]}
                                defaultSorted={[
                                    {
                                        id: 'purchases',
                                        desc: true
                                    }
                                ]}
                                defaultPageSize={25}
                                style={{ height: "400px" }}
                                className="-striped -highlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getListOfMonths = (allYears, year) => {
        for (const [key, value] of Object.entries(allYears)) {
            if (year == key) {
                let month = Math.min(value[value.length - 1], this.state.month)
                this.setState({ listOfMonthsForSelectedYear: value, month: value[value.length - 1], year  }, () => {
                    this.getConversionOpportunityData();
                })
            }
        }
    }


    handleFavVendorFeedback1 = (props) => {
        
        this.setState({
            show: true,
            selectedFavorbaleSupplier: props.original.favorableVendor1Name,
            selectedTargetedSupplier: props.original,
            selectedFavorbaleSupplierId: props.original.favorableVendor1Id,
            oppProductGroupId: props.original.productGroupId,
            oppProductGroup: props.original.productGroupName,
        })
        
        getCorpConversionFavVendorList().then((data) => {
            this.setState({
                getCorporateConversionFavVendorList: data
            })
        })
    
    
    }

    handleFavVendorFeedback2 = (props) => {
        this.setState({
            show: true,
            selectedFavorbaleSupplier: props.original.favorableVendor2Name,
            selectedTargetedSupplier: props.original,
            selectedFavorbaleSupplierId: props.original.favorableVendor2Id,
            oppProductGroupId: props.original.productGroupId,
            oppProductGroup: props.original.productGroupName,
        })
        getCorpConversionFavVendorList().then((data) => {
            this.setState({
                getCorporateConversionFavVendorList: data
            })
        })
    }

    handleFavVendorFeedback3 = (props) => {
        this.setState({
            show: true,
            selectedFavorbaleSupplier: props.original.favorableVendor3Name,
            selectedTargetedSupplier: props.original,
            selectedFavorbaleSupplierId: props.original.favorableVendor3Id,
            oppProductGroupId: props.original.productGroupId,
            oppProductGroup: props.original.productGroupName,
        })
        getCorpConversionFavVendorList().then((data) => {
            this.setState({
                getCorporateConversionFavVendorList: data
            })
        })
    }

    handleFavVendorFeedback4 = (props) => {
        this.setState({
            show: true,
            selectedFavorbaleSupplier: props.original.favorableVendor4Name,
            selectedTargetedSupplier: props.original,
            selectedFavorbaleSupplierId: props.original.favorableVendor4Id,
            oppProductGroupId: props.original.productGroupId,
            oppProductGroup: props.original.productGroupName,
        })
        getCorpConversionFavVendorList().then((data) => {
            this.setState({
                getCorporateConversionFavVendorList: data
            })
        })
    }

    handleSaveFeedback = () => {
        getCorpConversionSaveProductsFeedback().then((data) => { })
    }

    handleSearchForMember = (e, data) => {
        this.setState({ searchKeyMember: e.target.value });
        let results = data.filter(value => {
            return (
                value.memberName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.leavingVendorName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.memberId.toFixed()
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                value.purchases.toFixed()
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            );
        });

        this.setState({
            isMemberFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchMember: results
        });
    }

    clearButtonForMember = () => {
        this.setState({
            searchKeyMember: "",
            filteredSearchMember: [...this.state.getCorporateConversionMember],
        });

        let params = this.state.params;

        getCorpConversionOppMember(params).then((data) => {
            this.setState({
                getCorporateConversionMember: data,
                filteredSearchMember: data,
                isMemberFilteredDataAvailable: true
            })
        })
    }

    handleSearchForProductGroup = (e, data) => {
        
        this.setState({ searchKeyProductGroup: e.target.value });
        let results = data.filter(value => {
            return (
                value.productGroupName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
                 //value.leavingVendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 
                // value.purchases.toFixed()
                //     .toString()
                //     .toLowerCase()
                //     .indexOf(e.target.value.toLowerCase()) >= 0
            );

        });
        this.setState({
            isProductGroupFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchProductGroup: results
        });
    }

    clearButtonForProductGroup = () => {
        
        this.setState({
            searchKeyProductGroup: "",
            filteredSearchProductGroup: [...this.state.getCorporateConversionProductGroup],
        });

        let params = this.state.params;
        //.productGroupId= props.original.productGroupId,

        getCorpConversionOppProductGroup(params).then((data) => {
            this.setState({
                getCorporateConversionProductGroup: data,
                filteredSearchProductGroup: data,
                isProductGroupFilteredDataAvailable: true
            })
        })
        
    }

    handleSearchForPODetails = (e, data) => {
        this.setState({ searchKeyPODetails: e.target.value });
        let results = data.filter(value => {
            return (
                value.memberName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.leavingVendorName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.poCount
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                value.purchases.toFixed()
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                value.avgPurchases.toFixed()
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            );
        });
        this.setState({
            isPODetailsFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchPODetails: results
        });
    }

    clearButtonForPODetails = () => {
        this.setState({
            searchKeyPODetails: "",
            filteredSearchPODetails: [...this.state.getCorporateConversionPODetails],
        });

        let params = this.state.params;

        getCorpConversionOppPODetails(params).then((data) => {
            this.setState({
                getCorporateConversionPODetails: data,
                filteredSearchPODetails: data,
                isPODetailsFilteredDataAvailable: true
            })
        })
    }

    handleSearchForConversion = (e, data) => {
        this.setState({ searchKeyConversion: e.target.value });
        let results = data.filter(value => {
            return (
                value.memberName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.leavingVendorName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.businessGroupName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.productGroupName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                value.purchases.toFixed()
                    .toString()
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            );
        });
        this.setState({
            isConversionFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchConversion: results
        });
    }

    clearButtonForConversion = () => {
        this.setState({
            searchKeyConversion: "",
            filteredSearchConversion: [...this.state.getCorporateConversionOpportunityOverview],
        });

        let params = this.state.params;

        getCorpConversionOppConversionDetails(params).then((data) => {
            this.setState({
                getCorporateConversionOpportunityOverview: data,
                filteredSearchConversion: data,
                isConversionFilteredDataAvailable: true
            })
        })
    }

    handleBranch = (e) => {
        this.setState((prevState) => ({
            buyingGroupId: e.target.value,
            params: {
                ...prevState.params,
                buyingGroupId: e.target.value
            }
        }))
    }

    handleTerminatedVendor = (e) => {
        if (e.value == 0) {
            this.setState((prevState) => ({
                terminatedVendorId: '',
                params: {
                    ...prevState.params,
                    terminatedVendorId: ''
                }
            }))
        }
        else {
            this.setState((prevState) => ({
                terminatedVendorId: e.value,
                params: {
                    ...prevState.params,
                    terminatedVendorId: e.value
                }
            }))
        }
    }

    handlePeriod = (e) => {
        if (e.target.name === "QTR") {
            this.setState((prevState) => ({
                isQuarterSelected: true,
                period: e.target.name,
                params: {
                    ...prevState.params,
                    period: e.target.name
                }
            }))
        } else {
            this.setState((prevState) => ({
                isQuarterSelected: false,
                period: e.target.name,
                params: {
                    ...prevState.params,
                    period: e.target.name
                }
            }))
        }
    }

    handleYear = (e) => {
        this.setState((prevState) => ({
            year: e.target.value,
            params: {
                ...prevState.params,
                year: e.target.value
            },
            searchKeyMember: "",
            searchKeyProductGroup: "",
            searchKeyPODetails: "",
            searchKeyConversion: "",
            isMemberFilteredDataAvailable: false,
            isProductGroupFilteredDataAvailable: false,
            isPODetailsFilteredDataAvailable: false,
            isConversionFilteredDataAvailable: false,
            filteredSearchMember: [],
            filteredSearchProductGroup: [],
            filteredSearchPODetails: [],
            filteredSearchConversion: []
        }), () => {
            this.getListOfMonths(this.state.conversionMonthsFilter, e.target.value);
        });
    }

    handleQuarter = (e) => {
        this.setState((prevState) => ({
            quarter: e.target.value,
            params: {
                ...prevState.params,
                quarter: e.target.value
            }
        }));
    }

    handleMonth = (e) => {
        this.setState((prevState) => ({
            month: e.target.value,
            params: {
                ...prevState.params,
                month: e.target.value
            }
        }));
    }

    render() {
        console.log(this.state.isProductGroupFilteredDataAvailable)
             console.log(this.state.filteredSearchProductGroup)

        const {
            // year,
            // month,
            period,
            // source,
            // quarter,
            // buyingGroupId,
            // productGroupId,
            // terminatedVendorId,
            // params,
            getCorporateConversionOpportunityVendorList,
            listOfYears,
            listOfMonthsForSelectedYear,
            isQuarterSelected
        } = this.state;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        const customStyles = {
            container: base => ({
                ...base,
                width: '250px',
            }),
            control: base => ({
                ...base,
                boxShadow: 'none',
                borderRadius: '6px'
            }),
            valueContainer: base => ({
                ...base,
                fontSize: '15px',
                marginLeft: '4px',
            }),
            placeholder: base => ({
                ...base,
                marginTop: '0px',
                position: 'absolute',
            })
        }

        return (
            <div>
                {this.productDetailsModal()}
                {this.favorableSupplierFeedbackModal()}

                <div className="filtersContainer">
                    <div className="filterTitle">
                        <p> Conversion Opportunity </p>
                    </div>
                    <div className="filterItems">
                        <Form.Group as={Col} id="group1">
                            <Form.Control
                                as="select"
                                id="select1"
                                defaultValue="0"
                                onChange={(e) => this.handleBranch(e)}
                            >
                                <option value="0">IMARK ELECTRICAL & IMARK LLC</option>
                                <option value="1">IMARK LLC</option>
                                <option value="2">IMARK ELECTRICAL</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} id="group2">
                            <Select
                                className="full-width-select"
                                onChange={(value) => this.handleTerminatedVendor(value)}
                                placeholder='Select Targeted Supplier'
                                styles={customStyles}
                                isSearchable={true}
                                options={getCorporateConversionOpportunityVendorList.map((Item) => ({ value: Item.vendorId, label: Item.vendorName }))}
                            />
                            {/* <Form.Control
                                as="select"
                                id="select2"
                                defaultValue={"Select Targeted Supplier"}
                                style={{ width: '130px !important' }}
                                onChange={(e) => this.handleTerminatedVendor(e)}
                            >
                                <option value="">Select Targeted Supplier</option>
                                {getCorporateConversionOpportunityVendorList && getCorporateConversionOpportunityVendorList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.vendorId}>{item.vendorName}</option>
                                    )
                                })}
                            </Form.Control> */}
                        </Form.Group>
                        <ButtonGroup className="yearGroup">
                            <Button
                                onClick={(e) => this.handlePeriod(e)}
                                style={{ fontSize: 12, background: `${period === "MTH" ? "#0074d9" : "white"}`, color: `${period === "MTH" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="MTH"
                            >
                                MTH
                            </Button>
                            <Button
                                onClick={(e) => this.handlePeriod(e)}
                                style={{ fontSize: 12, background: `${period === "YTD" ? "#0074d9" : "white"}`, color: `${period === "YTD" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="YTD"
                            >
                                YTD
                            </Button>
                            <Button
                                onClick={(e) => this.handlePeriod(e)}
                                style={{ fontSize: 12, background: `${period === "QTR" ? "#0074d9" : "white"}`, color: `${period === "QTR" ? "white" : "#242424"}`, borderColor: '#ced4da' }}
                                name="QTR"
                            >
                                QTR
                            </Button>
                        </ButtonGroup>
                        <Form.Group as={Col} id="group3">
                            <Form.Control
                                as="select"
                                id="select2"
                                defaultValue={new Date().getFullYear()}
                                onChange={(e) => this.handleYear(e)}
                            >
                                {listOfYears && listOfYears.map((year) => {
                                    return <option key={year} selected={this.state.year==year} value={year}>{year}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        {isQuarterSelected ? (
                            <Form.Group as={Col} id="group4">
                                <Form.Control
                                    as="select"
                                    id="select4"
                                    defaultValue={"1"}
                                    onChange={(e) => this.handleQuarter(e)}
                                    style={{ minWidth: 100 }}
                                >
                                    <option value="1">QTR1</option>
                                    <option value="2">QTR2</option>
                                    <option value="3">QTR3</option>
                                    <option value="4">QTR4</option>
                                </Form.Control>
                            </Form.Group>
                        ) : (
                            <Form.Group as={Col} id="group4">
                                <Form.Control
                                    as="select"
                                    id="select3"
                                    defaultValue={listOfMonthsForSelectedYear[listOfMonthsForSelectedYear.length - 1]}
                                    onChange={(e) => this.handleMonth(e)}
                                    style={{ minWidth: 100 }}
                                >
                                    {listOfMonthsForSelectedYear && listOfMonthsForSelectedYear.map((month) => {
                                        return <option key={month} selected={month==this.state.month} value={`${month}`}>{switchMonths(month)}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        )}
                        <ExcelFile filename="CONVERSION DATA" element={<Button className="export" color="primary" onClick={(e) => { e.preventDefault() }} style={{ fontSize: 12 }}>Export</Button>}>
                            <ExcelSheet data={this.state.getCorporateConversionMember} name="Volume Opportunity By Member">
                                <ExcelColumn label="MEMBER NAME" value="memberName" />
                                <ExcelColumn label="MEMBER PURCHASES" value="purchases" />
                                <ExcelColumn label="LEAVING VENDOR" value="leavingVendorName" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.getCorporateConversionProductGroup} name="Volume Opportunity By Product Group">
                                <ExcelColumn label="PRODUCT GROUP" value="productGroupName" />
                                <ExcelColumn label="PURCHASES" value="purchases" />
                                <ExcelColumn label="LEAVING VENDOR" value="leavingVendorName" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.getCorporateConversionOpportunityOverview} name=" VP Conversion Opportunity">
                                <ExcelColumn label="LEAVING VENDOR" value="leavingVendorName" />
                                <ExcelColumn label="MEMBER NAME" value="memberName" />
                                <ExcelColumn label="BUSINESS GROUP" value="businessGroupName" />
                                <ExcelColumn label="PRODUCT GROUP" value="productGroupName" />
                                <ExcelColumn label="PURCHASES" value="purchases" />
                                <ExcelColumn label="FAVOURABLE VENDOR 1" value="favorableVendor1Name" />
                                <ExcelColumn label="FAVOURABLE VENDOR 2" value="favorableVendor2Name" />
                                <ExcelColumn label="FAVOURABLE VENDOR 3" value="favorableVendor2Name" />
                                <ExcelColumn label="FAVOURABLE VENDOR 4" value="favorableVendor4Name" />
                                <ExcelColumn label="FAVOURABLE VENDOR 5" value="favorableVendor5Name" />
                            </ExcelSheet>
                            <ExcelSheet data={this.state.getCorporateConversionPODetails} name="Conversion Opportunity PO Details">
                                <ExcelColumn label="LEAVING VENDOR" value="leavingVendorName" />
                                <ExcelColumn label="MEMBER NAME" value="memberName" />
                                <ExcelColumn label="PO COUNT" value="poCount" />
                                <ExcelColumn label="PURCHASES" value="purchases" />
                                <ExcelColumn label="AVERAGE VOLUME/PO" value="avgPurchases" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>

                {/* <div style={{ marginLeft: 60 }}>
                    <pre>
                        {JSON.stringify({
                            year,
                            month,
                            period,
                            source,
                            quarter,
                            buyingGroupId,
                            productGroupId,
                            terminatedVendorId,
                            params
                        }, null, 2)}
                    </pre>
                </div> */}

                <div style={{ marginLeft: "60px", marginRight: "60px" }}>
                    <div className="row">
                        <div className="col-6">
                            <TopTenMembers getCorporateConversionTopTenMember={this.state.getCorporateConversionTopTenMember} />
                        </div>
                        <div className="col-6">
                            {this.renderOpportunityByMember()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {this.renderOpportunityByProductGroup()}
                        </div>
                        <div className="col-6" >
                            <TopTenProductGroups getCorporateConversionTopTenProductGroup={this.state.getCorporateConversionTopTenProductGroup} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {this.renderConversionOpportunityPODetails()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ width: "700px", paddingBottom: "10px" }} >
                            {this.renderConversionOpportunity()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        getCorporateConversionMember: state.corporate.getCorporateConversionMember,
        getCorporateConversionProductGroup: state.corporate.getCorporateConversionProductGroup,
        getCorporateConversionPODetails: state.corporate.getCorporateConversionPODetails,
        getCorporateConversionOpportunityOverview: state.corporate.getCorporateConversionOpportunityOverview,
        getCorporateConversionOpportunityVendorList: state.corporate.getCorporateConversionOpportunityVendorList,
        getCorporateConversionFilters: state.corporate.getCorporateConversionFilters,
        getCorporateConversionProductCatList: state.corporate.getCorporateConversionProductCatList,
        getCorporateConversionFavVendorList: state.corporate.getCorporateConversionFavVendorList,
        getCorporateConversionProducts: state.corporate.getCorporateConversionProducts,
        getCorporateConversionTopTenMember: state.corporate.getCorporateConversionTopTenMember,
        getCorporateConversionTopTenProductGroup: state.corporate.getCorporateConversionTopTenProductGroup,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateConversion);
