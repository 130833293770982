import Axios from 'axios';
import { BASE_URL } from '../index';
import { convertJSONToURLParams } from '../utils/formatters';

// MEMBER

export const getTerminatedVPNamesForMember = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/vendor-directory?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('TerminatedVPNamesForMember', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getProductCategoryForMember = () => {
    let request= Axios.get(`/product-category`, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ProductCategoryForMember', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// MEMBER - OVERVIEW TAB

export const getMemberMonthsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/member-months-filter?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('MemberMonthsFilter', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getFilterMemberIds = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/filter-member-ids?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('FilterMemberIds', res.data);
            let arr = res.data;
            let obj = {
                branchId: arr.map((item) => item.branchId).join(','),
                branchName: 'All'
            };
            arr.unshift(obj);
            return arr;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getCompliantStatusForMember = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/compliant?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('Compliant', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getCardsDataForMemberDataTab = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/member/overview/rebate-overview?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('MemberRebateOverview', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getMemberPurchaseWithinImarkValue = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/member/overview/purchases?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
             console.log('MemberPurchaseWithinImark', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getMemberPurchaseOutsideImarkValue = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/member/outside-purchases-by-vendor?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('MemberPurchaseOutsideImark', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getMemberOutsidePurchaseByCategory = (formData) => {
    const selections = convertJSONToURLParams(formData);
    return Axios.get(`/member/outside-purchases-by-category?`+ selections).then(resp => resp.data);
}

export const getVendorFullInfo = (vendorId) => {
    
    let request= Axios.get(`/vendor/${vendorId}/info`, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    
    return request
        .then((res) => {
            console.log('VendorFullInfo1111111111111111111', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
        
}

export const getProductListByCategory = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/member/outside-purchases-by-category/product-list?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('ProductListByCategory', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const saveOutVendorFeedback = (formData) => {
    let request = Axios.post(`/save-out-vendor-feedback`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const saveProductFeedback = (formData) => {
    let request =  Axios.post(`/save-product-feedback`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// MEMBER - IMARK VERIFIED TAB

export const getMemberVerifiedMonthsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/member-verified-months-filter?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('MemberVerifiedMonthsFilter', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getCardsDataForIMARKVerifiedDataTab = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/verified-overview-boxes?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    
    return request
        .then((res) => {
            // console.log('ImarkVerifiedOverview', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getVerifiedRebateTrendsGraph = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/verified-rebate-trends-graph?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    
    return request
        .then((res) => {
            // console.log('RebateGraphDataImarkVerified', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getbHverifiedVendorPartnerPurchases = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/verified-inside-purchases?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('PurchaseWithinImarkVerified', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// MEMBER - INVOICES TAB

export const getInvoiceData = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/invoice/list?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('MemberInvoiceData', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const downloadInvoices = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/invoice/download?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('DownloadedInvoices', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// MEMBER - PURCHASE ORDERS

export const getPurchasedOrdersMember = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/purchase-order/list?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getBranches = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/branches?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const downloadPurchaseOrders = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/purchase-orders/download?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('DownloadedPurchaseOrders', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

// MEMBER - CONVERSION OPPORTUNITY

export const getTerminatedVPOpportunitiesDetails = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/conversion-opportunity-po-details?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getTerminatedVPOpportunities = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/conversion-opportunity-overview?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getConversionOpportunityVendorList = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/conversion-opportunity-vendor-list?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getConversionMonthsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request= Axios.get(`/conversion-months-filter?`+ selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            // console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })   
}
