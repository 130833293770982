
import Axios from 'axios';
import React, { Component } from 'react';
import { Alert, Button, Card, Form, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { acceptTerms, disagreeTerms, generateSampleSSOAuthenticationToken, login, loginWithSSOToken } from '../../actions';
import imarkLogo from '../../assets/images/imarkLogo.jpg';
import '../../assets/styles/Login.scss';
import { getStoredToken } from '../../utils/getStoredToken';
import { getUserType } from '../../utils/getUserType';
import { validateLogin } from '../../utils/validateLogin';
import Modal from './Modal';
import TermsOfUse from './TermsOfUse';
import bcrypt from 'bcryptjs';
// import Recaptcha from 'react-recaptcha'
import Recaptcha from "react-google-recaptcha"
import secureLocalStorage from 'react-secure-storage'


const salt = bcrypt.genSaltSync(10);
const characters='abc123'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isTokenPresent: false,
            isLogging: false,
            userType: null,
            username: "",
            password: "",
            showPassword: false,
            border: "1px solid #d3d3d3",
            errMessage: "",
            errors: {},
            showTncBox: false,
            termsAccepted: true,
            termsAcceptedAt: 0,
            displayModal: false,
            groupId: localStorage.getItem('groupId')
        }
    }

    closeModal = () => {
        this.setState({ displayModal: false });
    }

    // for demo se 
    // window.location.search
    componentDidMount() {
      if(this.props.location.search != ''){
        var searchParams = new URLSearchParams(this.props.location.search);
       console.log('searchParams', searchParams);

        console.log('SSO Paramter', searchParams.get('token'));

        if (searchParams.get('token')) {
            localStorage.clear();
            Axios.get(`/sso?token=` + encodeURIComponent(searchParams.get('token'))).then((res) => {
                res.data.groupName = res.data.groupName && res.data.groupName !== 'null' ? res.data.groupName : '';
                localStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
                localStorage.setItem('userType', `${res.data.userType}`);
                localStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
                localStorage.setItem('groupId', `${res.data.groupId}`);
                localStorage.setItem('groupName', `${res.data.groupName}`);
                sessionStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
                sessionStorage.setItem('userType', `${res.data.userType}`);
                sessionStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
                sessionStorage.setItem('groupId', `${res.data.groupId}`);
                sessionStorage.setItem('groupName', `${res.data.groupName}`);
                sessionStorage.setItem('imarkElectricalSupplierName', `${res.data.groupName}`);
                // localStorage.setItem('imarkElectricalSupplierName', `${res.data.groupName}`);

                Axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.jwtToken}`;
                let dateOfAcceptance = new Date(res.data.termsAccepted).getTime()
                let currentYear = new Date().getFullYear()
                let validDate = new Date(currentYear, 0, 1).getTime()
                
                if (res.data.termsAccepted != "0000-00-00 00:00:00.000000" && dateOfAcceptance >= validDate && this.state.termsAccepted) {
                    this.setState({
                        showTncBox: false,
                        isLogging: false,
                        isTokenPresent: getStoredToken() ? true : false,
                        userType: getUserType(),
                        groupId: localStorage.getItem('groupId'),
                        userId: res.data.userId
                    }, () => {
                        console.log(this.state.groupId, 'dealersOverallTotal1');
                      }); 
                } else {
                    this.setState({
                        showTncBox: false,
                        isLogging: false,
                        isTokenPresent: getStoredToken() ? true : false,
                        userType: getUserType(),
                        groupId: localStorage.getItem('groupId'),
                        userId: res.data.userId
                    }, () => {
                        console.log(this.state.groupId, 'dealersOverallTotal1');
                      }); 
                }
            }) .catch((err) => {
                console.log(err);
                if (err && err.response && err.response.status) {
                    if (err.response.status === 403) {
                        this.setState({
                            isLoading: false,
                            errMessage: 'You`re not authorized! Please signup.'
                        })
                        return this.props.history.push('/');
                    }
                } else if (err && err.message) {
                    if (err.message === 'Network Error') {
                        this.setState({
                            isLoading: false,
                            errMessage: 'Please check your internet connection or try logging in with credentials!'
                        })
                        return this.props.history.push('/');
                    };
                } else {
                    if (err) {
                        return this.props.history.push('/');
                    }
                }
            });
        }
    }
    else {
        var searchParams = new URLSearchParams(window.location.search);
        console.log('searchParams', searchParams);
 
         console.log('SSO Paramter', searchParams.get('token'));
 
         if (searchParams.get('token')) {
             localStorage.clear();
             Axios.get(`/sso?token=` + encodeURIComponent(searchParams.get('token'))).then((res) => {
                 res.data.groupName = res.data.groupName && res.data.groupName !== 'null' ? res.data.groupName : '';
                 localStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
                 localStorage.setItem('userType', `${res.data.userType}`);
                 localStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
                 localStorage.setItem('groupId', `${res.data.groupId}`);
                 localStorage.setItem('groupName', `${res.data.groupName}`);
                 sessionStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
                 sessionStorage.setItem('userType', `${res.data.userType}`);
                 sessionStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
                 sessionStorage.setItem('groupId', `${res.data.groupId}`);
                 sessionStorage.setItem('groupName', `${res.data.groupName}`);
                
                 Axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.jwtToken}`;
                 let dateOfAcceptance = new Date(res.data.termsAccepted).getTime()
                 let currentYear = new Date().getFullYear()
                 let validDate = new Date(currentYear, 0, 1).getTime()
                 
                 if (res.data.termsAccepted != "0000-00-00 00:00:00.000000" && dateOfAcceptance >= validDate && this.state.termsAccepted) {
                     this.setState({
                         showTncBox: false,
                         isLogging: false,
                         isTokenPresent: getStoredToken() ? true : false,
                         userType: getUserType(),
                         groupId: localStorage.getItem('groupId'),
                         userId: res.data.userId
                     }, () => {
                         console.log(this.state.groupId, 'dealersOverallTotal1');
                       }); 
                 } else {
                     this.setState({
                         showTncBox: true,
                         isLogging: false,
                         isTokenPresent: getStoredToken() ? true : false,
                         userType: getUserType(),
                         groupId: localStorage.getItem('groupId'),
                         userId: res.data.userId
                     }, () => {
                         console.log(this.state.groupId, 'dealersOverallTotal1');
                       }); 
                 }
             }) .catch((err) => {
                 console.log(err);
                 if (err && err.response && err.response.status) {
                     if (err.response.status === 403) {
                         this.setState({
                             isLoading: false,
                             errMessage: 'You`re not authorized! Please signup.'
                         })
                         return this.props.history.push('/');
                     }
                 } else if (err && err.message) {
                     if (err.message === 'Network Error') {
                         this.setState({
                             isLoading: false,
                             errMessage: 'Please check your internet connection or try logging in with credentials!'
                         })
                         return this.props.history.push('/');
                     };
                 } else {
                     if (err) {
                         return this.props.history.push('/');
                     }
                 }
             });
         }
    }

    }

    handleEmail = (e) => this.setState((prevState) => ({
        username: e.target.value,
        border: '1px solid #d3d3d3',
        errMessage: '',
        errors: {
            ...prevState.errors,
            username: ""
        }
    }));

    handlePassword = (e) => this.setState((prevState) => ({
        password: e.target.value,
        errors: {
            ...prevState.errors,
            password: ""
        }
    }));

    handleShowPasswordToggle = () => this.setState((prevState) => ({ showPassword: !prevState.showPassword }));

    handleLogin = () => {
        localStorage.getItem('jwtToken') && localStorage.removeItem('jwtToken');
        console.log('localstorage',localStorage.getItem('jwtToken'))
        const hashedPassword = bcrypt.hashSync(this.state.password, 10)

        const formData = { username: this.state.username, password: hashedPassword };
        let validatedResult = validateLogin(formData);

        if (validatedResult.isValid) {
            this.setState({ isLogging: true });

            this.props.loginAPI(formData)
                .then((res) => {
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${res.jwtToken}`;
                    let dateOfAcceptance = new Date(res.termsAccepted).getTime()
                    let currentYear = new Date().getFullYear()
                    let validDate = new Date(currentYear, 0, 1).getTime()
                    if (res.termsAccepted !== "0000-00-00 00:00:00.000000" && dateOfAcceptance >= validDate && this.state.termsAccepted) {
                        //localStorage.setItem('showTncBox', false);
                        this.setState({
                            showTncBox: false,
                            isLogging: false,
                            isTokenPresent: getStoredToken() ? true : false,
                            userType: getUserType(),
                            groupId: localStorage.getItem('groupId'),
                            userId: res.userId
                        });
                    }
                    else {
                       // localStorage.setItem('showTncBox', true);
                        this.setState({
                            showTncBox: true,
                            isLogging: false,
                            isTokenPresent: getStoredToken() ? true : false,
                            userType: getUserType(),
                            groupId: localStorage.getItem('groupId'),
                            userId: res.userId
                        });
                    }
                    console.log('login check',this.state.showTncBox, this.state.isTokenPresent, this.state.userType, this.state.isLogging)

                })
                .catch((err) => {
                    if (err && err.response && err.response.status) {
                        if (err.response.status === 403) {
                            this.setState({
                                isLogging: false,
                                border: '2px solid red',
                                errMessage: 'You`re not authorized! Please signup.'
                            })
                        }
                    } else if (err && err.message) {
                        if (err.message === 'Network Error') {
                            this.setState({
                                isLogging: false,
                                errMessage: 'Please check your internet connection!'
                            })
                        };
                    }
                })
        } else {
            this.setState({ errors: validatedResult.errors })
        }
    }

    acceptTerms = (nextUrl) => {
        console.log('Accept Terms User ID', this.state.userId);
        this.props.acceptTermsAPI({ userId: this.state.userId }).then((res, err) => {
            this.setState({ showTncBox: false, termsAccepted: true, termsAcceptedAt: new Date().getTime() })
            this.props.history.push(nextUrl);
        });
    }

    closeTermsModal = () => {
        localStorage.clear();
        this.props.disagreeTermsAPI({ userId: this.state.userId }).then((res, err) => {
            this.setState({ showTncBox: false, termsAccepted: false });
            setTimeout(() => {
                window.location('https://inside.imarkgroup.com/')
            }, 2000);
        });
    }

    renderModal() {
        console.log(this.state.displayModal);
        if (this.state.displayModal) {
            let { propsForModal } = this.state;
            return (
                <Modal
                    modalType='error'
                    propsForModal={propsForModal}
                    closeModal={this.closeModal}
                />
            );
        }
    }
    render() {
        console.log(this.state.showTncBox);
        let {
            isLoading,
            showPassword,
            isTokenPresent,
            userType,
            showTncBox
        } = this.state;

        if (isTokenPresent && userType === 'CORPORATE') {
            if (showTncBox) {
                return <div className='modal fade show' tabIndex='-1' role='dialog' style={{ display: 'block' }}>
                    <TermsOfUse acceptTerms={() => this.acceptTerms('/corporate/overview')} closeModal={this.closeTermsModal} />
                </div>
            }
            else {
                return <Redirect to={'/corporate/overview'} />
            }
        } else if (isTokenPresent && userType === 'MEMBER') {
            if (showTncBox) {
                return <div className='modal fade show' tabIndex='-1' role='dialog' style={{ display: 'block' }}>
                    <TermsOfUse acceptTerms={() => this.acceptTerms("/member/" + this.state.groupId)} closeModal={this.closeTermsModal} />
                </div>
            }
            else {
                return <Redirect to={"/member/" + this.state.groupId} />
            }
        } else if (isTokenPresent && (userType === 'VENDOR' || userType === 'SUPPLIER')) {
            if (showTncBox) {
                return <div className='modal fade show' tabIndex='-1' role='dialog' style={{ display: 'block' }}>
                    <TermsOfUse acceptTerms={() => this.acceptTerms("/supplier/" + this.state.groupId + "/overview")} closeModal={this.closeTermsModal} />
                </div>
            } else {
                return <Redirect to={"/supplier/" + this.state.groupId + "/overview"} />
            }
        } else {
            return isLoading ?
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <div className="loginContainer">
                    <div className="loginFormContainer">
                        <Card style={{ padding: 10 }}>
                            <div className="logoContainer">
                                <img src={imarkLogo} alt="noImage" height="48" />
                            </div>

                            <div className="formContainer">
                                <Form>
                                    {this.state.errMessage ? <Alert variant="danger">{this.state.errMessage}</Alert> : ''}
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label><b>Email*</b></Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            autocomplete="off"
                                            onChange={(e) => this.handleEmail(e)}
                                            style={{ border: this.state.border }}
                                        />
                                        <small style={{ color: 'red' }}>
                                            <b>{this.state.errors && this.state.errors.username}</b>
                                        </small>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label><b>Password*</b></Form.Label>
                                        <Form.Control
                                            type={!showPassword ? "password" : "text"}
                                            placeholder="Password"
                                            autocomplete="off"
                                            onChange={(e) => this.handlePassword(e)}
                                        />
                                        <small style={{ color: 'red' }}>
                                            <b>{this.state.errors && this.state.errors.password}</b>
                                        </small>
                                    </Form.Group>

                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label={<small>{!showPassword ? "Show" : "Hide"} Password</small>}
                                        onChange={() => this.handleShowPasswordToggle()}
                                        style={{ margin: '4px 0' }}
                                    />

                                    <Button
                                        variant="primary"
                                        onClick={(e) => this.handleLogin()}
                                        block
                                    >
                                        Log In
                                    </Button>
                                </Form>
                            </div>
                        </Card>
                    </div>

                </div>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authentication.isLoggedIn,
        ssoAuthToken: state.authentication.generatedSampleSSOAuthenticationTokenData
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        loginAPI: login,
        generateSampleSSOAuthenticationTokenAPI: generateSampleSSOAuthenticationToken,
//loginWithSSOTokenAPI: loginWithSSOToken,
        acceptTermsAPI: acceptTerms,
        disagreeTermsAPI: disagreeTerms,

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
