import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createHashHistory } from 'history';
import Noty from 'noty';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import "react-table-6/react-table.css";
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import NotFound from './Common/NotFound/NotFound';
import Login from './components/auth/Login';
import Corporate from './components/corporate';
import Member from './components/member';
import Supplier from './components/supplier';
import rootReducer from './reducers';
import './scss/custom.scss';

export const history = createHashHistory();

// export const BASE_URL = 'https://imark-electrical.tredence.com/imark-electrical';
// export const BASE_URL = 'https://imark-electrical.tredence.com/ie-demo';
export const BASE_URL = 'https://imark-electrical.tredence.com/ie-uat';
// export const BASE_URL = 'https://imark-electrical.tredence.com/ie-demo';
// export const BASE_URL = 'http://localhost:8080'

/**
 * Override Notification Defaults
 */
Noty.overrideDefaults({ layout: 'bottomRight', type: 'information', theme: 'bootstrap-v4', timeout: 3000 });

/**
 * API Configuration using BASE_URL
 */
Axios.defaults.baseURL = BASE_URL;
Axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
Axios.defaults.headers.common['Accept'] = 'application/json;charset=utf-8';
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.headers.common['X-Frame-Options'] = 'DENY';

if (localStorage.getItem('jwtToken')) {
  Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwtToken')}`;
}
Axios.interceptors.response.use(response => response, error => {
  if (
    error &&
    error.message &&
    error.message === "Network Error"
  ) {
    new Noty({
      theme: 'metroui',
      type: 'error',
      text: '<b>NETWORK ERROR :</b> Please refresh the page to check your internet connection or try by disconnecting VPN!'
    }).show();
  }
  else if (
    error &&
    error.response &&
    error.response.status &&
    (error.response.status === 401 || error.response.status === 403)
  ) {
    new Noty({
      theme: 'metroui',
      type: 'error',
      text: '<b>AUTH ERROR :</b> You`re not authorized! Please Signup.'
    }).show();
    localStorage.clear();
    window.location.url = process.env.PUBLIC_URL;
  }
  return Promise.reject(error);
});

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify({ navigation: state.navigation });
    localStorage.setItem('imarkElectricalPersistedState', serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromLocalStorage(state) {
  try {
    const serializedState = localStorage.getItem('imarkElectricalPersistedState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
  }
}

const persistedState = loadFromLocalStorage();

const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

store.subscribe(() => saveToLocalStorage(store.getState()));

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/member/:id" render={routeProps => <Member {...routeProps} />} />
        <Route path="/supplier/:id" render={routeProps => <Supplier {...routeProps} />} />
        <Route path="/corporate" render={routeProps => <Corporate />} />
        <Route exact path="/" render={(routeProps) => <Login {...routeProps} />} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);
