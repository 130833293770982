import React from 'react';
import { Form, Col, ButtonGroup, Button } from 'react-bootstrap';
import { switchMonths } from '../../../../utils/formatters';
import ReactExport from "react-export-excel";
import Select from 'react-select';

import '../../../../assets/styles/Filters.scss';

export default class ConversionOpportunityFilters extends React.Component {
    render() {
        const {
            year,
            period,
            handleYearReference,
            handleMonthReference,
            handlePeriodReference,
            handleQuarterReference,
            handleTerminatedSupplierReference,
            listOfYears,
            listOfMonthsForSelectedYear,
            conversionOpportunityVendorList,
            conversionOpportunityPODetails,
            conversionOpportunity,
            isQuarterSelected
        } = this.props;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        const customStyles = {
            container: base => ({
                ...base,
                width: '250px',
            }),
            control: base => ({
                ...base,
                boxShadow: 'none',
                borderRadius: '6px'
            }),
            valueContainer: base => ({
                ...base,
                fontSize: '15px',
                marginLeft: '4px',
            }),
            placeholder: base => ({
                ...base,
                marginTop: '0px',
                position: 'absolute',
            })
        }

        return (
            <div className="filtersContainer">
                <div className="filterTitle">
                    <p>{this.props.memberName}</p>
                </div>
                <div className="filterItems">
                    <Form.Group as={Col} id="group2">
                        <Select
                            className="full-width-select"
                            // value={conversionOpportunityVendorList}
                            onChange={(value) => handleTerminatedSupplierReference(value)}
                            placeholder='Select Targeted Supplier'
                            styles={customStyles}
                            isSearchable={true}
                            options={conversionOpportunityVendorList.map((Item) => ({ value: Item, label: Item.vendorName }))}
                        />
                        {/* <Form.Control
                            as="select"
                            defaultValue={"Select Targeted Supplier"}
                            id="select2"
                            onChange={(e) => handleTerminatedSupplierReference(e)}
                        >
                            <option value="">Select Targeted Supplier</option>
                            {conversionOpportunityVendorList && conversionOpportunityVendorList.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.vendorId}=${item.vendorName}`}>{item.vendorName}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>
                    <ButtonGroup className="yearGroup">
                        <Button
                            className={`btn ${period === "MTH" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            value="MTH"
                        >
                            MTH
                        </Button>
                        <Button
                            className={`btn ${period === "YTD" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            value="YTD"
                        >
                            YTD
                        </Button>
                        <Button
                            className={`btn ${period === "QTR" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            value="QTR"
                        >
                            QTR
                        </Button>
                    </ButtonGroup>
                    <Form.Group as={Col} id="group3" style={{ minWidth: 100 }}>
                        <Form.Control
                            as="select"
                            defaultValue={year}
                            id="select3"
                            onChange={(e) => handleYearReference(e)}
                        >
                            {listOfYears.map((year) => {
                                return <option key={year} selected={this.props.year==year} value={`${year}`}>{year}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    {!isQuarterSelected && <Form.Group as={Col} id="group4" style={{ minWidth: 100 }}>
                        <Form.Control
                            as="select"
                            defaultValue={listOfMonthsForSelectedYear[listOfMonthsForSelectedYear.length - 1]}
                            id="select4"
                            onChange={(e) => handleMonthReference(e)}
                        >
                            {listOfMonthsForSelectedYear.map((month) => {
                                return <option key={month} selected={month == this.props.month ? true : false} value={`${month}`}>{switchMonths(month)}</option>
                            })}
                        </Form.Control>
                    </Form.Group>}
                    {isQuarterSelected && <Form.Group as={Col} id="group4" style={{ minWidth: 100 }}>
                        <Form.Control
                            as="select"
                            defaultValue={"1"}
                            id="select5"
                            onChange={(e) => handleQuarterReference(e)}
                            style={{ fontSize: 12 }}
                        >
                            <option value="1">QTR1</option>
                            <option value="2">QTR2</option>
                            <option value="3">QTR3</option>
                            <option value="4">QTR4</option>
                        </Form.Control>
                    </Form.Group>}
                    <ExcelFile element={<Button className="export" color="primary" style={{ fontSize: 12 }}>Export</Button>}>
                        <ExcelSheet data={conversionOpportunityPODetails} name="VP Opportunity Details">
                            <ExcelColumn label="VENDOR NAME" value="leavingVendorName" />
                            <ExcelColumn label="PO COUNT" value="poCount" />
                            <ExcelColumn label="VOLUME" value="purchases" />
                            <ExcelColumn label="AVERAGE VOLUME/PO" value="avgPurchases" />
                        </ExcelSheet>
                        <ExcelSheet data={conversionOpportunity} name="Targeted VP Opportunity">
                            <ExcelColumn label="VENDOR NAME" value="leavingVendorName" />
                            <ExcelColumn label="CATEGORY GROUP" value="businessGroupName" />
                            <ExcelColumn label="PRODUCT GROUP" value="productGroupName" />
                            <ExcelColumn label="VOLUME" value="purchases" />
                            <ExcelColumn label="VENDOR 1" value="favorableVendor1Name" />
                            <ExcelColumn label="VENDOR 2" value="favorableVendor2Name" />
                            <ExcelColumn label="VENDOR 3" value="favorableVendor3Name" />
                            <ExcelColumn label="VENDOR 4" value="favorableVendor4Name" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </div>
        )
    }
}