import Noty from 'noty';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import React from 'react';
import { Button, Card, Col, Form, FormControl, InputGroup, Modal, ProgressBar } from 'react-bootstrap';
import ReactTable from "react-table-6";
import Toggle from 'react-toggle';
import ReactTooltip from 'react-tooltip';
import { getProductCategoryForMember, getProductListByCategory, saveProductFeedback } from '../../../../api/member-api';
import '../../../../assets/styles/OutSidePurchasesByProductGroup.scss';
import { priceFormatter } from '../../../../utils/formatters';
import ProductListByCategory from '../ProductListByCategory/ProductListByCategory';
import Select from 'react-select';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';



export default class OutsidePurchasesByProductGroup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSubmit: false,
            showFeedbackModal: false,
            cheeseIsReady: false,
            selectedProductGroup: null,
            selectedFavorableSupplier: null,
            selectedFavorableSupplierId: null,
            suggestedVendorId: null,
            suggestedVendorName: null,
            suggestedCategoryId: null,
            suggestedCategoryName: null,
            classificationFlag: null,
            feedbackComments: null,
            searchKey: "",
            isFilteredDataAvailable: false,
            filteredSearchResults: [...props.outsidePurchasesByProductGroup],
            productListByCategory: [],
            productCategoryForMember: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        Promise.allSettled([getProductCategoryForMember()]).then((allResponses) => {
            if (allResponses[0]) {
                this.setState({ productCategoryForMember: allResponses[0].value ? allResponses[0].value : [] })
            }
        }).catch((err) => console.log(err));
    }

    getProductList = (productGroupId) => {

        let formData = {
            memberId: this.props.memberId,
            year: this.props.year,
            month: this.props.month,
            quarter: this.props.quarter,
            period: this.props.period,
            productGroupId: `${productGroupId}`
        }

        Promise.allSettled([getProductListByCategory(formData)]).then((allResponses) => {
            console.log('ProductListForSelectedProductGroupId', allResponses[0])
            if (allResponses[0]) {
                this.setState({ productListByCategory: allResponses[0].value ? allResponses[0].value : [] })
            }
        }).catch((err) => {
            console.log('error', err);
        });
    }

    handleClose = () => this.setState({ show: false, cheeseIsReady: false, productListByCategory: [] })
    handleShow = () => this.setState({ show: true })

    handleFeedbackModalClose = () => this.setState({
        showFeedbackModal: false, cheeseIsReady: false,
        suggestedVendorId: null, suggestedVendorName: null
    })

    handleFeedbackModalShow = () => this.setState({ showFeedbackModal: true })

    handleCheeseChange = () => {
        if (this.state.cheeseIsReady) {
            this.setState({ cheeseIsReady: false, suggestedVendorId: null, suggestedVendorName: null, showSubmit: false })
        } else {
            this.setState({ cheeseIsReady: true, showSubmit:false })
        }
    }
    handleSelectedSupplier1 = (value) => {
        let arr = [];

        let vendorId = '';
        let vendorName = '';

        if (value) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let vendorId = arr[0].split(',')[0];
          let vendorName = arr[0].split(',')[1];
  
        }
        else {
            let vendorName = _findIndex(value,"Select Supplier");
            let vendorId = '';
          }

        console.log(arr)
        
          this.setState({
            suggestedVendorId: vendorId,
            suggestedVendorName: vendorName,
            showSubmit: true
        })
    
      };
    
    handleSelectedSupplier = (e) => {
        if (e.value) {
            let vendorId = e.value.split(',')[0];
            let vendorName = e.value.split(',')[1];

            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: true
            })
        }
        else {
            let vendorName = _findIndex(e,"Select Supplier");
            let vendorId = '';
            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: false
            })
          }
    }

    handleFeedbackComments = (e) => this.setState({ feedbackComments: e.target.value });

    onFeedbackSubmit = (e) => {

        e.preventDefault();

        let formData = {
            categoryId: this.state.selectedProductGroupId,
            categoryName: this.state.selectedProductGroup,
            favorableVendorWrong: this.state.cheeseIsReady,
            favorableVendorId: this.state.selectedFavorableSupplierId,
            favorableVendorName: this.state.selectedFavorableSupplier,
            suggestedFavorableVendorId: this.state.suggestedVendorId,
            suggestedFavorableVendorName: this.state.suggestedVendorName,
            feedbackComments: this.state.feedbackComments
        }

        saveProductFeedback(formData)
            .then((responseData) => {
                console.log('savedProductFeedback', responseData);
                this.handleFeedbackModalClose();
                new Noty({
                    text: responseData.text,
                    layout: "topRight",
                    theme: "mint",
                    type: "success"
                }).show();
            })
            .catch((err) => console.log(err));
            
    }

    productListByCategoryModal = (terminatedVPNamesForMember) => {
        let {
            suggestedCategoryId,
            suggestedCategoryName,
            productListByCategory,
            productCategoryForMember
        } = this.state;
console.log(this.state,"seeingllstate")
        return (
            <Modal dialogClassName="outsidePurchaseModal" show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Product List By Category for <b>{suggestedCategoryName}</b>
                </Modal.Header>
                <Modal.Body>
                    <ProductListByCategory
                        productListByCategory={productListByCategory}
                        suggestedCategoryId={suggestedCategoryId}
                        suggestedCategoryName={suggestedCategoryName}
                        terminatedVPNamesForMember={terminatedVPNamesForMember}
                        productCategoryForMember={productCategoryForMember}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    feedbackModal = (terminatedVPNamesForMember) => {

        let { selectedFavorableSupplier, selectedProductGroup } = this.state;

        return (
            <Modal show={this.state.showFeedbackModal} onHide={() => this.handleFeedbackModalClose()}>
                <Modal.Header closeButton>
                <p>Favorable Supplier Feedback for <b>{selectedFavorableSupplier}</b></p>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Is <b>{selectedFavorableSupplier}</b> Favorable Supplier wrong for <b>{selectedProductGroup}</b>?</p>
                        <Toggle
                            id='cheese-status5'
                            icons={false}
                            defaultChecked={this.state.cheeseIsReady}
                            onChange={() => this.handleCheeseChange()}
                        />
                    </div>
                    {this.state.cheeseIsReady && <Form.Group as={Col} controlId="formSelectSupplier">
                    <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleSelectedSupplier(e)}
                            placeholder={'Select Supplier'}
                            
                            options={Array.from(
                                terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                return {
                                  value: item.vendorId + ','+ item.vendorName,
                                  label: item.vendorName,
                                };
                                
                              })
                            )}
                          />
        
                        {/* <Form.Control as="select" defaultValue="Select Vendor" onChange={(e) => this.handleSelectedSupplier(e)}>
                            <option>Select Supplier</option>
                            {terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.vendorId}=${item.vendorName}`}>{item.vendorName}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea onChange={(e) => this.handleFeedbackComments(e)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit} onClick={(e) => this.onFeedbackSubmit(e)}>
                        Submit Feedback
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleFeedbackModalClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: false,
    });

    render() {

        const opportunities = this.props.outsidePurchasesByProductGroup && this.props.outsidePurchasesByProductGroup.map((item) => item.missedOpportunity);
        const maxOpportunity = opportunities && Math.max(...opportunities);

        let filteredRows = this.props.outsidePurchasesByProductGroup;

        if (this.state.searchKey) {
            filteredRows = this.props.outsidePurchasesByProductGroup.filter((item) => {
                return ((item.tierFour && item.tierFour.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) >= 0) ||
                    (item.favorableVendorOneName && item.favorableVendorOneName.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) >= 0) ||
                    (item.favorableVendorTwoName && item.favorableVendorTwoName.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) >= 0) ||
                    (item.favorableVendorThreeName && item.favorableVendorThreeName.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) >= 0) ||
                    (item.favorableVendorFourName && item.favorableVendorFourName.toLowerCase().indexOf(this.state.searchKey.toLowerCase()) >= 0))
            });
        }

        return (
            <React.Fragment>
                {this.productListByCategoryModal(this.props.terminatedVPNamesForMember)}
                {this.feedbackModal(this.props.terminatedVPNamesForMember)}
                <Card className="outSidePurchasesByProductGroupContainer">
                    <Card.Header>Non-Preferred Purchases by Product Group</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for Non-Preferred Purchases by Product Group info.."
                                    aria-label="Search for oustide purchases by product group info.."
                                    aria-describedby="basic-addon2"
                                    value={this.state.searchKey}
                                    onChange={(e) => this.setState({ searchKey: e.target.value })}
                                />
                                <InputGroup.Append>
                                    <Button onClick={() => {
                                        this.clearTable();
                                        this.props.clearRetrievedTableData();
                                    }}>Clear</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={filteredRows}
                            columns={[
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Product Group</b>,
                                    accessor: "tierFour",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Estimated Opportunity</b>,
                                    accessor: 'missedOpportunity',
                                    sortable: true,
                                    resizable: true,
                                    filterable: false,
                                    Cell: props => (
                                        <React.Fragment>
                                            <div
                                                data-for="tip"
                                                data-tip={`${priceFormatter(props.original.missedOpportunity).props.children}`}
                                                data-iscapture="true"
                                            >
                                                <ProgressBar now={((props.original.missedOpportunity * 100) / maxOpportunity)} />
                                            </div>
                                            <ReactTooltip
                                                id="tip"
                                                place={"top"}
                                                type={"dark"}
                                                effect={"solid"}
                                                multiline={true}
                                            />
                                        </React.Fragment>
                                    )
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier</b>,
                                    accessor: 'favorableVendorOneName',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props => (
                                        <React.Fragment>
                                            <div style={{ textAlign: "center" }}>
                                            <span>{props.original.favorableVendorOneName} </span>
                                            {props.original.favorableVendorOneName && <i className="fa fa-comment-alt" onClick={() => this.setState({
                                                selectedFavorableSupplier: props.original.favorableVendorOneName,
                                                selectedFavorableSupplierId: props.original.favorableVendorOneId,
                                                selectedProductGroup: props.original.tierFour,
                                                selectedProductGroupId: props.original.tierFourId,
                                                showFeedbackModal: true
                                            })} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>}
                                            </div>
                                        </React.Fragment>
                                    )
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier</b>,
                                    accessor: 'favorableVendorTwoName',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props => (
                                        <React.Fragment>
                                            <div style={{ textAlign: "center" }}>
                                            <span>{props.original.favorableVendorTwoName} </span>
                                            {props.original.favorableVendorTwoName && <i className="fa fa-comment-alt" onClick={() => this.setState({
                                                selectedFavorableSupplier: props.original.favorableVendorTwoName,
                                                selectedFavorableSupplierId: props.original.favorableVendorTwoId,
                                                selectedProductGroup: props.original.tierFour,
                                                selectedProductGroupId: props.original.tierFourId,
                                                showFeedbackModal: true
                                            })} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>}
                                            </div>
                                        </React.Fragment>
                                    )
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier</b>,
                                    accessor: 'favorableVendorThreeName',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props => (
                                        <React.Fragment>
                                            <div style={{ textAlign: "center" }}>
                                            <span>{props.original.favorableVendorThreeName} </span>
                                            {props.original.favorableVendorThreeName && <i className="fa fa-comment-alt" onClick={() => this.setState({
                                                selectedFavorableSupplier: props.original.favorableVendorThreeName,
                                                selectedFavorableSupplierId: props.original.favorableVendorThreeId,
                                                selectedProductGroup: props.original.tierFour,
                                                selectedProductGroupId: props.original.tierFourId,
                                                showFeedbackModal: true
                                            })} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>}
                                            </div>
                                        </React.Fragment>

                                    )
                                },
                                {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier</b>,
                                    accessor: 'favorableVendorFourName',
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: props => (
                                        <React.Fragment>
                                            <div style={{ textAlign: "center" }}>
                                            <span>{props.original.favorableVendorFourName} </span>
                                            {props.original.favorableVendorFourName && <i className="fa fa-comment-alt" onClick={() => this.setState({
                                                selectedFavorableSupplier: props.original.favorableVendorFourName,
                                                selectedFavorableSupplierId: props.original.favorableVendorFourId,
                                                selectedProductGroup: props.original.tierFour,
                                                selectedProductGroupId: props.original.tierFourId,
                                                showFeedbackModal: true
                                            })} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>}
                                            </div>
                                        </React.Fragment>
                                    )
                                },
                                {
                                    Header: <b>Details</b>,
                                    accessor: 'details',
                                    sortable: false,
                                    resizable: true,
                                    filterable: false,
                                    Cell: props => <i className="fa fa-info-circle" onClick={() => {
                                        this.setState({
                                            show: true,
                                            suggestedCategoryId: props.original.tierFourId,
                                            suggestedCategoryName: props.original.tierFour
                                        });
                                        this.getProductList(props.original.tierFourId);
                                    }} style={{ color: '#0D47A1', cursor: 'pointer' }}></i>,
                                    style: { textAlign: "center" }
                                }
                            ]}
                            defaultSorted={[{ id: "missedOpportunity", desc: true }]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                    return {
                                        onClick: e => {
                                            this.props.selectedRowValuesInOutsidePurchasesByPGReference(rowInfo.index, rowInfo.original && rowInfo.original.tierFourId)
                                            this.props.handleProductGroupId(rowInfo.original && rowInfo.original.tierFourId);
                                        },
                                        style: { background: rowInfo.index === this.props.selectedTableRowIndexInOutsidePurchasesByPG ? "lightblue" : null }
                                    };
                                } else {
                                    return {};
                                }
                            }}
                        />
                    </div>
                </Card>
            </React.Fragment>
        );
    }
}
