import React from "react";
import ReactTable from "react-table-6";
import { Card, InputGroup, ProgressBar, FormControl, Button } from 'react-bootstrap';

import '../../../../assets/styles/OpportunityByMember.scss';

export default class OpportunityByMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKey: "",
            filteredSearchResults: [...this.props.opportunityByMember],
            isFilteredDataAvailable: true
        }
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.memberName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        filteredSearchResults: [...this.props.opportunityByMember],
        isFilteredDataAvailable: true
    });

    render() {
        let {
            searchKey,
            filteredSearchResults
        } = this.state;
        const { opportunityByMember } = this.props;

        let opportunities = opportunityByMember && opportunityByMember.map((item) => item.purchases);
        let maxOpportunity = opportunities && Math.max(...opportunities);

        return (
            <div className="opportunityByMemberContainer">
                <Card>
                    <Card.Header>Opportunity By Member</Card.Header>
                    <div style={{ padding: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <InputGroup>
                                <FormControl
                                    placeholder="Search for member info.."
                                    aria-label="Search for member info.."
                                    aria-describedby="basic-addon2"
                                    value={searchKey}
                                    onChange={(e) => this.searchTable(e, opportunityByMember)}
                                />
                            <InputGroup.Append>
                                <Button onClick={() => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                            </InputGroup>
                        </div>
                        <ReactTable
                            data={this.state.isFilteredDataAvailable ? this.props.opportunityByMember : []}
                            columns={[
                                {
                                    Header: <b>ID</b>,
                                    accessor: "memberId",
                                    sortable: true,
                                    resizable: true,
                                    Cell: rows =><div style={{textAlign:'center'}}>{rows.value}</div>

                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Member Name</b>,
                                    accessor: "memberName",
                                    sortable: true,
                                    resizable: true,
                                    style: { 'whiteSpace': 'unset' },
                                    Cell: rows =><div style={{textAlign:'center'}}>{rows.value}</div>

                                }, {
                                    Header: <b>Purchase</b>,
                                    accessor: "purchases",
                                    sortable: true,
                                    resizable: true,
                                    Cell: props => <ProgressBar
                                        now={(props.original.purchases * 100 / maxOpportunity) > 1 ?
                                            props.original.purchases * 100 / maxOpportunity : 1}
                                    />
                                }, {
                                    Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                    accessor: "leavingVendorName",
                                    sortable: true,
                                    resizable: true,
                                    style: {  'whiteSpace': 'unset', 'textAlign': 'center' },
                                    Cell: rows =><div style={{textAlign:'center'}}>{rows.value}</div>

                                }
                            ]}
                            defaultSorted={[
                                {
                                    id: "purchases",
                                    desc: true
                                }
                            ]}
                            defaultPageSize={25}
                            style={{ height: "400px" }}
                            className="-striped -highlight"
                        />
                    </div>
                </Card>
            </div>
        );
    }
}
