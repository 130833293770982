import React, { Component } from 'react'
import C3Chart from 'react-c3js';
import { Card } from 'react-bootstrap';

import 'c3/c3.css';
import '../../../assets/styles/C3.scss';

export default class C3 extends Component {
    render() {
        const { heading } = this.props;

        return (
            <div>
                {heading && <Card>
                    <Card.Header>{heading}</Card.Header>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '466px' }}>
                        <C3Chart {...this.props} />
                    </div>
                </Card>}
                {!heading && <C3Chart {...this.props} />}
            </div>
        )
    }
}
