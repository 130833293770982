import React from 'react';
import ReactExport from "react-export-excel";
import { Form, Col, ButtonGroup, Button } from 'react-bootstrap';
import { switchMonths } from '../../../../utils/formatters';
import Select from 'react-select';

import '../../../../assets/styles/Filters.scss';

export default class Filters extends React.Component {
    render() {
        const {
            period,
            handlePeriodReference,
            listOfYears,
            listOfMonthsForSelectedYear,
            handleYearReference,
            handleMonthReference,
            handleQuarterReference,
            handleVendorReference,
            growthRebate,
            salesRebateOverview,
            salesOpportunityByMember,
            salesOpportunityByBusinessGroup,
            salesOpportunityByProductGroup,
            isQuarterSelected,
            vendorOptions
        } = this.props;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
        
        return (
            <div className="filtersContainer">
                <div className="filterTitle">
                    {/* <p>{sessionStorage.getItem('imarkElectricalSupplierName')}</p> */}
                     <p>{this.props.supplierName}</p>
                </div>
                <div className="filterItems">
                    <Form.Group as={Col} id="group2">
                    <Form.Control
                            as="select"
                            onChange={(value) => handleVendorReference(value)}
                            id="select2"
                            style={{ minWidth: 100 }}
                        >
                            {vendorOptions.map((item) => {
                                return <option key={item} value={item.vendorId}>{item.vendorName}{item.vendorName === "All" ? null : `-${item.vendorId}`}</option>
                            })}
                        </Form.Control>
                    
                    </Form.Group>
                    <ButtonGroup className="yearGroup">
                        <Button
                            className={`btn ${period === "MTH" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="MTH"
                        >
                            MTH
                        </Button>
                        <Button
                            className={`btn ${period === "YTD" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="YTD"
                        >
                            YTD
                        </Button>
                        <Button
                            className={`btn ${period === "QTR" ? "btn-success" : "btn-info"}`}
                            onClick={(e) => handlePeriodReference(e)} style={{ fontSize: 12 }}
                            name="QTR"
                        >
                            QTR
                        </Button>
                    </ButtonGroup>
                    <Form.Group as={Col} id="group3">
                        <Form.Control
                            as="select"
                            // defaultValue={"2020"}
                            id="select2"
                            onChange={(e) => handleYearReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            {listOfYears.map((year) => {
                                return <option key={year} selected={this.props.year == year} value={year}>{year}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    {!isQuarterSelected && <Form.Group as={Col} id="group4">
                        <Form.Control
                            as="select"
                            defaultValue={listOfMonthsForSelectedYear[listOfMonthsForSelectedYear
                                .length - 1]}
                            id="select3"
                            onChange={(e) => handleMonthReference(e)}
                            style={{ minWidth: 100 }}
                        >
                            {listOfMonthsForSelectedYear.map((month) => {
                                return <option key={month} selected={month == this.props.month} value={`${month}`}>{switchMonths(month)}</option>
                            })}
                        </Form.Control>
                    </Form.Group>}
                    {isQuarterSelected && <Form.Group as={Col} id="group4">
                        <Form.Control
                            as="select"
                            defaultValue={"1"}
                            id="select4"
                            onChange={(e) => handleQuarterReference(e)}
                        >
                            <option value="1">QTR1</option>
                            <option value="2">QTR2</option>
                            <option value="3">QTR3</option>
                            <option value="4">QTR4</option>
                        </Form.Control>
                    </Form.Group>}
                    <ExcelFile element={<Button className="export" color="primary" style={{ fontSize: 12 }}>Export</Button>}>
                        <ExcelSheet data={growthRebate} name="Growth Rebate">
                            <ExcelColumn label="Supplier ID" value="vendorId" />
                            <ExcelColumn label="Supplier Name" value="vendorName" />
                            <ExcelColumn label="Program Name" value="programName" />
                            <ExcelColumn label="Criteria" value={(col) => col.criteria[0]} />
                            <ExcelColumn label="Level/Tier" value={(col) => col.tier} />
                            <ExcelColumn label="Actual Sales ($)" value={(col) => col.currentYearSales[0]} />
                            <ExcelColumn label="Target Sales ($)" value={(col) => col.targetSales[0]} />
                            <ExcelColumn label="Gap ($)" value={(col) => col.gapAmount[0]} />
                            <ExcelColumn label="Attainment (%)" value={(col) => col.attainmentPercent[0]} />
                        </ExcelSheet>
                        <ExcelSheet data={salesRebateOverview} name="Sales Overview">
                            <ExcelColumn label="Member Name" value="memberName" />
                            <ExcelColumn label="Verified Date" value={(col) => new Date(col.imarkLastBilledDate).toLocaleDateString('en-US')} />
                            <ExcelColumn label="Supplier Date" value={(col) => new Date(col.vendorLastBilledDate).toLocaleDateString('en-US')} />
                            <ExcelColumn label="Verified Sales" value={(col) => col.verifiedSales} />
                            <ExcelColumn label="Supplier Sales" value={(col) => col.vendorSales} />
                        </ExcelSheet>
                        <ExcelSheet data={salesOpportunityByMember} name="Sales Opportunity By Members">
                            <ExcelColumn label="Member Name" value="memberName" />
                            {/* <ExcelColumn label="Opportunity" value={(col) => col.missedOpportunity} /> */}
                        </ExcelSheet>
                        <ExcelSheet data={salesOpportunityByBusinessGroup} name="Sales Opportunity by Business Group">
                            <ExcelColumn label="Business Group/Tier 2" value="businessName" />
                            {/* <ExcelColumn label="Opportunity" value={(col) => col.opportunity} /> */}
                        </ExcelSheet>
                        <ExcelSheet data={salesOpportunityByProductGroup} name="Sales Opportunity By Product Group">
                            <ExcelColumn label="Product Group/Tier 4" value="tierFour" />
                            {/* <ExcelColumn label="Opportunity" value={(col) => col.missedOpportunity} /> */}
                        </ExcelSheet>
                    </ExcelFile>
                </div>
            </div>
        )
    }
}
