import React from 'react';
import {
    Link,
    Redirect,
    Route
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navbar, Nav, Button } from 'react-bootstrap';

import SupplierOverview from './Overview';
import SupplierConversion from './Conversion';

import imarkLogo from '../../assets/images/imarkLogo.jpg';
import contactUs from '../../assets/images/contactUs.svg';
import logoutImg from '../../assets/images/logoutImg.svg';

import { getVisitedTabId } from '../../utils/getVisitedTabId';
import { getStoredToken } from '../../utils/getStoredToken';

import { updateSelectedNavIndex, logout } from '../../actions';
import Axios from 'axios';

import '../../assets/styles/Navigation.scss';

class Supplier extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: getStoredToken() ? true : false
        }
    }

    static getDerivedStateFromProps(props) {
        console.log('Vendor ID in Supplier Component', props.match.params.id);
        return { supplierId: props.match.params.id, vendorId: props.match.params.id };
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);
    }

    setNavIndex = (idx) => this.props.updateSelectedNavIndex(idx);

    handleLogout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userType');
        this.props.logoutAPI();
        window.location.hash = '/';
    }

    handleDashboardLink() {
        Axios.get('/sso/switch-dashboard ', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'mode': 'cors',
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            },
        }).then((res) => {
            console.log(res)
            if (res.status == 200) {
                console.log(res.data.redirectUrl)
                window.location = (res.data.redirectUrl);
            } else {
            }
        })
    }

    render() {
        const { supplierId, isLoggedIn } = this.state;
        return (
            isLoggedIn ?
                <div>
                    <div className="navigationContainer">
                        <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
                            <Navbar.Brand>
                                <img src={imarkLogo} alt="noImage" height="32" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="navbar-nav mr-auto">
                                    <Link className="nav-link" to={'/supplier/' + supplierId + '/overview'}>Supplier Overview</Link>
                                    <Link className="nav-link" to={'/supplier/' + supplierId + '/conversion'}>Conversion</Link>
                                </Nav>
                                <Nav className="nav-right">
                                    {/* <Button className="demo-dashboard-btn" onClick={(e) => { this.handleDashboardLink() }}>{'https://imark-electrical.tredence.com/imark-electrical' === process.env.REACT_APP_API_ROOT_URL ? 'Demo Dashboard' : 'Go to Dashboard'}</Button>
                                    */}
                                    <div>
                                        {/* <img className="contact-us" style={{ cursor: 'pointer' }} src={contactUs} alt="noImage" height="20" /> */}
                                        <a href="mailto:imarkelectrical.helpdesk@tredence.com,imark.support@tredence.com" className="btn btn-link" data-placement="bottom" data-container="body" data-title="Contact Us"><i className="fas fa-envelope"></i></a>

                                        <img className="logout" style={{ cursor: 'pointer' }} src={logoutImg} alt="noImage" height="20"
                                            onClick={() => this.handleLogout()}
                                        />
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    <div className="supplierContent">
                        <Route exact path="/supplier/:id/overview" component={SupplierOverview} />
                        <Route exact path="/supplier/:id/conversion" component={SupplierConversion} />
                    </div>
                </div>
                : <Redirect to="/" />
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedNavIndex: state.navigation.selectedNavIndex,
        isLoggedIn: state.authentication.isLoggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,
        logoutAPI: logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
