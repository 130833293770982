import {
    FETCH_SELECTED_NAV_INDEX,
    UPDATE_SELECTED_NAV_INDEX,
} from '../actions/types';

const initialState = {
    loading: false,
    selectedNavIndex: 0,
    error: ""
}

export default function reducerNavigate(state = initialState, action) {
    switch(action.type) {
        case FETCH_SELECTED_NAV_INDEX:
            return {
                ...state,
                loading: false,
                error: ""
            }

        case UPDATE_SELECTED_NAV_INDEX:
            return {
                ...state,
                loading: false,
                selectedNavIndex: action.payload,
                error: ""
            }

        default: return state;
    }
}