import React from 'react';
import './TermsOfUse.css';

class TermsOfUse extends React.Component {
    state = {
        accepted: false
    }

    handleCheckboxChange(e) {
        this.setState({ accepted: e.target.checked })
    }

    render() {
        return (
            <div className='terms-dialog' role='document' >
                <div className='modal-content terms-content' style={{width:'1220px'}}>
                    <div className='modal-header'>
                        {/* <h5 className='modal-title'>
                            TERMS OF USE
                        </h5> */}
                        <h2>
                          END USER LICENSE AGREEMENT
                        </h2>
                        <button
                            type='button'
                            className='close'
                            onClick={(e) => {
                                this.closeModal();
                            }}
                        >
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>
                    <div className='modal-body terms-body'>
                        {/* <h4>
                            EXACTUS ADVISORS LLP
                            WEBSITE TERMS OF USE AGREEMENT
                        </h4>
                        <div>
                            <b>1.	Read This:</b> This Website Terms of Use Agreement (“Agreement” or “Terms of Use”) is made by and between EXACTUS ADVISORS LLP (hereinafter "Company"), an Illinois limited liability partnership, with offices at 875 N. Michigan Ave., 31st Floor, Chicago, Illinois 60611, and you, the user (“you”, “your” or “User”).
                            This Agreement contains the complete terms and conditions that govern the use of the Company’s website(s) ("Website" or "Websites" or "Site").
                            BY CLICKING THE "I AGREE" BUTTON BELOW OR BY ACCESSING, VISITING, BROWSING, USING, OR ATTEMPTING TO INTERACT WITH OR USE ANY PART OF THIS WEBSITE, I AGREE THAT I HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE WEBSITE’S TERMS OF USE.
                            I FURTHER AGREE THAT EXACTUS ADVISORS LLP IS AN AUTHORIZED AGENT OF IMARK Group AND IS ACTING ON ITS BEHALF.  I VOLUNTARILY CONSENT TO PROVIDE CERTAIN OF MY INFORMATION TO EXACTUS ADVISORS LLP OR ITS SUBCONTRACTORS IN FURTHERANCE OF MY ACCESSING THE WEBSITE.  I UNDERSTAND MY CONSENT AND THE PROVISION OF CERTAIN INFORMATION (INCLUDING PERSONAL INFORMATION) TO EXACTUS ADVISORS LLP AND ITS TREATMENT, PROCESSING AND PROTECTION OF MY INFORMATION SHALL BE SUBJECT TO AND GOVERNED BY THE EXACTUS ADVISORS LLP’S PRIVACY POLICY.
                            IF I DO NOT AGREE TO BE BOUND BY THE TERMS OF USE (WHICH INCLUDES BY REFERENCE THE PRIVACY POLICY,) I WILL NOT CLICK “I AGREE” OR ACCESS OR USE ANY PART OF THIS WEBSITE. I UNDERSTAND THAT THE COMPANY RESERVES THE RIGHT, WITH OR WITHOUT NOTICE, TO MAKE CHANGES TO THIS AGREEMENT AT COMPANY'S SOLE DISCRETION.  MY CONTINUED USE OF ANY PART OF THIS WEBSITE OR THE SERVICES CONSTITUTES MY ACCEPTANCE OF SUCH CHANGES.

                        </div>
                        <br></br>
                        <div>
                            <b>2.	Access to This Site.</b>  To access the Site, Site resources, links or other content, you may be asked to provide certain registration details or other information. It is a condition of your use of this Site that all the information you provide will be correct, current, and complete. If Company believes the information you provide is not correct, current, or complete, Company has the right to deny access to this Site, or to any of its resources, and to terminate or suspend your access at any time.

                        </div>
                        <br></br>
                        <div>
                            <b>3.	Applicable Use of Site.</b>  You may use this Site only for purposes expressly permitted by this Agreement. As a condition of your use of Company's Websites, you warrant to Company that you will not use the Websites for any purpose that is unlawful, immoral, or otherwise prohibited by these terms, conditions, and notices.
                        </div>
                        <br></br>
                        <div>
                            <b>4.	No Unlawful Access.</b> You agree that you will not use Company's Website in any manner that could in any way disable, overburden, damage, or impair the Website or otherwise interfere with any other party's use and enjoyment of the Website. You further agree that you will not obtain, or attempt to obtain, any materials, content, or information by any means not expressly made available or provided for through the authorized use of the Websites.

                        </div>
                        <br></br>
                        <div>
                            <b>5.	Use Limitation.</b> Company's Websites are for professional purposes only.  All other uses are not authorized and must receive the prior express written permission of an authorized representative of the Company in each instance, which permission will be at Company’s sole and absolute discretion. You may not modify, copy, distribute, display, send, perform, reproduce, publish, license, create derivative works of, transfer, sell, or otherwise infringe upon any intellectual property rights related to any information, content, software, products or services obtained from or otherwise connected to Company's Websites, in whole or in part.
                        </div>
                        <br></br>
                        <div>
                            <b>6.	Proprietary Information.</b>  All content (except Customer Data, defined below) found on the Company Websites (the "Content") is considered the copyrighted and trademarked intellectual property of Company, or of the party that created and/or licensed the Content to Company. No rights or title to any of the Content contained on any Company Website shall be considered transferred or assigned to the User at any time. Subject to all applicable laws, you agree that you will not copy, distribute, republish, modify, create derivative works of, or otherwise use the Content in any unauthorized way, without the prior written consent of Company in each instance, except that you may print out and/or save one copy of the Content for your personal use only. “Customer Data” is defined as information, data and other content, in any form or medium, that is collected, downloaded, managed, stored, transmitted, or otherwise received, directly or indirectly from Customer, User, or End-User by or through the Services.

                        </div>
                        <br></br>
                        <div>
                            <b>7.	Aggregate Data.</b> Company may monitor the use of authorized users and customers and use the information gathered in a de-identified, aggregate and anonymous manner. You agree that Company may use and publish such information, provided that such information does not incorporate any identifiable Customer Data and/or identify you.  See Company’s Privacy Policy for more details.
                        </div>
                        <br></br>
                        <div>
                            <b>  8.	Hyperlinking.</b> This Site may be hyperlinked to and by other websites which are not maintained by, or related to, Company. Hyperlinks to such sites are provided as a service to Users and are not sponsored by, endorsed or otherwise affiliated with this Site or Company. Company has not reviewed any or all of such sites and is not responsible for the content of any linking sites, and any links made directly from a Company Website to another web page should be accessed at the User's own risk. Company makes no representations or warranties about the content, completeness, quality or accuracy of any such website, and as such, shall not be liable in connection with any loss, damage, cost or injury associated with any access thereto via this Site.
                        </div>
                        <br></br>
                        <div>
                            <b>9.	Use of Data Inputting Services. </b>Company's Website contains data fields and other data inputting facilities designed to allow you to effectively use the Site by entering your Customer Data (collectively, "Data Inputting Services").  Among other actions, when using a Data Inputting Service, you agree that you will not post, send, submit, publish, or transmit in connection with this Site, or cause to be posted, sent, submitted, published or transmitted, any material that:
                            <p></p>(a)	you do not have the right to post, including without limitation any proprietary material of any third party protected by intellectual property laws (or by rights of privacy or publicity);
                            <p></p>(b)	advocates or could reasonably serve to encourage, either directly or indirectly, any illegal or immoral activity, or discusses an intent to commit an illegal act or violate any law, rule, or regulation;
                            <p></p>(c)	is vulgar, obscene, pornographic, incendiary, or indecent;
                            <p></p>(d)	threatens or abuses others;
                            <p></p>(e)	is libelous or defamatory towards others;
                            <p></p>(f)	is racist, abusive, harassing, threatening or offensive;
                            <p></p>(g)	seeks to exploit or harm children by exposing them to inappropriate content, or asking for personally identifiable details or information;
                            <p></p>(h)	harvests or otherwise collects information about others, including e-mail addresses, financial information or other personally identifying information, without their prior express consent in each instance;
                            <p></p>(i)	impersonates or misrepresents your connection to any other entity or person or otherwise manipulates or forges headers or identifiers to disguise the origin of content;
                            <p></p>(j)	falsifies or deletes any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is permissibly uploaded (e.g., copyright, trademark or patent notices);
                            <p></p>(k)	advertises any commercial endeavor (e.g., offering for sale products or services) or otherwise engages in any commercial activity (e.g., conducting raffles or contests, displaying sponsorship banners, and/or soliciting goods or services) except as may be specifically authorized on the Website;
                            <p></p>(l)	solicits funds, advertisers or sponsors for any purpose;
                            <p></p>(m)	includes programs that contain viruses, worms and/or Trojan horses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications device;
                            <p></p>(n)	disrupts the normal flow of dialogue, causes a screen to scroll faster than other users are able to type, or otherwise acts in a way which affects the ability of other people to engage in real-time activities via the Website;
                            <p></p>(o)	amounts to a pyramid or other like scheme, including without limitation contests, chain letters, and surveys;
                            <p></p>(p)	disobeys any policy or regulations including any code of conduct or other guidelines established from time to time regarding the use of this Site and/or any networks connected to this Site; or
                            <p></p>(q)	contains hyperlinks to other sites that contain content that falls within the scope of this Section.
                            You acknowledge that any materials uploaded to the Website may be subject to posted limits on use, reproduction, and/or dissemination, and you are responsible for abiding by such limitations with respect to your submissions, including any downloaded materials.
                            Notwithstanding these rights, you remain solely responsible for the content of your submissions. You acknowledge and agree that neither Company nor any third party that provides Content to Company will assume or have any liability for any action made by Company or such third party with respect to any submission.
                            You acknowledge that the Website may or may not pre-screen materials uploaded to the Data Inputting Service, yet the Website and its designees shall have the right, but not the obligation, in their sole discretion, to pre-screen, refuse, remove, or delete any Content that violates this Agreement or is otherwise objectionable as determined by the Website in its sole discretion.
                            The Website reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Data Inputting Services (or any part thereof) with or without notice. You agree that the Website will not be liable to you or any third party for any modification, suspension, or discontinuance of the Data Inputting Services.
                            WHILE THE WEBSITE EXPLICITLY PROHIBITS THE ABOVE CONDUCT, YOU ACKNOWLEDGE AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND CONTENT, AND THAT YOUR USE OF THE WEBSITE IS ENTIRELY AT YOUR OWN RISK, AND THAT THE WEBSITE SHALL HAVE NO LIABILITY OF ANY KIND FOR SUCH CONDUCT.
                            YOU UNDERSTAND AND AGREE THAT IF YOUR USE OF DATA INPUTTING SERVICES VIOLATES ANY OF THE ABOVE CODES OF CONDUCT, THE WEBSITE CAN SUSPEND AND/OR TERMINATE YOUR USE OF THE WEBSITE IMMEDIATELY WITHOUT PRIOR NOTICE AND WITHOUT ANY RIGHT OF REFUND, SET-OFF, OR HEARING.

                        </div>
                        <br></br>
                        <div>
                            <b>  10.	Right to Terminate Access.</b>  Company reserves the right to monitor use of this Site to determine compliance with these Terms of Use, as well as the right to edit, refuse to post, or remove any information or materials, in whole or in part, at its sole discretion. Company reserves the right to terminate your access to any or all of the Site at any time without notice for any reason whatsoever.
                        </div>
                        <br></br>
                        <div>
                            <b>11.	Disclosure Under Law.</b> Company reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process, or governmental request.

                        </div>
                        <br></br>
                        <div>
                            <b>12.	Personally Identifiable Information.</b> In an effort to preserve your privacy, Company agrees that it will treat any personally identifying information that you submit through this Site in accordance with the terms outlined in its Privacy Policy, as well as in conformance with all applicable laws, rules, and regulations.

                        </div>
                        <br></br>
                        <div>
                            <b>  13.	Disclaimer & Limitations on Liability.</b> You understand that Company cannot and does not guarantee or warrant that files available for downloading from the Company Websites will be free of viruses, worms, Trojan horses, or other code that may cause damage or harm to your computer(s) or network(s). You acknowledge that you will be solely responsible for implementing sufficient procedures and checkpoints to protect your computer(s) and network(s), and that you will maintain adequate means of backup of your personal data, external to this Website. Company further disclaims any responsibility to ensure that the Content located on its Websites is necessarily complete and up-to-date.
                            YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE CONTENT IS PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. COMPANY DISCLAIMS ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED ON ANY COMPANY WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF ANY CONTENT IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE USER ACKNOWLEDGES THAT THE CONTENT MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND COMPANY MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS SITE OR ITS CONTENT. COMPANY MAKES NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OF OTHERS AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.
                            COMPANY, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES WERE REASONABLY FORSEEABLE.
                            IN NO EVENT WILL THE COLLECTIVE LIABILITY OF COMPANY AND ITS SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS, REGARDLESS OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT, OR OTHERWISE), EXCEED THE GREATER OF $100 OR THE AMOUNT YOU HAVE PAID TO COMPANY FOR THE APPLICABLE CONTENT OR SERVICE OUT OF WHICH LIABILITY AROSE. ALL CLAIMS MADE BY YOU HEREUNDER MUST BE MADE WITHIN ONE YEAR OF THE ACTION TO WHICH SUCH CLAIM RELATES OR FOREVER BE BARRED.

                        </div>
                        <br></br>
                        <div>
                            <b>14.	Indemnity.</b> YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD COMPANY, ITS SUBSIDIARIES, AFFILIATES, LICENSORS, CONTENT PROVIDERS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, AND CONTRACTORS (COLLECTIVELY, THE “INDEMNIFIED PARTIES”) HARMLESS FROM ANY BREACH OF THESE TERMS OF USE BY YOU, INCLUDING ANY USE OF CONTENT OTHER THAN AS EXPRESSLY AUTHORIZED IN THESE TERMS OF USE. YOU AGREE THAT THE INDEMNIFIED PARTIES WILL HAVE NO LIABILITY IN CONNECTION WITH ANY SUCH BREACH OR UNAUTHORIZED USE, AND YOU AGREE TO INDEMNIFY ANY AND ALL RESULTING LOSS, DAMAGES, JUDGMENTS, AWARDS, COSTS, EXPENSES, AND ATTORNEYS' FEES OF THE INDEMNIFIED PARTIES IN CONNECTION THEREWITH. YOU WILL ALSO INDEMNIFY, DEFEND, AND HOLD THE INDEMNIFIED PARTIES HARMLESS FROM AND AGAINST ANY CLAIMS BROUGHT BY THIRD PARTIES ARISING OUT OF YOUR USE OF THE INFORMATION ACCESSED FROM THIS SITE.

                        </div>
                        <br></br>
                        <div>
                            <b>15.	Trademarks and Copyrights.</b> Trademarks, service marks, logos, trade names, and copyrighted works (hereinafter, "Intellectual Property") appearing on this Site are the property of Company or the party that provided the Intellectual Property to Company. Company and any party that provides Intellectual Property to Company retain all rights with respect to any of their respective Intellectual Property appearing in this Site and do not transfer at any time to user and/or any other third party. All contents of Company's Websites are © Exactus Advisors LLP 2021.

                        </div>
                        <br></br>
                        <div>
                            <b>16.	Security.</b> Any passwords used for this Site are for individual use only. You will be responsible for the security of your password(s) at all times. From time to time, Company may require that you change your password. You are prohibited from using any services or facilities provided in connection with this Site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password crackers, rootkits, Trojan horses, or network probing tools) is strictly prohibited. If you become involved in any violation of system security, Company reserves the right to release your account details to the system administrators of other websites and/or the authorities in order to assist them in resolving security incidents. Company reserves the right to investigate suspected violations of these Terms of Use. Company reserves the right to fully cooperate with any law enforcement authorities or court order requesting or directing Company to disclose the identity of anyone posting any e-mail messages, or publishing or otherwise making available any materials that are believed to violate these Terms of Use.
                            BY ACCEPTING THIS AGREEMENT YOU WAIVE ALL RIGHTS NOT SPECIFICALLY SET FORTH HEREIN, AND AGREE TO HOLD COMPANY HARMLESS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER COMPANY OR LAW ENFORCEMENT AUTHORITIES.

                        </div>
                        <br></br>
                        <div>
                            <b>17.	Disputes.</b> If there is any dispute about or involving these Terms of Use, the Website and/or any Communications Services, you agree that any dispute shall be governed by the laws of the State of Illinois, notwithstanding any principles of conflicts of law. You specifically consent to personal and exclusive jurisdiction by and venue in the state and federal courts located in Chicago, Illinois, in connection with any dispute between you and Company arising out of or involving this Agreement, the Website and/or any Communications Services.

                        </div>
                        <br></br>
                        <div>
                            <b> 18.	Miscellaneous.</b>   (a)	If any part of these Terms of Use is found by a court of competent jurisdiction to be unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of any remaining provisions.
                            <p></p>(b)	You agree that no joint venture, partnership, employment, or agency relationship exists between you and Company as a result of this Agreement or use of Company's Websites.
                            <p></p>(c)	These Terms of Use constitute the entire agreement among the parties relating to the subject matter hereof, and supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written between you and Company with respect to Company's Websites. Notwithstanding the foregoing, you may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy, guidelines, or rules that may apply when you use the Website or any of its Services. Company may revise these Terms of Use at any time by updating this posting. You should review the Agreement from time to time to determine if any changes have been made to the Agreement. Your continued use of the Website after any changes have been made to this Agreement signifies and confirms your acceptance of any changes or amendments to this Agreement.
                            <p></p>(d)	The failure of Company to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. Any waiver of this Agreement by Company must be in writing and signed by an authorized representative of the Company.
                            <p></p>(e)	The section titles in the Agreement are for convenience only and have no legal or contractual effect.
                            <p></p>(f)	Please report any violations of this Agreement to the Company info@exactusadvisors.com
                            <p></p>(g)	 If you have any questions regarding this Agreement, please contact us at szingsheim@exactusadvisors.com


                        </div>

                        <h4>
                            EXACTUS ADVISORS LLP
                            PRIVACY POLICY
                        </h4>
                        <i>Last Modified:  April 20, 2021</i>
                        <div>
                            We are deeply committed to the protection of your privacy and your Personal Information.  We have prepared this Privacy Policy to explain to you how we collect, use, and share Personal Information.  This Privacy Policy governs the Exactus Advisors LLP portal for and on behalf IMARK Group portal ("Site") and any other applications, interactive features, and resources offered by Exactus Advisors LLP (“Exactus”, “us” or “we”) through its Site, including on internet websites, mobile devices or other platforms (collectively, the "Service").
                            <p></p>Please read this Privacy Policy carefully, and if you have any questions, feel free to contact us.
                        </div>
                        <br></br>
                        <div>
                            <b>1.	Questions.</b>If you would like to ask questions or provide comments regarding this Privacy Policy you may do so by sending us an e-mail at szingsheim@exactusadvisors.com or by writing to us at Exactus Advisors LLP, Attn: Exactus Advisors LLP Client Service – Privacy, John Hancock Center
                            875 N. Michigan Avenue, 31st Floor | Chicago, IL 60611.
                            We will not disclose Personal Information to third parties for their direct marketing purposes without your consent. If, after providing consent, you would like to opt out of having your Personal Information disclosed to third parties for their direct marketing purposes, please let us know by sending us an e-mail at info@exactusadvisors.com  or by writing to us at Exactus Advisors LLP, Attn: Client Service – Privacy, John Hancock Center
                            875 N. Michigan Avenue, 31st Floor | Chicago, IL 60611
                            Please be sure to include your full name, e-mail address and mailing address and specifically state that you do not want your Personal Information shared with third parties for their direct marketing purposes.

                        </div>
                        <br></br>
                        <div>
                            <b>2.	Information You Choose to Submit.</b> If you register as a user of the Service for your organization, we may collect your name, e-mail address, as well as any other personally identifiable information that you choose to provide, including, without limitation, phone number and professional and employment affiliation (collectively, “Personal Information”). The decision to provide this information is optional.  However, if you choose not to provide the requested information, you may not be able to use some or all of the features of the Service.  We may also collect non-personal information that may relate to your professional organization or business, including transaction and buying history.
                            The Service provides tools for you to correct, update or edit your Personal Information.  You can edit your Personal and other information here: imarkelectrical@exactusadvisors.com , imarkplumbing@exactusadvisors.com, bluehawk@exactusadvisors.com


                        </div>
                        <br></br>
                        <div>
                            <b>3.	Information We Automatically Collect.</b>Usage Data.
                            We collect certain information to help us understand how our users use the Service (which we will refer to in this Privacy Policy, collectively, as “Usage Data”).  For example, each time you use the Service we may automatically collect information regarding your browser type, operating system, your internet Service Provider, the pages you view, and the time and duration of your visits to the Site.  We use this information to help us understand how our users use the Site and Service, and to enhance the services we offer.
                            Cookies and Web Beacons.  We use cookies (a small text file placed on your computer to identify your computer and browser) and may use web beacons (an electronic file placed on a web site that monitors usage) to improve the experience of the Service, such as pre-populating your username for easier login.  We do not use cookies or web beacons to collect Personal Information.  Most web browsers are initially set up to accept cookies.  You can reset your web browser to refuse all cookies or to indicate when a cookie is being sent.  However, certain features of the Service may not work if you delete or disable cookies.  Some of our Service Providers may use their own cookies and Web beacons in connection with the services they perform on our behalf.
                            Do Not Track.  The Service does not respond to web browser “do not track” signals or other mechanisms that provide consumers the ability to exercise choice regarding the collection of Personal Information about an individual consumer’s online activities over time.  Visit www.donottrack.us to find out more about “do not track.”

                        </div>
                        <br></br>
                        <div>
                            <b>4.	How We Use Information.</b>We may use your Personal Information for purposes of (a) maintaining or updating your account; (b) improving the Service; (c) providing client service; (d) notifying you of updates or changes to the Service; and (h) obtaining your feedback and input about the Service.  In addition, if you have opted into receiving other communications from us, we use your Personal Information for that purpose.
                            We will retain your Personal Information for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
                            We use Personal Information only in conformance and compliance with applicable law.

                        </div>
                        <br></br>
                        <div>
                            <b>5.	How We Share Your Information.</b>Other than as set forth in this Privacy Policy, except for aggregated information that we may share with third parties in a manner that does not link such information to any customer or individual, we will not share your Personal Information with third parties, unless we have your permission to do so.
                            We may sometimes use other businesses (“Service Providers”) to perform certain services for us, e.g., such as hosting and maintenance, data storage and management services, production and fulfillment, and analytics.  We provide our Service Providers with the information reasonably necessary for them to perform the services on our behalf.  Each Service Provider must agree to implement and maintain reasonable security procedures and practices appropriate to the nature of the information involved in order to protect information from unauthorized access, destruction, use, modification or disclosure.
                            We may disclose aggregated Usage Data in order to (a) describe the use of the Service to others, including existing or potential business partners, investors, lenders, financial advisors, and other third parties; (b) comply with laws, regulations, court orders or subpoenas or in response to a government request; or (c) protect or exercise our legal rights or defend against legal claims.  However, this aggregated data will not involve the disclosure of Personal Information nor personally identify you, your organization, or any other users of the Service.

                        </div>
                        <br></br>
                        <div>
                            <b>6.	Other Authorized Disclosures.</b>Notwithstanding anything in this Privacy Policy to the contrary, you understand and agree that Exactus may access, preserve and disclose any information we have collected about you or that you have submitted: (a) in response to subpoenas, court orders or a legal or regulatory process, or to establish, protect or exercise our legal rights or defend against legal claims; (b) to protect the rights and property of Exactus, its affiliates or the public; (c) if we believe it is necessary in order to investigate, prevent or take action regarding illegal activities, fraud, copyright violations, trademark violations or other violations of the personal or intellectual property rights of others or situations involving potential threats to the safety of any person; (d) if Exactus is acquired by or merged with another entity or in connection with a sale of our assets; (e) to facilitate services Exactus receives from its professional advisors, including lawyers, accountants or auditors; (f) to maintain and improve our services, functionality and overall user experience; (g) to improve Exactus’s website; and (h) to improve and facilitate client relationship management and marketing (including using your contact information to solicit feedback to improve services.)
                        </div>
                        <br></br>
                        <div>
                            <b>7.	Public Areas. </b>Your use of areas of the Service that are intended for interaction with others may reveal your username and may allow you to publish certain Personal Information about you.  By choosing to use these areas, (including the feedback area of the site) you understand and agree that anyone with access to those areas may access and forward any information about you that is contained in or that you post to those areas.  You should use care when disclosing your Personal Information for these purposes.
                        </div>
                        <br></br>
                        <div>
                            <b>8.	Links to Other Sites.</b>The Service may contain links to websites operated and maintained by third parties over which we have no control.  Privacy policies on such linked websites may be different from our Privacy Policy.  You access such linked websites at your own risk.  We encourage you to review the privacy policy of any such linked website you visit to understand that website’s collection, storage and use of your information.
                        </div>
                        <br></br>
                        <div>
                            <b>9.	Security.</b>We use standard security procedures and practices appropriate to the nature of the information in an effort to protect information from unauthorized access, destruction, use, modification or disclosure.  We also require our Service Providers to implement and maintain reasonable security procedures and practices appropriate to the nature of the information we share with them.  No data transmission over the Internet or any wireless network can be guaranteed to be 100% secure.  As a result, while we strive to protect Personal Information, we cannot and do not guarantee or warrant the security of any information you transmit to or from the Service, and you do so at your own risk.
                        </div>
                        <br></br>
                        <div>
                            <b>10.	Children's Privacy Statement.</b>We do not knowingly collect personally identifiable information from anyone under the age of 18.  If we become aware that we have inadvertently received personally identifiable information from a user under the age of 18, we will delete such information immediately from our records.  Because we do not knowingly collect any personally identifiable information from users under the age of thirteen 18, we also do not knowingly distribute such information to third parties.
                        </div>
                        <br></br>
                        <div>
                            <b>11.	Data Processing in the United States.</b>Please be aware that your Personal Information and communications may be transferred to and maintained on servers or databases located outside your state, province, or region.  By using our Service, you agree that the collection, use, transfer and disclosure of your Personal Information and communications will be governed by the applicable laws in the United States. If you are located outside of the United States, please be aware that the laws in the United States may not be as protective of your privacy as those in your location.
                        </div>
                        <br></br>
                        <div>
                            <b>12.	Changes.</b>Exactus may, in its sole and absolute discretion, change this Privacy Policy from time to time.  All changes to the Privacy Policy will be effective when posted, and we will provide notification of such changes to you via the Service or directly to your organization.
                        </div>
                        <br></br>
                        <i>Effective date:  October 5th, 2021.</i>
                        <br></br>
                        <br></br>
                        <br></br> */}
                          {/* <h2>
                          END USER LICENSE AGREEMENT
                        </h2> */}
                        <h4>PLEASE READ THIS END-USER LICENSE AGREEMENT ("EULA") CAREFULLY. BY INSTALLING OR USING THE PLATFORM THAT ACCOMPANIES THIS EULA, YOU AGREE TO THE TERMS HEREIN. IF YOU DO NOT AGREE, YOU SHOULD NOT INSTALL OR USE THIS PLATFORM.</h4>
                        <h3>Terms and conditions</h3>
                        <div>
                        This is an agreement between the <b>“End Users”</b> and <b>Tredence, Inc.,</b> a company incorporated under the laws of Delaware, having its principal place of business at 1900 Camden Ave #66, San Jose CA 95124 (hereinafter referred to as <b>“Company”,</b> which expression shall include its affiliates,
                        successors and assigns, subsidiaries) is THE SOLE OWNER OF THE PLATFORM APPLICATION
                        Rebate.ai <b>(Helps customers to manage and track their Rebate Earnings)</b> - Read carefully before
                        using this web/desktop application. By using this web/desktop application, you agree with these
                        terms and condition.</div>
                        <br></br>
                        <div>
                            <b>1.	</b><b>“Rebate.ai” </b>  (hereinafter referred to as <b>"Product"</b> for the sake of brevity), <b>Product</b> means the Company’s proprietary Platform application more fully described in <b>Annexure 1</b> of this Agreement, which is to be used in accordance with terms of this Agreement and in the manner as set out in the Documentation and shall include all Customizations and Enhancements thereto.

                        </div>
                        <br></br>
                        <div>
                            <b>2.	</b>The Company is engaged in the business of data analytics and has developed Product, a Platform application which helps customers manage and optimize their rebate in a B2B procurement ecosystem.
                        </div>
                        <br></br>
                        <div>
                            <b>3.   </b> 3.	The Company agrees to license Product and the End User agrees to use the same in accordance with the terms set out in this Agreement. Where required following terms shall be construed as following:

                        </div>
                        <br></br>
                        <div>
                            <b>a.   </b> <b>“End User Data”</b> means any information, application, file, text or such other material that is uploaded into the Product only. Company has no access to End User’s any personally identifiable information (PII) data.
                        </div>
                        <br></br>
                        <div>
                            <b>b.   </b>	<b>“Permitted Systems”</b> means the systems used by the Customer to host the Product.

                        </div>
                        <br></br>
                        <div>
                            <b>c.	</b>	<b>“Intellectual Property Right(s)”</b> means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks (in each case, whether registered or not), registered designs, database right, design right, moral right or any other property rights that grant similar rights as the foregoing, whether registered or not, anywhere in the world which also included all pre-existing intellectual property owned by Company;
                        </div>
                        <br></br>
                        <div>
                            <b>d.	</b>	<b>Proprietary Information</b> means: (i) the Product and the Documentation and any complete or partial copies thereof (including any modifications or enhancements thereto); (ii) the concepts, techniques, ideas, and know-how embodied and expressed in the Product, including its architecture, structure, sequence, and organization; and (iii) any inventions, drawings, performances, Platform, databases and methodologies in any manner related to the Product and the Documentation;
                        </div>
                        <br></br>
                        <div>
                            <b>e.	</b>	<b>Documentation</b> means the period specified in attached SOW;
                        </div>
                        <br></br>
                        <div>
                            <b>f.	</b>	<b>Term</b> means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks (in each case, whether registered or not), registered designs, database right, design right, moral right or any other property rights that grant similar rights as the foregoing, whether registered or not, anywhere in the world which also included all pre-existing intellectual property owned by Company;
                        </div>
                        <br></br>
                        <div>
                            <b>g.	</b>	<b>Use</b> or <b>Used  </b> means to directly or indirectly activate the processing capabilities of the Product, and/or execute, access, utilize, or display information resulting from such capabilities; and
                        </div>
                        <br></br>
                        <div>
                            <b>h.	</b>	<b>“Intellectual Property Right(s)”</b> means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks (in each case, whether registered or not), registered designs, database right, design right, moral right or any other property rights that grant similar rights as the foregoing, whether registered or not, anywhere in the world which also included all pre-existing intellectual property owned by Company;
                        </div>
                        <br></br>
                        <div>
                            <b>i.	</b>	<b>	End User </b>means an individual who Use the Product or who has been supplied user identifications and passwords (in compliance with the requirements specified in this regard by the Company). Tredence do not limit usage of license to certain number of Users only, provided they are allowed to shall use Product as per this Agreement.
                        </div>
                        <br></br>

                        <div>
                            <b>  4.	</b> Company hereby grants the End User a revocable, non-exclusive, non-transferable, non-sublicensable, worldwide, limited license to install the Product on the Permitted Systems and Use the Product and the corresponding Documentation, for the Term, subject to the terms of this Agreement and attached SOW.
                        </div>
                        <br></br>
                        <div>
                            <b>  5.	</b> End User shall not do any modifications to the Product and its features.
                        </div>
                        <br></br>
                        <div>
                            <b>  6.	</b> Nothing set forth in this Agreement shall restrict the Company from collecting, storing, analyzing and using information related to the performance and usage of the Product by the End User for any purpose it deems appropriate including but not limited to tracking the End User’s usage of the Product, tracking Product performance, marketing and sales materials, benchmarking studies and further development of the Product. Such information collected shall be construed to be Proprietary Information.
                        </div>
                        <br></br>
                        <div>
                            <b>  7.	</b> End User agrees that at all times End User retain responsibility of all the Data that it uploads, downloads, posts, emails, transmits, stores, shares or otherwise makes available through its use of the Product.
                        </div>
                        <br></br>
                        <div>
                            <b>  8.	</b> End user cannot use this Product without accepting the terms and conditions of this End User License Agreement (EULA). Once you open or Use the Product, it is considered that you accepted all of the terms and conditions of the EULA and you will be licensed to use the Product for your Single License only, solely in connection with Product set out in Annexure 1.
                        </div>
                        <br></br>
                        <div>
                            <b>  9.	</b> This is first version of the Product and Company has full rights of launching new versions of Product and can use its rights to suspend or close the license for the Product at any time with 2 weeks of prior notice.
                        </div>
                        <br></br>
                        <div>
                            <b>  10.	</b> Upon your acceptance of the EULA, it is considered that you agreed to changing the terms and conditions of EULA at any time by Company without your separate acceptance. When such changes are made to EULA, you shall be bound to the updated version.
                        </div>
                        <br></br>
                        <div>
                            <b>  11.	</b> All the rights in the Product are owned by Company solely and any third parties or End User shall not reserve any rights pertaining to the said Product by any which ways.
                        </div>
                        <br></br>
                        <div>
                            <b>  12.	</b> Under all conditions, Company can stop distributing the Product at its sole decision and there shall be no dispute or claims arising pertaining to the losses of any nature towards Company by the end user.
                        </div>
                        <br></br>
                        <div>
                            <b>  13.	</b> No warranty; limitations of liability
                            <br></br>
                            <br></br><b>a.</b>	 Company provides the Product "as is," "with all faults" and "as available." Company does not guarantee the accuracy or timeliness of information deemed to be available from the use of Product. Your use of the Product is at your sole risk. Company gives no express warranties, guarantees or conditions. Company excludes any implied warranties including those of merchantability, fitness for a particular purpose, workmanlike effort, title and non-infringement.
                            <br></br>
                            <br></br><b>b.</b>	Company makes no representations and shall not be liable about the suitability, reliability, availability, timeliness, or viruses or other harmful components (if any) related to the Product.
                            <br></br><b>c.</b>	You understand and agree that Company shall not be liable to you for any direct, indirect, punitive, incidental, special consequential damages or any damages whatsoever including, without limitation damages for loss of use, data, or profits, arising out of or in any way connected with the use or performance of the Product, with delay or inability to use the Product, or the provision or failure to provide the Product, whether based on contract, tort, negligence, strict liability or otherwise, even if Company has been advised of the possibility of damages.
                            <br></br>
                            <br></br><b>d.</b>	Each provision of this clause excluding or limiting liability shall be construed separately, applying and surviving even if for any reason one or other of these provisions is held inapplicable or unenforceable in any circumstances and shall remain in force notwithstanding the expiration or termination of this agreement.
                            <br></br>

                        </div>
                        <br></br>
                        <div>
                            <b>  14.	</b> At all times your information provided in conjunction with the use of the Application will be treated in accordance with Privacy Policy of Company which can be viewed at  xxxxxxxxx on the said websiteAnnexure - I.
                        </div>
                        <br></br>
                        <div>
                            <b>  15.	</b> California state law governs the interpretation of this Agreement, regardless of conflict of laws principles. Any dispute arising out of or related to this agreement shall exclusively fall within the jurisdiction of courts at San Jose.
                        </div>
                        <br></br>
                        <div>
                            <b>  16.	</b> The license is a paid license with the detailed break-up of cost and duration documented in the attached SOW.
                        </div>
                        <br></br>
                        <div>
                            <b>  17.	</b> Company does not hold any responsibility of loss of data by End User due to natural disaster or disaster due to User's mistake.
                        </div>
                        <br></br>


                         <h3>Annexure 1 – Privacy Policy</h3>

                         <div>
                            <b>OUR PRIVACY POLICY</b>

                            <br></br>
                            At Tredence, we are committed and obligated to protect your privacy and take reasonable precautions to ensure that any information you provide with us remains secret and private.
                         </div>
                         <br></br>
                         <div>
                            <b>We respect and value your privacy</b>
                            <br></br>
                            The information you provide on the website inquiry forms is kept private and confidential.
                            Your contact information (name, phone number, and email address) will only be used by us to communicate with you about our products, services, and solutions. Without your permission, your personal information will never be shared, sold, leased, or provided to any third-party companies for marketing. We use appropriate policies and tools to prevent unauthorized access, use, and/or disclosure of your personal data/information.
                            We may need to share your personal information with approved consultants, affiliates, and business partners to offer our products, services, and solutions.
                         </div>
                         <br></br>
                         <div>
                            <b>Your information (Cookies) is used to provide you with a better service </b>
                            <br></br>
                            Our website (www.rebate.ai) uses cookies to provide a personalized and meaningful user experience. We solely use the data gathered by cookies to help us enhance our website’s products, services and solutions. The cookies do not provide us remote access to your systems or access to any personal information you have not shared with us. We will never reveal your location or any other customer-specific information to the public.
                            Cookies are saved and restricted to the memory of your browser or system. You can turn them off in your browser's settings. However, if you disable cookies, you may not be able to use our website to its full potential.
                         </div>
                         <br></br>
                         <div>
                            <b>Links to external websites</b>
                            <br></br>
                            Any third-party links on our website are provided only for your ease and convenience and must be used at your risk. Tredence Inc. is not responsible for these third-party websites' content, features, security/privacy policies in any way.
                            </div>                        You have the right to request copies of your personal data and how and where the data is maintained and why it is kept.
                            </div>
                         <br></br>
                         <div>
                            <b>Changes to our Privacy Policy</b>
                            <br></br>
                            Tredence Inc. shall review, revise, or update its privacy policy regularly. We'll also make sure that our most current privacy policy is accessible on our website.
                            <br></br>
                         </div>
                         <div>
                            <br></br>
                            <b>How to get in touch</b>
                            <br></br>
                            We are GDPR compliant and rigorously adhere to our privacy policies. If you have any queries regarding our privacy policy, please get in touch with us by <b>email at: rebate.ai@tredence.com </b>
                         <br></br>
                         <div>
                            <b>GDPR Compliance (For European Union visitors to our website)</b>
                            <br></br>
                             Basic identity information submitted by you on our websites, such as your name, contact number, email address, company name and country, as well as web/online data such as your IP address, cookies, and system location, is maintained safely and privately. This information will only be used to communicate with current and prospective clients about our products, services, and solutions.
                            Unless a longer retention time is required by law or for closely connected legitimate business objectives, we will not keep this personal information for any longer than is necessary to fulfill the goals outlined in this privacy policy.

                            <br></br>
                      </div>
                       <br></br>
                        <br></br>
                        <h4>
                        <input type="checkbox" style={{height: '18px', width: '18px',  }} onChange={this.handleCheckboxChange.bind(this)} />
                        {' '}
                        <label > Yes, I agree to the Terms of Use stated above. </label>
                        </h4>

                    </div>
                    <div className='modal-footer'>
                        <button
                            type='button'
                            disabled={!this.state.accepted}
                            className='btn btn-secondary'
                            onClick={(e) => {
                                this.props.acceptTerms();
                            }}
                        >
                            Agree
                        </button>
                        <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={(e) => {
                                this.props.closeModal();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

        );
    }
}
export default TermsOfUse;
