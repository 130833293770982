import React from 'react';
// import { Route } from 'react-router';
import { PrivateRoute } from '../../PrivateRoute';
import {
    Link,
    Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import Axios from 'axios';
import { BASE_URL } from '../../index';

import CorporateOverview from './Overview';
import CorporateVendorData from './VendorData';
import CorporateOpportunity from './Opportunity';
import CorporateFeedBack from './Feedback';
import CorporateConversion from './conversion';
import CorporateCompliance from './Compliance';

import imarkLogo from '../../assets/images/imarkLogo.jpg';
import contactUs from '../../assets/images/contactUs.svg';
import logoutImg from '../../assets/images/logoutImg.svg';

import { getStoredToken } from '../../utils/getStoredToken';
import { corporateNavigationItems } from '../../utils/navigationLinks';

import { updateSelectedNavIndex, logout } from '../../actions';

import '../../assets/styles/Navigation.scss';

class Corporate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: getStoredToken() ? true : false
        }
    }

    setNavIndex = (idx) => this.props.updateSelectedNavIndex(idx);

    handleDashboardLink() {
        Axios.get('/sso/switch-dashboard ', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'mode': 'cors',
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
            },
        }).then((res) => {
            console.log("responseeee",res)
            if (res.status == 200) {
                console.log(res.data.redirectUrl)
                window.location = (res.data.redirectUrl);
            } else {
            }
        })
    }


    handleLogout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userType');
        if (!localStorage.getItem('jwtToken')) {
            this.props.logoutAPI()
            this.setState({ isLoggedIn: getStoredToken() ? true : false })
        }
    }

    render() {
        const { isLoggedIn } = this.state;
        const {
            selectedNavIndex,
            // isLoggedIn
        } = this.props;

        return (
            isLoggedIn ?
                <div>
                    <div className="navigationContainer">
                        <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
                            <Navbar.Brand>
                                <img src={imarkLogo} alt="noImage" height="32" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="mr-auto">
                                    {corporateNavigationItems.map((navigationItem, index) => {
                                        return (
                                            <div key={index}>
                                                <Link
                                                    className="nav-link"
                                                    to={navigationItem.link}
                                                    onClick={() => {
                                                        this.setNavIndex(navigationItem.id)
                                                        window.scrollTo({ top: 0, left: 0, scrollBehaviour: 'smooth' })
                                                    }}
                                                >
                                                    {navigationItem.title}
                                                    <div className={`${navigationItem.id === selectedNavIndex ? 'selectedNavTab' : ''}`}></div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Nav>
                                <Nav className="nav-right">
                                    <Button className="demo-dashboard-btn" onClick={(e) => { this.handleDashboardLink() }}>{'https://imark-electrical.tredence.com/imark-electrical' === process.env.REACT_APP_API_ROOT_URL ? 'Demo Dashboard' : 'Go to Dashboard'}</Button>
                                    <div>
                                        {/* <img className="contact-us" style={{ cursor: 'pointer' }} src={contactUs} alt="noImage" height="20" /> */}
                                        <a href="mailto:imarkelectrical.helpdesk@tredence.com,imark.support@tredence.com" className="btn btn-link" data-placement="bottom" data-container="body" data-title="Contact Us"><i className="fas fa-envelope"></i></a>
                                        <img className="logout" style={{ cursor: 'pointer' }} src={logoutImg} alt="noImage" height="20"
                                            onClick={() => this.handleLogout()}
                                        />
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                    <div className="memberContent">
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate" component={CorporateOverview} />}
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate/overview" component={CorporateOverview} />}
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate/verified" component={CorporateVendorData} />}
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate/opportunity" component={CorporateOpportunity} />}
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate/feedback" component={CorporateFeedBack} />}
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate/conversion" component={CorporateConversion} />}
                        {localStorage.getItem('userType') && localStorage.getItem('userType') === 'CORPORATE' && <PrivateRoute exact path="/corporate/compliance" component={CorporateCompliance} />}
                    </div>
                </div>
                : <Redirect to="/" />
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedNavIndex: state.navigation.selectedNavIndex,
        isLoggedIn: state.authentication.isLoggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,
        logoutAPI: logout
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Corporate);
