// export const BASE_URL = 'http://localhost:3000/imark-electrical';
// export const BASE_URL = 'https://omni.tredence.com/api-electrical';
//  export const BASE_URL = 'https://imark-electrical.tredence.com/imark-electrical';
//   export const BASE_URL = 'https://imark-electrical.tredence.com/ie-demo';
  export const BASE_URL = 'https://imark-electrical.tredence.com/ie-uat';


export const memberNavigationItems = [
    {
        'id': 1,
        'title': 'Member Overview',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/member/overview' : `/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/overview`}`
    }, {
        'id': 2,
        'title': 'IMARK Remits',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/member/verified' : `/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/verified`}`
    }, {
        'id': 3,
        'title': 'Supplier Invoices',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/member/invoices' : `/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/invoices`}`
    }, {
        'id': 4,
        'title': 'Member Invoices',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/member/purchase-orders' : `/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/purchase-orders`}`
    }, {
        'id': 5,
        'title': 'Conversion Opportunity',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/member/conversion' : `/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/conversion`}`
    }
]

export const supplierNavigationItems = [
    {
        'id': 1,
        'title': 'Supplier Overview',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/supplier/overview' : `/supplier/${localStorage.getItem('imarkElectricalSupplierId') && localStorage.getItem('imarkElectricalSupplierId')}/overview`}`
    }, {
        'id': 2,
        'title': 'Conversion',
        'link': `${process.env.REACT_APP_API_ROOT_URL !== BASE_URL ? '/supplier/conversion' : `/supplier/${localStorage.getItem('imarkElectricalSupplierId') && localStorage.getItem('imarkElectricalSupplierId')}/conversion`}`
    }
]

export const corporateNavigationItems = [
    {
        'id': 1,
        'title': 'Corporate Overview',
        'link': '/corporate/overview'
    }, {
        'id': 2,
        'title': 'Supplier Data',
        'link': '/corporate/verified'
    }, {
        'id': 3,
        'title': 'Opportunity',
        'link': '/corporate/opportunity'
    }, {
        'id': 4,
        'title': 'Feedback',
        'link': '/corporate/feedback'
    },
    {
        'id': 5,
        'title': 'Conversion Opportunity',
        'link': '/corporate/conversion'
    },
    {
        'id': 6,
        'title': 'Compliance',
        'link': '/corporate/compliance'
    }
]
