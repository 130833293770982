import {
    MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB,
    MEMBER_PURCHASE_WITHIN_IMARK,
    MEMBER_PURCHASE_OUTSIDE_IMARK,
    MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY,
    MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB,
    MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH,
    MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES,
    MEMBER_GET_INVOICE_DATA,
    MEMBER_GET_PURCHASED_ORDERS,
    MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS,
    MEMBER_GET_TERMINATED_VP_OPPORTUNITIES
} from '../actions/types';

export default function reducerMember(state = {
    isFetching: true
}, action) {
    let isFetching = true;
    switch (action.type) {
        /* ******************************** MEMBER - OVERVIEW ******************************** */
        case MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB:
            // console.log('MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB ==>>', action.payload);
            isFetching = false;
            return { ...state, getCardsDataForMemberDataTab: action.payload, isFetching };
        case MEMBER_PURCHASE_WITHIN_IMARK:
            // console.log('MEMBER_PURCHASE_WITHIN_IMARK ==>>', action.payload);
            isFetching = false;
            return { ...state, getMemberPurchaseWithinImarkValue: action.payload, isFetching }
        case MEMBER_PURCHASE_OUTSIDE_IMARK:
            // console.log('MEMBER_PURCHASE_OUTSIDE_IMARK ==>>', action.payload);
            isFetching = false;
            return { ...state, getMemberPurchaseOutsideImarkValue: action.payload, isFetching }
        case MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY:
            // console.log('MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY ==>>', action.payload);
            isFetching = false;
            return { ...state, getMemberOutsidePurchaseByCategory: action.payload, isFetching }

        /* ******************************* MEMBER - IMARK VERIFIED ***************************** */
        case MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB:
            // console.log('MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB ==>>', action.payload);
            isFetching = false;
            return { ...state, getCardsDataForIMARKVerifiedDataTab: action.payload, isFetching }
        case MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH:
            // console.log('MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH ==>>', action.payload);
            isFetching = false;
            return { ...state, getVerifiedRebateTrendsGraph: action.payload, isFetching }
        case MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES:
            // console.log('MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES ==>>', action.payload);
            isFetching = false;
            return { ...state, getbHverifiedVendorPartnerPurchases: action.payload, isFetching }
        default:
            return state;

        /* ********************************* MEMBER - INVOICES ********************************* */
        case MEMBER_GET_INVOICE_DATA:
            // console.log('MEMBER_GET_INVOICE_DATA ==>>', action.payload);
            isFetching = false;
            return { ...state, getInvoiceData: action.payload, isFetching }

        /* ****************************** MEMBER - PURCHASE ORDERS ***************************** */
        case MEMBER_GET_PURCHASED_ORDERS:
            // console.log('MEMBER_GET_PURCHASED_ORDERS ==>>', action.payload);
            isFetching = false;
            return { ...state, getPurchasedOrdersMember: action.payload, isFetching }

        /* ************************** MEMBER - CONVERSION OPPORTUNITY ************************** */
        case MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS:
            // console.log('MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS ==>>', action.payload);
            isFetching = false;
            return { ...state, getTerminatedVPOpportunitiesDetails: action.payload, isFetching }
        
        case MEMBER_GET_TERMINATED_VP_OPPORTUNITIES:
            // console.log('MEMBER_GET_TERMINATED_VP_OPPORTUNITIES ==>>', action.payload);
            isFetching = false;
            return { ...state, getTerminatedVPOpportunities: action.payload, isFetching }
    }
}
