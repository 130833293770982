export const switchIndicator = (ratings) => {
    let CASE_VAlUE = ratings > 0 ? 'POSITIVE' : ratings < 0 ? 'NEGATIVE' : null;
    switch(CASE_VAlUE) {
        case "POSITIVE":
            return <i className="fa fa-arrow-circle-up" style={{ color: 'green' }}></i>;
        case "NEGATIVE":
            return <i className="fa fa-arrow-circle-down" style={{ color: 'red' }}></i>;
        default:
            return;
    }
}