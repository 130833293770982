import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Spinner } from 'react-bootstrap';
import Filters from './Filters/Filters';
import Cards from '../Cards/Cards';
import C3 from '../C3/C3';
import GrowthRebate from './GrowthRebate/GrowthRebate';
import SalesRebateOverview from './SalesRebateOverview/SalesRebateOverview';
import SalesOpportunityByRegion from './SalesOpportunityByRegion/SalesOpportunityByRegion';
import SalesOpportunityByMember from './SalesOpportunityByMember/SalesOpportunityByMember';
import SalesOpportunityByBusinessGroup from './SalesOpportunityByBusinessGroup/SalesOpportunityByBusinessGroup';
import SalesOpportunityByProductGroup from './SalesOpportunityByProductGroup/SalesOpportunityByProductGroup';
import { priceFormatter } from '../../../utils/formatters';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
import { updateSelectedNavIndex } from '../../../actions';

import {
    getVendorConversionOpportunity,
    getVendorMonthsFilter,
    getVendorCompliantStatus,
    getVendorOverviewBoxes,
    getVendorSalesVsOpportunityGraph,
    getGrowthRebate,
    getSalesRebateOverview,
    getSalesOpportunityByRegion,
    getSalesOpportunityByMember,
    getSalesOpportunityByBusinessGroup,
    getSalesOpportunityByProductGroup,
    getProductCategory,
    getVendorBranches
} from '../../../api/supplier-api';

import '../../../assets/styles/SupplierOverview.scss';

class SupplierOverview extends Component {
    constructor(props) {
        super(props);
 const currentDate = new Date();
        this.state = {
            isLoaded: false,
            isContentLoaded: false,
            vendorId: this.props.match.params.id,
            isQuarterSelected: false,
            year: currentDate.getFullYear(),
            month: currentDate.getMonth() + 1,
            period: 'YTD',
            quarter: "1",
            selectedRegion: "",
            selectedMember: "",
            selectedBusinessGroupId: "",
            selectedBusinessGroupRow: "",
            selectedBusinessGroupName: "",
            productGroupId: "",
            vendorConverstionOpportunity: null,
            vendorCompliantStatus: null,
            vendorMonthsFilter: {},
            listOfYears: [],
            listOfMonthsForSelectedYear: [],
            vendorOverviewBoxes: [],
            vendorSalesVsOpportunityGraph: [],
            growthRebate: [],
            salesRebateOverview: [],
            salesOpportunityByRegion: [],
            salesOpportunityByMember: [],
            salesOpportunityByBusinessGroup: [],
            salesOpportunityByProductGroup: [],
            productCategory: [],
            vendorOptions: [],
            timer: 0,
            supplierName: sessionStorage.getItem('groupName') ?  sessionStorage.getItem('imarkElectricalSupplierName'): sessionStorage.getItem('groupName')

        }
    }

    // static getDerivedStateFromProps(props) {
    //     console.log('Vendor ID in Vendor Component', props.match.params.id);
    //     return { supplierId: props.match.params.id, vendorId: props.match.params.id };
    // }

    // componentDidMount() {
    //     this.setState( { supplierId: this.props.match.params.id, vendorId: this.props.match.params.id },() =>
    //     {let idx = getVisitedTabId(window.location.hash);
    //         this.props.updateSelectedNavIndex(idx);});

    // }
    componentDidMount() {
        this.setState({ supplierId: this.props.match.params.id, vendorId: this.props.match.params.id }, () => {
            let idx = getVisitedTabId(window.location.hash);
            this.props.updateSelectedNavIndex(idx);
            this.loadingTimer = setInterval(() => {
                this.setState((pS) => ({
                    timer: pS.timer + 1
                }));
            }, 1000);

            this.loadData();
        });
    }

    componentDidUpdate(pP, pS, sS) {
        if (pS.year !== this.state.year) {
            this.getSupplierOverviewData();
        }
        if (pS.month !== this.state.month) {
            this.getSupplierOverviewData();
        }
        if (pS.period !== this.state.period) {
            this.getSupplierOverviewData();
        }
        if (pS.vendorId !== this.state.vendorId) {
            this.getSupplierOverviewData();
        }
        if (pS.selectedBusinessGroupId !== this.state.selectedBusinessGroupId) {
            this.getRegionAndMemberAndProductGroupDataByBusinessGroupId();
        }
        if (pS.selectedRegion !== this.state.selectedRegion) {
            this.getMemberAndPGAndBGDataByRegionCode();
        }
    }

    loadData = () => {
        this.mounted = true;

        Promise.allSettled([
            getVendorBranches({ vendorId: this.state.vendorId, year: this.state.year }),
            getVendorConversionOpportunity({ vendorId: this.state.vendorId }),
            getVendorCompliantStatus({ vendorId: this.state.vendorId }),
            getVendorMonthsFilter({ vendorId: this.state.vendorId }),
            getProductCategory(),

        ])
            .then((allResponses) => {
                console.log('SUPPLIER OVERVIEW DATA: ON MOUNT ==>', allResponses);

                if (this.mounted) {
                    if (allResponses[0].value) {
                        this.setState({
                            vendorOptions: allResponses[0].value ? allResponses[0].value : [],
                            vendorId: allResponses[0].value[0].vendorId
                        },
                            () => { this.getSupplierOverviewData(); })
                    }
                    if (allResponses[1].value) {
                        this.setState({ vendorConversionOpportunity: allResponses[1].value ? allResponses[1].value : [] })
                    }
                    if (allResponses[2].value) {
                        this.setState({ vendorCompliantStatus: allResponses[2].value ? allResponses[2].value : [] })
                    }
                    if (allResponses[3].value) {
                        const availableYears = Object.keys(allResponses[3].value).sort((a, b) => b - a);

                        this.setState({
                            vendorMonthsFilter: allResponses[3].value ? allResponses[3].value : {},
                            listOfYears: allResponses[3].value && Object.keys(allResponses[3].value),
                            // year: allResponses[2].value && Object.keys(allResponses[2].value)[0]
                        }, () => {
                            this.getListOfMonths(allResponses[3].value ,availableYears[0])
                        })
                    }
                    if (allResponses[4].value) {
                        this.setState({ productCategory: allResponses[4].value ? allResponses[4].value : [] })
                    }
                    this.setState({ isLoaded: true })
                    clearInterval(this.loadingTimer);

                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    formatCards = (cards) => {
        cards.forEach(card => {
            let cardTitle = card.cardTitle
            var day = cardTitle;
            switch (cardTitle) {
                case "IMARK Total Sales":
                    day = "Total IMARK Remits (SAP)";
                    break;
                case "Vendor Total Sales":
                    day = "Total Supplier Invoices";
                    break;
                case "Sales Opportunity ($)":
                    day = "Sales Opportunity ($)";
                    break;

            }
            card.cardTitle = day
        });
        this.setState({ vendorOverviewBoxes: cards })
    }

    getSupplierOverviewData = () => {
        this.mounted = true;

        const formData = {
            year: this.state.year,
            month: this.state.month,
            period: this.state.period,
            quarter: this.state.quarter,
            vendorId: this.state.vendorId
        };

        Promise.allSettled([
            getVendorOverviewBoxes(formData),
            getVendorSalesVsOpportunityGraph(formData),
            getGrowthRebate(formData),
            getSalesRebateOverview(formData),
            getSalesOpportunityByRegion(formData),
            getSalesOpportunityByMember(formData),
            getSalesOpportunityByBusinessGroup(formData),
            getSalesOpportunityByProductGroup(formData),
        ])
            .then((allResponses) => {
                console.log('SUPPLIER OVERVIEW DATA: ON CHANGE ==>', allResponses);

                if (this.mounted) {
                    if (allResponses[0].value) {
                        // this.setState({ vendorOverviewBoxes: allResponses[0].value ? allResponses[0].value : [] })
                        this.formatCards(allResponses[0].value)

                    }
                    if (allResponses[1].value) {
                        this.setState({ vendorSalesVsOpportunityGraph: allResponses[1].value ? allResponses[1].value : [] })
                    }
                    if (allResponses[2].value) {
                        this.setState({ growthRebate: allResponses[2].value ? allResponses[2].value : [] })
                    }
                    if (allResponses[3].value) {
                        this.setState({ salesRebateOverview: allResponses[3].value ? allResponses[3].value : [] })
                    }
                    if (allResponses[4].value) {
                        this.setState({ salesOpportunityByRegion: allResponses[4].value ? allResponses[4].value : [],isContentLoaded: false  })
                    }
                    if (allResponses[5].value) {
                        this.setState({ salesOpportunityByMember: allResponses[5].value ? allResponses[5].value : [] })
                    }
                    if (allResponses[6].value) {
                        this.setState({ salesOpportunityByBusinessGroup: allResponses[6].value ? allResponses[6].value : [] })
                    }
                    if (allResponses[7].value) {
                        this.setState({ salesOpportunityByProductGroup: allResponses[7].value ? allResponses[7].value : [] })
                    }
                    this.setState({ isContentLoaded: true })
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    getListOfMonths = (allYears, year) => {
        for (const [key, value] of Object.entries(allYears)) {
            if (year == key) {
                this.setState({
                    listOfMonthsForSelectedYear: value,
                    month: value[value.length - 1],year
                }, () => {
                })
            }
        }
    }
    handleVendorChange = (e) => {
        this.setState({ vendorId: e.target.value })
    }

    handleYear = (e) => {
        this.setState({ year: e.target.value })
        this.getListOfMonths(this.state.vendorMonthsFilter, e.target.value)
    };
    handleMonth = (e) => this.setState({ month: e.target.value });
    handlePeriod = (e) => this.setState({
        period: e.target.name,
        isQuarterSelected: e.target.name === "QTR" ? true : false,
        quarter: e.target.name === "YTD" || e.target.name === "MTH" ? "1" : "1"
    });
    handleQuarter = (e) => this.setState({ quarter: e.target.value })

    handleBusinessId = (businessId) => this.setState({ selectedBusinessGroupId: businessId });
    handleSelectedRowValuesInOppByBG = (index, businessGroupName) => {
        this.setState({
            selectedBusinessGroupRow: index,
            selectedBusinessGroupName: businessGroupName
        })
    }

    getRegionAndMemberAndProductGroupDataByBusinessGroupId = () => {
        this.mounted = true;

        const formData = {
            year: this.state.year,
            month: this.state.month,
            period: this.state.period,
            quarter: this.state.quarter,
            vendorId: this.state.vendorId,
            businessId: this.state.selectedBusinessGroupId
        };

        Promise.allSettled([
            getSalesOpportunityByRegion(formData),
            getSalesOpportunityByMember(formData),
            getSalesOpportunityByProductGroup(formData)
        ])
            .then((allResponses) => {
                if (this.mounted) {
                    if (allResponses[0].value) {
                        this.setState({ salesOpportunityByRegion: allResponses[0].value ? allResponses[0].value : [] ,isLoading: false })
                    }
                    if (allResponses[1].value) {
                        this.setState({ salesOpportunityByMember: allResponses[1].value ? allResponses[1].value : [] })
                    }
                    if (allResponses[2].value) {
                        this.setState({ salesOpportunityByProductGroup: allResponses[2].value ? allResponses[2].value : [] })
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    handleSelectedRegionCode = (region) => this.setState({ selectedRegion: region });

    getMemberAndPGAndBGDataByRegionCode = () => {
        this.mounted = true;

        const formData = {
            year: this.state.year,
            month: this.state.month,
            period: this.state.period,
            quarter: this.state.quarter,
            vendorId: this.state.vendorId,
            region: this.state.selectedRegion,
            businessId: this.state.selectedBusinessGroupId
        };

        Promise.allSettled([
            getSalesOpportunityByMember(formData),
            getSalesOpportunityByBusinessGroup(formData),
            getSalesOpportunityByProductGroup(formData)
        ])
            .then((allResponses) => {
                if (this.mounted) {
                    if (allResponses[0].value) {
                        this.setState({ salesOpportunityByMember: allResponses[0].value ? allResponses[0].value : [] })
                    }
                    if (allResponses[1].value) {
                        this.setState({ salesOpportunityByBusinessGroup: allResponses[1].value ? allResponses[1].value : [] })
                    }
                    if (allResponses[2].value) {
                        this.setState({ salesOpportunityByProductGroup: allResponses[2].value ? allResponses[2].value : [] })
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    handleResetTableData = () => {
        this.mounted = true;

        this.setState({
            selectedBusinessGroupId: "",
            selectedBusinessGroupRow: "",
            selectedBusinessGroupName: ""
        }, () => {
            const formData = {
                year: this.state.year,
                month: this.state.month,
                period: this.state.period,
                quarter: this.state.quarter,
                vendorId: this.state.vendorId
            };

            Promise.allSettled([
                getSalesOpportunityByMember(formData),
                getSalesOpportunityByBusinessGroup(formData),
                getSalesOpportunityByProductGroup(formData)
            ])
                .then((allResponses) => {
                    if (this.mounted) {
                        if (allResponses[0].value) {
                            this.setState({ salesOpportunityByMember: allResponses[0].value ? allResponses[0].value : [] })
                        }
                        if (allResponses[1].value) {
                            this.setState({ salesOpportunityByBusinessGroup: allResponses[1].value ? allResponses[1].value : [] })
                        }
                        if (allResponses[2].value) {
                            this.setState({ salesOpportunityByProductGroup: allResponses[2].value ? allResponses[2].value : [] })
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        })
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        let {
            isLoaded,
            isContentLoaded,
            isQuarterSelected,
            vendorId,
            year,
            month,
            period,
            quarter,
            vendorOptions,
            listOfYears,
            listOfMonthsForSelectedYear,
            vendorOverviewBoxes,
            vendorSalesVsOpportunityGraph,
            growthRebate,
            salesRebateOverview,
            salesOpportunityByRegion,
            salesOpportunityByMember,
            salesOpportunityByBusinessGroup,
            salesOpportunityByProductGroup,
            productCategory,
            selectedBusinessGroupRow,
            selectedBusinessGroupName,
            // timer
        } = this.state;

        let salesOpportunity = [1000000, 1100000, 1280000, 1300000, 1400000, 1440000, 1580000, 1640000, 1720000];
        let totalSupplierInvoices = [900000, 920000, 980000, 1150000, 1240000, 1320000, 1680000, 1780000, 1900491];
        let totalImarkRemits = [960000, 1010000, 1120000, 1240000, 1306000, 1408000, 1450000, 1560000, 1980000];

        salesOpportunity = vendorSalesVsOpportunityGraph && vendorSalesVsOpportunityGraph.map((item) => item.salesOpportunity);
        totalSupplierInvoices = vendorSalesVsOpportunityGraph && vendorSalesVsOpportunityGraph.map((item) => item.vendorTotalSales);
        totalImarkRemits = vendorSalesVsOpportunityGraph && vendorSalesVsOpportunityGraph.map((item) => item.verifiedTotalSales);

        let salesVsOpportunityChart = {
            data: {
                x: 'x',
                columns: [
                    ['x', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                    ['Total IMARK Remits (SAP)', ...totalImarkRemits],
                    ['Sales Opportunity ($)', ...salesOpportunity],
                    ['Total Supplier Invoices ($)', ...totalSupplierInvoices],
                ]
            },
            axis: {
                x: {
                    label: "Month",
                    type: 'category'
                },
                y: {
                    label: 'Volume',
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            }
        };

        return !isLoaded ? <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <React.Fragment>
                <Spinner animation="border" variant="primary" />
                {/* {timer} */}
            </React.Fragment>
        </div> :
            <div>
                <Filters
                    year={year}
                    month={month}
                    period={period}
                    listOfYears={listOfYears}
                    listOfMonthsForSelectedYear={listOfMonthsForSelectedYear}
                    vendorOptions={vendorOptions}
                    handleYearReference={this.handleYear}
                    handleMonthReference={this.handleMonth}
                    handlePeriodReference={this.handlePeriod}
                    handleQuarterReference={this.handleQuarter}
                    handleVendorReference={this.handleVendorChange}
                    growthRebate={growthRebate}
                    salesRebateOverview={salesRebateOverview}
                    salesOpportunityByMember={salesOpportunityByMember}
                    salesOpportunityByBusinessGroup={salesOpportunityByBusinessGroup}
                    salesOpportunityByProductGroup={salesOpportunityByProductGroup}
                    isQuarterSelected={isQuarterSelected}
                    supplierName={this.state.supplierName}
                />

                {/* <pre style={{ marginLeft: 60 }}>
                    {JSON.stringify({
                        vendorId,
                        year,
                        month,
                        period,
                        quarter,
                        // listOfYears: JSON.stringify(listOfYears),
                        // listOfMonthsForSelectedYear: JSON.stringify(listOfMonthsForSelectedYear),
                        // vendorOverviewBoxes,
                        // vendorSalesVsOpportunityGraph,
                        // timer
                    }, null, '\t')}
                </pre> */}

                {!isContentLoaded ?
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                        minHeight: 1000
                    }}>
                        <React.Fragment>
                            <Spinner animation="border" variant="primary" />
                        </React.Fragment>
                    </div> :
                    <React.Fragment>
                        <Card className="sectionDivider">
                            <Cards
                                cardItems={vendorOverviewBoxes}
                            />
                            <C3
                                heading={""}
                                data={salesVsOpportunityChart.data}
                                axis={salesVsOpportunityChart.axis}
                            />
                        </Card>

                        <div className="tableDivider">
                            <SalesOpportunityByRegion
                                salesOpportunityByRegion={salesOpportunityByRegion}
                                handleSelectedRegionCodeReference={this.handleSelectedRegionCode}
                                handleResetTableDataReference={this.handleResetTableData}
                            />

                            <SalesOpportunityByMember
                                salesOpportunityByMember={salesOpportunityByMember}
                                handleResetTableDataReference={this.handleResetTableData}
                            />
                        </div>
                        <div className="tableDivider">
                            <SalesOpportunityByBusinessGroup
                                salesOpportunityByBusinessGroup={salesOpportunityByBusinessGroup}
                                handleBusinessIdReference={this.handleBusinessId}
                                selectedRowValuesInOppByBGReference={this.handleSelectedRowValuesInOppByBG}
                                selectedBusinessGroupRow={selectedBusinessGroupRow}
                                selectedBusinessGroupName={selectedBusinessGroupName}
                                handleResetTableDataReference={this.handleResetTableData}
                            />

                            <SalesOpportunityByProductGroup
                                year={year}
                                month={month}
                                period={period}
                                quarter={quarter}
                                vendorId={vendorId}
                                salesOpportunityByProductGroup={salesOpportunityByProductGroup}
                                productCategory={productCategory}
                                handleResetTableDataReference={this.handleResetTableData}
                            />
                        </div>
                        <GrowthRebate
                            growthRebate={growthRebate}
                        />

                        <SalesRebateOverview
                            salesRebateOverview={salesRebateOverview}
                        />


                    </React.Fragment>}
            </div>
    }
}

const mapStateToProps = state => {
    return {
        selectedNavIndex: state.navigation.selectedNavIndex,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierOverview);
