import React from "react";
import PropTypes from "prop-types";
import { Form, Col, Button } from 'react-bootstrap';

const defaultButton = props => <a  {...props}>{props.children}</a>;

export default class Pagination extends React.Component {
  constructor(props) {
    super();

    this.changePage = this.changePage.bind(this);

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages),
    };
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage(page) {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });

    this.props.onPageChange(page - 1);
  }

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    let pageSizeOptions = this.props.pageSizeOptions ? this.props.pageSizeOptions : [5, 10, 20, 25, 50, 100]
   
    return (
      <div className="d-flex justify-content-center">
      <ul className="pagination my-3">
        <li className="page-item">
          <PageButtonComponent
            className="page-link"
            style={{'cursor': 'pointer'}}
            onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </li>
        <li className="page-item">

          <Form.Group as={Col} controlId="formSelectSupplier">
            <Form.Control as="select" defaultValue={100} className="page-link" onChange={(e) => {this.props.onPageSizeChange(e.target.value) }}>
              {pageSizeOptions.map((item, index) => {
                return <option key={index} value={item}>{item} rows</option>
              })}
            </Form.Control>
          </Form.Group>

        </li>
        {visiblePages.map((page, index, array) => {
          return (
            <li className={
              activePage === page
                ? "page-item active" : "page-item"}>

              <PageButtonComponent
                key={page}
                style={{'cursor': 'pointer'}}
                className={
                  activePage === page
                    ? "page-link active"
                    : "page-link"
                }
                onClick={this.changePage.bind(null, page)}
              >
                {array[index - 1] + 2 < page ? `...${page}` : page}
              </PageButtonComponent>
            </li>
          );
        })}
        <li className="page-item">
          <PageButtonComponent
            className="page-link"
            style={{'cursor': 'pointer'}}
            onClick={() => {
              if (activePage === this.props.pages) {
                if (this.props.loadMoreData != undefined)
                  this.props.loadMoreData();
                else
                  return;
              }
              else
                this.changePage(activePage + 1);
            }}
          // disabled={activePage === this.props.pages}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </li>
      </ul>
      </div>
    );
  }
}
