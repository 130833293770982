import React, { Component } from 'react';
import Noty from 'noty';
import ReactExport from "react-export-excel";
import ReactTable from "react-table-6";
import { Button, Form, Col, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
// import "../../../node_modules/react-toggle/style.css";
import {
    getProductCategory,
    saveProductFeedback
} from '../../../../api/supplier-api';
import Select from 'react-select';
import _findIndex from 'lodash/findIndex';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";




export default class ProductDetails extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            showSubmit: false,
            productCategoryWrong: false,
            productCategory: [],
            selectedProductId: "",
            selectedProductName: "",
            selectedProductCategoryId: "",
            selectedProductCategoryName: "",
            feedbackComments: ""
        }
    }

    componentDidMount() {
        getProductCategory()
            .then((responseData) => {
                this.setState({ productCategory: responseData })
            })
            .catch((err) => console.log(err));
    }

    handleClose = () => this.setState({ show: false, productCategoryWrong: false ,showSubmit:false})

    handleProductCategoryWrong = () => {
        if (this.state.productCategoryWrong) {
            this.setState({ productCategoryWrong: false })
        } else {
            this.setState({ productCategoryWrong: true })
        }
    }

    handleProductCategory1 = (value) => {
        let arr = [];
        let productCategoryId = '';
        let productCategoryName = '';

      
        if (value) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let productCategoryId = arr[0].split(',')[0];
          let productCategoryName = arr[0].split(',')[1];
  
        }
        else {
            let productCategoryName = _findIndex(value,"Select");
            let productCategoryId = '';
          }
       
          this.setState({
            suggestedCategoryId: productCategoryId,
            suggestedCategoryName: productCategoryName,
            showSubmit: true

        })
    
      };
    

    handleProductCategory = (e) => {
        // if (e.value) {
        //     let productCategoryId = e.value.split("=")[0];
        //     let productCategoryName = e.value.split("=")[1];

        //     this.setState({
        //         selectedProductCategoryId: productCategoryId,
        //         selectedProductCategoryName: productCategoryName,
        //         showSubmit: true
        //     })
        // } else {
        //     this.setState({ selectedProductCategoryId: "", selectedProductCategoryName: "" })
        // }
        if (e.value) {
            let productCategoryId = e.value.split(',')[0];
            let productCategoryName = e.value.split(',')[1];

            this.setState({
                selectedProductCategoryId: productCategoryId,
                selectedProductCategoryName: productCategoryName,
                showSubmit: true
            })
        }
       
        else {
            let productCategoryName = _findIndex(e,"Select Supplier");
            let productCategoryId = '';
            this.setState({
                selectedProductCategoryId: productCategoryId,
                selectedProductCategoryName: productCategoryName,
                showSubmit: false
            })
        }
    }

    handleProductFeedback = () => {
        
        let formData = {
            productId: this.state.selectedProductId,
            productDesc: this.state.selectedProductName,
            categoryId: this.props.selectedProductGroupId,
            categoryName: this.props.selectedProductGroupName,
            productCategoryWrong: this.state.productCategoryWrong,
            suggestedCategoryId: this.state.productCategoryWrong ? this.state.selectedProductCategoryId : null,
            suggestedCategoryName: this.state.productCategoryWrong ? this.state.selectedProductCategoryName : null,
            feedbackComments: this.state.feedbackComments
        }

        saveProductFeedback(formData)
            .then((responseData) => {
                this.setState({ show: false ,productCategoryWrong: false,showSubmit:false})
                new Noty({
                    text: responseData.text,
                    layout: "topRight",
                    theme: "mint",
                    type: "success"
                }).show();
            })
            .catch((err) => {
                console.log(err);
            })
            
    }

    productFeedbackModal = () => {
        let { productCategory } = this.state;

        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                   <p> Product Feedback for <b>{this.state.selectedProductName}</b></p>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p><b>{this.state.selectedProductName}</b> Is wrongly classified as ?</p>
                        <Toggle
                            id='cheese-status5'
                            icons={false}
                            defaultChecked={this.state.productCategoryWrong}
                            onChange={() => this.handleProductCategoryWrong()}
                        />
                    </div>
                    {this.state.productCategoryWrong && <Form.Group as={Col} controlId="formSelectNewProductCategory">
                    <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleProductCategory(e)}
                            placeholder={'Select new Product Category'}
                        
                            options={Array.from(
                                productCategory && productCategory.map((item, index) => {
                                return {
                                  value: item.productCategoryId + ','+ item.productCategory,
                                  label: item.productCategory,
                                };
                                
                              })
                            )}
                          />
                    
                        {/* <Form.Control as="select" defaultValue="Select New Product Category" onChange={(e) => this.handleProductCategory(e)}>
                            <option value="">Select new Product Category</option>
                            {productCategory && productCategory.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.productCategoryId}=${item.productCategory}`}>{item.productCategory}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea onChange={(e) => this.setState({ feedbackComments: e.target.value })}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit} onClick={() => this.handleProductFeedback()}>
                        Submit Feedback
                </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { productDetails } = this.props;

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <div className="productDetailsContainer">
                {this.productFeedbackModal()}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <h6>Product Details</h6>
                    <ExcelFile element={<Button style={{ fontSize: 12 }}>Download Product Names</Button>}>
                        <ExcelSheet data={productDetails} name="Product Details">
                            <ExcelColumn label="Product" value="productDesc" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
                <ReactTable
                    data={productDetails}
                    columns={[
                        {
                            Header: <b>Product</b>,
                            accessor: "productDesc",
                            sortable: true,
                            resizable: true,
                            style: { 'whiteSpace': 'unset' }
                        }, {
                            Header: <b>Feedback</b>,
                            accessor: "feedback",
                            sortable: true,
                            resizable: true,
                            Cell: props => <i className="fa fa-comment-alt" onClick={() => {
                                this.setState({
                                    selectedProductId: props.original.productId,
                                    selectedProductName: props.original.productDesc,
                                    show: true
                                })
                            }} style={{ color: '#0D47A1' }}></i>,
                            style: { textAlign: "center" }
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: "opportunity",
                            desc: true
                        }
                    ]}
                    defaultPageSize={25}
                    style={{ height: "400px" }}
                    className="-striped -highlight"
                />
                <br />
            </div>
        );
    }
}
