import React, { Component } from 'react';
import Noty from 'noty';

import ReactExport from "react-export-excel";
import ReactTable from "react-table-6";
import { Button, Form, Col, Modal, ProgressBar } from 'react-bootstrap';
import Toggle from 'react-toggle';
// import "../../../node_modules/react-toggle/style.css";

import {
    getConversionOpportunityProducts,
    getProductDetailsInSalesOpportunityByProductGroup,
    getConversionOpportunityVendorList,
    getProductCategory,
    saveProductFeedback
} from '../../../../api/supplier-api';
import '../../../../assets/styles/ProductListByCategory.scss';
import Select from 'react-select';
import _findIndex from 'lodash/findIndex';
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";



export default class ProductList extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            productCategoryWrong: false,
            selectedProductId: "",
            selectedProductName: "",
            selectedProductCategoryId: "",
            selectedProductCategoryName: "",
            feedbackComments: "",
            productList: [],
            conversionOpportunityVendorList: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const formData = {
            year: this.props.year,
            month: this.props.month,
            quarter: this.props.quarter,
            period: this.props.period,
            vendorId: this.props.vendorId,
            productGroupId: this.props.productGroupId,
            region: "0",
            businessId: "0"
        };

        getProductDetailsInSalesOpportunityByProductGroup(formData)
            .then((res) => {
                this.setState({ productList: res })
            })
            .catch((err) => {
                console.log(err);
            })

        getProductCategory({
            year: this.props.year,
            month: this.props.month,
            quarter: this.props.quarter,
            period: this.props.yearGroup,
            vendorId: this.props.vendorId,
        })
            .then((res) => {
                this.setState({ conversionOpportunityVendorList: res.data })
            })
            .catch((err) => {
                console.log(err);
            })
    }

    handleClose = () => this.setState({ show: false, productCategoryWrong: false })

    handleProductCategoryWrong = () => {
        if (this.state.productCategoryWrong) {
            this.setState({ productCategoryWrong: false })
        } else {
            this.setState({ productCategoryWrong: true })
        }
    }

    handleProductFeedback = () => {
        
        console.log(this.props);
        let formData = {
            productId: this.state.selectedProductId,
            productDesc: this.state.selectedProductName,
            categoryId: this.props.selectedProductGroupId,
            categoryName: this.props.selectedProductGroupName,
            productCategoryWrong: this.state.productCategoryWrong,
            suggestedCategoryId: this.state.productCategoryWrong ? this.state.selectedProductCategoryId : null,
            suggestedCategoryName: this.state.productCategoryWrong ? this.state.selectedProductCategoryName : null,
            feedbackComments: this.state.feedbackComments
        }

        saveProductFeedback(formData)
            .then((responseData) => {
                console.log(responseData);
                this.setState({ show: false ,productCategoryWrong: false})
                new Noty({
                    text: responseData.text,
                    layout: "topRight",
                    theme: "mint",
                    type: "success"
                }).show();
            })
            .catch((err) => {
                console.log(err);
            })
            
    }
    handleProductCategory1 = (value) => {
        let arr = [];
        let productCategoryId = '';
        let productCategoryName = '';

      
        if (value ) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let productCategoryId = arr[0].split(',')[0];
          let productCategoryName = arr[0].split(',')[1];
  
        }
        else {
            let productCategoryName = _findIndex(value,"Select");
            let productCategoryId = '';
          }
       
          this.setState({
            selectedProductCategoryId: productCategoryId,
            selectedProductCategoryName: productCategoryName,
            showSubmit: true

        })
    
      };
    


    handleProductCategory = (e) => {
        // if (e.value) {
        //     let productCategoryId = e.value.split("=")[0];
        //     let productCategoryName = e.value.split("=")[1];

        //     this.setState({
        //         selectedProductCategoryId: productCategoryId,
        //         selectedProductCategoryName: productCategoryName,
        //     })
        // }
        if (e.value) {
            let productCategoryId = e.value.split(',')[0];
            let productCategoryName = e.value.split(',')[1];

            this.setState({
                selectedProductCategoryId: productCategoryId,
                selectedProductCategoryName: productCategoryName,
                showSubmit: true
            })
        }
       
        else {
            let productCategoryName = _findIndex(e,"Select Supplier");
            let productCategoryId = '';
            this.setState({
                selectedProductCategoryId: productCategoryId,
                selectedProductCategoryName: productCategoryName,
                showSubmit: false
            })
        }
    }

    saveProductFeedbackModal = () => {
        let { productCategory } = this.props;

        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                <p> Product Feedback for <b>{this.state.selectedProductName}</b> </p>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p><b>{this.state.selectedProductName}</b> Is wrongly classified as ?</p>
                        <Toggle
                            id='cheese-status5'
                            icons={false}
                            defaultChecked={this.state.productCategoryWrong}
                            onChange={() => this.handleProductCategoryWrong()}
                        />
                    </div>
                    {this.state.productCategoryWrong && <Form.Group as={Col} controlId="formSelectNewProductCategory">
                    <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleProductCategory(e)}
                            placeholder={'Select new Product Category'}
                        
                            options={Array.from(
                                productCategory && productCategory.map((item, index) => {
                                return {
                                  value: item.productCategoryId + ','+ item.productCategory,
                                  label: item.productCategory,
                                };
                                
                              })
                            )}
                          />
                        {/* <Form.Control as="select" defaultValue="Select new Product Category" onChange={(e) => this.handleProductCategory(e)}>
                            <option value="">Select new Product Category</option>
                            {productCategory.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.productCategoryId}=${item.productCategory}`}>{item.productCategory}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea onChange={(e) => this.setState({ feedbackComments: e.target.value })}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit} onClick={() => this.handleProductFeedback()}>
                        Submit Feedback
                </Button>
                    {/* <Button variant="secondary" disabled={!(this.state.productCategoryWrong && this.state.selectedProductCategoryId)} onClick={() => this.handleProductFeedback()}>
                        Submit Feedback
                </Button> */}
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { productList } = this.state;

        let opportunities = productList && productList.map((item) => item.missedSalesAmount);
        let maxOpportunity = opportunities && Math.max(...opportunities);

        const ExcelFile = ReactExport.ExcelFile;
        const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
        const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <div className="productDetailsContainer">
                {this.saveProductFeedbackModal()}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                    }}
                >
                    <h6>Product List</h6>
                    <ExcelFile element={<Button style={{ fontSize: 12 }} onClick={() => this.setState({ export: true })}>Download Product Names</Button>}>
                        <ExcelSheet data={productList} name="Product List">
                            <ExcelColumn label="Product" value="productDescription" />
                            <ExcelColumn label="Outside Supplier" value="vendorUnapprovedName" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
                <ReactTable
                    data={productList}
                    columns={[
                        {
                            Header: <b style={{ 'whiteSpace': 'normal' }}>Product</b>,
                            accessor: "productDescription",
                            sortable: true,
                            resizable: true,
                            style: { 'whiteSpace': 'unset' }
                        }, {
                            Header: <b style={{ 'whiteSpace': 'normal' }}>Outside Supplier</b>,
                            accessor: "vendorUnapprovedName",
                            sortable: true,
                            resizable: true,
                            style: { 'whiteSpace': 'unset' }
                        }, {
                            Header: <b>Opportunity</b>,
                            accessor: "missedSalesAmount",
                            sortable: true,
                            resizable: true,
                            Cell: props => <ProgressBar
                                now={(props.original.missedSalesAmount * 100 / maxOpportunity) > 1 ?
                                    props.original.missedSalesAmount * 100 / maxOpportunity : 1}
                            />
                        }, {
                            Header: <b>Feedback</b>,
                            accessor: "feedback",
                            sortable: true,
                            resizable: true,
                            Cell: props => <i className="fa fa-comment-alt"
                                onClick={() => {
                                    this.setState({
                                        selectedProductId: props.original.productId,
                                        selectedProductName: props.original.productDescription
                                    });
                                    this.setState({ show: true });
                                }}
                                style={{ color: '#0D47A1' }}
                            ></i>,
                            style: { textAlign: "center" }
                        },
                    ]}
                    defaultSorted={[
                        {
                            id: "missedSalesAmount",
                            desc: true
                        }
                    ]}
                    defaultPageSize={25}
                    style={{ height: "400px" }}
                    className="-striped -highlight"
                />
                <br />
            </div>
        );
    }
}
