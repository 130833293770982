import accounting from 'accounting-js';

export function PriceFormatter1(val) {
    if (val === undefined) {
        return "$0";
    }
    else {
        let str = accounting.formatMoney(val);
        str = str.slice(0, -3);
        return str;
    }
}
export function jsonToParam(params, startWith) {
    if (params) {
        let paramArr = [];
        Object.keys(params).forEach(function (key) {
            if (typeof params[key] != 'object')
                paramArr.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
            if (typeof params[key] == 'object')
                paramArr.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        });
        return startWith ? startWith + paramArr.join('&') : paramArr.join('&');
    }
    else {
        return '';
    }
}
export function paramsToJSON(params) {
    if (params.indexOf('?') > -1) {
        params = params.split('?')[1];
    }
    var pairs = params.split('&');
    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });
    return result;
}
export function PercentFormatter(val) {
    if (val) {
        return Number((val) / 100).toLocaleString('en-US', {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    } else {
        return '';
    }
}
export const REGIONS = {
    nw: {
        name: 'North West',
        states: ['or', 'id', 'wy', 'mt', 'wa']
    },
    sw: {
        name: 'South West',
        states: ['nm', 'ak', 'az', 'co', 'ut', 'nv', 'ca', 'hi']
    },
    sc: {
        name: 'South Central',
        states: ['la', 'ar', 'ks', 'ok', 'tx']
    },
    se: {
        name: 'South East',
        states: ['va', 'nc', 'sc', 'tn', 'ms', 'al', 'ga', 'fl']
    },
    ne: {
        name: 'North East',
        states: ['md', 'de', 'nj', 'pa', 'ny', 'ma', 'ct', 'nh', 'vt', 'me', 'ri']
    },
    mw: {
        name: 'Mid West',
        states: ['nd', 'mn', 'sd', 'ne', 'ia', 'mo', 'ky', 'wv', 'oh', 'mi', 'il', 'wi', 'in']
    }
};

export const getRegionName = (stateCode) => {
    stateCode = stateCode.replace('US-', '').toLowerCase();
    for (let regionCode in REGIONS) {
        if (REGIONS[regionCode].states.filter(state => state === stateCode).length > 0) {
            return REGIONS[regionCode].name;
        }
    }
    return 'NA';
};

export const getRegionCode = (stateCode) => {
    stateCode = stateCode.replace('US-', '').toLowerCase();
    for (let regionCode in REGIONS) {
        if (REGIONS[regionCode].states.filter(state => state === stateCode).length > 0) {
            return regionCode;
        }
    }
    return null;
};


