import React, { Component } from 'react';
import { VectorMap } from 'react-jvectormap';
import { Card } from 'react-bootstrap';
import { getRegionCode, getRegionName, REGIONS } from '../../../helper';

export default class MissedOpportunityByRegion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionMinValue: 0,
            regionMaxValue: 0,
            regionSeries: {},
            // regionsAndOpportunity: [
            //     { region: "US-AK", opportunity: 3671501.57 },
            //     { region: "US-AL", opportunity: 1601725.45 },
            //     { region: "US-AR", opportunity: 1475244.95 },
            //     { region: "US-AZ", opportunity: 1601725.45 },
            //     { region: "US-CA", opportunity: 1601725.45 },
            //     { region: "US-CO", opportunity: 1601725.45 },
            //     { region: "US-CT", opportunity: 4735694.41 },
            //     { region: "US-DE", opportunity: 3007737.82 },
            //     { region: "US-FL", opportunity: 4735694.41 },
            //     { region: "US-GA", opportunity: 3671501.57 },
            //     { region: "US-HI", opportunity: 4735694.41 },
            //     { region: "US-IA", opportunity: 4735694.41 },
            //     { region: "US-ID", opportunity: 4735694.41 },
            //     { region: "US-IL", opportunity: 4735694.41 },
            //     { region: "US-IN", opportunity: 2625991.34 },
            //     { region: "US-KS", opportunity: 3007737.82 },
            //     { region: "US-KY", opportunity: 3007737.82 },
            //     { region: "US-LA", opportunity: 2625991.34 },
            //     { region: "US-MA", opportunity: 2625991.34 },
            //     { region: "US-MD", opportunity: 1475244.95 },
            //     { region: "US-ME", opportunity: 2625991.34 },
            //     { region: "US-MI", opportunity: 4735694.41 },
            //     { region: "US-MN", opportunity: 1601729.45 },
            //     { region: "US-MO", opportunity: 4735694.41 },
            //     { region: "US-MS", opportunity: 3671501.57 },
            //     { region: "US-MT", opportunity: 1601729.45 },
            //     { region: "US-NC", opportunity: 1601729.45 },
            //     { region: "US-ND", opportunity: 1601729.45 },
            //     { region: "US-NE", opportunity: 3671501.57 },
            //     { region: "US-NH", opportunity: 2625991.34 },
            //     { region: "US-NJ", opportunity: 3671501.57 },
            //     { region: "US-NM", opportunity: 3007737.82 },
            //     { region: "US-NV", opportunity: 1601729.45 },
            //     { region: "US-NY", opportunity: 2625991.34 },
            //     { region: "US-OH", opportunity: 4735694.41 },
            //     { region: "US-OK", opportunity: 4735694.41 },
            //     { region: "US-OR", opportunity: 4735694.41 },
            //     { region: "US-PA", opportunity: 2625991.34 },
            //     { region: "US-RI", opportunity: 1475244.95 },
            //     { region: "US-SC", opportunity: 3671501.57 },
            //     { region: "US-SD", opportunity: 4735694.41 },
            //     { region: "US-TN", opportunity: 3007737.82 },
            //     { region: "US-TX", opportunity: 3007737.82 },
            //     { region: "US-UT", opportunity: 1475244.95 },
            //     { region: "US-VA", opportunity: 3671501.57 },
            //     { region: "US-VT", opportunity: 4735694.41 },
            //     { region: "US-WA", opportunity: 1601729.45 },
            //     { region: "US-WI", opportunity: 1601729.45 },
            //     { region: "US-WV", opportunity: 1475244.95 },
            //     { region: "US-WY", opportunity: 2625991.34 }
            // ]
        }
    }

    componentDidMount() {
        console.log(this.props,"hiiiii")
        if (this.props.getCorporateOppRegion) {
            let regionOpportunity = {};
            for (let regionCode in REGIONS) {
                let regionOpportunitySum = 0;
                REGIONS[regionCode].states.forEach(stateCode => {
                    this.props.getCorporateOppRegion.filter(row => row.region === stateCode.toUpperCase()).forEach(row => {
                        regionOpportunitySum += row.missedAmount;
                    });
                });
                regionOpportunity[regionCode] = regionOpportunitySum;
            }

            let regionSeries = {};
            let regionMinValue = 0;
            let regionMaxValue = 0;

            for (let regionCode in REGIONS) {
                REGIONS[regionCode].states.forEach(stateCode => {
                    let value = regionOpportunity[regionCode] || 0;
                    regionSeries['US-' + stateCode.toUpperCase()] = value;
                    regionMaxValue = value > regionMaxValue ? value : regionMaxValue;
                });
            }

            this.setState({ regionSeries, regionMinValue, regionMaxValue });

        }
    }

    handleRegionTipShow = (e, el, code) => {
        let value = Number(this.state.regionSeries[code]).toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0 });
        let regionName = getRegionName(code);
        el.html(`Region: ${regionName}<br>State: ${el.html()}<br><b>Sales Opportunity: ${value}</b>`);
    }

    handleLabelShow = (e, el, code) => {

    }
    handleRegionClick = (e, el, code) => {
        const region = getRegionCode(el);
        this.props.handleSelectedRegionCodeReference(region);
    }
    clearToolTip = (e) => {
        document.querySelectorAll('.jvectormap-tip').forEach((ele) => ele.remove())
    }
    render() {
        return (
            <div className="salesOpportunityByRegionContainer">
                {this.clearToolTip()}
                <Card>
                    <Card.Header>Sales Opportunity By Region</Card.Header>
                    <VectorMap
                        containerClassName="map"
                        containerStyle={{
                            width: '-webkit-fill-available',
                            height: '466px',
                            padding: 10
                        }}
                        ref="map"
                        map={'us_aea'}
                        series={{
                            regions: [{
                                scale: ['#e6f9ff', '#0099cc'],
                                attribute: 'fill',
                                values: this.state.regionSeries,
                                min: this.state.regionMinValue,
                                max: this.state.regionMaxValue
                            }]
                        }}
                        backgroundColor="transparent"
                        regionsSelectable={true}
                        regionStyle={{
                            initial: {
                                fill: "#e4e4e4",
                                "fill-opacity": 0.9,
                                stroke: "none",
                                "stroke-width": 0,
                                "stroke-opacity": 0
                            },
                            hover: {
                                "fill-opacity": 0.8,
                                cursor: "pointer"
                            },
                            selected: {
                                fill: "rgb(43, 142, 187)"
                            },
                            selectedHover: {}
                        }}
                        onRegionTipShow={(e, el, code) => this.handleRegionTipShow(e, el, code)}
                        onRegionClick={(e, el, code) => this.handleRegionClick(e, el, code)}
                        onLabelShow={(e, el, code) => this.handleLabelShow(e, el, code)}
                        // onRegionOut={(e) => this.clearToolTip(e)}
                    />
                </Card>
            </div>
        )
    }
}
