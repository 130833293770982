import axios from 'axios';
import {
    FETCH_SELECTED_NAV_INDEX,
    UPDATE_SELECTED_NAV_INDEX,
    LOGIN,
    LOGIN_WITH_SSO_TOKEN,
    GENERATE_SAMPLE_SSO_AUTHENTICATION_TOKEN,
    LOGOUT,
    VENDOR_GET_TERMINATED_VP_NAMES_MEMBER,
    GET_PRODUCT_CATEGORY_FOR_MEMBER, // Ref: MEMBER_PRODUCT_CATEGPRY in Plumbing
    MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB,
    CORPORATE_GET_CARDS_DATA_FOR_OVERVIEW_DATA_TAB,
    CORPORATE_GET_OVERVIEW_VENDOR_PERFORMANCE_DATA,
    CORPORATE_GET_OVERVIEW_MEMBER_PERFORMANCE_DATA,
    CORPORATE_GET_OVERVIEW_GROWTH_DATA,
    CORPORATE_GET_OVERVIEW_GRAPH_DATA,
    CORPORATE_GET_OVERVIEW_VP_INVOICE,
    CORPORATE_GET_OVERVIEW_MEMBER_INVOICE,
    CORPORATE_GET_VENDOR_DATA_GROWTH_DATA,
    CORPORATE_SUPPLIER_FILTERS,
    CORPORATE_GET_CARDS_DATA_FOR_VENDOR_DATA_TAB,
    CORPORATE_GET_CARDS_DATA_FOR_OPPORTUNITY_TAB,
    CORPORATE_OPPORTUNITY_REGION,
    CORPORATE_OPPORTUNITY_MEMBER,
    CORPORATE_OPPORTUNITY_VENDOR,
    CORPORATE_OPP_FILTERS,
    CORPORATE_OPPORTUNITY_PRODUCT_GROUP,
    CORPORATE_OPPORTUNITY_SALES_CHART_DATA,
    CORPORATE_OPPORTUNITY_VENDOR_LIST,
    CORPORATE_COMPLIANCE_FILTERS,
    CORPORATE_COMPLIANCE_MEMBER,
    CORPORATE_COMPLIANCE_VENDOR,
    CORPORATE_CONVERSION_MEMBER,
    CORPORATE_CONVERSION_FILTERS,
    CORPORATE_CONVERSION_PRODUCT_GROUP,
    CORPORATE_CONVERSION_PO_DETAILS,
    CORPORATE_CONVERSION_OPPORTUNITY_OVERVIEW_DETAILS,
    CORPORATE_CONVERSION_OPPORTUNITY_VENDOR_LIST,
    CORPORATE_CONVERSION_PRODUCT_CATEGORY_LIST,
    CORPORATE_CONVERSION_TOP_TEN_MEMBER,
    CORPORATE_CONVERSION_TOP_TEN_PRODUCT_GROUP,
    CORPORATE_CONVERSION_FAV_VENDOR_LIST,
    CORPORATE_CONVERSION_PRODUCTS,
    CORPORATE_FEEDBACK_WRONG_CATEGORY,
    CORPORATE_FEEDBACK_FAV_SUPPLIER,
    CORPORATE_FEEDBACK_PRODUCT_NOT_SOLD,
    CORPORATE_FEEDBACK_IN_VENDOR,
    CORPORATE_FEEDBACK_OUT_VENDOR,
    CORPORATE_FEEDBACK_BUY_SELL_VENDOR,
    MEMBER_PURCHASE_WITHIN_IMARK,
    MEMBER_PURCHASE_OUTSIDE_IMARK,
    MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY,
    VENDOR_FULL_INFO,
    MEMBER_PRODUCT_CATEGORY,
    MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB,
    MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES,
    MEMBER_GET_INVOICE_DATA,
    MEMBER_GET_PURCHASED_ORDERS,
    MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS,
    MEMBER_GET_TERMINATED_VP_OPPORTUNITIES,
    MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH
} from './types';


/* ********** Navigation ********** */

export const fetchSelectedNavIndex = () => {
    return {
        type: FETCH_SELECTED_NAV_INDEX
    }
}

export const updateSelectedNavIndex = (idx) => {
    return {
        type: UPDATE_SELECTED_NAV_INDEX,
        payload: idx
    }
}

// ****************************** AUTHENTICATION ****************************** //

export const login = (selections) => async (dispatch) => {
    let request = axios.post(`/login`, selections, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'mode': 'cors'
        }
    })
        .then((res) => {
            // console.log('loginData', res.data);
            localStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
            localStorage.setItem('userType', `${res.data.userType}`);
            localStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
            localStorage.setItem('groupId', `${res.data.groupId}`);
            localStorage.setItem('groupName', `${res.data.groupName}`);
            sessionStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
            sessionStorage.setItem('userType', `${res.data.userType}`);
            sessionStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
            sessionStorage.setItem('groupId', `${res.data.groupId}`);
            sessionStorage.setItem('groupName', `${res.data.groupName}`);
           
            dispatch({ type: LOGIN, payload: true });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        })

    return request;
}

export const loginWithSSOToken = (selections) => async (dispatch) => {
    return axios.get(`/sso?token=` + selections)
        .then((res) => {
            res.data.groupName = res.data.groupName && res.data.groupName !== 'null' ? res.data.groupName : '';
            // console.log('loginWithSSOTokenData', res.data);
            localStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
            localStorage.setItem('userType', `${res.data.userType}`);
            localStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
            localStorage.setItem('groupId', `${res.data.groupId}`);
            localStorage.setItem('groupName', `${res.data.groupName}`);
            sessionStorage.setItem('jwtToken', `Bearer ${res.data.jwtToken}`);
            sessionStorage.setItem('userType', `${res.data.userType}`);
            sessionStorage.setItem('imarkElectricalCorporateId', `${res.data.userId}`);
            sessionStorage.setItem('groupId', `${res.data.groupId}`);
            sessionStorage.setItem('groupName', `${res.data.groupName}`);
            dispatch({ type: LOGIN_WITH_SSO_TOKEN, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

export const generateSampleSSOAuthenticationToken = (selections) => (dispatch) => {
    let request = axios.get(`/sso/test-encryption?` + selections, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'mode': 'cors'
        }
    })
        .then((res) => {
            // console.log('generatedSampleSSOAuthenticationTokenData', res.data);
            dispatch({ type: GENERATE_SAMPLE_SSO_AUTHENTICATION_TOKEN, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        })

    return request;
}

export const acceptTerms = (selections) => async (dispatch) => {
    let request = axios.get(`/acceptTerms?userId=` + selections.userId)
        .then((res) => {
            return Promise.resolve(res.data);

        });
};

export const disagreeTerms = (selections, callback) => async (dispatch) => {
    let request = axios.get(`/disagreeTerms?userId=` + selections.userId)
        .then((res) => {
            return Promise.resolve(res.data);

        });
};


export const logout = () => {
    localStorage.clear();
    // localStorage.getItem('jwtToken') && localStorage.removeItem('jwtToken');
    // localStorage.getItem('userType') && localStorage.removeItem('userType');
    // localStorage.getItem('userId') && localStorage.removeItem('userId');

    return {
        type: LOGOUT,
        payload: false
    }
}

// ****************************** MEMBER ACTIONS ****************************** //

export const getTerminatedVPNamesForMember = (selections) => async dispatch => {
    let request = axios.get(`/vendor-directory?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: VENDOR_GET_TERMINATED_VP_NAMES_MEMBER, payload: res.data });
            return Promise.resolve(res.data);
        }).catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const getProductCategoryForMember = (selections) => async dispatch => {
    let request = axios.get(`/product-category`, selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: GET_PRODUCT_CATEGORY_FOR_MEMBER, payload: res.data });
            return Promise.resolve(res.data);
        }).catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

// MEMBER - OVERVIEW TAB

export const getCardsDataForMemberDataTab = (selections) => async dispatch => {
    let request = axios.get(`/member/overview/rebate-overview?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_CARDS_DATA_FOR_MEMBER_DATA_TAB, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            // console.log('error', err);
            return Promise.reject(err);
        })
    // console.log('request', request);
    return request;
}

export const getMemberPurchaseWithinImarkValue = (selections) => async dispatch => {
    let request = axios.get(`/member/overview/purchases?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_PURCHASE_WITHIN_IMARK, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            // console.log('error', err);
            return Promise.reject(err);
        })
    // console.log('request', request);
    return request;
}

export const getMemberPurchaseOutsideImarkValue = (selections) => async dispatch => {
    let request = axios.get(`/member/outside-purchases-by-vendor?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_PURCHASE_OUTSIDE_IMARK, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            // console.log('error', err);
            return Promise.reject(err);
        })
    // console.log('request', request);
    return request;
}

export const getMemberOutsidePurchaseByCategory = (selections) => async dispatch => {
    let request = axios.get(`/member/outside-purchases-by-category?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_OUTSIDE_PURCHASE_BY_CATEGORY, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const getVendorFullInfo = (vendorId) => async dispatch => {
    let request = axios.get(`/vendor/${vendorId}/info`)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: VENDOR_FULL_INFO, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const getProductListByCategory = (selections) => async dispatch => {
    let request = axios.get(`/member/outside-purchases-by-category/product-list` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_PRODUCT_CATEGORY, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const saveOutVendorFeedback = (selections) => async dispatch => {
    let request = axios.post(`/save-out-vendor-feedback`, selections)
        .then((res) => {
            console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
    console.log('request', request);
    return request;
}

export const saveProductFeedback = (selections) => async dispatch => {
    let request = axios.post(`/save-product-feedback`, selections)
        .then((res) => {
            console.log('response', res.data);
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
    console.log('request', request);
    return request;
}

// MEMBER - IMARK VERIFIED TAB

export const getCardsDataForIMARKVerifiedDataTab = (selections) => async dispatch => {
    let request = axios.get(`/verified-overview-boxes?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_CARDS_DATA_FOR_IMARKVERIFIED_DATA_TAB, payload: res.data });
            return Promise.resolve(res.data);
        }).catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const getVerifiedRebateTrendsGraph = (selections) => async dispatch => {
    let request = axios.get(`/verified-rebate-trends-graph?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_VERIFIED_REBATE_TRENDS_GRAPH, payload: res.data });
            return Promise.resolve(res.data);
        }).catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const getbHverifiedVendorPartnerPurchases = (selections) => async dispatch => {
    let request = axios.get(`/verified-inside-purchases?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_BH_VERIFIED_VENDOR_PARTNER_PURCHASES, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

// MEMBER - INVOICES TAB

export const getInvoiceData = (selections) => async dispatch => {
    let request = axios.get(`/invoice/list?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_INVOICE_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

// MEMBER - PURCHASE ORDERS TAB

export const getPurchasedOrdersMember = (selections) => async dispatch => {
    let request = axios.get(`/purchase-order/list?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_PURCHASED_ORDERS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

// MEMBER - CONVERSION OPPORTUNITY

export const getTerminatedVPOpportunitiesDetails = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-po-details?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_TERMINATED_VP_OPPORTUNITIES_DETAILS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

export const getTerminatedVPOpportunities = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-overview?` + selections)
        .then((res) => {
            // console.log('response', res.data);
            dispatch({ type: MEMBER_GET_TERMINATED_VP_OPPORTUNITIES, payload: res.data });
            return Promise.resolve(res.data);
        }).catch((error) => {
            // console.log('error', error);
            return Promise.reject(error);
        })
    // console.log('request', request);
    return request;
}

// ****************************** CORPORATE ACTIONS ****************************** //

// CORPORATE - OVERVIEW TAB

export const getCardsDataForCorporateOverviewTab = (selections) => async dispatch => {
    let request = axios.get(`/rebate-overview-verified?` + selections)
        .then((res) => {
            console.log("res", res)
            dispatch({ type: CORPORATE_GET_CARDS_DATA_FOR_OVERVIEW_DATA_TAB, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpVendorPerformanceData = (selections) => async dispatch => {
    let request = axios.get(`/vendor-performance?` + selections)
        .then((res) => {
            console.log("res", res)
            dispatch({ type: CORPORATE_GET_OVERVIEW_VENDOR_PERFORMANCE_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpMemberPerformanceData = (selections) => async dispatch => {
    let request = axios.get(`/member-performance?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_OVERVIEW_MEMBER_PERFORMANCE_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpOverviewGrowthData = (selections) => async dispatch => {
    let request = axios.get(`/growth-rebate-verified?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_OVERVIEW_GROWTH_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpOverviewGraphData = (selections) => async dispatch => {
    let request = axios.get(`/verified-data-overview?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_OVERVIEW_GRAPH_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorporateOverviewVPInvoices = (selections) => async dispatch => {
    let request = axios.get(`/invoice-per-po-by-vendor?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_OVERVIEW_VP_INVOICE, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorporateOverviewMemberInvoices = (selections) => async dispatch => {
    let request = axios.get(`/invoice-per-po-by-member?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_OVERVIEW_MEMBER_INVOICE, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

// CORPORATE - VENDOR DATA TAB
export const getCorpSupplierMonthsFilter = (selections) => async dispatch => {
    let request = axios.get(`/months-filter?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_SUPPLIER_FILTERS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCardsDataForCorporateVendorDataTab = (selections) => async dispatch => {
    let request = axios.get(`/corporate-vendor-rebate-overview?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_CARDS_DATA_FOR_VENDOR_DATA_TAB, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpVendorDataGrowthData = (selections) => async dispatch => {
    let request = axios.get(`/growth-rebate-vendor?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_VENDOR_DATA_GROWTH_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

// CORPORATE - OPPORTUNITY TAB

export const getCorpOppFilter = (selections) => async dispatch => {
    let request = axios.get(`/opportunity-months-filter?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_OPP_FILTERS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCardsDataForCorporateOpportunityTab = (selections) => async dispatch => {
    let request = axios.get(`/corporate-opportunity-overview?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_GET_CARDS_DATA_FOR_OPPORTUNITY_TAB, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpOpportunityRegion = (selections) => async dispatch => {
    let request = axios.get(`/corporate-opportunity-region?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_OPPORTUNITY_REGION, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpOpportunityPurchaseMember = (selections) => async dispatch => {
    let request = axios.get(`/corporate-opportunity-member?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_OPPORTUNITY_MEMBER, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpOpportunityPurchaseVendor = (selections) => async dispatch => {
    let request = axios.get(`/corporate-opportunity-out-vendors?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_OPPORTUNITY_VENDOR, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpOpportunityPurchaseProductGroup = (selections) => async dispatch => {
    console.log('in action');
    let request = axios.get(`/corporate-opportunity-product-group?` + selections)
        .then((res) => {
            console.log('in actions', res.data);
            dispatch({ type: CORPORATE_OPPORTUNITY_PRODUCT_GROUP, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpOpportunitySalesChartData = (selections) => async dispatch => {
    let request = axios.get(`/corporate-opportunity-trend?` + selections)
        .then((res) => {
            console.log('in actions', res.data);
            dispatch({ type: CORPORATE_OPPORTUNITY_SALES_CHART_DATA, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpOpportunityVendorList = (selections) => async dispatch => {
    let request = axios.get(`/vendor-directory?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_OPPORTUNITY_VENDOR_LIST, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpOpportunitySaveVendorFeedback = (selections) => async dispatch => {
    let request = axios.post(`/save-out-vendor-feedback`, selections)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
    return request;
}

// CORPORATE - COMPLIANCE TAB

export const getCorpComplianceFilters = (selections) => async dispatch => {
    let request = axios.get(`/compliance-year-filter?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_COMPLIANCE_FILTERS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpComplianceMember = (selections) => async dispatch => {
    let request = axios.get(`/compliance-member?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_COMPLIANCE_MEMBER, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpComplianceVendor = (selections) => async dispatch => {
    let request = axios.get(`/compliance-vendor?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_COMPLIANCE_VENDOR, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

// CORPORATE - CONVERSION TAB

export const getCorpConversionFilters = (selections) => async dispatch => {
    let request = axios.get(`/conversion-months-filter?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_FILTERS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionOppMember = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-member?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_MEMBER, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionOppProductGroup = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-product-group?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_PRODUCT_GROUP, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionOppPODetails = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-po-details?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_PO_DETAILS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionOppConversionDetails = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-overview?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_OPPORTUNITY_OVERVIEW_DETAILS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionOppVendorList = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-vendor-list?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_OPPORTUNITY_VENDOR_LIST, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionProductCategoryList = (selections) => async dispatch => {
    let request = axios.get(`/product-category?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_PRODUCT_CATEGORY_LIST, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpConversionOppTopTenMember = (selections) => async dispatch => {
    let request = axios.get(`/top-ten-conversion-opportunity-member?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_TOP_TEN_MEMBER, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpConversionOppTopTenProductGroup = (selections) => async dispatch => {
    let request = axios.get(`/top-ten-conversion-opportunity-product-group?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_TOP_TEN_PRODUCT_GROUP, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionFavVendorList = (selections) => async dispatch => {
    let request = axios.get(`/vendor-directory?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_FAV_VENDOR_LIST, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpConversionProducts = (selections) => async dispatch => {
    let request = axios.get(`/conversion-opportunity-products?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_CONVERSION_PRODUCTS, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpConversionSaveProductsFeedback = (selections) => async dispatch => {
    let request = axios.post(`/save-product-feedback`, selections)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
    return request;
}

export const getCorpConversionSaveFavSupplierFeedback = (selections) => async dispatch => {
    let request = axios.post(`/save-product-feedback`, selections)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
    return request;
}


//CORP FEEDBACK

export const getCorpFeedBackWrongCat = (selections) => async dispatch => {
    let request = axios.get(`/wrong-category-feedback?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_FEEDBACK_WRONG_CATEGORY, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpFeedBackFavSupplier = (selections) => async dispatch => {
    let request = axios.get(`/favorable-vendor-feedback?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_FEEDBACK_FAV_SUPPLIER, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpFeedBackProductNotSold = (selections) => async dispatch => {
    let request = axios.get(`/product-not-sold-feedback?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_FEEDBACK_PRODUCT_NOT_SOLD, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const getCorpFeedBackINSupplier = (selections) => async dispatch => {
    let request = axios.get(`/in-vendor-feedback?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_FEEDBACK_IN_VENDOR, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpFeedBackOUTSupplier = (selections) => async dispatch => {
    let request = axios.get(`/out-vendor-feedback?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_FEEDBACK_OUT_VENDOR, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}
export const getCorpFeedBackBuySellSupplier = (selections) => async dispatch => {
    let request = axios.get(`/buysell-rep-vendor-feedback?` + selections)
        .then((res) => {
            dispatch({ type: CORPORATE_FEEDBACK_BUY_SELL_VENDOR, payload: res.data });
            return Promise.resolve(res.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        })
    return request;
}

export const updateFeedbackWrongCategory =
    (id, feedbackStatus, callback) => async dispatch => {
        axios.post(`/wrong-category-feedback/${id}`,
            {
                request: feedbackStatus,
            }
        )
            .then((res) => {
                callback(res);
            }).catch(err => {
                console.log(err);
            })

    }

export const updateFeedbackProductNotSold =
    (id, feedbackStatus, callback) => async dispatch => {
        axios.post(`/product-not-sold-feedback/${id}`,
            {
                request: feedbackStatus,
            }
        )
            .then((res) => {
                callback(res);
            }).catch(err => {
                console.log(err);
            })

    }
export const updateFeedbackFavourableVendor =
    (id, feedbackStatus, callback) => async dispatch => {
        axios.post(`/favorable-vendor-feedback/${id}`,
            {
                request: feedbackStatus,
            }
        )
            .then((res) => {
                callback(res);
            }).catch(err => {
                console.log(err);
            })

    }
export const updateFeedbackforInOutLpgBuySellVendor =
    (id, feedbackStatus, callback) => async dispatch => {
        axios.post(`/vendor-feedback/${id}`,
            {
                request: feedbackStatus,
            }
        )
            .then((res) => {
                callback(res);
            }).catch(err => {
                console.log(err);
            })

    }

