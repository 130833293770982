import {
    CORPORATE_GET_CARDS_DATA_FOR_OVERVIEW_DATA_TAB,
    CORPORATE_GET_OVERVIEW_GRAPH_DATA,
    CORPORATE_GET_OVERVIEW_VENDOR_PERFORMANCE_DATA,
    CORPORATE_GET_OVERVIEW_MEMBER_PERFORMANCE_DATA,
    CORPORATE_GET_OVERVIEW_GROWTH_DATA,
    CORPORATE_GET_CARDS_DATA_FOR_VENDOR_DATA_TAB,
    CORPORATE_GET_VENDOR_DATA_GROWTH_DATA,
    CORPORATE_SUPPLIER_FILTERS,
    CORPORATE_GET_OVERVIEW_VP_INVOICE,
    CORPORATE_GET_OVERVIEW_MEMBER_INVOICE,
    CORPORATE_GET_CARDS_DATA_FOR_OPPORTUNITY_TAB,
    CORPORATE_OPPORTUNITY_REGION,
    CORPORATE_OPPORTUNITY_MEMBER,
    CORPORATE_OPPORTUNITY_VENDOR,
    CORPORATE_OPP_FILTERS,
    CORPORATE_OPPORTUNITY_PRODUCT_GROUP,
    CORPORATE_OPPORTUNITY_SALES_CHART_DATA,
    CORPORATE_OPPORTUNITY_VENDOR_LIST,
    CORPORATE_COMPLIANCE_FILTERS,
    CORPORATE_COMPLIANCE_MEMBER,
    CORPORATE_COMPLIANCE_VENDOR,
    CORPORATE_CONVERSION_MEMBER,
    CORPORATE_CONVERSION_FILTERS,
    CORPORATE_CONVERSION_PRODUCT_GROUP,
    CORPORATE_CONVERSION_PO_DETAILS,
    CORPORATE_CONVERSION_OPPORTUNITY_OVERVIEW_DETAILS,
    CORPORATE_CONVERSION_OPPORTUNITY_VENDOR_LIST,
    CORPORATE_CONVERSION_PRODUCT_CATEGORY_LIST,
    CORPORATE_CONVERSION_TOP_TEN_MEMBER,
    CORPORATE_CONVERSION_TOP_TEN_PRODUCT_GROUP,
    CORPORATE_CONVERSION_FAV_VENDOR_LIST,
    CORPORATE_CONVERSION_PRODUCTS,
    CORPORATE_FEEDBACK_WRONG_CATEGORY,
    CORPORATE_FEEDBACK_FAV_SUPPLIER,
    CORPORATE_FEEDBACK_PRODUCT_NOT_SOLD,
    CORPORATE_FEEDBACK_IN_VENDOR,
    CORPORATE_FEEDBACK_OUT_VENDOR,
    CORPORATE_FEEDBACK_BUY_SELL_VENDOR,

} from '../actions/types';

export default function reducerCorporate(state = {
    isFetching: true
}, action) {
    let isFetching = true;
    switch (action.type) {
        /* ******************************** CORP - OVERVIEW ******************************** */
        case CORPORATE_GET_CARDS_DATA_FOR_OVERVIEW_DATA_TAB:
            isFetching = false;
            return { ...state, getCardsDataForCorporateOverviewTab: action.payload, isFetching };
        case CORPORATE_GET_OVERVIEW_VENDOR_PERFORMANCE_DATA:
            isFetching = false;
            return { ...state, getCorporateOverviewVendorPerformance: action.payload, isFetching };
        case CORPORATE_GET_OVERVIEW_MEMBER_PERFORMANCE_DATA:
            isFetching = false;
            return { ...state, getCorporateOverviewMemberPerformance: action.payload, isFetching };
        case CORPORATE_GET_OVERVIEW_GROWTH_DATA:
            isFetching = false;
            return { ...state, getCorporateOverviewGrowth: action.payload, isFetching };
        case CORPORATE_GET_OVERVIEW_GRAPH_DATA:
            isFetching = false;
            return { ...state, getCorporateOverviewGraphData: action.payload, isFetching };
        case CORPORATE_GET_OVERVIEW_VP_INVOICE:
            isFetching = false;
            return { ...state, getCorporateOverviewVpInvoice: action.payload, isFetching };
        case CORPORATE_GET_OVERVIEW_MEMBER_INVOICE:
            isFetching = false;
            return { ...state, getCorporateOverviewMemberInvoice: action.payload, isFetching };

        /* ******************************** CORP - VENDOR DATA ******************************** */

        case CORPORATE_GET_CARDS_DATA_FOR_VENDOR_DATA_TAB:
            isFetching = false;
            return { ...state, getCardsDataForCorporateVendorDataTab: action.payload, isFetching };
        case CORPORATE_GET_VENDOR_DATA_GROWTH_DATA:
            isFetching = false;
            return { ...state, getCorporateVendorDataGrowth: action.payload, isFetching };
        case CORPORATE_SUPPLIER_FILTERS:
            isFetching = false;
            return { ...state, getCorporateVendorFilters: action.payload, isFetching };
        /* ******************************** CORP - OPPORTUNITY ******************************** */
        case CORPORATE_GET_CARDS_DATA_FOR_OPPORTUNITY_TAB:
            isFetching = false;
            return { ...state, getCardsDataForCorporateOpportunityTab: action.payload, isFetching };

        case CORPORATE_OPPORTUNITY_MEMBER:
            isFetching = false;
            return { ...state, getCorporateOpportunityMember: action.payload, isFetching };

        case CORPORATE_OPPORTUNITY_VENDOR:
            isFetching = false;
            return { ...state, getCorporateOpportunityVendor: action.payload, isFetching };
        case CORPORATE_OPPORTUNITY_PRODUCT_GROUP:
            isFetching = false;
            return { ...state, getCorporateOpportunityProductGroup: action.payload, isFetching };

        case CORPORATE_OPPORTUNITY_SALES_CHART_DATA:
            isFetching = false;
            return { ...state, getCorporateOpportunitySalesChartData: action.payload, isFetching };

        case CORPORATE_OPPORTUNITY_VENDOR_LIST:
            isFetching = false;
            return { ...state, getCorporateOpportunityVendorList: action.payload, isFetching };

        case CORPORATE_OPP_FILTERS:
            isFetching = false;
            return { ...state, getCorporateOppFilters: action.payload, isFetching };

        case CORPORATE_OPPORTUNITY_REGION:
            isFetching = false;
            return { ...state, getCorporateOppRegion: action.payload, isFetching };

        /* ******************************** CORP - COMPLIANCE ******************************** */

        case CORPORATE_COMPLIANCE_FILTERS:
            isFetching = false;
            return { ...state, getCorporateComplianceFilters: action.payload, isFetching };

        case CORPORATE_COMPLIANCE_MEMBER:
            isFetching = false;
            return { ...state, getCorporateComplianceMember: action.payload, isFetching };

        case CORPORATE_COMPLIANCE_VENDOR:
            isFetching = false;
            return { ...state, getCorporateComplianceVendor: action.payload, isFetching };
        /* ******************************** CORP - CONVERSION ******************************** */

        case CORPORATE_CONVERSION_FILTERS:
            isFetching = false;
            return { ...state, getCorporateConversionFilters: action.payload, isFetching };

        case CORPORATE_CONVERSION_MEMBER:
            isFetching = false;
            return { ...state, getCorporateConversionMember: action.payload, isFetching };

        case CORPORATE_CONVERSION_PRODUCT_GROUP:
            isFetching = false;
            return { ...state, getCorporateConversionProductGroup: action.payload, isFetching };

        case CORPORATE_CONVERSION_PO_DETAILS:
            isFetching = false;
            return { ...state, getCorporateConversionPODetails: action.payload, isFetching };

        case CORPORATE_CONVERSION_OPPORTUNITY_OVERVIEW_DETAILS:
            isFetching = false;
            return { ...state, getCorporateConversionOpportunityOverview: action.payload, isFetching };

        case CORPORATE_CONVERSION_OPPORTUNITY_VENDOR_LIST:
            isFetching = false;
            return { ...state, getCorporateConversionOpportunityVendorList: action.payload, isFetching };

        case CORPORATE_CONVERSION_PRODUCT_CATEGORY_LIST:
            isFetching = false;
            return { ...state, getCorporateConversionProductCatList: action.payload, isFetching };

        case CORPORATE_CONVERSION_FAV_VENDOR_LIST:
            isFetching = false;
            return { ...state, getCorporateConversionFavVendorList: action.payload, isFetching };

        case CORPORATE_CONVERSION_PRODUCTS:
            isFetching = false;
            return { ...state, getCorporateConversionProducts: action.payload, isFetching };

        case CORPORATE_CONVERSION_TOP_TEN_MEMBER:
            isFetching = false;
            return { ...state, getCorporateConversionTopTenMember: action.payload, isFetching };

        case CORPORATE_CONVERSION_TOP_TEN_PRODUCT_GROUP:
            isFetching = false;
            return { ...state, getCorporateConversionTopTenProductGroup: action.payload, isFetching };






        /* ******************************** CORP - CONVERSION ******************************** */
        case CORPORATE_FEEDBACK_WRONG_CATEGORY:
            isFetching = false;
            return { ...state, getCorporateFeedbackWrongCat: action.payload, isFetching };

        case CORPORATE_FEEDBACK_FAV_SUPPLIER:
            isFetching = false;
            return { ...state, getCorporateFeedbackFavSupplier: action.payload, isFetching };

        case CORPORATE_FEEDBACK_PRODUCT_NOT_SOLD:
            isFetching = false;
            return { ...state, getCorporateFeedbackProductNotSold: action.payload, isFetching };

        case CORPORATE_FEEDBACK_IN_VENDOR:
            isFetching = false;
            return { ...state, getCorporateFeedbackINVendor: action.payload, isFetching };

        case CORPORATE_FEEDBACK_OUT_VENDOR:
            isFetching = false;
            return { ...state, getCorporateFeedbackOUTVendor: action.payload, isFetching };

        case CORPORATE_FEEDBACK_BUY_SELL_VENDOR:
            isFetching = false;
            return { ...state, getCorporateFeedbackBuySellVendor: action.payload, isFetching };

        default:
            return state;
    }

}

