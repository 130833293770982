import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import {InvoiceFilters} from './InvoiceFilters/InvoiceFilters';
import InvoicesTable from './InvoicesTable/InvoicesTable';
import { getVisitedTabId } from '../../../utils/getVisitedTabId';
// import { convertJSONToURLParams } from '../../utils/formatters';

import {
    updateSelectedNavIndex,
    // getInvoiceData
} from '../../../actions';
import {
    getTerminatedVPNamesForMember,
    getInvoiceData
} from '../../../api/member-api';

class MemberInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isContentLoaded: false,
            vendorId: "",
            vendorName: "",
            startDate: "",
            endDate: "",
            invoiceNo: "",
            poNumber: "",
            suppliers: [],
            invoices: [],
            filteredInvoices: []
        }
        this.invoiceNoRef = React.createRef();
    }

    static getDerivedStateFromProps(props) {
        console.log('Member ID in Member Component', props.match.params.id);
        return { memberId: props.match.params.id };
    }

    componentDidMount() {
        let idx = getVisitedTabId(window.location.hash);
        this.props.updateSelectedNavIndex(idx);

        this.loadData();
    }

    componentDidUpdate(pP, pS, sS) {
        if(pS.poNumber !== this.state.poNumber) {
            this.getInvoices()
        }
        // if(pS.invoiceNo !== this.state.invoiceNo) {
        //     this.getInvoices()
        // }
        
        if(pS.startDate !== this.state.startDate) {
            this.getInvoices()
        }

        if(pS.endDate !== this.state.endDate) {
            this.getInvoices()
        }

        if(
            pS.vendorName !== this.state.vendorName &&
            pS.vendorId !== this.state.vendorId
        ) {
            this.getInvoices()
        }
    }

    loadData = () => {
        this.mounted = true;

        const formData = {
            memberId: this.state.memberId,
            fromDate: this.state.startDate, // YYYY-MM-DD
            toDate: this.state.endDate, // YYYY-MM-DD
            invoiceNo: this.state.invoiceNo,
            vendorId: this.state.vendorId,
            poNumber: this.state.poNumber
            // start: Number(),
            // length: Number(),
        }

        // const selections = convertJSONToURLParams(formData);
        // this.props.getInvoiceDataAPI(selections)

        Promise.allSettled([
            getInvoiceData(formData),
            getTerminatedVPNamesForMember()
        ])
        .then((allResponses) => {
            console.log('MEMBER INVOICES ON MOUNT ==>', allResponses);

            if(this.mounted) {
                if(allResponses[0].value) {
                    this.setState({ invoices: allResponses[0].value ? allResponses[0].value : [] })
                }
                if(allResponses[1].value) {
                    this.setState({ suppliers: allResponses[1].value ? allResponses[1].value : [] })
                }
            }

            this.setState({ isLoaded: true })
        })
        .catch((err) => console.log(err));
    }

    getInvoices = () => {
        this.mounted = true;

        this.setState({ isContentLoaded: true })

        const formData = {
            memberId: this.state.memberId,
            fromDate: this.state.startDate, // YYYY-MM-DD
            toDate: this.state.endDate, // YYYY-MM-DD
            invoiceNo: this.state.invoiceNo,
            vendorId: this.state.vendorId,
            poNumber: this.state.poNumber
            // start: Number(),
            // length: Number(),
        }

        Promise.allSettled([ getInvoiceData(formData) ])
        .then((allResponses) => {
            if(this.mounted) {
                if(allResponses[0].value) {
                    this.setState({ invoices: allResponses[0].value ? allResponses[0].value : [] })
                }

                this.setState({ isContentLoaded: false });
            }
        })
        .catch((err) => console.log(err));
    }

    getMorePurchaseOrdersRows = (start, length) => {
        this.mounted = true;

        const formData = {
            memberId: this.state.memberId,
            fromDate: this.state.startDate, // YYYY-MM-DD
            toDate: this.state.endDate, // YYYY-MM-DD
            invoiceNo: this.state.invoiceNo,
            vendorId: this.state.vendorId,
            poNumber: this.state.poNumber,
            start: start,
            length: length,
        }

        Promise.allSettled([ getInvoiceData(formData) ])
        .then((allResponses) => {
            if(this.mounted) {
                if(allResponses[0].value) {
                    let invoices = this.state.invoices.concat(allResponses[0].value);
                    this.setState({ invoices })
                }
            }
        })
        .catch((err) => console.log(err));
    }

    handleSelectedSupplier = (e) => {
        let { invoices } = this.state;

        let po = invoices && invoices.find((item) => item.vendorId === e.target.value.split('=')[0])
        console.log(po);

        if(e.target.value) {
            this.setState({
                vendorId: e.target.value.split('=')[0],
                vendorName: e.target.value.split('=')[1],
                poNumber: po ? po.poNo : ""
            })
        } else {
            this.setState({ vendorId: "", vendorName: "", poNumber: "" })
        }
    }

    handleStartDate = (e) => this.setState({ startDate: moment(e.target.value).locale('en').format('YYYY-MM-DD') });
    handleEndDate = (e) => this.setState({ endDate: moment(e.target.value).locale('en').format('YYYY-MM-DD') });

    handleInvoiceNoReference = (e) => {
        this.setState(
            { invoiceNo: e.target.value },
            () => {
                this.filterByInvoiceNo()
            }
        );
    }

    filterByInvoiceNo = (e) => {
        
        let results = this.state.invoices.filter((item) => {
            return item.invoiceNo.toString().indexOf(this.state.invoiceNo.toString()) >= 0;
        })

        this.setState({
            filteredInvoices: results
        });
    }

    handleSearchInvoices = () => {
        let results = this.state.invoices.filter((item) => {
            return item.invoiceNo.toLowerCase().indexOf(this.state.invoiceNo.toLowerCase()) >= 0;
        })
        this.setState({ filteredInvoices: results });
    }

    handleClearInvoiceNo = () => {
        this.invoiceNoRef.current.value = "";
        this.setState({ invoiceNo: "", filteredInvoices: [] })
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        let {
            isLoaded,
            isContentLoaded,
            // memberId,
            // vendorId,
            // vendorName,
            // startDate,
            // endDate,
            invoiceNo,
            poNumber,
            invoices,
            suppliers,
            filteredInvoices,
        } = this.state;
        // let { invoices } = this.props;

        return !isLoaded ? <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <Spinner animation="border" variant="primary" />
        </div> :
            <div className="invoiceContainer">
                <InvoiceFilters
                    invoices={filteredInvoices.length ? filteredInvoices : invoices}
                    suppliers={suppliers}
                    handleSelectedSupplierReference={this.handleSelectedSupplier}
                    handleInvoiceNoReference={this.handleInvoiceNoReference}
                    handleStartDateReference={this.handleStartDate}
                    handleEndDateReference={this.handleEndDate}
                    handleSearchInvoicesReference={this.handleSearchInvoices}
                    handleClearInvoiceNoReference={this.handleClearInvoiceNo}
                    ref={this.invoiceNoRef}
                    memberName={localStorage.getItem('groupName')}
                />
                {/* <pre style={{ marginLeft: 60 }}>
                    {JSON.stringify({
                        isLoaded,
                        invoiceNo,
                        poNumber
                    }, null, '\t')}
                </pre> */}
                {isContentLoaded ?
                    <div style={{
                        display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
                        minHeight: 1000
                    }}>
                        <Spinner animation="border" variant="primary" />
                    </div> :
                    <InvoicesTable
                        heading={"Invoices"}
                        invoices={filteredInvoices.length ? filteredInvoices : invoices}
                        loadMoreData = {this.getMorePurchaseOrdersRows}
                        invoiceNo={invoiceNo}
                    />
                }
            </div>
    }
}

function mapStateToProps(state) {
    return {
        invoices: state.member.getInvoiceData
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSelectedNavIndex: updateSelectedNavIndex,
        // getInvoiceDataAPI: getInvoiceData
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberInvoices);
