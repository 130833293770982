import React from 'react';
import ReactTable from "react-table-6";
import { Card, InputGroup, FormControl, Button } from 'react-bootstrap';
import { priceFormatter } from '../../../../utils/formatters';

import '../../../../assets/styles/ConversionOpportunityPODetails.scss';

export default class ConversionOpportunityPODetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // conversionOpportunityPODetails: [],
            searchKey: "",
            filteredSearchResults: [...this.props.conversionOpportunityPODetails],
            isFilteredDataAvailable: true
        }
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return item.leavingVendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0;
        })

        this.setState({
            isFilteredDataAvailable: results.length >= 1 ? true : false,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: true,
        filteredSearchResults: [...this.props.conversionOpportunityPODetails]
    });

    render() {
        const {
            // conversionOpportunityPODetails,
            searchKey,
            filteredSearchResults
        } = this.state;
        const { conversionOpportunityPODetails } = this.props;

        return (
            <Card className="conversionOpportunityPODetailsTableContainer">
                <Card.Header>Conversion Opportunity PO Details</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search for conversion opportunity po details info.."
                                aria-label="Search for conversion opportunity po details info.."
                                aria-describedby="basic-addon2"
                                value={searchKey}
                                onChange={(e) => this.searchTable(e, conversionOpportunityPODetails)}
                            />
                            <InputGroup.Append>
                                <Button onClick={(e) => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <ReactTable
                        data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.conversionOpportunityPODetails }
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                accessor: "leavingVendorName",
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>PO Count</b>,
                                accessor: 'poCount',
                                sortable: true,
                                resizable: true,
                                style: { textAlign: 'right' },
                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b>Purchases</b>,
                                accessor: 'purchases',
                                sortable: true,
                                resizable: false,
                                style: { textAlign: 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.purchases)}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Average Purchases Per PO</b>,
                                accessor: 'avgPurchases',
                                sortable: true,
                                resizable: true,
                                style: { textAlign: 'right' },
                                Cell: props => <div style={{ textAlign: "center" }}>{priceFormatter(props.original.avgPurchases)}</div>
                            }
                        ]}
                        defaultPageSize={25}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                    />
                </div>
            </Card>
        );
    }
}
