import React, { Component } from 'react';
import { Form, Button, ListGroup, Row, Col } from 'react-bootstrap';
import { toast } from "react-toastify";
import {
    updateFeedbackWrongCategory,
    updateSelectedFeedbackWrongCategory
} from '../../../api/corporate-api';

class WrongCategoryFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            FeedbackWrongCat: this.props.getCorporateFeedbackWrongCat
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', this.state.FeedbackWrongCat);
        if (this.props !== nextProps) {
            this.setState({ FeedbackWrongCat: nextProps });
            // console.log('componentWillReceiveProps in if condition', nextProps);
        }
    }

    updateFeedback = (feedback, status) => {
        updateFeedbackWrongCategory(feedback, { feedbackStatus: status }).then((response) => {
            let existingFeedback = [];
            Object.assign(existingFeedback, this.state.FeedbackWrongCat);
            if (existingFeedback) {
                existingFeedback.getCorporateFeedbackWrongCat.splice(existingFeedback.getCorporateFeedbackWrongCat.findIndex(feed => feed.id === feedback), 1);
                this.setState({ FeedbackWrongCat: existingFeedback });
                toast(response.text, 50);
            }
        })
    }

    updateSelectedFeedback = (status) => {
        const formData = {
            feedbackStatus: status,
            ids: this.state.selectedFeedbackIds
        }
        updateSelectedFeedbackWrongCategory(formData).then((response) => {
            let existingFeedback = [];
            Object.assign(existingFeedback, this.state.FeedbackWrongCat);
            console.log("ids", this.state.selectedFeedbackIds)
            if (existingFeedback) {
                this.state.selectedFeedbackIds.forEach(feedbackId => existingFeedback.getCorporateFeedbackWrongCat.splice(existingFeedback.getCorporateFeedbackWrongCat.findIndex(feed => feed.id === parseInt(feedbackId)), 1));
                this.setState({ FeedbackWrongCat: existingFeedback, selectedFeedbackIds: [] });
                toast(response.text, 50);
            }
        })
    }

    selectFeedback = (event) => {
        let existingSelectedFeedbackIds = [];
        Object.assign(existingSelectedFeedbackIds, this.state.selectedFeedbackIds);
        if (event.target.checked === true) {
            existingSelectedFeedbackIds.push(event.target.value);
        } else {
            existingSelectedFeedbackIds.splice(existingSelectedFeedbackIds.indexOf(event.target.value, 1));
        }
        this.setState({ selectedFeedbackIds: existingSelectedFeedbackIds });
    };

    render() {
        let { getCorporateFeedbackWrongCat } = this.props;
        // console.log('getCorporateFeedbackWrongCat', getCorporateFeedbackWrongCat);

        return (
            <React.Fragment>
                <ListGroup.Item variant="light" bg="light">
                    <Form inline >
                        <Button variant="success" className="mr-2" onClick={() => this.updateSelectedFeedback('Approved')}>Approve selected feedbacks</Button>
                        <Button variant="danger" onClick={() => this.updateSelectedFeedback('Rejected')}>Reject selected feedbacks</Button>
                    </Form>
                </ListGroup.Item>
                <ListGroup>
                    {
                        getCorporateFeedbackWrongCat && getCorporateFeedbackWrongCat.length > 0 ?
                            getCorporateFeedbackWrongCat.map(feedback => <ListGroup.Item key={feedback.id} className="border my-2">
                                <Row>
                                    <Col xs="auto"><Form.Check type="checkbox" value={feedback.id} onChange={this.selectFeedback} /></Col>
                                    <Col>
                                        <p>Product <strong>{feedback.productDesc}</strong> is wrongly categorized as <strong>{feedback.productCategory}</strong></p>
                                        <p>Suggested category is <strong>{decodeURIComponent(feedback.productCategorySuggested)}</strong></p>
                                        {feedback.feedbackComments ? <p>Comments: <em>{feedback.feedbackComments}</em></p> : ''}
                                        <small>
                                            Feedback from {feedback.userDirectory && feedback.userDirectory.userType.description + ' user: '}
                                            <strong style={{ marginLeft: '4px' }}>
                                                {(feedback.userDirectory && feedback.userDirectory.firstName ? feedback.userDirectory.firstName : '') +
                                                    (feedback.userDirectory && feedback.userDirectory.lastName ? ' ' + feedback.userDirectory.lastName : '')}
                                                <em> ({feedback.userDirectory && feedback.userDirectory.email ? feedback.userDirectory.email : ''})</em>
                                            </strong>
                                        </small>
                                        <p>
                                            <small>
                                                Created On:
                                                <strong style={{ marginLeft: '4px' }}>
                                                    {new Date(feedback.createdOn).toLocaleDateString('en-US')}
                                                </strong>

                                                <em style={{ marginLeft: '4px' }}>
                                                    Updated On:</em>
                                                <strong style={{ marginLeft: '4px' }}>
                                                    {new Date(feedback.updatedOn).toLocaleDateString('en-US')}
                                                </strong>
                                            </small>
                                        </p>

                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="outline-success" onClick={() => this.updateFeedback(feedback.id, 'Approved')}>Approve</Button>
                                        <Button variant="outline-danger ml-2" onClick={() => this.updateFeedback(feedback.id, 'Rejected')}>Reject</Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>) : <ListGroup.Item className="text-center text-primary border my-2 bg-light">No Feedbacks</ListGroup.Item>
                    }</ListGroup>
            </React.Fragment>
        );
    }
}


export default WrongCategoryFeedback;
