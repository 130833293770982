import React from 'react';
import { Card } from 'react-bootstrap';
import { switchIndicator } from '../../utils/switchIndicator';
import { formatYOY, priceFormatter } from '../../utils/formatters';
import '../../assets/styles/Cards.scss';

const Cards = ({ cardItems }) => {
    return (
        <div className="cardsContainer" style={{'gridTemplateColumns': 'repeat(3, 3fr)'}} >
            {cardItems && cardItems.map((card, index) => {
                return (
                    <Card className="rebateCard" key={index}>
                        <Card.Body>{card.cardTitle !== ''?<div>
                            <Card.Text className="cardTitle">{card.cardTitle}</Card.Text>
                            <Card.Text className="cardSubtitle">{priceFormatter(card.value)}</Card.Text>
                            <div className="cardBottom">
                                <Card.Text>YOY</Card.Text>
                                <div className="cardRatings">
                                    <Card.Text>{formatYOY(card.value, card.lastYearValue)}</Card.Text>
                                    {switchIndicator(formatYOY(card.value, card.lastYearValue))}
                                </div>
                            </div>
                            </div>:null}
                        </Card.Body>
                    </Card>
                )
            })}
        </div>
    );
};

export default Cards;