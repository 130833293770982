import Axios from 'axios';
import { BASE_URL } from '../index';
import { convertJSONToURLParams } from '../utils/formatters';

// CORP - OVERVIEW TAB

export const getCorpMonthsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);
    return Axios.get(`/months-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    }).then((res) => {
        return res.data;
    }).catch((err) => {
        console.log('error', err);
    });
}

export const getCardsDataForCorporateOverviewTab = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/rebate-overview-verified?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpVendorPerformanceData = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/vendor-performance?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpMemberPerformanceData = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/member-performance?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpOverviewGrowthData = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/growth-rebate-verified?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorporateOverviewVPInvoices = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/invoice-per-po-by-vendor?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorporateOverviewMemberInvoices = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/invoice-per-po-by-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

// CORP - VENDOR DATA TAB

export const getCorpSupplierMonthsFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/months-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        }).catch((err) => {
            console.log('error', err);
        })
}

export const getCardsDataForCorporateVendorDataTab = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/corporate-vendor-rebate-overview?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpVendorDataGrowthData = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/growth-rebate-vendor?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpOverviewGraphData = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/verified-data-overview?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

// CORP - OPPORTUNITY TAB

export const getCorpOppFilter = (formData) => {
    const selections = convertJSONToURLParams(formData);

    let request = Axios.get(`/opportunity-months-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })

    return request
        .then((res) => {
            return res.data;
        }).catch((err) => {
            console.log('error', err);
        })
}

export const getCardsDataForCorporateOpportunityTab = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/corporate-opportunity-overview?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpOpportunityPurchaseMember = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/corporate-opportunity-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpOpportunityPurchaseVendor = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request =  Axios.get(`/corporate-opportunity-out-vendors?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpOpportunityPurchaseProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/corporate-opportunity-product-group?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorporateOpportunitySalesChartData = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request;
    if (BASE_URL === process.env.REACT_APP_API_ROOT_URL) {
        request = Axios.get(`/corporate-opportunity-trend?` + selections, {
            headers: {
                'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
            }
        })
        return request
            .then((res) => {
                let responseData = res.data;
                let months = ['x', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
                let sales = []
                let salesOpportunity = []
                let rebate = []
                let rebateOpportunity = []
                sales.push('Sales ($)')
                salesOpportunity.push('Sales Opportunity ($)')
                rebate.push('Rebate ($)')
                rebateOpportunity.push('Rebate Opportunity ($)')
                responseData.forEach(item => {
                    sales[item.month] = item.totalMemberSales
                    salesOpportunity[item.month] = item.salesOpportunity
                    rebate[item.month] = item.totalRebate
                    rebateOpportunity[item.month] = item.rebateOpportunity
                })
                let columns = {
                    months: months,
                    sales: sales,
                    salesOpportunity: salesOpportunity,
                    rebate: rebate,
                    rebateOpportunity: rebateOpportunity
                }
                return columns
            })
            .catch((err) => {
                console.log(err);
            })
    } else {
        let columns = {
            months: ['x', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            sales: ['Sales ($)', 166785899, 349008774, 543000445, 654788332, 700874000, 750876221, 950000223, 1100773331, 1150772889, 1250774321, 1320890665, 1423699798],
            salesOpportunity: ['Sales Opportunity ($)', 46898265, 98137228, 152685441, 184118901, 197077657, 211137703, 267129068, 309524721, 323584018, 351703263, 371419168, 400327906],
            rebate: ['Rebate ($)', 3836404, 8027889, 12490080, 15061422, 16121483, 17271633, 21851877, 25319956, 26470044, 28770274, 30383088, 32747901],
            rebateOpportunity: ['Rebate Opportunity ($)', 1500744, 3140391, 4885934, 5891805, 6306485, 6756406, 8548130, 9904791, 10354689, 11254504, 11885413, 12810493]
        }
        return columns
    }
}

export const getCorpOpportunityVendorList = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request =Axios.get(`/vendor-directory?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpOpportunitySaveVendorFeedback = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/save-out-vendor-feedback`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getCorpOpportunityRegion = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/corporate-opportunity-region?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

// CORP - COMPLIANCE

export const getCorpComplianceFilters = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/compliance-year-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpComplianceMember = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/compliance-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpComplianceVendor = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/compliance-vendor?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

// CORP - CONVERSION

export const getCorpConversionFilters = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/conversion-months-filter?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionProductCategoryList = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/product-category?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionFavVendorList = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/vendor-directory?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionProducts = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/conversion-opportunity-products?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionSaveProductsFeedback = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/save-product-feedback`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getCorpConversionSaveFavSupplierFeedback = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/save-product-feedback`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const getCorpConversionOppMember = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/conversion-opportunity-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionOppTopTenMember = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/top-ten-conversion-opportunity-member?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionOppTopTenProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/top-ten-conversion-opportunity-product-group?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionOppProductGroup = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/conversion-opportunity-product-group?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionOppPODetails = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/conversion-opportunity-po-details?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionOppConversionDetails = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request = Axios.get(`/conversion-opportunity-overview?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpConversionOppVendorList = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/conversion-opportunity-vendor-list?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

// CORP - FEEDBACK

export const getCorpFeedBackWrongCat = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/wrong-category-feedback?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpFeedBackFavSupplier = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/favorable-vendor-feedback?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpFeedBackProductNotSold = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/product-not-sold-feedback?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpFeedBackINSupplier = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/in-vendor-feedback?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpFeedBackOUTSupplier = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/out-vendor-feedback?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const getCorpFeedBackBuySellSupplier = (formData) => {
    const selections = convertJSONToURLParams(formData);
    let request= Axios.get(`/buysell-rep-vendor-feedback?` + selections, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    })
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log(err);
        })
}

export const updateFeedbackWrongCategory = (id, feedbackStatus, callback) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/wrong-category-feedback/${id}`, feedbackStatus, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateSelectedFeedbackWrongCategory = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/wrong-category-feedback-update`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateFeedbackProductNotSold = (id, feedbackStatus, callback) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/product-not-sold-feedback/${id}`, feedbackStatus, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateSelectedFeedbackProductNotSold = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/product-not-sold-feedback-update`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateFeedbackFavourableVendor = (id, feedbackStatus, callback) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/favorable-vendor-feedback/${id}`, feedbackStatus, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateSelectedFeedbackFavourableVendor = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/favorable-vendor-feedback-update`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateFeedbackforInOutLpgBuySellVendor = (id, feedbackStatus, callback) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/vendor-feedback/${id}`, feedbackStatus, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}

export const updateSelectedFeedbackforInOutLpgBuySellVendor = (formData) => {
    // const selections = convertJSONToURLParams(formData);
    let request = Axios.post(`/vendor-feedback-update`, formData, {
        headers: {
            'Authorization': localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')
        }
    });
    return request
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            console.log('error', err);
        })
}
