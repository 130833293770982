import React from 'react';
import ReactTable from "react-table-6";
import { Button, Card, Form, Col, Modal, InputGroup, FormControl } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Noty from 'noty';
import { priceFormatter } from '../../../../utils/formatters';

import { saveProductFeedback } from '../../../../api/member-api';

import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
// import "../../../../../node_modules/react-toggle/style.css"
import '../../../../assets/styles/ConversionOpportunityTable.scss';
import Select from 'react-select';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import './ConversionOpportunityTable.css';

export default class ConversionOpportunityTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSubmit: false,
            favorableVendorWrong: false,
            productGroupId: null,
            productGroupName: null,
            favorableVendorId: null,
            favorableVendorName: null,
            suggestedVendorId: null,
            suggestedVendorName: null,
            feedbackComments: null,
            selectedFavorbaleSupplier: null,
            selectedLeavingSupplier: {},
            // conversionOpportunity: [],
            // terminatedVPNamesForMember: [],
            searchKey: "",
            filteredSearchResults: [...this.props.conversionOpportunity],
            isFilteredDataAvailable: true
        }
    }

    handleClose = () => this.setState({
        show: false, favorableVendorWrong: false,
        suggestedVendorId: null, suggestedVendorName: null
    })
    handleShow = () => this.setState({ show: true })

    handleCheeseChange = () => {
        if (this.state.favorableVendorWrong) {
            this.setState({ favorableVendorWrong: false, suggestedVendorId: null, suggestedVendorName: null, showSubmit: false })
        } else {
            this.setState({ favorableVendorWrong: true, showSubmit: false })
        }
    }

    handleSelectedSupplier1 = (value) => {
        let arr = [];

        let vendorId = '';
        let vendorName = '';

        if (value) {
          let valueee = value.map((option, index) => {
            arr.push(option.value);
            
          });
          let vendorId = arr[0].split(',')[0];
          let vendorName = arr[0].split(',')[1];
  
        }
        else {
            let vendorName = _findIndex(value,"Select Supplier");
            let vendorId = '';
          }

          this.setState({
            suggestedVendorId: vendorId,
            suggestedVendorName: vendorName,
            showSubmit: true
        })
    }


    handleSelectedSupplier = (e) => {
        // console.log(e.target.value.split('=')[0]);
        // console.log(e.target.value.split('=')[1]);

        if (e.value) {
            let vendorId = e.value.split(',')[0];
            let vendorName = e.value.split(',')[1];

            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: true
            })
        }
        else {
            let vendorName = _findIndex(e,"Select Supplier");
            let vendorId = '';
            this.setState({
                suggestedVendorId: vendorId,
                suggestedVendorName: vendorName,
                showSubmit: false
            })
          }


    }

    handleFeedbackComments = (e) => this.setState({ feedbackComments: e.target.value });

    onFeedbackSubmit = () => {
        let formData = {
            categoryId: this.state.productGroupId,
            categoryName: this.state.productGroupName,
            favorableVendorWrong: this.state.favorableVendorWrong,
            favorableVendorId: this.state.favorableSupplierId,
            favorableVendorName: this.state.favorableSupplierName,
            suggestedFavorableVendorId: this.state.suggestedVendorId,
            suggestedFavorableVendorName: this.state.suggestedVendorName,
            feedbackComments: this.state.feedbackComments
        }

        console.log('savedProductFeedbackBodyInConversionOpportunity', formData);

        saveProductFeedback(formData)
            .then((responseData) => {
                console.log('savedProductFeedbackInConversionOpportunity', responseData);
                this.handleClose();
                new Noty({
                    text: responseData.text,
                    layout: "topRight",
                    theme: "mint",
                    type: "success"
                }).show();
            })
            .catch((err) => console.log(err));
    }

    conversionOpportunityFeedbackModal = (terminatedVPNamesForMember) => {
        let { selectedFavorbaleSupplier, selectedLeavingSupplier } = this.state;
        return (
            <Modal show={this.state.show} onHide={() => this.handleClose()}>
                <Modal.Header closeButton>
                    Favorable Supplier Feedback for {selectedFavorbaleSupplier}
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Is <b>{selectedFavorbaleSupplier}</b> Favorable Supplier wrong for <b>{selectedLeavingSupplier.productGroupName}</b>?</p>
                        <Toggle
                            id='cheese-status5'
                            icons={false}
                            defaultChecked={this.state.favorableVendorWrong}
                            onChange={() => this.handleCheeseChange()}
                        />
                    </div>
                    {this.state.favorableVendorWrong && <Form.Group as={Col} controlId="formSelectVendor">
                    <Select
                            
                            value={this.state.selectOptions}
                            isDisabled={this.state.isLoading}
                            onChange={(e) => this.handleSelectedSupplier(e)}
                            placeholder={'Select Supplier'}
                            
                            options={Array.from(
                                terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                return {
                                  value: item.vendorId + ','+ item.vendorName,
                                  label: item.vendorName,
                                };
                                
                              })
                            )}
                          />
                     
                        {/* <Form.Control as="select" defaultValue="Select Category" onChange={(e) => this.handleSelectedSupplier(e)}>
                            <option>Select Supplier</option>
                            {terminatedVPNamesForMember && terminatedVPNamesForMember.map((item, index) => {
                                return (
                                    <option key={index} value={`${item.vendorId},${item.vendorName}`}>{item.vendorName}</option>
                                )
                            })}
                        </Form.Control> */}
                    </Form.Group>}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>Comments</label>
                        <textarea onChange={(e) => this.handleFeedbackComments(e)}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.state.showSubmit?"primary":"secondary"} disabled={!this.state.showSubmit} onClick={() => this.onFeedbackSubmit()}>
                        Submit Feedback
                    </Button>
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    searchTable = (e, data) => {
        this.setState({ searchKey: e.target.value });

        let results = data.filter((item) => {
            return ((item.leavingVendorName && item.leavingVendorName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) ||
                (item.businessGroupName && item.businessGroupName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) ||
                (item.productGroupName && item.productGroupName.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) ||
                (item.favorableVendor1Name && item.favorableVendor1Name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) ||
                (item.favorableVendor2Name && item.favorableVendor2Name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) ||
                (item.favorableVendor3Name && item.favorableVendor3Name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) ||
                (item.favorableVendor4Name && item.favorableVendor4Name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0))
        })

        this.setState({
            isFilteredDataAvailable: true,
            filteredSearchResults: results
        });
    }

    clearTable = () => this.setState({
        searchKey: "",
        isFilteredDataAvailable: true,
        filteredSearchResults: [...this.props.conversionOpportunity]
    });

    render() {
        let {
            // conversionOpportunity,
            searchKey,
            filteredSearchResults
        } = this.state;
        let {
            conversionOpportunity,
            terminatedVPNamesForMember
        } = this.props;

        return (
            <Card className="conversionOpportunityTableContainer">
                {this.conversionOpportunityFeedbackModal(terminatedVPNamesForMember)}
                <Card.Header>Conversion Opportunity</Card.Header>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <InputGroup>
                            <FormControl
                                placeholder="Search for conversion opportunity info.."
                                aria-label="Search for conversion opportunity info.."
                                aria-describedby="basic-addon2"
                                value={searchKey}
                                onChange={(e) => this.searchTable(e, conversionOpportunity)}
                            />
                            <InputGroup.Append>
                                <Button onClick={() => this.clearTable()}>Clear</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <ReactTable
                        data={this.state.isFilteredDataAvailable ? filteredSearchResults : this.props.conversionOpportunity}
                        // filterable
                        columns={[
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Targeted Supplier</b>,
                                accessor: "leavingVendorName",
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                // Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Business Group</b>,
                                accessor: 'businessGroupName',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: props => <span style={{ textTransform: 'capitalize',}}>{props.original.businessGroupName}</span>
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Product Group</b>,
                                accessor: 'productGroupName',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                // Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                            },
                            {
                                Header: <b>Purchases</b>,
                                accessor: 'purchases',
                                sortable: true,
                                resizable: true,
                                filterable: false,
                                style: { 'textAlign': 'right' },
                                // Cell: props => priceFormatter(props.original.purchases)
                                Cell: props =>priceFormatter(props.original.purchases)

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier </b>,
                                accessor: 'favorableVendor1Name',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },

                                Cell: props => props.original.favorableVendor1Name && (
                                   <React.Fragment>
                                    <span>{props.original.favorableVendor1Name}</span>
                                   <i className="fa fa-comment-alt" onClick={() => this.setState({
                                   productGroupId: props.original.productGroupId,
                                    productGroupName: props.original.productGroupName,
                                   favorableSupplierId: props.original.favorableVendor1Id,
                                    favorableSupplierName: props.original.favorableVendor1Name,
                                    selectedFavorbaleSupplier: props.original.favorableVendor1Name,
                                    selectedLeavingSupplier: props.original,
                                    show: true
                                    })} style={{ color: '#0D47A1', cursor: 'pointer' ,paddingLeft:'5px' }}></i>
                                   </React.Fragment>
                                )

                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier</b>,
                                accessor: 'favorableVendor2Name',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: props =>props.original.favorableVendor2Name && (
                                    <React.Fragment>
                                        <span>{props.original.favorableVendor2Name}</span>
                                        <i className="fa fa-comment-alt" onClick={() => this.setState({
                                            productGroupId: props.original.productGroupId,
                                            productGroupName: props.original.productGroupName,
                                            favorableSupplierId: props.original.favorableVendor2Id,
                                            favorableSupplierName: props.original.favorableVendor2Name,
                                            selectedFavorbaleSupplier: props.original.favorableVendor2Name,
                                            selectedLeavingSupplier: props.original,
                                            show: true
                                        })} style={{ color: '#0D47A1', cursor: 'pointer' ,paddingLeft:'5px' }}></i>
                                    </React.Fragment>
                                )
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier </b>,
                                accessor: 'favorableVendor3Name',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: props =>props.original.favorableVendor3Name &&  (
                                    <React.Fragment>
                                        <span>{props.original.favorableVendor3Name}</span>
                                        <i className="fa fa-comment-alt" onClick={() => this.setState({
                                            productGroupId: props.original.productGroupId,
                                            productGroupName: props.original.productGroupName,
                                            favorableSupplierId: props.original.favorableVendor3Id,
                                            favorableSupplierName: props.original.favorableVendor3Name,
                                            selectedFavorbaleSupplier: props.original.favorableVendor3Name,
                                            selectedLeavingSupplier: props.original,
                                            show: true
                                        })} style={{ color: '#0D47A1', cursor: 'pointer' ,paddingLeft:'5px'}}></i>
                                    </React.Fragment>
                                )
                            },
                            {
                                Header: <b style={{ 'whiteSpace': 'normal' }}>Favorable Supplier </b>,
                                accessor: 'favorableVendor4Name',
                                sortable: true,
                                resizable: true,
                                style: { 'whiteSpace': 'unset' },
                                Cell: props => props.original.favorableVendor4Name &&  (
                                    <React.Fragment>
                                       <span>{props.original.favorableVendor4Name}</span>
                                        <i className="fa fa-comment-alt" onClick={() => this.setState({
                                            productGroupId: props.original.productGroupId,
                                            productGroupName: props.original.productGroupName,
                                            favorableSupplierId: props.original.favorableVendor4Id,
                                            favorableSupplierName: props.original.favorableVendor4Name,
                                            selectedFavorbaleSupplier: props.original.favorableVendor4Name,
                                            selectedLeavingSupplier: props.original,
                                            show: true
                                        })} style={{ color: '#0D47A1', cursor: 'pointer' ,paddingLeft:'5px' }}></i>
                                    </React.Fragment>
                                )


                            }
                        ]}
                        defaultPageSize={25}
                        style={{ height: "400px" }}
                        className="-striped -highlight"
                    />
                </div>
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
            </Card>
        );
    }
}
