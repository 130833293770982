import React, { Component } from 'react';
import C3 from '../../supplier/C3/C3';
import { priceFormatter } from '../../../utils/formatters';

export default class TopTenProductGroups extends Component {
    productGroupName = []
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let { getCorporateConversionTopTenProductGroup } = this.props;
        var Purchases = ["Purchases"];
        var memberId = ["members"];
        this.productGroupName = [];
        if (getCorporateConversionTopTenProductGroup) {
            getCorporateConversionTopTenProductGroup.forEach((item) => {
                Purchases.push(item.purchases);
                this.productGroupName.push(item.productGroupName);
                memberId.push(item.memberId)
            })
        }

        const topTenProductGroupsChartData = {
            data: {
                x: 'x',
                columns: [
                    ['x', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    Purchases
                ],

                colors: {
                    "Purchases": '#1f77b4'
                },
                type: 'bar',
                bar: {
                    width: {
                        ratio: 0.2
                    },
                },
                labels: {
                    format: function (d) { return priceFormatter(d).props.children; }
                },
                legend: {
                    show: true
                },
            },
            axis: {
                x: {
                    type: 'category'
                },
                y: {
                    label: { text: 'Purchase Amount', position: 'outer-middle' },
                    tick: {
                        format: function (d) { return priceFormatter(d).props.children; }
                    }
                }
            },
            tooltip: {
                format: {
                    title: (d) => this.productGroupName[d],
                    value: (value, ratio, id) => priceFormatter(value).props.children
                }
            }
        };

        return (
            <C3
                heading={"Top 10 Product Groups"}
                data={topTenProductGroupsChartData.data}
                tooltip={topTenProductGroupsChartData.tooltip}
                axis={topTenProductGroupsChartData.axis}
            />
        )
    }
}
