// export const BASE_URL = 'http://localhost:3000/imark-electrical';
// export const BASE_URL = 'https://omni.tredence.com/api-electrical';
// export const BASE_URL = 'https://imark-electrical.tredence.com/imark-electrical';
//  export const BASE_URL = 'https://imark-electrical.tredence.com/ie-demo';
 export const BASE_URL = 'https://imark-electrical.tredence.com/ie-uat';

//  export const BASE_URL = 'https://imark-electrical.tredence.com/ie-demo';

export const getVisitedTabId = (hash) => {
    if (process.env.REACT_APP_API_ROOT_URL === BASE_URL) {
        switch (hash) {
            case '#/':
                return 1;
            case `#/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/overview`:
                return 1;
            case `#/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/verified`:
                return 2;
            case `#/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/invoices`:
                return 3;
            case `#/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/purchase-orders`:
                return 4;
            case `#/member/${localStorage.getItem('imarkElectricalMemberId') && localStorage.getItem('imarkElectricalMemberId')}/conversion`:
                return 5;
            case `#/supplier/${localStorage.getItem('imarkElectricalSupplierId') && localStorage.getItem('imarkElectricalSupplierId')}/overview`:
                return 1;
            case `#/supplier/${localStorage.getItem('imarkElectricalSupplierId') && localStorage.getItem('imarkElectricalSupplierId')}/conversion`:
                return 2;
            case `#/corporate/overview`:
                return 1;
            case `#/corporate/verified`:
                return 2;
            case `#/corporate/opportunity`:
                return 3;
            case `#/corporate/feedback`:
                return 4;
            case `#/corporate/conversion`:
                return 5;
            case `#/corporate/compliance`:
                return 6;
            default:
                return null;
        }
    } else {
        switch (hash) {
            case '#/':
                return 1;
            case '#/member/overview':
                return 1;
            case '#/member/verified':
                return 2;
            case '#/member/invoices':
                return 3;
            case '#/member/purchase-orders':
                return 4;
            case '#/member/conversion':
                return 5;
            case '#/supplier/overview':
                return 1;
            case '#/supplier/conversion':
                return 2;
            case '#/corporate/overview':
                return 1;
            case '#/corporate/verified':
                return 2;
            case '#/corporate/opportunity':
                return 3;
            case '#/corporate/feedback':
                return 4;
            case '#/corporate/conversion':
                return 5;
            case '#/corporate/compliance':
                return 6;
            default:
                return null;
        }
    }
}
